import {
  FlexBox,
  FlexBoxAlignItems,
  FlexBoxDirection,
  FlexBoxJustifyContent,
  Label,
  Link,
  ObjectStatus,
  Text,
  ValueState,
} from '@fioneer/ui5-webcomponents-react'
import get from 'lodash.get'
import isNil from 'lodash.isnil'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import AnalyticalTableCell from 'components/domains/business-events-and-tasks/decision-paper/tiles/annual-review-basel-two-confirmation/annual-review/shared/ui/tables/analytical/AnalyticalTableCell'
import { ErrorDataUnavailableInCell } from 'components/domains/business-events-and-tasks/decision-paper/tiles/risk-monitoring/businesspartner/shared/ErrorDataUnavailableInCell'
import {
  ENTITY_TYPES,
  ARREARS_HEADQUARTER_CURRENCY,
  NO_DEAL,
} from 'components/domains/business-events-and-tasks/decision-paper/tiles/risk-monitoring/businesspartner/shared/constants.js'
import styles from 'components/domains/business-events-and-tasks/decision-paper/tiles/risk-monitoring/businesspartner/v1/CurrentArrearsTableCellsV1.module.css'
import EntityCell from 'components/domains/business-events-and-tasks/decision-paper/tiles/risk-monitoring/shared/ui/tables/cells/EntityCell'

const SPACE = <span>&nbsp;</span>

const getFirstDefinedValueByAccessor = (rows, accessor, value = null) => {
  if (value) {
    return value
  }
  rows?.forEach((subRow) => {
    const valueOrNull = get(subRow, accessor)
    if (valueOrNull) {
      value = valueOrNull
    } else {
      value = getFirstDefinedValueByAccessor(subRow.subRows, accessor, value)
    }
  })
  return value
}

const isFinancialProduct = (entityType) =>
  entityType === ENTITY_TYPES.TRANCHE ||
  entityType === ENTITY_TYPES.DRAWING ||
  entityType === ENTITY_TYPES.NON_LOAN ||
  entityType === ENTITY_TYPES.BCA

const getNonLoanLink = (businessPartnerId, dealId, dealDisplayId) =>
  dealId === NO_DEAL
    ? `/business-partners/${businessPartnerId}/financial-product-overview`
    : `/deals/${dealDisplayId}/financing?dataSource=existingBusiness`

const getBankCustomerAccountLink = (businessPartnerId, dealId, dealDisplayId, entityId) =>
  dealId === NO_DEAL
    ? `/business-partners/${businessPartnerId}/financial-product-overview`
    : `/deals/${dealDisplayId}/financing/bank-customer-accounts/${entityId}`

export const LabelWithEntityIdLink = ({ label, entityId, entityLink }) => (
  <FlexBox className={styles.noMarginTopAndFullSize} alignItems={FlexBoxAlignItems.Center}>
    <Label showColon>{label}</Label>
    {SPACE}
    <Link href={entityLink} target={'_blank'}>
      {entityId}
    </Link>
  </FlexBox>
)
LabelWithEntityIdLink.propTypes = {
  label: PropTypes.string,
  entityId: PropTypes.string,
  entityLink: PropTypes.string,
}

const BoldLabel = ({ label }) => (
  <FlexBox className={styles.noMarginTopAndFullSize} alignItems={FlexBoxAlignItems.Center}>
    <Label style={{ fontWeight: 'bold' }}>{label}</Label>
  </FlexBox>
)
BoldLabel.propTypes = {
  label: PropTypes.string,
}

export const ArrearTypeCell = (
  <AnalyticalTableCell>
    {(
      _tableData,
      _tableHelpers,
      {
        cell: {
          row: { original: row },
        },
      },
    ) => {
      const { t } = useTranslation('decisionPaper', {
        keyPrefix: 'pages.business-partner.arrears.current-arrears.table.entity-types',
      })

      const businessPartnerId = row.businessPartnerId
      const dealId = row.dealId
      const dealDisplayId = row?.dealDisplayId
      const dealName = row?.dealName
      const entityId = row.entityId
      const secondaryEntityId = row.secondaryEntityId
      const entityType = row.entityType
      const productDealDisplayId = row.rowData?.product?.dealDisplayId

      switch (entityType) {
        case ENTITY_TYPES.DEAL:
          return entityId === NO_DEAL ? (
            <FlexBox
              className={styles.noMarginTopAndFullSize}
              alignItems={FlexBoxAlignItems.Center}
            >
              <BoldLabel label={t('no-deal')} />
            </FlexBox>
          ) : (
            <FlexBox
              className={styles.noMarginTopAndFullSize}
              alignItems={FlexBoxAlignItems.Center}
            >
              <EntityCell
                link={`/deals/${dealDisplayId}`}
                id={dealDisplayId}
                name={dealName}
                options={{ openInNewTab: true }}
              />
            </FlexBox>
          )
        case ENTITY_TYPES.TRANCHE:
          return (
            <LabelWithEntityIdLink
              label={t(entityType.toLowerCase())}
              entityLink={`/deals/${productDealDisplayId}/financing/tranches/${entityId}/details?dataSource=existingBusiness`}
              entityId={entityId}
            />
          )
        case ENTITY_TYPES.DRAWING:
          return (
            <LabelWithEntityIdLink
              label={t(entityType.toLowerCase())}
              entityLink={`/deals/${productDealDisplayId}/financing/drawdowns/${entityId}/details?dataSource=existingBusiness`}
              entityId={entityId}
            />
          )
        case ENTITY_TYPES.ARREAR:
          return (
            <FlexBox
              className={styles.noMarginTopAndFullSize}
              alignItems={FlexBoxAlignItems.Center}
            >
              {row.rowData.arrear.type.name}
            </FlexBox>
          )
        case ENTITY_TYPES.NON_LOAN:
          return (
            <LabelWithEntityIdLink
              label={t(entityType.toLowerCase().replaceAll('_', '-'))}
              entityLink={getNonLoanLink(businessPartnerId, dealId, productDealDisplayId)}
              entityId={secondaryEntityId ?? entityId}
            />
          )
        case ENTITY_TYPES.BCA:
          return (
            <LabelWithEntityIdLink
              label={t(entityType.toLowerCase().replaceAll('_', '-'))}
              entityLink={getBankCustomerAccountLink(
                businessPartnerId,
                dealId,
                productDealDisplayId,
                secondaryEntityId,
              )}
              entityId={secondaryEntityId}
            />
          )
      }
    }}
  </AnalyticalTableCell>
)

export const ArrearStatusCell = (
  <AnalyticalTableCell>
    {(
      _tableData,
      _tableHelpers,
      {
        cell: {
          row: { original: row },
        },
      },
    ) => {
      const { t } = useTranslation('decisionPaper', {
        keyPrefix: 'pages.business-partner.arrears.current-arrears.table.status',
      })
      const statusToTranslationKey = (status) => status.replaceAll('_', '-').toLowerCase()
      const statusToBadgeColor = {
        APPROVED: ValueState.Success,
        NOT_APPROVED: ValueState.Error,
        IN_APPROVAL: ValueState.Information,
        PARTIALLY_IN_APPROVAL: ValueState.Information,
        PARTIALLY_APPROVED: ValueState.Warning,
      }
      const arrearStatusList = row.rowData?.arrear?.approvalStatusList
      if (arrearStatusList?.length > 0) {
        return (
          <FlexBox
            className={styles.noMarginTopAndFullSize}
            direction={FlexBoxDirection.Column}
            justifyContent={FlexBoxJustifyContent.Center}
          >
            {arrearStatusList.map((arrearStatus, index) => (
              <ObjectStatus
                className={index ? styles.statusMarginTop : ''}
                key={arrearStatus}
                inverted
                state={statusToBadgeColor[arrearStatus]}
              >
                {t(statusToTranslationKey(arrearStatus))}
              </ObjectStatus>
            ))}
          </FlexBox>
        )
      }
    }}
  </AnalyticalTableCell>
)

export const ArrearApprovedUntilCell = (
  <AnalyticalTableCell alignRight>
    {(
      _tableData,
      { formatDate },
      {
        cell: {
          row: { original: row },
        },
      },
    ) => {
      const entityType = row.entityType
      if (isFinancialProduct(entityType)) {
        const approvedUntil = getFirstDefinedValueByAccessor(
          row.subRows,
          'rowData.arrearApprovalItem.approvedUntil',
        )
        return (
          <FlexBox
            className={styles.noMarginTopAndFullSize}
            direction={FlexBoxDirection.Column}
            justifyContent={FlexBoxJustifyContent.Center}
          >
            {approvedUntil && (
              <Text className={styles.textAlignRight}>{formatDate(approvedUntil)}</Text>
            )}
          </FlexBox>
        )
      }
    }}
  </AnalyticalTableCell>
)

export const ArrearClassificationCell = (
  <AnalyticalTableCell alignRight>
    {(
      _tableData,
      _tableHelpers,
      {
        cell: {
          row: { original: row },
        },
      },
    ) => {
      const entityType = row.entityType
      if (isFinancialProduct(entityType)) {
        const classification = getFirstDefinedValueByAccessor(
          row.subRows,
          'rowData.arrearApprovalItem.classification.name',
        )
        return (
          <FlexBox
            className={styles.noMarginTopAndFullSize}
            direction={FlexBoxDirection.Column}
            justifyContent={FlexBoxJustifyContent.Center}
          >
            {classification && <Text>{classification}</Text>}
          </FlexBox>
        )
      }
    }}
  </AnalyticalTableCell>
)

const MoneyCell = ({
  headquarterValue,
  originalValue,
  originalCurrency,
  formatCurrency,
  isSectionHeader,
}) => (
  <FlexBox direction={FlexBoxDirection.Column}>
    {!isNil(headquarterValue) && (
      <span
        className={
          isSectionHeader
            ? `${styles.textAlignRight} sapFontBoldFamily`
            : `${styles.textAlignRight}`
        }
      >
        {formatCurrency(headquarterValue, ARREARS_HEADQUARTER_CURRENCY)}
      </span>
    )}
    {!isNil(originalValue) &&
      !isNil(originalCurrency) &&
      originalCurrency !== ARREARS_HEADQUARTER_CURRENCY && (
        <span className={`${styles.textAlignRight} sapNeutralColor`}>
          {formatCurrency(originalValue, originalCurrency)}
        </span>
      )}
    {!isNil(originalValue) &&
      !isNil(originalCurrency) &&
      originalCurrency === ARREARS_HEADQUARTER_CURRENCY &&
      isNil(headquarterValue) && (
        <span className={`${styles.textAlignRight}`}>
          {formatCurrency(originalValue, originalCurrency)}
        </span>
      )}
  </FlexBox>
)
MoneyCell.propTypes = {
  headquarterValue: PropTypes.number,
  originalValue: PropTypes.number,
  originalCurrency: PropTypes.string,
  formatCurrency: PropTypes.func,
  isSectionHeader: PropTypes.bool,
  className: PropTypes.string,
}

const OwnShareMoneyCell = ({
  headquarterValue,
  originalValue,
  originalCurrency,
  formatCurrency,
  isSectionHeader,
}) => {
  const { t } = useTranslation('decisionPaper')
  return (
    <FlexBox
      className={styles.marginTopBetweenValues}
      direction={FlexBoxDirection.Row}
      justifyContent={FlexBoxJustifyContent.Center}
      alignItems={FlexBoxAlignItems.Center}
    >
      <div>
        <span
          className={
            isSectionHeader
              ? `${styles.textAlignRight} sapFontBoldFamily sapNeutralColor`
              : `${styles.textAlignRight} sapNeutralColor`
          }
        >
          {`${t(
            'pages.business-partner.arrears.current-arrears.table.columns.arrear-amount.own-share',
          )}:`}
        </span>
        <span>&nbsp;</span>
      </div>
      <MoneyCell
        isSectionHeader={isSectionHeader}
        headquarterValue={headquarterValue}
        originalValue={originalValue}
        originalCurrency={originalCurrency}
        formatCurrency={formatCurrency}
      />
    </FlexBox>
  )
}
OwnShareMoneyCell.propTypes = {
  headquarterValue: PropTypes.number,
  originalValue: PropTypes.number,
  originalCurrency: PropTypes.string,
  formatCurrency: PropTypes.func,
  isSectionHeader: PropTypes.bool,
  className: PropTypes.string,
}

const renderValueOrSumMoneyCell = ({
  isSectionHeader,
  isErrorBcaAmounts,
  formatCurrency,
  shouldRenderValues,
  values,
  sums,
}) => {
  const hasOwnShare = !isNil(values.ownShareValue) || !isNil(values.headquarterOwnShareValue)
  if (isErrorBcaAmounts) {
    return <ErrorDataUnavailableInCell />
  }
  return shouldRenderValues ? (
    <FlexBox
      className={styles.noMarginTopAndFullSize}
      justifyContent={FlexBoxJustifyContent.Center}
      alignItems={FlexBoxAlignItems.End}
      direction={FlexBoxDirection.Column}
    >
      <MoneyCell
        isSectionHeader={false}
        headquarterValue={values.headquarterValue}
        originalValue={values.originalValue}
        originalCurrency={values.originalCurrency}
        formatCurrency={formatCurrency}
      />
      {hasOwnShare && (
        <OwnShareMoneyCell
          isSectionHeader={false}
          headquarterValue={values.headquarterOwnShareValue}
          originalValue={values.ownShareValue}
          originalCurrency={values.originalCurrency}
          formatCurrency={formatCurrency}
        />
      )}
    </FlexBox>
  ) : (
    <FlexBox
      className={styles.noMarginTopAndFullSize}
      justifyContent={FlexBoxJustifyContent.Center}
      alignItems={FlexBoxAlignItems.End}
      direction={FlexBoxDirection.Column}
    >
      <MoneyCell
        isSectionHeader={isSectionHeader}
        headquarterValue={sums.headquarterValue}
        originalValue={sums.originalValue}
        originalCurrency={sums.originalCurrency}
        formatCurrency={formatCurrency}
      />
      {sums.hasOwnShareSum && (
        <OwnShareMoneyCell
          isSectionHeader={isSectionHeader}
          headquarterValue={sums.headquarterOwnShareValue}
          originalValue={sums.ownShareValue}
          originalCurrency={sums.originalCurrency}
          formatCurrency={formatCurrency}
        />
      )}
    </FlexBox>
  )
}

export const ArrearAmountCell = (
  <AnalyticalTableCell alignRight>
    {(
      _tableData,
      { formatCurrency },
      {
        cell: {
          row: { original: row },
        },
      },
    ) =>
      renderValueOrSumMoneyCell({
        isSectionHeader: row.isSectionHeader,
        formatCurrency,
        shouldRenderValues: row.entityType === ENTITY_TYPES.ARREAR,
        values: {
          headquarterValue: row.rowData.arrear?.headquarterArrearAmount,
          originalValue: row.rowData.arrear?.arrearAmount,
          originalCurrency: row.rowData.arrear?.currency,
          ownShareValue: row.rowData.arrear?.ownShare,
          headquarterOwnShareValue: row.rowData.arrear?.headquarterOwnShare,
        },
        sums: {
          headquarterValue: row.sumData.arrear?.arrearAmount?.headquarterSum,
          originalValue: row.sumData.arrear?.arrearAmount?.originalSum,
          originalCurrency: row.sumData.arrear?.arrearAmount?.originalCurrency,
          ownShareValue: row.sumData.arrear?.arrearAmountOwnShare?.originalSum,
          headquarterOwnShareValue: row.sumData.arrear?.arrearAmountOwnShare?.headquarterSum,
          hasOwnShareSum: row.sumData.arrear?.arrearAmountOwnShare?.hasOwnShareSum,
        },
      })
    }
  </AnalyticalTableCell>
)

export const ArrearOverpaymentCell = (
  <AnalyticalTableCell alignRight>
    {(
      _tableData,
      { formatCurrency },
      {
        cell: {
          row: { original: row },
        },
      },
    ) =>
      // We do not want to show own share values for overpayments.
      renderValueOrSumMoneyCell({
        isSectionHeader: row.isSectionHeader,
        formatCurrency,
        shouldRenderValues: row.entityType === ENTITY_TYPES.ARREAR,
        values: {
          headquarterValue: row.rowData.arrear?.headquarterOverpaymentAmount,
          originalValue: row.rowData.arrear?.overpaymentAmount,
          originalCurrency: row.rowData.arrear?.currency,
        },
        sums: {
          headquarterValue: row.sumData.arrear?.overpaymentAmount?.headquarterSum,
          originalValue: row.sumData.arrear?.overpaymentAmount?.originalSum,
          originalCurrency: row.sumData.arrear?.overpaymentAmount?.originalCurrency,
        },
      })
    }
  </AnalyticalTableCell>
)

export const ArrearApprovedAmountCell = (
  <AnalyticalTableCell alignRight>
    {(
      _tableData,
      { formatCurrency },
      {
        cell: {
          row: { original: row },
        },
      },
    ) =>
      renderValueOrSumMoneyCell({
        isSectionHeader: row.isSectionHeader,
        formatCurrency,
        shouldRenderValues: row.entityType === ENTITY_TYPES.ARREAR,
        values: {
          headquarterValue: row.rowData.arrearApprovalItem?.approvalAmount,
          originalValue: row.rowData.arrearApprovalItem?.foreignApprovalAmount,
          originalCurrency: row.rowData.arrearApprovalItem?.foreignCurrency,
        },
        sums: {
          headquarterValue: row.sumData.arrearApprovalItem?.approvalAmount?.headquarterSum,
          originalValue: row.sumData.arrearApprovalItem?.approvalAmount?.originalSum,
          originalCurrency: row.sumData.arrearApprovalItem?.approvalAmount?.originalCurrency,
        },
      })
    }
  </AnalyticalTableCell>
)

export const ArrearBookingDateCell = (
  <AnalyticalTableCell alignRight>
    {(
      _tableData,
      { formatDate },
      {
        cell: {
          row: { original: row },
        },
      },
    ) => {
      const bookingDate = row.rowData?.arrear?.bookingDate
      return (
        <FlexBox
          className={styles.noMarginTopAndFullSize}
          direction={FlexBoxDirection.Column}
          justifyContent={FlexBoxJustifyContent.Center}
        >
          {bookingDate && <Text className={styles.textAlignRight}>{formatDate(bookingDate)}</Text>}
        </FlexBox>
      )
    }}
  </AnalyticalTableCell>
)

export const ArrearDaysOverdue = (
  <AnalyticalTableCell alignRight>
    {(
      _tableData,
      { formatDays },
      {
        cell: {
          row: { original: row },
        },
      },
    ) => {
      const daysOverdue = row.rowData?.arrear?.daysOverdue
      return (
        <FlexBox
          className={styles.noMarginTopAndFullSize}
          direction={FlexBoxDirection.Column}
          justifyContent={FlexBoxJustifyContent.Center}
        >
          {!isNil(daysOverdue) && (
            <Text className={styles.textAlignRight}>{formatDays(daysOverdue)}</Text>
          )}
        </FlexBox>
      )
    }}
  </AnalyticalTableCell>
)

export const ArrearCurrentClosedCommitmentCell = (
  <AnalyticalTableCell alignRight>
    {(
      _tableData,
      { formatCurrency },
      {
        cell: {
          row: { original: row },
        },
      },
    ) =>
      renderValueOrSumMoneyCell({
        isSectionHeader: row.isSectionHeader,
        isErrorBcaAmounts: row.rowData.product?.isErrorBcaAmounts,
        formatCurrency,
        shouldRenderValues: isFinancialProduct(row.entityType),
        values: {
          headquarterValue: row.rowData.product?.headquarterCurrentClosedCommitment,
          originalValue: row.rowData.product?.currentClosedCommitment,
          originalCurrency: row.rowData.product?.currentClosedCommitmentCurrency,
          ownShareValue: row.rowData.product?.currentClosedCommitmentOwnShare,
          headquarterOwnShareValue: row.rowData.product?.headquarterCurrentClosedCommitmentOwnShare,
        },
        sums: {
          headquarterValue: row.sumData.product?.currentClosedCommitment?.headquarterSum,
          originalValue: row.sumData.product?.currentClosedCommitment?.originalSum,
          originalCurrency: row.sumData.product?.currentClosedCommitment?.originalCurrency,
          ownShareValue: row.sumData.product?.currentClosedCommitmentOwnShare?.originalSum,
          headquarterOwnShareValue:
            row.sumData.product?.currentClosedCommitmentOwnShare?.headquarterSum,
          hasOwnShareSum: row.sumData.product?.currentClosedCommitmentOwnShare?.hasOwnShareSum,
        },
      })
    }
  </AnalyticalTableCell>
)

export const ArrearOutstandingCell = (
  <AnalyticalTableCell alignRight>
    {(
      _tableData,
      { formatCurrency },
      {
        cell: {
          row: { original: row },
        },
      },
    ) =>
      row.entityType !== ENTITY_TYPES.NON_LOAN && // We do not want to display anything for non loans here
      renderValueOrSumMoneyCell({
        isSectionHeader: row.isSectionHeader,
        isErrorBcaAmounts: row.rowData.product?.isErrorBcaAmounts,
        formatCurrency,
        shouldRenderValues: isFinancialProduct(row.entityType),
        values: {
          headquarterValue: row.rowData.product?.headquarterOutstandingOwnShare,
          originalValue: row.rowData.product?.outstandingOwnShare,
          originalCurrency: row.rowData.product?.ownShareCurrency,
        },
        sums: {
          headquarterValue: row.sumData.product?.outstandingOwnShare?.headquarterSum,
          originalValue: row.sumData.product?.outstandingOwnShare?.originalSum,
          originalCurrency: row.sumData.product?.outstandingOwnShare?.originalCurrency,
        },
      })
    }
  </AnalyticalTableCell>
)

export const ArrearAvailableCell = (
  <AnalyticalTableCell alignRight>
    {(
      _tableData,
      { formatCurrency },
      {
        cell: {
          row: { original: row },
        },
      },
    ) =>
      row.entityType !== ENTITY_TYPES.NON_LOAN && // We do not want to display anything for non loans here
      renderValueOrSumMoneyCell({
        isSectionHeader: row.isSectionHeader,
        isErrorBcaAmounts: row.rowData.product?.isErrorBcaAmounts,
        formatCurrency,
        shouldRenderValues: isFinancialProduct(row.entityType),
        values: {
          headquarterValue: row.rowData.product?.headquarterAvailableOwnShare,
          originalValue: row.rowData.product?.availableOwnShare,
          originalCurrency: row.rowData.product?.ownShareCurrency,
        },
        sums: {
          headquarterValue: row.sumData.product?.availableOwnShare?.headquarterSum,
          originalValue: row.sumData.product?.availableOwnShare?.originalSum,
          originalCurrency: row.sumData.product?.availableOwnShare?.originalCurrency,
        },
      })
    }
  </AnalyticalTableCell>
)
