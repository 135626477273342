import PropTypes from 'prop-types'
import { useMemo } from 'react'
import { useSelector } from 'react-redux'
import { TILE_VERSION } from 'components/domains/business-events-and-tasks/decision-paper/tiles/constant'
import CurrentArrearsTable from 'components/domains/business-events-and-tasks/decision-paper/tiles/risk-monitoring/businesspartner/shared/CurrentArrearsTable'
import useCurrentArrearsTableColumnsV1 from 'components/domains/business-events-and-tasks/decision-paper/tiles/risk-monitoring/businesspartner/v1/useCurrentArrearsTableColumnsV1'
import useCurrentArrearsTableColumnsV2 from 'components/domains/business-events-and-tasks/decision-paper/tiles/risk-monitoring/businesspartner/v2/useCurrentArrearsTableColumnsV2'

const CurrentArrearsTableTile = ({ tileId, isPdfView }) => {
  const { version } = useSelector((state) => state.decisionPaper.tilesOverview.tiles[tileId])

  const tableColumnsV1 = useCurrentArrearsTableColumnsV1()
  const tableColumnsV2 = useCurrentArrearsTableColumnsV2()

  return useMemo(() => {
    switch (version) {
      case TILE_VERSION.V1:
        return (
          <CurrentArrearsTable
            tileId={tileId}
            isPdfView={isPdfView}
            tableColumns={tableColumnsV1}
          />
        )
      default:
        return (
          <CurrentArrearsTable
            tileId={tileId}
            isPdfView={isPdfView}
            tableColumns={tableColumnsV2}
          />
        )
    }
  }, [isPdfView, tableColumnsV1, tableColumnsV2, tileId, version])
}

CurrentArrearsTableTile.propTypes = {
  tileId: PropTypes.string.isRequired,
  isPdfView: PropTypes.bool.isRequired,
}

export default CurrentArrearsTableTile
