import {
  Button,
  ButtonDesign,
  FlexBox,
  FlexBoxAlignItems,
  FlexBoxDirection,
  FlexBoxJustifyContent,
  MultiInput,
  Token,
} from '@fioneer/ui5-webcomponents-react'
import PropTypes from 'prop-types'
import { useCallback, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import ModularTitle from 'components/domains/properties/modular-table/ModularTableTitle'
import ModularToolbar from 'components/domains/properties/modular-table/toolbar/ModularToolbar'
import styles from 'components/domains/properties/properties-search/dialog/PropertiesSearchDialog.module.css'
import PropertiesSearchTable from 'components/domains/properties/properties-search/dialog/PropertiesSearchTable'
import Dialog, { DialogPrimaryButton } from 'components/ui/dialog/Dialog'

const emptyArray = []

const PropertiesSearchDialog = ({
  isOpen,
  setIsOpen,
  onAccept,
  allowMultiSelect = true,
  initiallySelectedProperties,
  addButtonText,
  initialFilterCriteria,
  hiddenFilters = [],
  propertyIdsToHide = [],
}) => {
  const { t } = useTranslation('translation')

  const [selectedProperties, setSelectedProperties] = useState(initiallySelectedProperties ?? [])

  const multiInputContent = useMemo(
    () => (
      <>
        {selectedProperties.map(({ uuid, id, description }) => (
          <Token key={uuid} data-uuid={uuid} text={`${description} (${id})`} />
        ))}
      </>
    ),
    [selectedProperties],
  )

  const onTokenDelete = useCallback(
    (event) => {
      const propertyUuid = event.detail.token.dataset.uuid
      setSelectedProperties([...selectedProperties.filter(({ uuid }) => uuid !== propertyUuid)])
    },
    [selectedProperties],
  )

  const onClose = useCallback(() => {
    setIsOpen(false)
  }, [setIsOpen])

  const submitAddition = useCallback(() => {
    onAccept(selectedProperties)
    onClose()
  }, [onAccept, onClose, selectedProperties])

  const handleResetProperties = useCallback(() => {
    setSelectedProperties(emptyArray)
  }, [])

  const addButton = useMemo(
    () => (
      <DialogPrimaryButton
        id="submit-add-btn"
        onClick={submitAddition}
        disabled={!selectedProperties.length}
      >
        {addButtonText ?? t('buttons.add')}
      </DialogPrimaryButton>
    ),
    [selectedProperties.length, submitAddition, t, addButtonText],
  )

  return (
    <Dialog
      open={isOpen}
      onAfterClose={onClose}
      className={styles.dialog}
      headerText={t('components.property-search-dialog.title')}
      primaryButton={addButton}
    >
      <PropertiesSearchTable
        allowMultiSelect={allowMultiSelect}
        selectedProperties={selectedProperties}
        onSelectionChange={setSelectedProperties}
        initialFilterCriteria={initialFilterCriteria}
        hiddenFilters={hiddenFilters}
        propertyIdsToHide={propertyIdsToHide}
        startSearchOnNonEmptyFilterCriteria
      />
      <ModularToolbar>
        {allowMultiSelect ? (
          <ModularTitle
            title={t('components.property-search-dialog.selected-properties')}
            nrOfEntries={selectedProperties.length}
          />
        ) : (
          <></>
        )}
      </ModularToolbar>
      {allowMultiSelect ? (
        <FlexBox
          direction={FlexBoxDirection.Row}
          alignItems={FlexBoxAlignItems.Start}
          justifyContent={FlexBoxJustifyContent.SpaceBetween}
        >
          <MultiInput
            className={styles.multiInput}
            onTokenDelete={onTokenDelete}
            tokens={multiInputContent}
          />
          <Button
            icon="sap-icon://decline"
            id="clear-selection-button"
            design={ButtonDesign.Transparent}
            onClick={handleResetProperties}
          />
        </FlexBox>
      ) : (
        <></>
      )}
    </Dialog>
  )
}

PropertiesSearchDialog.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  setIsOpen: PropTypes.func.isRequired,
  onAccept: PropTypes.func.isRequired,
  allowMultiSelect: PropTypes.bool,
  initiallySelectedProperties: PropTypes.arrayOf(PropTypes.object),
  addButtonText: PropTypes.string,
  initialFilterCriteria: PropTypes.shape({
    propertyName: PropTypes.string.isRequired,
    propertyId: PropTypes.string.isRequired,
    country: PropTypes.string.isRequired,
    city: PropTypes.string.isRequired,
    dealId: PropTypes.string.isRequired,
    zipCode: PropTypes.string.isRequired,
    marketId: PropTypes.string.isRequired,
  }),
  hiddenFilters: PropTypes.arrayOf(PropTypes.string),
  propertyIdsToHide: PropTypes.arrayOf(PropTypes.string),
}

export default PropertiesSearchDialog
