import { Button, ButtonDesign, Modals } from '@fioneer/ui5-webcomponents-react'
import { useQueryClient } from '@tanstack/react-query'
import isEmpty from 'lodash.isempty'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import { eventStatus } from 'api/events/status'
import useShowErrorMessageBox from 'components/domains/deals/message/useShowErrorMessageBox'
import useUpdateCovenantCheckLinkedEvent from 'hooks/services/deals/covenants/checks/useUpdateCovenantCheckLinkedEvent'

const CreateMonitoringEventButton = ({
  covenantCheckUuid,
  covenantCheckStatus,
  linkedEvent = {},
}) => {
  const { t: tNoPrefix } = useTranslation('translation')
  const { t } = useTranslation('translation', {
    keyPrefix: 'components.deals.covenants.monitoring-event-card',
  })

  const showErrorMessageBox = useShowErrorMessageBox()
  const queryClient = useQueryClient()
  const showToast = Modals.useShowToast()

  const onError = async (error) => {
    const errorResponse = await error.response.json()
    showErrorMessageBox({
      message: tNoPrefix('components.cards.save-error'),
      error: errorResponse,
    })
  }
  const onSuccess = () => {
    showToast({ children: t('create-event-success') }, document.body)
    queryClient.invalidateQueries(['covenants', 'covenant-checks', covenantCheckUuid])
  }
  const updateLinkedEvent = useUpdateCovenantCheckLinkedEvent({ onSuccess, onError })

  const performUpdate = () => updateLinkedEvent.mutate({ covenantCheckUuid })

  const showButton =
    (isEmpty(linkedEvent) ||
      linkedEvent.status === eventStatus.completed ||
      linkedEvent.status === eventStatus.aborted) &&
    covenantCheckStatus !== 'CLOSED'

  return showButton ? (
    <Button design={ButtonDesign.Transparent} onClick={performUpdate}>
      {t('create-monitoring-event')}
    </Button>
  ) : null
}

CreateMonitoringEventButton.propTypes = {
  covenantCheckUuid: PropTypes.string.isRequired,
  covenantCheckStatus: PropTypes.string,
  linkedEvent: PropTypes.shape({
    status: PropTypes.string,
  }),
}

export default CreateMonitoringEventButton
