import { Text } from '@fioneer/ui5-webcomponents-react'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import {
  CartesianGrid,
  Label,
  Legend,
  Line,
  LineChart,
  ReferenceLine,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from 'recharts'
import styles from 'components/domains/deals/deal-cashflow/result-charts/chart-components/DealCashflowLineChart.module.css'
import KpiTimeSeriesTooltip from 'components/domains/kpis/chart/KpiTimeSeriesTooltip'
import sharedChartStyles from 'components/ui/charts/shared-chart-styles.module.css'
import {
  useNumberFormatter,
  usePercentageFormatter,
  useShortDateFormatter,
} from 'hooks/i18n/useI18n'

const THRESHOlD_COLOR = '#000000'
const AXIS_COLOR = '#9B9B9B'
const VALUES_COLOR = '#0000FF'
const ADJUSTED_VALUES_COLOR = '#FF8800'

const KpiTimeSeriesChart = ({ kpiTimeSeries = [], thresholds = [], unit, detailed = false }) => {
  const { t } = useTranslation('translation', { keyPrefix: '' })

  const formatPercentage = usePercentageFormatter()
  const formatNumber = useNumberFormatter({ notation: 'compact' })
  const { format: formatDate } = useShortDateFormatter()

  const xTickFormatter = (value) => formatDate(new Date(value).toISOString())

  const yTickFormatter =
    unit?.type === 'PERCENT' ? (val) => formatPercentage(val / 100) : formatNumber

  const domainExtension = 1.25
  const yAxisDomain = [0, (dataMax) => dataMax * domainExtension]

  return (
    <ResponsiveContainer width="100%" aspect={2.2} maxHeight={400}>
      <LineChart
        data={kpiTimeSeries}
        margin={{ bottom: 28 }}
        className={`${sharedChartStyles.cwpChart}`}
      >
        <CartesianGrid vertical={false} />
        <YAxis
          axisLine={false}
          tickLine={false}
          tickFormatter={yTickFormatter}
          domain={yAxisDomain}
          scale="linear"
          interval="preserveStartEnd"
          type="number"
        />
        <XAxis
          dataKey="keyDate"
          tickLine={false}
          domain={['dataMin', 'dataMax']}
          tickFormatter={xTickFormatter}
          padding={{
            left: 20,
            right: 20,
          }}
          interval="preserveStartEnd"
          axisLine={{ stroke: AXIS_COLOR }}
          angle={-45}
          minTickGap={60}
          textAnchor="end"
        />

        <Tooltip content={<KpiTimeSeriesTooltip unit={unit} detailed={detailed} />} />
        <Legend
          wrapperStyle={{ marginBottom: '-40px' }}
          payload={[
            {
              value: (
                <Text className={styles.legendText}>
                  {t('components.kpis.chart.calculated-values')}
                </Text>
              ),
              type: 'plainline',
              color: VALUES_COLOR,
              payload: {},
            },
            {
              value: (
                <Text className={styles.legendText}>
                  {t('components.kpis.chart.adjusted-values')}
                </Text>
              ),
              type: 'plainline',
              color: ADJUSTED_VALUES_COLOR,
              payload: {},
            },
            {
              value: (
                <Text className={styles.legendText}>{t('components.kpis.chart.thresholds')}</Text>
              ),
              type: 'line',
              color: THRESHOlD_COLOR,
              payload: {},
            },
          ]}
        />
        {thresholds.map(({ value, name }) => (
          <ReferenceLine
            ifOverflow={'extendDomain'}
            key={name}
            y={value}
            position={'start'}
            stroke="black"
            strokeWidth={2}
            strokeDasharray="3 3"
          >
            <Label position={'insideBottomLeft'}>{name}</Label>
          </ReferenceLine>
        ))}
        {detailed ? (
          <>
            <Line type="stepAfter" dataKey="calculatedValue" stroke={VALUES_COLOR} dot={false} />
            <Line
              type="stepAfter"
              dataKey="directAdjustedValue"
              stroke={ADJUSTED_VALUES_COLOR}
              dot={false}
            />
          </>
        ) : (
          <>
            <Line type="stepAfter" dataKey="value" stroke={VALUES_COLOR} dot={false} />
            <Line
              type="stepAfter"
              dataKey="overallAdjustedValue"
              stroke={ADJUSTED_VALUES_COLOR}
              dot={false}
            />
          </>
        )}
      </LineChart>
    </ResponsiveContainer>
  )
}

KpiTimeSeriesChart.propTypes = {
  kpiTimeSeries: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.number,
      keyDate: PropTypes.string,
      updatedBy: PropTypes.string,
    }),
  ).isRequired,
  unit: PropTypes.shape({
    type: PropTypes.string,
    code: PropTypes.string,
  }).isRequired,
  thresholds: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.number,
      name: PropTypes.string,
    }),
  ).isRequired,
  detailed: PropTypes.bool,
}

export default KpiTimeSeriesChart
