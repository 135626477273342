import camelize from 'camelize'
import isEmpty from 'lodash.isempty'
import { useRequest } from 'hooks/services/baseService'

const useGetRequirements = ({ conditionId, options = {} }) => {
  const result = useRequest({
    path: `/conditions/external/${conditionId}/requirements2`,
    keys: ['conditions', 'external', conditionId, 'requirements2'],
    options: { ...options, enabled: !isEmpty(conditionId) },
  })

  return camelize(result)
}

export default useGetRequirements
