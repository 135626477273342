import {
  FlexBox,
  FlexBoxDirection,
  ObjectPageSubSection,
  ResponsiveGridLayout,
} from '@fioneer/ui5-webcomponents-react'
import { t } from 'i18next'
import styles from 'components/domains/properties/rent-roll/overview/PropertyRentRollOverview.module.css'
import PropertyRentRollOverviewCurrentRentCard from 'components/domains/properties/rent-roll/overview/current-rent/PropertyRentRollOverviewCurrentRentCard'
import PropertyRentRollOverviewExpiringLeasesCard from 'components/domains/properties/rent-roll/overview/expiring-leases/PropertyRentRollOverviewExpiringLeasesCard'
import PropertyRentRollOverviewOccupancyCard from 'components/domains/properties/rent-roll/overview/occupancy/PropertyRentRollOverviewOccupancyCard'
import PropertyRentRollOverviewRentalIncomeOverTimeCard from 'components/domains/properties/rent-roll/overview/rental-income-over-time/PropertyRentRollOverviewRentalIncomeOverTimeCard'
import PropertyRentRollOverviewAreaDetailsCard from 'components/domains/properties/rent-roll/overview/structure-overview/area-details/PropertyRentRollOverviewAreaDetailsCard'
import PropertyRentRollOverviewRentalIncomeCard from 'components/domains/properties/rent-roll/overview/structure-overview/rental-income/PropertyRentRollOverviewRentalIncomeCard'
import PropertyRentRollOverviewVacancyOverTimeCard from 'components/domains/properties/rent-roll/overview/vacancy-over-time/PropertyRentRollOverviewVacancyOverTimeCard'
import PropertyRentRollOverviewWaultCard from 'components/domains/properties/rent-roll/overview/wault/PropertyRentRollOverviewWaultCard'

// HINT: Using a function instead of a component means the dropdown in the tab bar is rendered correctly
const renderPropertyRentRollOverview = () => [
  <ObjectPageSubSection
    titleText={t('pages.property.rent-roll.overview.rental-income-overview')}
    id="rental-income-overview"
    key="rental-income-overview"
  >
    <ResponsiveGridLayout
      className={styles.grid}
      columnsXL={8}
      columnsL={8}
      columnsM={8}
      columnsS={8}
      columnGap="10px"
      rowGap="10px"
    >
      <FlexBox className={styles.gridColumnSpan5} direction={FlexBoxDirection.Column}>
        <PropertyRentRollOverviewRentalIncomeOverTimeCard />
      </FlexBox>
      <FlexBox className={styles.gridColumnSpan3} direction={FlexBoxDirection.Column}>
        <PropertyRentRollOverviewCurrentRentCard />
      </FlexBox>
    </ResponsiveGridLayout>
  </ObjectPageSubSection>,

  <ObjectPageSubSection
    titleText={t('pages.property.rent-roll.overview.occupancy-overview')}
    id="occupancy-overview"
    key="occupancy-overview"
  >
    <ResponsiveGridLayout
      className={styles.grid}
      columnsXL={8}
      columnsL={8}
      columnsM={8}
      columnsS={8}
      columnGap="10px"
      rowGap="10px"
    >
      <FlexBox className={styles.gridColumnSpan5} direction={FlexBoxDirection.Column}>
        <PropertyRentRollOverviewVacancyOverTimeCard />
      </FlexBox>
      <FlexBox className={styles.gridColumnSpan3} direction={FlexBoxDirection.Column}>
        <PropertyRentRollOverviewOccupancyCard />
      </FlexBox>
    </ResponsiveGridLayout>
  </ObjectPageSubSection>,

  <ObjectPageSubSection
    titleText={t('pages.property.rent-roll.overview.lease-expiry-overview')}
    id="lease-expiry-overview"
    key="lease-expiry-overview"
  >
    <ResponsiveGridLayout
      className={styles.grid}
      columnsXL={8}
      columnsL={8}
      columnsM={8}
      columnsS={8}
      columnGap="10px"
      rowGap="10px"
    >
      <FlexBox className={styles.gridColumnSpan4} direction={FlexBoxDirection.Column}>
        <PropertyRentRollOverviewExpiringLeasesCard />
      </FlexBox>
      <FlexBox className={styles.gridColumnSpan4} direction={FlexBoxDirection.Column}>
        <PropertyRentRollOverviewWaultCard />
      </FlexBox>
    </ResponsiveGridLayout>
  </ObjectPageSubSection>,

  <ObjectPageSubSection
    titleText={t('pages.property.rent-roll.overview.rental-income-structure-overview')}
    id="rental-income-structure-overview"
    key="rental-income-structure-overview"
  >
    <ResponsiveGridLayout
      className={styles.grid}
      columnsXL={8}
      columnsL={8}
      columnsM={8}
      columnsS={8}
      columnGap="10px"
      rowGap="10px"
    >
      <FlexBox direction={FlexBoxDirection.Column} className={styles.gridColumnSpan8}>
        <PropertyRentRollOverviewAreaDetailsCard />
      </FlexBox>
      <FlexBox direction={FlexBoxDirection.Column} className={styles.gridColumnSpan8}>
        <PropertyRentRollOverviewRentalIncomeCard />
      </FlexBox>
    </ResponsiveGridLayout>
  </ObjectPageSubSection>,
]
export default renderPropertyRentRollOverview
