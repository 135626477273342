import PropTypes from 'prop-types'
import { useCallback } from 'react'
import MultipleDealSelectionPdfViewWrapper from 'components/domains/business-events-and-tasks/decision-paper/tiles/annual-review-basel-two-confirmation/annual-review/deal-overview/wrapper/MultipleDealSelectionPdfViewWrapper'
import { availableAutomaticTiles } from 'components/domains/business-events-and-tasks/decision-paper/tiles/automatic/availableTiles'
import RiskMonitoringDealSpecificsValuationHistory from 'components/domains/business-events-and-tasks/decision-paper/tiles/risk-monitoring/deal-specifics/RiskMonitoringDealSpecificsValuationHistory'

const RiskMonitoringDealSpecificsValuationHistorySelectionWrapper = ({ tileId, isPdfView }) => {
  const contentCreator = useCallback(
    ({ selectedDealIndex }) => (
      <RiskMonitoringDealSpecificsValuationHistory
        tileId={tileId}
        selectedDealIndex={selectedDealIndex}
        isPdfView={isPdfView}
      />
    ),
    [isPdfView, tileId],
  )
  return (
    <MultipleDealSelectionPdfViewWrapper
      tileId={tileId}
      contentCreator={contentCreator}
      isPdfView={isPdfView}
      dealSelectionTileCode={
        availableAutomaticTiles.monitoredRiskIndicatorDealSpecificsDealSelection
      }
    />
  )
}

RiskMonitoringDealSpecificsValuationHistorySelectionWrapper.propTypes = {
  tileId: PropTypes.string,
  isPdfView: PropTypes.bool,
}

export default RiskMonitoringDealSpecificsValuationHistorySelectionWrapper
