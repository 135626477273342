import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import styles from 'components/domains/properties/rent-roll/details/PropertyRentRollDetailsTile.module.css'
import StaffMemberByUserId from 'components/ui/staff-member/StaffMemberByUserId'
import { useShortDateFormatter } from 'hooks/i18n/useI18n'

const PropertyRentRollDetailsTile = ({ rentRoll }) => {
  const { t } = useTranslation()
  const { format: formatDateAndTime } = useShortDateFormatter({
    hour: '2-digit',
    minute: '2-digit',
  })

  const { creation_user_account_id: creator, creation_date_time: creationTime } =
    rentRoll?.system_administrative_data ?? {}

  const getCreator = () =>
    (creator && <StaffMemberByUserId s4UserId={creator} />) ||
    `<${t('pages.property.rent-roll.loading-error')}>`
  const getCreationTime = () =>
    formatDateAndTime(creationTime) ?? `<${t('pages.property.rent-roll.loading-error')}>`

  const getJoinedClassName = (classNames) =>
    classNames.map((className) => styles[className]).join(' ')

  return (
    <div className={styles['rent-roll-tile']}>
      <div
        className={getJoinedClassName([
          'grid-area-label-creator',
          'rent-roll-label',
          'rent-roll-tile-row-first',
        ])}
      >
        {t('pages.properties.rent-roll.creator')}
      </div>
      <div
        className={getJoinedClassName([
          'grid-area-creator',
          'rent-roll-tile-row-first',
          'rent-roll-tile-col-second',
        ])}
      >
        {getCreator()}
      </div>
      <div
        className={getJoinedClassName([
          'grid-area-label-description',
          'rent-roll-label',
          'rent-roll-tile-row-first',
        ])}
      >
        {t('pages.properties.rent-roll.description')}
      </div>
      <div className={getJoinedClassName(['grid-area-description', 'rent-roll-tile-row-first'])}>
        {rentRoll.description}
      </div>
      <div className={getJoinedClassName(['grid-area-label-creation-time', 'rent-roll-label'])}>
        {t('pages.properties.rent-roll.creation-time')}
      </div>
      <div className={getJoinedClassName(['grid-area-creation-time', 'rent-roll-tile-col-second'])}>
        {getCreationTime()}
      </div>
      <div className={getJoinedClassName(['grid-area-label-key-date', 'rent-roll-label'])}>
        {t('pages.properties.rent-roll.key-date')}
      </div>
      <div className={getJoinedClassName(['grid-area-key-date'])}>{rentRoll.key_date}</div>
    </div>
  )
}

PropertyRentRollDetailsTile.propTypes = {
  rentRoll: PropTypes.object.isRequired,
}

export default PropertyRentRollDetailsTile
