import {
  CardHeader,
  FlexBox,
  FlexBoxDirection,
  IllustrationMessageSize,
  IllustrationMessageType,
} from '@fioneer/ui5-webcomponents-react'
import uniq from 'lodash.uniq'
import PropTypes from 'prop-types'
import { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import PropertyPortfolioInformationCard from 'components/domains/properties/portfolio/overview/PropertyPortfolioInformationCard'
import styles from 'components/domains/properties/portfolio/overview/PropertyPortfolioOverview.module.css'
import PropertyPortfolioOverviewMapCard from 'components/domains/properties/portfolio/overview/PropertyPortfolioOverviewMapCard'
import PropertyPortfolioOverviewTableCard from 'components/domains/properties/portfolio/overview/PropertyPortfolioOverviewTableCard'
import PropertyPortfolioRentRollInfoCard from 'components/domains/properties/portfolio/overview/PropertyPortfolioRentRollInfoCard'
import PropertyPortfolioOverviewPerCityChartsCard from 'components/domains/properties/portfolio/overview/pie-charts/PropertyPortfolioOverviewPerCityChartsCard'
import PropertyPortfolioOverviewPerCountryChartsCard from 'components/domains/properties/portfolio/overview/pie-charts/PropertyPortfolioOverviewPerCountryChartsCard'
import PropertyPortfolioOverviewPerPropertyTypeChartsCard from 'components/domains/properties/portfolio/overview/pie-charts/PropertyPortfolioOverviewPerPropertyTypeChartsCard'
import EmptyCardContent from 'components/ui/card/EmptyCardContent'
import CWPLayout from 'components/ui/layout/CWPLayout'

const PropertyPortfolioOverview = ({ properties }) => {
  const { t } = useTranslation()

  const hasPropertiesInDifferentCountries = useMemo(
    () => uniq(properties.map((property) => property.address.country_code)).length > 1,
    [properties],
  )
  return (
    <CWPLayout>
      <FlexBox direction={FlexBoxDirection.Column} className={styles.containerGap}>
        <PropertyPortfolioInformationCard properties={properties} />
        <PropertyPortfolioRentRollInfoCard properties={properties} />
      </FlexBox>
      <PropertyPortfolioOverviewMapCard
        className={styles.propertyPortfolioOverviewMapCard}
        header={<CardHeader titleText={t('pages.properties.portfolio.overview.locations')} />}
        properties={properties}
        renderError={() => (
          <EmptyCardContent
            title={t('components.cards.empty.title')}
            subtitle={t('components.cards.empty.subtitle')}
            illustrationName={IllustrationMessageType.UnableToLoad}
            size={IllustrationMessageSize.Scene}
          />
        )}
      />
      <CWPLayout.Full>
        <FlexBox className={styles.containerGap}>
          {hasPropertiesInDifferentCountries ? (
            <PropertyPortfolioOverviewPerCountryChartsCard />
          ) : (
            <PropertyPortfolioOverviewPerCityChartsCard />
          )}
          <PropertyPortfolioOverviewPerPropertyTypeChartsCard />
        </FlexBox>
      </CWPLayout.Full>
      <CWPLayout.Full>
        <PropertyPortfolioOverviewTableCard properties={properties} />
      </CWPLayout.Full>
    </CWPLayout>
  )
}

PropertyPortfolioOverview.propTypes = {
  properties: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      propertyName: PropTypes.string,
      type_name: PropTypes.string,
      address: PropTypes.object,
      geo_location: PropTypes.shape({
        latitude: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
        longitude: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
      }),
    }),
  ),
}

export default PropertyPortfolioOverview
