import {
  FlexBoxAlignItems,
  Icon,
  ObjectStatus,
  TableGrowingMode,
  TableRowType,
  ValueState,
  Text,
  AnalyticalTableSelectionMode,
} from '@fioneer/ui5-webcomponents-react'
import PropTypes from 'prop-types'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import { WorkingVersionType } from 'components/domains/deals/deal-adjustment/model/WorkingVersionType'
import { DisplayValueOfCodeText } from 'components/domains/deals/deal-search/DisplayValueOfCodeText'
import { FormattedDateText } from 'components/domains/deals/deal-search/FormattedDateText'
import LoadingStateWrapper from 'components/ui/screens/LoadingStateWrapper'
import EntityCell from 'components/ui/tables/cells/EntityCell'
import SortedTable from 'components/ui/tables/sorted-tables/SortedTable'
import useMultipleStaffMemberByObjectIdOrEmail from 'hooks/services/business-partners/staff-members/useMultipleStaffMemberByObjectIdOrEmail'
import {
  useBusinessSegments,
  useDealTypes,
} from 'hooks/services/deals/configurations/useBusinessSegments'
import { useOriginationTeams } from 'hooks/services/deals/configurations/useOriginationTeams'
import styles from 'routes/deals/DealSearchTable.module.css'
import paths from 'routes/paths'

const columnKeyToDealDataMap = {
  deal: 'deal_id',
  businessSegment: 'businessSegmentCode',
  dealType: 'dealType',
  originationPlatform: 'originationTeamCode',
  originationTeam: 'originationTeamCode',
  status: 'status',
  createdAt: 'created_at',
  createdBy: 'created_by',
}

export const DealSearchTableMode = {
  SingleSelect: AnalyticalTableSelectionMode.SingleSelect,
  MultiSelect: AnalyticalTableSelectionMode.MultiSelect,
  NavigateOnClick: 'None',
}

export const DealSearchTableColumn = {
  DEAL: 'deal',
  BUSINESSSEGMENT: 'businessSegment',
  DEALTYPE: 'dealType',
  ORIGINATIONPLATFORM: 'originationPlatform',
  ORIGINATIONTEAM: 'originationTeam',
  STATUS: 'status',
  CREATEDAT: 'createdAt',
  CREATEDBY: 'createdBy',
}

/**
 * DealSearchTable
 * - mode: DealSearchTableMode
 * - columnsRestrictedTo: null | Array<DealSearchTableColumn>
 */
const DealSearchTable = ({
  deals,
  mode,
  orderField,
  orderDirection,
  onSortingChanged,
  onSelectionChanged = () => {},
  selectedDealUuids = [],
  columnsRestrictedTo = null,
  pagination,
  isAllowedToReadDeal,
  loadMore,
  isError,
  isLoading,
}) => {
  const { t } = useTranslation('translation', { keyPrefix: 'pages.deals' })
  const { t: tNoPrefix } = useTranslation()
  const navigate = useNavigate()

  const showMoreButton = pagination.limit < pagination.total
  const growingMode = showMoreButton ? TableGrowingMode.Button : TableGrowingMode.None
  const growingButtonText = tNoPrefix('components.ui.tables.sorted-tables.growing-button-text')
  const growingButtonSubtext = `[ ${pagination.limit} / ${pagination.total} ]`

  const { data: staffMembers = {} } = useMultipleStaffMemberByObjectIdOrEmail({
    objectIdOrEmailAddressList: deals.map((deal) => deal.userCreated),
  })

  const noDataText = isError ? t('table.error') : t('table.no-data')

  let columnDefinitions = [
    {
      title: t('table.deal'),
      columnKey: 'deal',
      isSelectableForHiding: false,
    },
    {
      title: t('table.business-segment'),
      columnKey: DealSearchTableColumn.BUSINESSSEGMENT,
      sortingDisabled: true,
    },
    {
      title: t('table.deal-type'),
      columnKey: DealSearchTableColumn.DEALTYPE,
      sortingDisabled: true,
    },
    {
      title: t('table.origination-platform'),
      columnKey: DealSearchTableColumn.ORIGINATIONPLATFORM,
      sortingDisabled: true,
    },
    {
      title: t('table.origination-team'),
      columnKey: DealSearchTableColumn.ORIGINATIONTEAM,
      sortingDisabled: true,
    },
    {
      title: t('table.status'),
      columnKey: DealSearchTableColumn.STATUS,
    },
    {
      title: t('table.created-at'),
      columnKey: DealSearchTableColumn.CREATEDAT,
      isVisible: false,
    },
    {
      title: t('table.created-by'),
      columnKey: DealSearchTableColumn.CREATEDBY,
      isVisible: false,
      sortingDisabled: true,
    },
  ]
  if (mode === DealSearchTableMode.NavigateOnClick)
    columnDefinitions.push({
      title: '',
      columnKey: 'arrow',
      sortingDisabled: true,
      alignment: FlexBoxAlignItems.End,
      isSelectableForHiding: false,
    })
  if (columnsRestrictedTo !== null) {
    columnDefinitions = columnDefinitions.filter((column) =>
      columnsRestrictedTo.includes(column.columnKey),
    )
  }

  const customOrderFunction = (tableData) => tableData

  const customOrderCallback = (columnKey, localOrderDirection) => {
    const newOrderField = columnKeyToDealDataMap[columnKey]
    onSortingChanged(newOrderField, localOrderDirection)
  }

  const sortingColumnKey = Object.keys(columnKeyToDealDataMap).find(
    (key) => columnKeyToDealDataMap[key] === orderField,
  )

  const tableData = deals.map(
    ({
      dealUuid,
      dealId,
      name,
      businessSegmentCode,
      dealTypeCode,
      originationTeamCode,
      created,
      userCreated,
      status,
      workingVersion,
    } = {}) => {
      const dealName =
        workingVersion === WorkingVersionType.WORKING_VERSION
          ? t('table.working-version.name', { dealName: name })
          : name
      return {
        rowKey: `deal-table-row-${dealUuid}`,
        rowProperties: {
          onClick: ({ ctrlKey, metaKey } = {}) => {
            if (!isAllowedToReadDeal) return
            if (mode !== DealSearchTableMode.NavigateOnClick) return

            const shouldOpenInNewTab = !!ctrlKey || !!metaKey
            if (shouldOpenInNewTab) window.open(`/${paths.deals}/${dealId}`, '_blank')
            else navigate(`${dealId}`)
            // Note for SingleSelect/MultiSelect:
            // Selected rows are passed directly from/to
            // SortedTable->DealSearchDialog via onSelectionChanged callback
          },
          selected: selectedDealUuids.includes(dealUuid),
          type: isAllowedToReadDeal ? TableRowType.Active : TableRowType.Inactive,
          'data-deal-id': dealId,
          'data-deal-uuid': dealUuid,
          'data-deal-name': dealName,
        },
        deal: {
          value: dealId,
          cellComponent: <EntityCell name={dealName} id={dealId} options={{ isNameBold: true }} />,
        },
        businessSegment: {
          value: businessSegmentCode,
          cellComponent: (
            <DisplayValueOfCodeText code={businessSegmentCode} loadingHook={useBusinessSegments} />
          ),
        },
        dealType: {
          value: dealTypeCode,
          cellComponent: <DisplayValueOfCodeText code={dealTypeCode} loadingHook={useDealTypes} />,
        },
        originationPlatform: {
          value: originationTeamCode,
          cellComponent: (
            <DisplayValueOfCodeText
              code={originationTeamCode}
              loadingHook={useOriginationTeams}
              valueKey="platform"
            />
          ),
        },
        originationTeam: {
          value: originationTeamCode,
          cellComponent: (
            <DisplayValueOfCodeText code={originationTeamCode} loadingHook={useOriginationTeams} />
          ),
        },
        status: {
          value: status,
          cellComponent: (
            <ObjectStatus inverted state={status ? ValueState.Information : ValueState.None}>
              {status}
            </ObjectStatus>
          ),
        },
        createdAt: {
          value: created,
          cellComponent: <FormattedDateText dateString={created} showTime />,
        },
        createdBy: {
          value: userCreated,
          cellComponent: <Text>{staffMembers[userCreated]?.fullName ?? userCreated}</Text>,
        },
        arrow: {
          cellComponent: isAllowedToReadDeal && <Icon name="slim-arrow-right" />,
        },
      }
    },
  )

  const additionalTableProperties = () => ({
    mode,
    stickyColumnHeader: true,
    onSelectionChange: onSelectionChanged,
  })

  const renderSortedTable = () => (
    <div className={`${!isAllowedToReadDeal && styles['default-cursor']}`}>
      <SortedTable
        columnDefinitions={columnDefinitions}
        customOrderCallback={customOrderCallback}
        customOrderFunction={customOrderFunction}
        tableData={tableData}
        toolbarConfig={{
          title: tNoPrefix('pages.deals.subtitle'),
          sorting: {
            columnKey: sortingColumnKey,
            isSortingAscending: orderDirection === 'asc',
          },
        }}
        noDataText={noDataText}
        paginationConfig={{
          growing: growingMode,
          growingButtonText: growingButtonText,
          growingButtonSubtext: growingButtonSubtext,
          totalNumberOfItems: pagination?.total,
          loadMore: loadMore,
        }}
        additionalTableProperties={additionalTableProperties()}
      />
    </div>
  )

  return (
    <LoadingStateWrapper
      isError={isError}
      isLoading={isLoading}
      renderContent={renderSortedTable}
      errorTitle={t('error.title')}
      errorDescription={t('error.description')}
    />
  )
}

export default DealSearchTable

DealSearchTable.propTypes = {
  deals: PropTypes.array.isRequired,
  mode: PropTypes.oneOf(Object.values(DealSearchTableMode)),
  onSortingChanged: PropTypes.func.isRequired,
  onSelectionChanged: PropTypes.func,
  selectedDealUuids: PropTypes.arrayOf(PropTypes.string),
  columnsRestrictedTo: PropTypes.arrayOf(PropTypes.oneOf(Object.values(DealSearchTableColumn))),
  orderField: PropTypes.string.isRequired,
  orderDirection: PropTypes.oneOf(['asc', 'desc']),
  pagination: PropTypes.shape({
    limit: PropTypes.number,
    total: PropTypes.number,
  }).isRequired,
  isAllowedToReadDeal: PropTypes.bool,
  loadMore: PropTypes.func,
  isError: PropTypes.bool,
  isLoading: PropTypes.bool,
}
