import {
  FilterGroupItem,
  Input,
  MultiComboBox,
  MultiComboBoxItem,
} from '@fioneer/ui5-webcomponents-react'
import PropTypes from 'prop-types'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import BusinessPartnerAutocompleteInput from 'components/domains/deals/creation-dialog/BusinessPartnerAutocompleteInput'
import useTranslatedWorkingVersionTypes from 'components/domains/deals/deal-adjustment/model/useTranslatedWorkingVersionTypes'
import StaffMemberAutocompleteInput from 'components/ui/input/StaffMemberAutocompleteInput'
import DashboardFilterBar from 'components/ui/page/DashboardFilterBar'
import useBusinessPartnerMiniByIds from 'hooks/services/business-partners/minis/useBusinessPartnerMiniByIds'
import useStaffMemberById from 'hooks/services/business-partners/staff-members/useStaffMemberById'
import {
  useBusinessSegments,
  useDealTypes,
} from 'hooks/services/deals/configurations/useBusinessSegments'
import useDealStatus from 'hooks/services/deals/configurations/useDealStatus'
import {
  useOriginationPlatforms,
  useOriginationTeams,
} from 'hooks/services/deals/configurations/useOriginationTeams'

export const DealFilterKeys = {
  Name: 'deal_name',
  Id: 'deal_id',
  WorkingVersion: 'working_version',
  AccountManager: 'account_manager_user_id',
  BusinessSegments: 'business_segments',
  Type: 'deal_types',
  OriginationPlatform: 'origination_platform',
  OriginationTeam: 'origination_team',
  Status: 'status',
  Borrower: 'borrower_id',
  CreatedAt: 'created_at', // only used for sorting, not filtering
}

const filterOptions = {
  [DealFilterKeys.Name]: { visibleOnBar: true },
  [DealFilterKeys.Id]: { visibleOnBar: true },
  [DealFilterKeys.WorkingVersion]: { visibleOnBar: true },
  [DealFilterKeys.AccountManager]: { visibleOnBar: true },
  [DealFilterKeys.BusinessSegments]: { visibleOnBar: false },
  [DealFilterKeys.Type]: { visibleOnBar: true },
  [DealFilterKeys.OriginationPlatform]: { visibleOnBar: false },
  [DealFilterKeys.OriginationTeam]: { visibleOnBar: true },
  [DealFilterKeys.Status]: { visibleOnBar: true },
  [DealFilterKeys.Borrower]: { visibleOnBar: true },
  [DealFilterKeys.CreatedAt]: { visibleOnBar: false },
}

const defaultVisibleFilters = Object.keys(filterOptions) //default is to show all filters

/**
 * DealFilterBar
 *
 * @param isOpen: bool // external info used to reset to initial filter values (optional)
 * @param initialValues: {[key in DealFilterKeys]: string }
 * @param visibleFilters: null | Array<DealFilterKeys>
 * @param readOnlyFilters: null | Array<DealFilterKeys>
 * @param onGo: Func
 * @returns {JSX.Element}
 * @constructor
 */
export const DealFilterBar = ({
  isOpen,
  initialValues,
  visibleFilters = defaultVisibleFilters,
  readOnlyFilters = [],
  onGo: onGoExternal = () => {},
}) => {
  const { t } = useTranslation('translation', { keyPrefix: 'pages.deals' })

  const [isFilterDialogOpen, setIsFilterDialogOpen] = useState(false)

  //set and reset filter params when dialog opens/closes
  const [filterParams, setFilterParams] = useState(initialValues ?? {})
  useEffect(() => {
    if (initialValues !== undefined) setFilterParams(initialValues)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isOpen])

  const { data: businessSegments = [] } = useBusinessSegments()
  const { data: dealTypes = [] } = useDealTypes()
  const { data: dealStatus = [] } = useDealStatus()
  const { data: originationTeams = [] } = useOriginationTeams()
  const { data: originationPlatforms = [] } = useOriginationPlatforms()
  const borrowerBpId = filterParams[DealFilterKeys.Borrower]
  const { data: businessPartnerMiniData } = useBusinessPartnerMiniByIds(
    borrowerBpId ? [borrowerBpId] : [],
  )
  const workingVersionTypes = useTranslatedWorkingVersionTypes()

  const businessPartnerMini = businessPartnerMiniData?.businessPartnerMinis?.[0] ?? undefined
  const borrowerName = businessPartnerMini?.fullName ?? ''
  const { data: { fullName: accountManageName } = {} } = useStaffMemberById(
    filterParams[DealFilterKeys.AccountManager],
  )

  const updateFilterParams = (key, value) => {
    setFilterParams({
      ...filterParams,
      [key]: value,
    })
  }

  const onGo = () => {
    onGoExternal({ ...filterParams })
  }

  const handleTextInputChangeForKey =
    (key) =>
    ({ target: { value } }) =>
      updateFilterParams(key, value.trim())

  const handleKeyDown = (event) => {
    if ((event.code === 'Enter' || event.code === 'NumpadEnter') && !isFilterDialogOpen) {
      event.stopPropagation()
      onGo()
    }
  }

  const handleSelectionChangeForKey =
    (key) =>
    ({ detail: { items } }) => {
      const selectedFilterOptions = items.map((type) => type.dataset.key)
      updateFilterParams(key, selectedFilterOptions.join(','))
    }

  const getActiveFiltersCount = () =>
    filterParams ? Object.entries(filterParams).filter(([, value]) => !!value).length : 0

  const handleClearUserInput = (key) => (event) => !event.target?.value && updateFilterParams(key)

  const isVisibleFilter = (key) => visibleFilters?.includes(key) ?? true
  const isReadOnlyFilter = (key) => readOnlyFilters?.includes(key) ?? false
  const isActiveFilter = (key) => Object.keys(filterParams).includes(key) && isVisibleFilter(key)

  const isSelectedOption = (key, code) => filterParams[key]?.split(',').includes(code)

  const getFilterParamValue = (key) => filterParams[key] ?? ''

  const buildFilters = () => {
    const allFilterDefinitions = [
      <FilterGroupItem
        label={t('filter.deal-name')}
        key={DealFilterKeys.Name}
        active={isActiveFilter(DealFilterKeys.Name)}
      >
        <Input
          id="deal-name-filter"
          value={getFilterParamValue('deal_name')}
          onInput={handleTextInputChangeForKey(DealFilterKeys.Name)}
          onKeyDown={handleKeyDown}
          onFocus={(e) => e.stopPropagation()}
          disabled={isReadOnlyFilter(DealFilterKeys.Name)}
          showClearIcon
        />
      </FilterGroupItem>,
      <FilterGroupItem
        label={t('filter.deal-id')}
        key={DealFilterKeys.Id}
        active={isActiveFilter(DealFilterKeys.Id)}
      >
        <Input
          id="deal-id-filter"
          value={getFilterParamValue('deal_id')}
          onInput={handleTextInputChangeForKey(DealFilterKeys.Id)}
          onKeyDown={handleKeyDown}
          onFocus={(e) => e.stopPropagation()}
          showClearIcon
          disabled={isReadOnlyFilter(DealFilterKeys.Id)}
        />
      </FilterGroupItem>,
      <FilterGroupItem
        label={t('filter.working-version')}
        key={DealFilterKeys.WorkingVersion}
        active={isActiveFilter(DealFilterKeys.WorkingVersion)}
      >
        <MultiComboBox
          id="working-version-filter"
          onSelectionChange={handleSelectionChangeForKey(DealFilterKeys.WorkingVersion)}
          disabled={isReadOnlyFilter(DealFilterKeys.WorkingVersion)}
        >
          {workingVersionTypes.map(({ code, name }) => (
            <MultiComboBoxItem
              key={code}
              text={name}
              selected={isSelectedOption(DealFilterKeys.WorkingVersion, code)}
              data-key={code}
            />
          ))}
        </MultiComboBox>
      </FilterGroupItem>,
      <FilterGroupItem
        label={t('filter.account-manager')}
        key={DealFilterKeys.AccountManager}
        active={isActiveFilter(DealFilterKeys.AccountManager)}
      >
        <div>
          <StaffMemberAutocompleteInput
            id="deal-account-manager-filter"
            staffMemberName={accountManageName}
            onStaffMemberSelect={({ id } = {}) =>
              updateFilterParams(DealFilterKeys.AccountManager, id)
            }
            onInput={handleClearUserInput(DealFilterKeys.AccountManager)}
            disabled={isReadOnlyFilter(DealFilterKeys.AccountManager)}
            showClearIcon
            onFocus={(e) => e.stopPropagation()}
          />
        </div>
      </FilterGroupItem>,
      <FilterGroupItem
        label={t('filter.business-segment')}
        key={DealFilterKeys.BusinessSegments}
        active={isActiveFilter(DealFilterKeys.BusinessSegments)}
      >
        <MultiComboBox
          id="business-segment-filter"
          onSelectionChange={handleSelectionChangeForKey(DealFilterKeys.BusinessSegments)}
          disabled={isReadOnlyFilter(DealFilterKeys.BusinessSegments)}
        >
          {businessSegments?.map(({ code, name }) => (
            <MultiComboBoxItem
              key={code}
              text={name}
              selected={isSelectedOption(DealFilterKeys.BusinessSegments, code)}
              data-key={code}
            />
          ))}
        </MultiComboBox>
      </FilterGroupItem>,
      <FilterGroupItem
        label={t('filter.deal-type')}
        key={DealFilterKeys.Type}
        active={isActiveFilter(DealFilterKeys.Type)}
      >
        <MultiComboBox
          id="deal-type-filter"
          onSelectionChange={handleSelectionChangeForKey(DealFilterKeys.Type)}
          disabled={isReadOnlyFilter(DealFilterKeys.Type)}
        >
          {dealTypes?.map(({ code, name }) => (
            <MultiComboBoxItem
              key={code}
              text={name}
              selected={isSelectedOption(DealFilterKeys.Type, code)}
              data-key={code}
            />
          ))}
        </MultiComboBox>
      </FilterGroupItem>,
      <FilterGroupItem
        label={t('filter.origination-platform')}
        key={DealFilterKeys.OriginationPlatform}
        active={isActiveFilter(DealFilterKeys.OriginationPlatform)}
      >
        <MultiComboBox
          id="origination-platform-filter"
          onSelectionChange={handleSelectionChangeForKey(DealFilterKeys.OriginationPlatform)}
          disabled={isReadOnlyFilter(DealFilterKeys.OriginationPlatform)}
        >
          {originationPlatforms.map((platform) => (
            <MultiComboBoxItem
              key={platform}
              text={platform}
              selected={isSelectedOption(DealFilterKeys.OriginationPlatform, platform)}
              data-key={platform}
            />
          ))}
        </MultiComboBox>
      </FilterGroupItem>,
      <FilterGroupItem
        label={t('filter.origination-team')}
        key={DealFilterKeys.OriginationTeam}
        active={isActiveFilter(DealFilterKeys.OriginationTeam)}
      >
        <MultiComboBox
          id="origination-teams-filter"
          onSelectionChange={handleSelectionChangeForKey(DealFilterKeys.OriginationTeam)}
          disabled={isReadOnlyFilter(DealFilterKeys.OriginationTeam)}
        >
          {originationTeams.map(({ code, name }) => (
            <MultiComboBoxItem
              key={code}
              text={name}
              selected={isSelectedOption(DealFilterKeys.OriginationTeam, code)}
              data-key={code}
            />
          ))}
        </MultiComboBox>
      </FilterGroupItem>,
      <FilterGroupItem
        label={t('filter.status')}
        key={DealFilterKeys.Status}
        active={isActiveFilter(DealFilterKeys.Status)}
      >
        <MultiComboBox
          id="status-filter"
          onSelectionChange={handleSelectionChangeForKey(DealFilterKeys.Status)}
          disabled={isReadOnlyFilter(DealFilterKeys.Status)}
        >
          {dealStatus.map(({ code, name }) => (
            <MultiComboBoxItem
              key={code}
              text={name}
              selected={isSelectedOption(DealFilterKeys.Status, code)}
              data-key={code}
            />
          ))}
        </MultiComboBox>
      </FilterGroupItem>,
      <FilterGroupItem
        label={t('filter.borrower')}
        key={DealFilterKeys.Borrower}
        active={isActiveFilter(DealFilterKeys.Borrower)}
      >
        <div>
          <BusinessPartnerAutocompleteInput
            id="borrower-id-filter"
            onBusinessPartnerSelected={(id) => updateFilterParams(DealFilterKeys.Borrower, id)}
            onInput={handleClearUserInput(DealFilterKeys.Borrower)}
            defaultValue={borrowerName}
            disabled={isReadOnlyFilter(DealFilterKeys.Borrower)}
          />
        </div>
      </FilterGroupItem>,
    ]

    // calculate available filters
    return allFilterDefinitions.filter((filter) => isVisibleFilter(filter.key))
  }

  return (
    <DashboardFilterBar
      filterOptions={filterOptions}
      activeFiltersCount={getActiveFiltersCount()}
      onGo={onGo}
      setDialogOpen={setIsFilterDialogOpen}
      searchInputField={<Input placeholder="Search" />}
    >
      {buildFilters()}
    </DashboardFilterBar>
  )
}

DealFilterBar.propTypes = {
  isOpen: PropTypes.bool,
  initialValues: PropTypes.object,
  visibleFilters: PropTypes.arrayOf(PropTypes.oneOf(Object.values(DealFilterKeys))),
  readOnlyFilters: PropTypes.arrayOf(PropTypes.oneOf(Object.values(DealFilterKeys))),
  onGo: PropTypes.func,
}
