import { CardHeader, FlexBox } from '@fioneer/ui5-webcomponents-react'
import { t } from 'i18next'
import { useContext } from 'react'
import { useTranslation } from 'react-i18next'
import { propertyAllowedOperations } from 'api/property/propertyAllowedOperations'
import styles from 'components/domains/properties/rent-roll/overview/structure-overview/area-details/PropertyRentRollOverviewAreaDetailsCard.module.css'
import PropertyRentRollOverviewAreaDetailsTable from 'components/domains/properties/rent-roll/overview/structure-overview/area-details/PropertyRentRollOverviewAreaDetailsTable'
import Card from 'components/ui/card/Card'
import EmptyCardContent from 'components/ui/card/EmptyCardContent'
import LoadingStateWrapper from 'components/ui/screens/LoadingStateWrapper'
import { useSegments } from 'hooks/services/properties/segments/useSegments'
import { usePropertiesPermissions } from 'hooks/services/properties/usePropertiesPermissions'
import { PropertyContext } from 'routes/properties/PropertyContext'

const PropertyRentRollOverviewAreaDetailsCard = () => {
  const { isLoading: isLoadingPermissions, data: propertyPermissions } = usePropertiesPermissions()
  const allowedPropertyOperations = propertyPermissions?.allowed_operations ?? []
  const isAllowedToReadProperty = allowedPropertyOperations.includes(
    propertyAllowedOperations.propertyRead,
  )

  const { property } = useContext(PropertyContext)

  const propertyUuid = property?.uuid
  const { t: tAreaDetails } = useTranslation('translation', {
    keyPrefix: 'pages.property.rent-roll.overview.area-details',
  })

  const {
    isLoading: isLoadingSegments,
    isError: isErrorSegments,
    data: segmentsData,
  } = useSegments({ propertyUuid })
  const segments = segmentsData?.segments ?? []

  return (
    <Card header={<CardHeader titleText={tAreaDetails('title')} />}>
      <LoadingStateWrapper
        isLoading={isLoadingSegments || isLoadingPermissions}
        isError={isErrorSegments}
        renderContent={() => (
          <FlexBox className={styles.tablePadding}>
            <PropertyRentRollOverviewAreaDetailsTable
              property={property}
              segments={segments}
              hasPermission={isAllowedToReadProperty}
            />
          </FlexBox>
        )}
        renderCustomErrorScreen={() => (
          <EmptyCardContent
            title={t('components.cards.error.title')}
            subtitle={t('components.cards.error.subtitle')}
          />
        )}
      />
    </Card>
  )
}

export default PropertyRentRollOverviewAreaDetailsCard
