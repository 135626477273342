import {
  FlexBox,
  FlexBoxDirection,
  FlexBoxJustifyContent,
  IllustrationMessageSize,
  IllustrationMessageType,
  Popover,
  Text,
  Title,
  TitleLevel,
} from '@fioneer/ui5-webcomponents-react'
import PropTypes from 'prop-types'
import { useMemo, useState } from 'react'
import { createPortal } from 'react-dom'
import { useTranslation } from 'react-i18next'
import styles from 'components/domains/properties/valuation/PropertyValuationResultsHistoryChart.module.css'
import PropertyValuationResultsHistoryLineChartChildren from 'components/domains/properties/valuation/PropertyValuationResultsHistoryLineChartChildren'
import GenericChart from 'components/ui/charts/GenericChart'
import CustomizedIllustratedMessage from 'components/ui/illustrated-message/CustomizedIllustratedMessage'
import { useNumberFormatter } from 'hooks/i18n/useI18n'

const PropertyValuationResultsHistoryChart = ({ preparedValuationResultData }) => {
  const { t } = useTranslation('translation', {
    keyPrefix: 'pages.property.valuation.results-history',
  })

  const formatNumber = useNumberFormatter()
  const [chartPopoverValuationResultData, setChartPopoverValuationResultData] = useState({})

  const minMarketValue = useMemo(
    () =>
      Math.min(
        ...preparedValuationResultData.map((valuationResult) =>
          valuationResult.marketValue !== undefined
            ? +valuationResult.marketValue
            : Number.MAX_SAFE_INTEGER,
        ),
      ),
    [preparedValuationResultData],
  )
  const maxMarketValue = useMemo(
    () =>
      Math.max(
        ...preparedValuationResultData.map((valuationResult) =>
          valuationResult.marketValue !== undefined
            ? +valuationResult.marketValue
            : Number.MIN_SAFE_INTEGER,
        ),
      ),
    [preparedValuationResultData],
  )

  const minMortgageLendingValue = useMemo(
    () =>
      Math.min(
        ...preparedValuationResultData.map((valuationResult) =>
          valuationResult.mortgageLendingValue !== undefined
            ? +valuationResult.mortgageLendingValue
            : Number.MAX_SAFE_INTEGER,
        ),
      ),
    [preparedValuationResultData],
  )

  const maxMortgageLendingValue = useMemo(
    () =>
      Math.max(
        ...preparedValuationResultData.map((valuationResult) =>
          valuationResult.mortgageLendingValue !== undefined
            ? +valuationResult.mortgageLendingValue
            : Number.MIN_SAFE_INTEGER,
        ),
      ),
    [preparedValuationResultData],
  )

  const overallDataMin = useMemo(
    () => Math.min(minMarketValue, minMortgageLendingValue),
    [minMarketValue, minMortgageLendingValue],
  )
  const overallDataMax = useMemo(
    () => Math.max(maxMarketValue, maxMortgageLendingValue),
    [maxMarketValue, maxMortgageLendingValue],
  )

  const renderChartPopoverValuationResultDataPopover = () => {
    if (!chartPopoverValuationResultData.visibility) return

    return (
      <div
        id="popover-div-id"
        style={{
          position: 'absolute',
          top: chartPopoverValuationResultData.position.y,
          left: chartPopoverValuationResultData.position.x,
        }}
      >
        {createPortal(
          <Popover
            header={
              <Title level={TitleLevel.H6} className={styles.popoverTitle}>
                {t('popover-header.title')}
              </Title>
            }
            opener="popover-div-id"
            hideArrow={true}
            open={chartPopoverValuationResultData.visibility}
            onBeforeClose={() => setChartPopoverValuationResultData({ visibility: false })}
            placementType="Bottom"
            className={styles.popoverWidth}
          >
            <FlexBox direction={FlexBoxDirection.Column}>
              <Text className={styles.popoverText}>
                <b>{chartPopoverValuationResultData.payload.date}</b>
              </Text>
              <FlexBox
                direction={FlexBoxDirection.Row}
                justifyContent={FlexBoxJustifyContent.SpaceBetween}
              >
                <Text>{chartPopoverValuationResultData.payload.method}</Text>
                <Text>
                  {`${formatNumber(chartPopoverValuationResultData.payload.value)} ${
                    chartPopoverValuationResultData.payload.currency
                  }`}
                </Text>
              </FlexBox>
            </FlexBox>
          </Popover>,
          document.body,
        )}
      </div>
    )
  }

  if (preparedValuationResultData.length === 0) {
    return (
      <CustomizedIllustratedMessage
        name={IllustrationMessageType.UnableToLoad}
        size={IllustrationMessageSize.Scene}
        titleText={t('empty')}
        subtitleText={t('empty.subtitle')}
      />
    )
  }

  return (
    <div
      id={`div-valuation-results-history`}
      className={styles.valuationResultsHistoryChartWrapper}
    >
      <GenericChart
        lineChartData={preparedValuationResultData}
        lineChartChildren={PropertyValuationResultsHistoryLineChartChildren}
        additionalLineChartArguments={{
          setChartPopoverValuationResultData,
          overallDataMin,
          overallDataMax,
        }}
        renderChildrenOutsideLineChart={renderChartPopoverValuationResultDataPopover}
      />
    </div>
  )
}

PropertyValuationResultsHistoryChart.propTypes = {
  preparedValuationResultData: PropTypes.array.isRequired,
}

export default PropertyValuationResultsHistoryChart
