import { Label, Modals } from '@fioneer/ui5-webcomponents-react'
import { useQueryClient } from '@tanstack/react-query'
import { useContext, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import DisplayAndEditCard from 'components/ui/card/DisplayAndEditCard'
import { hideOptions } from 'components/ui/card/DisplayCardView'
import editComponentTypes from 'components/ui/card/editComponentTypes'
import { useCustomizableCurrencyFormatter } from 'hooks/i18n/useI18n'
import useMultiPropertyValuations from 'hooks/services/properties/valuations/useMultiPropertyValuations'
import { usePutPropertyValuations } from 'hooks/services/properties/valuations/usePutPropertyValuations'
import { useValuationCreateMethodCodes } from 'hooks/services/properties/valuations/useValuationCreateMethodCodes'
import { PropertyContext } from 'routes/properties/PropertyContext'

const PropertyValuationCard = () => {
  const { t: tNoPrefix } = useTranslation()
  const { t } = useTranslation('translation', {
    keyPrefix: 'pages.property.general-information.valuation',
  })

  const showToast = Modals.useShowToast()

  const {
    property: {
      uuid: property_uuid,
      currency_code: property_currency_code,
      change_request_exist_indicator: changeRequestExistIndicator,
      allowed_operations,
    },
  } = useContext(PropertyContext)

  const isAllowedPropertyValuationsUpdate =
    !changeRequestExistIndicator &&
    !!allowed_operations?.allowed_operations?.includes('PropertyValuation_Update')

  const {
    isLoading: isLoadingValuationCreateMethodCodes,
    isError: isErrorValuationCreateMethodCodes,
    data: valuationCreateMethodCodes,
  } = useValuationCreateMethodCodes()

  const RELEVANT = 'Relevant'
  const MARKET_VALUE = 'MARKET_VALUE'
  const MORTGAGE_LENDING_VALUE = 'MORTGAGE_LENDING_VALUE'
  const DEFINITIONS = {
    MARKET_VALUE: 'market-value',
    MARKET_VALUE_TYPE: 'market-value-type',
    MORTGAGE_LENDING_VALUE: 'mortgage-lending-value',
    MORTGAGE_LENDING_VALUE_TYPE: 'mortgage-lending-value-type',
    PURCHASE_PRICE: 'purchase-price',
    PURCHASE_PRICE_TYPE: 'purchase-price-type',
  }

  const findValuationCreateMethodCodeByType = (type) =>
    valuationCreateMethodCodes.find((code) => code?.createMethodCode === type)

  const FIRST_MARKET_VALUE = 'FIRST_MARKET_VALUE'
  const FIRST_MORTGAGE_LENDING_VALUE = 'FIRST_MORTGAGE_LENDING_VALUE'
  const PURCHASE_PRICE = 'PURCHASE_PRICE'

  const valuationCreateMethods = {
    firstMarketValue: findValuationCreateMethodCodeByType(FIRST_MARKET_VALUE),
    firstMortgageLendingValue: findValuationCreateMethodCodeByType(FIRST_MORTGAGE_LENDING_VALUE),
    purchasePrice: findValuationCreateMethodCodeByType(PURCHASE_PRICE),
  }

  const initialValuation = useMemo(
    () => ({
      value_amount: {
        number: undefined,
        currency: property_currency_code,
      },
      calculation_method_name: undefined,
    }),
    [property_currency_code],
  )

  const hasValue = (valuation) => !!valuation?.value_amount?.number

  const formatMoney = useCustomizableCurrencyFormatter()

  const {
    isLoading: isLoadingValuations,
    isError: isErrorValuations,
    data: valuationsData,
  } = useMultiPropertyValuations([property_uuid])

  const mostRecentMarketValue = valuationsData?.valuations?.[property_uuid]?.[MARKET_VALUE] ?? {
    ...initialValuation,
    calculation_method_name: valuationCreateMethods.firstMarketValue?.displayName,
  }

  const mostRecentMortgageLendingValue = valuationsData?.valuations?.[property_uuid]?.[
    MORTGAGE_LENDING_VALUE
  ] ?? {
    ...initialValuation,
    calculation_method_name: valuationCreateMethods.firstMortgageLendingValue?.displayName,
  }

  const mostRecentPurchasePrice = valuationsData?.valuations?.[property_uuid]?.[PURCHASE_PRICE] ?? {
    ...initialValuation,
    calculation_method_name: valuationCreateMethods.purchasePrice?.displayName,
  }

  const queryClient = useQueryClient()

  const {
    mutate: putValuations,
    isSuccess: isSuccessPutValuations,
    isError: isErrorPutValuations,
    error: errorCreateOrUpdateValuations,
    isLoading: isLoadingPutValuations,
  } = usePutPropertyValuations({
    onSuccess: () => {
      queryClient.invalidateQueries(['properties'])
      showToast({
        children: tNoPrefix('toast.changes-saved'),
      })
    },
    onError: () => {
      queryClient.invalidateQueries(['properties', 'multi-valuations', [property_uuid]])
    },
  })

  const getValueAmountNumber = (valuation) => valuation?.value_amount?.number
  const getValueAmountCurrency = (valuation) => valuation?.value_amount?.currency
  const getRegulatoryRelevance = (valuation) => valuation?.regulatory_relevance
  const getCalculationMethodName = (valuation) => valuation?.calculation_method_name

  const getFormattedValueAmountNumber = (valuation) =>
    valuation?.value_amount?.number
      ? formatMoney(getValueAmountNumber(valuation), getValueAmountCurrency(valuation))
      : ''

  const isValuationReadOnly = (valuation) => getRegulatoryRelevance(valuation) === RELEVANT

  const fieldDefinitions = [
    {
      label: t(DEFINITIONS.MARKET_VALUE),
      name: DEFINITIONS.MARKET_VALUE,
      formattedValue: getFormattedValueAmountNumber(mostRecentMarketValue),
      value: getValueAmountNumber(mostRecentMarketValue),
      hideOption: hideOptions.hideWhenEmpty,
      isShownInDisplay: true,
      customInfoComponent: null,
      isShownInEdit: true,
      customEditComponent: null,
      editComponentType: editComponentTypes.FormattedNumberInput,
      editComponentProps: {
        readonly: isValuationReadOnly(mostRecentMarketValue),
        type: 'Text',
        icon: (
          <Label style={{ color: 'var(--sapContent_LabelColor)' }}>
            {mostRecentMarketValue?.value_amount?.currency}
          </Label>
        ),
      },
      isMandatory: false,
      editComponentSelectOptions: null,
    },
    {
      label: t(DEFINITIONS.MARKET_VALUE_TYPE),
      name: DEFINITIONS.MARKET_VALUE_TYPE,
      value: getCalculationMethodName(mostRecentMarketValue),
      hideOption: hideOptions.hideWhenEmpty,
      isShownInDisplay: hasValue(mostRecentMarketValue),
      customInfoComponent: null,
      isShownInEdit: true,
      customEditComponent: null,
      editComponentType: editComponentTypes.Input,
      editComponentProps: {
        readonly: true,
      },
      isMandatory: false,
      editComponentSelectOptions: null,
    },
    {
      label: t(DEFINITIONS.MORTGAGE_LENDING_VALUE),
      name: DEFINITIONS.MORTGAGE_LENDING_VALUE,
      formattedValue: getFormattedValueAmountNumber(mostRecentMortgageLendingValue),
      value: getValueAmountNumber(mostRecentMortgageLendingValue),
      hideOption: hideOptions.hideWhenEmpty,
      isShownInDisplay: true,
      customInfoComponent: null,
      isShownInEdit: true,
      customEditComponent: null,
      editComponentType: editComponentTypes.FormattedNumberInput,
      editComponentProps: {
        readonly: isValuationReadOnly(mostRecentMortgageLendingValue),
        type: 'Text',
        icon: (
          <Label style={{ color: 'var(--sapContent_LabelColor)' }}>
            {mostRecentMarketValue?.value_amount?.currency}
          </Label>
        ),
      },
      isMandatory: false,
      editComponentSelectOptions: null,
    },
    {
      label: t(DEFINITIONS.MORTGAGE_LENDING_VALUE_TYPE),
      name: DEFINITIONS.MORTGAGE_LENDING_VALUE_TYPE,
      value: getCalculationMethodName(mostRecentMortgageLendingValue),
      hideOption: hideOptions.hideWhenEmpty,
      isShownInDisplay: hasValue(mostRecentMortgageLendingValue),
      customInfoComponent: null,
      isShownInEdit: true,
      customEditComponent: null,
      editComponentType: editComponentTypes.Input,
      editComponentProps: { readonly: true },
      isMandatory: false,
      editComponentSelectOptions: null,
    },
    {
      label: t(DEFINITIONS.PURCHASE_PRICE),
      name: DEFINITIONS.PURCHASE_PRICE,
      formattedValue: getFormattedValueAmountNumber(mostRecentPurchasePrice),
      value: getValueAmountNumber(mostRecentPurchasePrice),
      hideOption: hideOptions.hideWhenEmpty,
      isShownInDisplay: true,
      customInfoComponent: null,
      isShownInEdit: true,
      customEditComponent: null,
      editComponentType: editComponentTypes.FormattedNumberInput,
      editComponentProps: {
        readonly: isValuationReadOnly(mostRecentPurchasePrice),
        type: 'Text',
        icon: (
          <Label style={{ color: 'var(--sapContent_LabelColor)' }}>
            {mostRecentPurchasePrice?.value_amount?.currency}
          </Label>
        ),
      },
      isMandatory: false,
      editComponentSelectOptions: null,
    },
    {
      label: t(DEFINITIONS.PURCHASE_PRICE_TYPE),
      name: DEFINITIONS.PURCHASE_PRICE_TYPE,
      value: getCalculationMethodName(mostRecentPurchasePrice),
      hideOption: hideOptions.hideWhenEmpty,
      isShownInDisplay: hasValue(mostRecentPurchasePrice),
      customInfoComponent: null,
      isShownInEdit: true,
      customEditComponent: null,
      editComponentType: editComponentTypes.Input,
      editComponentProps: { readonly: true },
      isMandatory: false,
      editComponentSelectOptions: null,
    },
  ]

  const isSameNumber = (value) => Number(value) === getValueAmountNumber(mostRecentMarketValue)

  const isSaveable = (valuation, value) =>
    !(isValuationReadOnly(valuation) || isNaN(value) || isSameNumber(value))

  const getCreateOrUpdateValuationPayload = (value, calculationMethodCode) => ({
    calculation_method_code: calculationMethodCode,
    value_amount: {
      number: value,
      currency: property_currency_code,
    },
  })

  const isFirstValuation = (valuation) => !valuation?.valuation_id

  const getCreateOrUpdatePayload = (valuation, value, valuationCreateMethod) => {
    if (isSaveable(valuation, value)) {
      const valueOrDefault = value || 0
      if (isFirstValuation(valuation)) {
        return getCreateOrUpdateValuationPayload(valueOrDefault, valuationCreateMethod?.key)
      } else {
        return getCreateOrUpdateValuationPayload(valueOrDefault, valuation.calculation_method_code)
      }
    }
    return null
  }

  const saveChanges = (changes) => {
    const keys = Object.keys(changes)
    const valuationsToCreateOrUpdate = []
    keys.forEach((key) => {
      switch (key) {
        case DEFINITIONS.MARKET_VALUE:
          valuationsToCreateOrUpdate.push(
            getCreateOrUpdatePayload(
              mostRecentMarketValue,
              changes[key],
              valuationCreateMethods.firstMarketValue,
            ),
          )
          break
        case DEFINITIONS.MORTGAGE_LENDING_VALUE:
          valuationsToCreateOrUpdate.push(
            getCreateOrUpdatePayload(
              mostRecentMortgageLendingValue,
              changes[key],
              valuationCreateMethods.firstMortgageLendingValue,
            ),
          )
          break
        case DEFINITIONS.PURCHASE_PRICE:
          valuationsToCreateOrUpdate.push(
            getCreateOrUpdatePayload(
              mostRecentPurchasePrice,
              changes[key],
              valuationCreateMethods.purchasePrice,
            ),
          )
          break
      }
    })
    putValuations({
      property_uuid: property_uuid,
      valuations: valuationsToCreateOrUpdate.filter((valuation) => valuation),
    })
  }

  const isLoading =
    isLoadingValuationCreateMethodCodes || isLoadingValuations || isLoadingPutValuations
  const isError = isErrorValuationCreateMethodCodes || isErrorValuations

  return (
    <DisplayAndEditCard
      cardHeaderTitle={t('card.title')}
      isLoading={isLoading}
      isError={isError}
      saveHookIsSuccess={isSuccessPutValuations}
      saveHookIsError={isErrorPutValuations}
      saveHookError={errorCreateOrUpdateValuations}
      fieldDefinitions={fieldDefinitions}
      saveChanges={saveChanges}
      isEditable={isAllowedPropertyValuationsUpdate}
    />
  )
}
export default PropertyValuationCard
