import PropTypes from 'prop-types'
import React, { useContext } from 'react'
import { kpisAllowedOperations } from 'api/kpis/kpisAllowedOperations'
import KpiOverviewCard from 'components/domains/kpis/KpiOverviewCard'
import KpiOverviewChartCard from 'components/domains/kpis/KpiOverviewChartCard'
import EntityTypeAndIdWithClipboard from 'components/ui/entity-info/EntityTypeAndIdWithClipboard'
import CWPLayout from 'components/ui/layout/CWPLayout'
import { cwpEntityTypes } from 'constants/cwpEntityTypes'
import KpiPermissionWrapper from 'routes/kpis/KpiPermissionWrapper'
import { PropertyContext } from 'routes/properties/PropertyContext'
import PropertyPage from 'routes/properties/PropertyPage'

const PropertyKpiOverviewPage = ({
  propertyDescription,
  breadcrumbs,
  status,
  additionalStatuses,
}) => {
  const { property } = useContext(PropertyContext)

  const subtitle = <EntityTypeAndIdWithClipboard id={property.id} />

  return (
    <PropertyPage
      pageTitle={propertyDescription}
      id={property.id}
      subtitle={subtitle}
      breadcrumbs={breadcrumbs}
      status={status}
      additionalStatuses={additionalStatuses}
    >
      <KpiPermissionWrapper requiredOperation={kpisAllowedOperations.readKpi}>
        <CWPLayout>
          <CWPLayout.Full>
            <KpiOverviewCard
              referenceEntityType={cwpEntityTypes.PROPERTY}
              referenceEntityId={property.uuid}
            />
          </CWPLayout.Full>
          <CWPLayout.Full>
            <KpiOverviewChartCard
              referenceEntityType={cwpEntityTypes.PROPERTY}
              referenceEntityId={property.uuid}
            />
          </CWPLayout.Full>
        </CWPLayout>
      </KpiPermissionWrapper>
    </PropertyPage>
  )
}

PropertyKpiOverviewPage.propTypes = {
  propertyDescription: PropTypes.string.isRequired,
  breadcrumbs: PropTypes.arrayOf(
    PropTypes.shape({
      text: PropTypes.string.isRequired,
      href: PropTypes.string,
    }),
  ),
  status: PropTypes.shape({
    text: PropTypes.string.isRequired,
    valueState: PropTypes.string,
  }),
  additionalStatuses: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      text: PropTypes.string.isRequired,
      valueState: PropTypes.string,
    }),
  ),
}

export default PropertyKpiOverviewPage
