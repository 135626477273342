import { Button, CustomListItem, Label, ListItemType } from '@fioneer/ui5-webcomponents-react'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import styles from 'components/domains/business-partners/tile/general-information/keyInternalContact/KeyInternalContactEdit.module.css'
import { KeyInternalContactEditField } from 'components/domains/business-partners/tile/general-information/keyInternalContact/KeyInternalContactEditField'
import { KeyInternalContactEditItem } from 'components/domains/business-partners/tile/general-information/keyInternalContact/KeyInternalContactEditItem'
import StaffMemberAutocompleteInput from 'components/ui/input/StaffMemberAutocompleteInput'
import LoadingStateWrapper from 'components/ui/screens/LoadingStateWrapper'
import useStaffMemberById from 'hooks/services/business-partners/staff-members/useStaffMemberById'

/**
 * Edit component for create, edit, delete a key internal contact
 * @param id of the key internal contact
 * @param functions assigned functions of the key internal contact
 * @param editKeyInternalContact callBack function to edit the key internal contact
 * @param removeKeyInternalContact callBack function to remove the key internal contact
 * @param renderMultiSelectComponent component with multi select of key internal functions
 * @param index index of the key internal contact
 * @param userIdsToExcludeFromSuggestions array of user ids to exclude from the staff member suggestions
 */
const KeyInternalContactEdit = ({
  id,
  functions,
  editKeyInternalContact,
  removeKeyInternalContact,
  renderMultiSelectComponent,
  userIdsToExcludeFromSuggestions = [],
}) => {
  const { t } = useTranslation(undefined, {
    keyPrefix: 'components.business-partners.general-information.key-internal-contacts',
  })
  const { data: keyInternalContact, isFetching, isError } = useStaffMemberById(id)

  const handleKeyContactFunctionsSelectionChange = (event) => {
    const newFunctions = event.detail.items.map(({ dataset, textContent }) => ({
      id: dataset.value,
      name: textContent,
    }))
    editKeyInternalContact(id, newFunctions)
  }

  const handleStaffMemberChange = (staffMember) => {
    editKeyInternalContact(staffMember?.id, functions)
  }

  const staffMemberSuggestionsFilter = (staffMember) =>
    !userIdsToExcludeFromSuggestions.includes(staffMember.id)

  return (
    <CustomListItem type={ListItemType.Inactive}>
      <LoadingStateWrapper
        isError={isError}
        isLoading={isFetching}
        renderContent={() => (
          <KeyInternalContactEditItem>
            <KeyInternalContactEditField>
              <Label required>{t('user')}</Label>
              <StaffMemberAutocompleteInput
                staffMemberSuggestionsFilter={staffMemberSuggestionsFilter}
                staffMemberName={keyInternalContact?.fullName || ''}
                suggestionsLimit={null}
                searchMinLength={1}
                onStaffMemberSelect={handleStaffMemberChange}
              />
            </KeyInternalContactEditField>
            <KeyInternalContactEditField>
              {/* Always show plural for this label */}
              <Label required>{t('function', { count: 2 })}</Label>
              {renderMultiSelectComponent({
                userId: id,
                selected: functions,
                onSelectionChange: handleKeyContactFunctionsSelectionChange,
              })}
            </KeyInternalContactEditField>
            <div className={styles.buttonContainer}>
              <Button onClick={removeKeyInternalContact} design="Transparent">
                {t('delete')}
              </Button>
            </div>
          </KeyInternalContactEditItem>
        )}
      />
    </CustomListItem>
  )
}

KeyInternalContactEdit.propTypes = {
  id: PropTypes.string.isRequired,
  functions: PropTypes.arrayOf(
    PropTypes.shape({ id: PropTypes.string.isRequired, name: PropTypes.string.isRequired }),
  ),
  editKeyInternalContact: PropTypes.func.isRequired,
  removeKeyInternalContact: PropTypes.func.isRequired,
  renderMultiSelectComponent: PropTypes.func.isRequired,
  userIdsToExcludeFromSuggestions: PropTypes.arrayOf(PropTypes.string),
}

export default KeyInternalContactEdit
