import {
  FlexBox,
  FlexBoxJustifyContent,
  IllustratedMessage,
  IllustrationMessageSize,
  IllustrationMessageType,
} from '@fioneer/ui5-webcomponents-react'
import PropType from 'prop-types'
import { useTranslation } from 'react-i18next'
import { isMissingPermissionError, isNotFoundError } from 'api/requests'
import PropertyAssignMarketButton from 'components/domains/properties/PropertyAssignMarketButton'
import PropertyMarketInfoCard from 'components/domains/properties/overview/PropertyMarketInfoCard'
import EmptyCard from 'components/ui/card/EmptyCard'
import LoadingStateWrapper from 'components/ui/screens/LoadingStateWrapper'
import '@ui5/webcomponents-fiori/dist/illustrations/sapIllus-Spot-UnableToLoad.js'
import usePropertyIdForAssignedMarket from 'hooks/services/markets/usePropertyIdForAssignedMarket'
import { usePropertyMonitoring } from 'hooks/services/properties/monitoring/usePropertyMonitoring'

const PropertyMarketInfoCardWrapper = ({ propertyId, displayAssignButton }) => {
  const { t } = useTranslation('translation', { keyPrefix: 'pages.property-overview.market-info' })
  const {
    isLoading,
    isError,
    data: marketData,
    error,
  } = usePropertyIdForAssignedMarket([propertyId])

  const {
    isLoading: monitoringIsLoading,
    isError: monitoringIsError,
    data: monitoringData,
    error: monitoringError,
  } = usePropertyMonitoring(propertyId)

  const monitoringStatus = monitoringData?.monitoring_status

  const renderCustomErrorScreen = () => (
    <IllustratedMessage
      name={IllustrationMessageType.UnableToLoad}
      size={IllustrationMessageSize.Scene}
      titleText={t('error.title')}
      subtitleText={t('error.description')}
    />
  )

  const renderAssignMarketButton = () =>
    displayAssignButton && (
      <FlexBox justifyContent={FlexBoxJustifyContent.Center} style={{ marginTop: '16px' }}>
        <PropertyAssignMarketButton
          propertyId={propertyId}
          label={t('assign')}
          monitoringStatus={monitoringStatus}
        />
      </FlexBox>
    )
  const renderContent = () => {
    if (marketData.markets.length === 0) {
      return (
        <>
          <EmptyCard
            cardHeaderTitle={t('empty.header-title')}
            title={t('empty')}
            subtitle={t('empty.subtitle')}
            showCardHeaderActions={false}
            actions={renderAssignMarketButton()}
          />
        </>
      )
    }
    const market = marketData.markets[0] // hook either returns one market at position [0] or no market
    return (
      <PropertyMarketInfoCard
        propertyId={propertyId}
        marketId={market.id}
        marketName={market.info.name}
        marketType={market.market_type.value}
        marketPropertyIds={market.properties}
        monitoringStatus={monitoringStatus}
        displayAssignButton={displayAssignButton}
      />
    )
  }

  return (
    <LoadingStateWrapper
      isLoading={isLoading || monitoringIsLoading}
      loadingTitle={t('loading')}
      loadingDescription={t('loading.description')}
      isError={
        (isError && !isMissingPermissionError(error)) ||
        (monitoringIsError &&
          !isMissingPermissionError(monitoringError) &&
          !isNotFoundError(monitoringError))
      }
      renderContent={renderContent}
      renderCustomErrorScreen={renderCustomErrorScreen}
    />
  )
}

PropertyMarketInfoCardWrapper.propTypes = {
  propertyId: PropType.string.isRequired,
  displayAssignButton: PropType.bool.isRequired,
}

export default PropertyMarketInfoCardWrapper
