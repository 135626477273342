import {
  TableGrowingMode,
  FlexBoxJustifyContent,
  ButtonDesign,
  FlexBox,
  FlexBoxAlignItems,
  Modals,
  Text,
} from '@fioneer/ui5-webcomponents-react'
import { useQueryClient } from '@tanstack/react-query'
import isNil from 'lodash.isnil'
import PropTypes from 'prop-types'
import { useCallback, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import {
  marketValuationConfirmationStatus,
  getObjectStatusForMarketValuationConfirmationStatus,
  propertyMonitoringPermissions,
} from 'api/property/monitoring/propertyMonitoringConfiguration'
import styles from 'components/domains/properties/monitoring/market-valuation/MarketValueConfirmationCard.module.css'
import MarketValueConfirmationStatusChangeButton from 'components/domains/properties/monitoring/market-valuation/MarketValueConfirmationStatusChangeButton'
import MarketConfirmationUpdateActionCell from 'components/domains/properties/monitoring/market-valuation/MarketValueConfirmationUpdateActionCell'
import BusinessEventCell from 'components/domains/properties/monitoring/market-valuation/table-cells/BusinessEventCell'
import MarketValueConfirmationStatusCell from 'components/domains/properties/monitoring/market-valuation/table-cells/MarketValueConfirmationStatusCell'
import UserTableCell from 'components/domains/properties/monitoring/market-valuation/table-cells/UserTableCell'
import Card from 'components/ui/card/Card'
import { ErrorDataUnavailableInContent } from 'components/ui/errors/ErrorDataUnavailableInContent'
import { RequestStateResolver } from 'components/ui/loading/RequestStateResolver'
import {
  useShowMessageBox,
  MessageBoxActions,
  MessageBoxTypes,
} from 'components/ui/message-box/MessageBox'
import CollapsibleRichTextView from 'components/ui/rich-text/CollapsibleRichTextView'
import SortedTable from 'components/ui/tables/sorted-tables/SortedTable'
import { useShortDateFormatter } from 'hooks/i18n/useI18n'
import useMarketValueConfirmations from 'hooks/services/properties/monitoring/useMarketValueConfirmations'
import usePropertyMonitoringPermissions from 'hooks/services/properties/monitoring/usePropertyMonitoringPermissions'

const COMMENT_COLLAPSIBLE_CHARACTER_LIMIT = 200
const COMMENT_MAX_LENGTH = 600
const PAGINATION_LIMIT = 50

const isEventCompletedError = (responseData) => responseData?.isEventCompletedSuccessfully === false
const isLinkedEventCompleted = (responseData) => responseData?.isEventCompletedSuccessfully
const isCommentWrittenError = (responseData) => responseData?.isCommentWrittenToEvent === false
const isValuationRequestCreationError = (responseData) =>
  responseData?.isValuationRequestCreatedSuccessfully === false

const MarketValueConfirmationCard = ({ propertyUuid }) => {
  const { t: tNoPrefix } = useTranslation()
  const { t } = useTranslation('translation', {
    keyPrefix: 'components.property-monitoring.market-valuation-confirmation',
  })
  const { format: formatDateAndTime } = useShortDateFormatter({
    hour: '2-digit',
    minute: '2-digit',
  })

  const queryClient = useQueryClient()

  const {
    isFetching: isFetchingPropertiesAllowedOperations,
    isError: isErrorPropertiesAllowedOperations,
    data: propertyPermissions,
  } = usePropertyMonitoringPermissions()

  const hasConfirmatUpdatePermission = propertyPermissions?.includes(
    propertyMonitoringPermissions.confirmationUpdate,
  )

  const marketValuationConfirmationStatusEnum = useMemo(
    () => ({
      [marketValuationConfirmationStatus.pending]: tNoPrefix(
        getObjectStatusForMarketValuationConfirmationStatus(
          marketValuationConfirmationStatus.pending,
        ).translationKey,
      ),
      [marketValuationConfirmationStatus.confirmed]: tNoPrefix(
        getObjectStatusForMarketValuationConfirmationStatus(
          marketValuationConfirmationStatus.confirmed,
        ).translationKey,
      ),
      [marketValuationConfirmationStatus.notConfirmed]: tNoPrefix(
        getObjectStatusForMarketValuationConfirmationStatus(
          marketValuationConfirmationStatus.notConfirmed,
        ).translationKey,
      ),
      ['default']: tNoPrefix(
        getObjectStatusForMarketValuationConfirmationStatus('default').translationKey,
      ),
    }),
    [tNoPrefix],
  )

  const columns = useMemo(
    () => [
      {
        title: t('table.columns.event'),
        columnKey: 'event',
      },
      {
        title: t('table.columns.confirmation-status'),
        columnKey: 'confirmationStatus',
        additionalFilterOptions: {
          enumValues: marketValuationConfirmationStatusEnum,
          allowCustomValues: false,
        },
      },
      {
        title: t('table.columns.changed-on'),
        columnKey: 'changedOn',
        alignment: FlexBoxJustifyContent.End,
        minWidth: 600,
        demandPopin: true,
        popinText: t('table.columns.changed-on'),
      },
      {
        title: t('table.columns.changed-by'),
        columnKey: 'changedBy',
        minWidth: 400,
        demandPopin: true,
        popinText: t('table.columns.changed-by'),
      },
      {
        title: t('table.columns.comment'),
        columnKey: 'comment',
        minWidth: 1500,
        demandPopin: true,
        popinText: t('table.columns.comment'),
      },
      {
        title: '',
        columnKey: 'actions',
        minWidth: 600,
        demandPopin: true,
        isVisible: hasConfirmatUpdatePermission,
        isSelectableForHiding: false,
      },
    ],
    [hasConfirmatUpdatePermission, marketValuationConfirmationStatusEnum, t],
  )

  const {
    isFetching,
    isError,
    data: marketValueConfirmationData,
    fetchNextPage,
  } = useMarketValueConfirmations({ propertyUuid, limit: PAGINATION_LIMIT })

  const showMessageBox = useShowMessageBox()
  const showToast = Modals.useShowToast()

  const showErrorMessageBox = useCallback(
    ({ errorMessage, isWarning = false }) => {
      showMessageBox(
        {
          type: isWarning ? MessageBoxTypes.Warning : MessageBoxTypes.Error,
          titleText: isWarning ? t('update.warning.title') : t('update.error.title'),
          children: errorMessage,
          actions: [MessageBoxActions.Close],
        },
        document.body,
      )
    },
    [t, showMessageBox],
  )

  const handleStatusChangeSuccess = useCallback(
    (responseData, eventId) => {
      queryClient.invalidateQueries([
        'property-monitoring',
        'market-value-confirmations',
        propertyUuid,
      ])
      if (isLinkedEventCompleted(responseData)) {
        queryClient.invalidateQueries(['events', eventId])
      }
      if (isEventCompletedError(responseData)) {
        showErrorMessageBox({
          errorMessage: t('update.error.business-event-completed.text'),
          isWarning: true,
        })
        return
      }
      if (isCommentWrittenError(responseData)) {
        showErrorMessageBox({
          errorMessage: t('update.error.comment-written.text'),
          isWarning: true,
        })
        return
      }
      if (isValuationRequestCreationError(responseData)) {
        showErrorMessageBox({ errorMessage: t('update.error.valuation-request-creation.text') })
        return
      }
      showToast({ children: t('update.success.text') })
    },
    [queryClient, propertyUuid, showErrorMessageBox, showToast, t],
  )

  const handleStatusChangeError = useCallback(() => {
    showErrorMessageBox({ errorMessage: t('update.error.text') })
  }, [showErrorMessageBox, t])

  const renderTable = useCallback(() => {
    const tableData =
      marketValueConfirmationData?.marketValueConfirmations?.map(
        ({
          id,
          info: {
            eventId,
            status: confirmationStatus,
            lastUpdatedBy: changedBy,
            lastUpdatedAt: changedOn,
            comment,
          },
        }) => ({
          rowKey: `market-value-confirmation-${id}`,
          event: {
            cellComponent: <BusinessEventCell eventId={eventId} />,
          },
          confirmationStatus: {
            cellComponent: (
              <MarketValueConfirmationStatusCell
                confirmationStatus={confirmationStatus}
                eventId={eventId}
              />
            ),
          },
          changedBy: {
            cellComponent: isNil(changedBy) ? undefined : (
              <UserTableCell
                userId={changedBy}
                errorMessage={t('table.columns.changed-by.loading.error')}
              />
            ),
          },
          changedOn: {
            cellComponent: isNil(changedOn) ? undefined : (
              <Text>{formatDateAndTime(changedOn)}</Text>
            ),
          },
          comment: {
            cellComponent: isNil(comment) ? undefined : (
              <CollapsibleRichTextView
                text={comment}
                characterLimit={COMMENT_COLLAPSIBLE_CHARACTER_LIMIT}
              />
            ),
          },
          actions: {
            cellComponent: (
              <MarketConfirmationUpdateActionCell
                eventId={eventId}
                confirmationStatus={confirmationStatus}
                actionButtons={
                  <FlexBox
                    fitContainer
                    alignItems={FlexBoxAlignItems.Center}
                    className={styles.actionButtonContainer}
                  >
                    <MarketValueConfirmationStatusChangeButton
                      key="confirm"
                      marketValueConfirmationId={id}
                      eventId={eventId}
                      buttonDesign={ButtonDesign.Positive}
                      statusForChange={marketValuationConfirmationStatus.confirmed}
                      onStatusChangeSuccess={handleStatusChangeSuccess}
                      onStatusChangeError={handleStatusChangeError}
                      translationPrefix="components.property-monitoring.market-valuation-confirmation.confirm"
                      maxCommentLength={COMMENT_MAX_LENGTH}
                    />
                    <MarketValueConfirmationStatusChangeButton
                      key="deny"
                      marketValueConfirmationId={id}
                      eventId={eventId}
                      buttonDesign={ButtonDesign.Negative}
                      statusForChange={marketValuationConfirmationStatus.notConfirmed}
                      onStatusChangeSuccess={handleStatusChangeSuccess}
                      onStatusChangeError={handleStatusChangeError}
                      translationPrefix="components.property-monitoring.market-valuation-confirmation.deny"
                      maxCommentLength={COMMENT_MAX_LENGTH}
                    />
                  </FlexBox>
                }
              />
            ),
          },
        }),
      ) ?? []
    return (
      <div className={styles.tableWrapper}>
        <SortedTable
          additionalTableProperties={{
            // Inline styling is needed because ui5 overwrites height property
            style: {
              maxHeight: '462px',
              overflowX: 'hidden',
              overflowY: 'auto',
            },
          }}
          columnDefinitions={columns}
          tableData={tableData}
          toolbarConfig={{
            title: t('table.title'),
          }}
          noDataText={t('table.no-data.text')}
          paginationConfig={{
            growing:
              tableData.length < marketValueConfirmationData?.total
                ? TableGrowingMode.Button
                : undefined,
            growingButtonText: tNoPrefix('buttons.more'),
            growingButtonSubtext: `[${tableData.length} / ${
              marketValueConfirmationData?.total ?? 0
            }]`,
            totalNumberOfItems: marketValueConfirmationData?.total,
            loadMore: fetchNextPage,
          }}
        />
      </div>
    )
  }, [
    columns,
    fetchNextPage,
    formatDateAndTime,
    handleStatusChangeError,
    handleStatusChangeSuccess,
    marketValueConfirmationData?.marketValueConfirmations,
    marketValueConfirmationData?.total,
    t,
    tNoPrefix,
  ])

  return (
    <Card className={styles.card}>
      <RequestStateResolver
        isLoading={isFetching || isFetchingPropertiesAllowedOperations}
        isError={isError || isErrorPropertiesAllowedOperations}
        errorToDisplay={<ErrorDataUnavailableInContent />}
        renderContent={renderTable}
      />
    </Card>
  )
}

MarketValueConfirmationCard.propTypes = {
  propertyUuid: PropTypes.string.isRequired,
}

export default MarketValueConfirmationCard
