import { Grid, MessageStrip } from '@fioneer/ui5-webcomponents-react'
import { useContext, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import styles from 'components/domains/properties/rent-roll/comparison/PropertyRentRollComparison.module.css'
import PropertyRentRollComparisonCard from 'components/domains/properties/rent-roll/comparison/PropertyRentRollComparisonCard'
import PropertyRentRollComparisonCurrentRentCard from 'components/domains/properties/rent-roll/comparison/PropertyRentRollComparisonCurrentRentCard'
import PropertyRentRollComparisonCurrentRentPerUomCard from 'components/domains/properties/rent-roll/comparison/PropertyRentRollComparisonCurrentRentPerUomCard'
import PropertyRentRollComparisonVacancyCard from 'components/domains/properties/rent-roll/comparison/PropertyRentRollComparisonVacancyCard'
import PropertyRentRollComparisonWaultCard from 'components/domains/properties/rent-roll/comparison/PropertyRentRollComparisonWaultCard'
import { CurrentRentRollContext } from 'components/domains/properties/rent-roll/comparison/constants'
import CWPLayout from 'components/ui/layout/CWPLayout'
import { RequestStateResolver } from 'components/ui/loading/RequestStateResolver'
import { useShortDateFormatter } from 'hooks/i18n/useI18n'
import { useRentRoll } from 'hooks/services/properties/useRentRoll'
import { PropertyContext } from 'routes/properties/PropertyContext'

const PropertyRentRollComparison = () => {
  const { property } = useContext(PropertyContext)
  const { t: tComparison } = useTranslation('translation', {
    keyPrefix: 'pages.property.rent-roll.comparison',
  })
  const rentRollToCompare = useSelector(
    (state) => state.properties.compareRentRoll.rentRollToCompare,
  )

  const comparisonKeyDate = useMemo(() => rentRollToCompare?.keyDate, [rentRollToCompare])

  const { format: formatDate } = useShortDateFormatter()

  const {
    isLoading: isLoadingRentRoll,
    isError: isErrorRentRoll,
    data: currentRentRoll,
  } = useRentRoll(property.uuid)

  const renderComparisonMessageStripContent = () => (
    <>
      {rentRollToCompare?.index &&
        tComparison('in-comparison', {
          currentKeyDate: formatDate(currentRentRoll?.key_date),
          comparisonKeyDate: formatDate(comparisonKeyDate),
        })}
      {!rentRollToCompare?.index && tComparison('not-in-comparison')}
    </>
  )

  return (
    <RequestStateResolver
      isLoading={isLoadingRentRoll}
      isError={isErrorRentRoll}
      center={true}
      renderContent={() => (
        <CurrentRentRollContext.Provider value={currentRentRoll}>
          <CWPLayout>
            <CWPLayout.Full>
              <MessageStrip type="Information" hideCloseButton={true}>
                {renderComparisonMessageStripContent()}
              </MessageStrip>
            </CWPLayout.Full>
            <CWPLayout.Full>
              <Grid defaultSpan="XL3 L3 M12 S12" hSpacing={'16px'} className={styles.grid}>
                <PropertyRentRollComparisonCurrentRentCard />
                <PropertyRentRollComparisonCurrentRentPerUomCard />
                <PropertyRentRollComparisonVacancyCard />
                <PropertyRentRollComparisonWaultCard />
              </Grid>
            </CWPLayout.Full>
            <CWPLayout.Full>
              <PropertyRentRollComparisonCard />
            </CWPLayout.Full>
          </CWPLayout>
        </CurrentRentRollContext.Provider>
      )}
    />
  )
}

export default PropertyRentRollComparison
