const RISK_MONITORING_STATUS_KEY = 'riskMonitoringStatus'

export const mapRiskMonitoringStatus = (dealUuid, rowResponses) => {
  const response = rowResponses?.find(({ data }) => data?.dealUuid === dealUuid)
  const result = response?.data?.customFields?.find(
    (field) => field.key === RISK_MONITORING_STATUS_KEY,
  )

  return result?.value ?? null
}
