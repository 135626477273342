import { IllustratedMessage } from '@fioneer/ui5-webcomponents-react'
import camelize from 'camelize'
import { useContext, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { propertyAllowedOperations } from 'api/property/propertyAllowedOperations'
import PropertyValuationsRequests from 'components/domains/properties/valuation/PropertyValuationsRequests'
import PropertyValuationsResults from 'components/domains/properties/valuation/PropertyValuationsResults'
import { PropertyContext } from 'routes/properties/PropertyContext'

const PropertyValuations = () => {
  const { t } = useTranslation('translation', { keyPrefix: 'app' })
  const { property } = useContext(PropertyContext)

  const camelizedProperty = useMemo(() => camelize(property), [property])
  const allowedOperations = camelizedProperty?.allowedOperations?.allowedOperations
  if (!allowedOperations?.includes(propertyAllowedOperations.propertyValuationRead)) {
    return (
      <IllustratedMessage
        size="Spot"
        name="UnableToLoad"
        titleText={t('permission-error.title')}
        subtitleText={t('permission-error.subtitle')}
      />
    )
  }

  return (
    <>
      <PropertyValuationsResults
        propertyUuid={camelizedProperty.uuid}
        propertyCurrencyCode={camelizedProperty.currencyCode}
        propertyAllowedOperations={camelizedProperty.allowedOperations.allowedOperations}
      />
      <PropertyValuationsRequests
        propertyUuid={camelizedProperty.uuid}
        propertyDisplayId={camelizedProperty.id}
        propertyName={camelizedProperty.description}
        propertyAddress={camelizedProperty.address}
        allowedOperations={allowedOperations}
      />
    </>
  )
}

export default PropertyValuations
