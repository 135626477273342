import { DynamicPageTitle, Title, TitleLevel } from '@fioneer/ui5-webcomponents-react'
import PropTypes from 'prop-types'
import { useMemo } from 'react'
import { decisionStageStatus as possibleDecisionStageStatus } from 'api/decision-process/decisionProcessApi'
import useDecisionStageName from 'components/domains/business-events-and-tasks/common/useDecisionStageName'
import styles from 'components/domains/business-events-and-tasks/decision-stage/DecisionStageTitle.module.css'
import SmallLoadingWrapper from 'components/ui/loading/SmallLoadingWrapper'
import useDecisionStageDetailButtons from 'hooks/services/business-events-and-tasks/decision-process/stages/buttons/useDecisionStageDetailButtons'
import useDecisionStageOverarchingVoteButtons from 'hooks/services/business-events-and-tasks/decision-process/stages/buttons/useDecisionStageOverarchingVoteButtons'

const noop = () => {}

const DecisionStageTitle = ({
  decisionStageId,
  decisionStageName,
  decisionStageType,
  decisionStageStatus,
  decisionPaperVersion,
}) => {
  const decisionStageDisplayName = useDecisionStageName({
    name: decisionStageName,
    decisionStageType,
  })

  const {
    buttons: overarchingVoteButtons,
    isLoading: isOverarchingVoteButtonsLoading,
    isError: isOverarchingVoteButtonsError,
  } = useDecisionStageOverarchingVoteButtons({
    decisionStageId,
    decisionStageStatus,
    decisionStageType,
  })

  const { buttons: decisionStageDetailButtons } = useDecisionStageDetailButtons({
    decisionStageId,
    decisionStageStatus,
    decisionPaperVersion,
  })

  const actions = useMemo(() => {
    if (isOverarchingVoteButtonsLoading || isOverarchingVoteButtonsError) {
      return (
        <SmallLoadingWrapper
          isLoading={isOverarchingVoteButtonsLoading}
          isError={isOverarchingVoteButtonsError}
          error=""
          renderContent={noop}
        />
      )
    }

    return [...overarchingVoteButtons, ...decisionStageDetailButtons]
  }, [
    decisionStageDetailButtons,
    isOverarchingVoteButtonsError,
    isOverarchingVoteButtonsLoading,
    overarchingVoteButtons,
  ])

  return (
    <DynamicPageTitle
      className={styles.header}
      header={
        <Title level={TitleLevel.H4} className={styles.headerText}>
          {decisionStageDisplayName}
        </Title>
      }
      actions={actions}
    />
  )
}

DecisionStageTitle.propTypes = {
  decisionStageId: PropTypes.string.isRequired,
  decisionStageName: PropTypes.string.isRequired,
  decisionStageType: PropTypes.string.isRequired,
  decisionStageStatus: PropTypes.oneOf(Object.values(possibleDecisionStageStatus)).isRequired,
  decisionPaperVersion: PropTypes.string,
}

export default DecisionStageTitle
