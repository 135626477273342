import { Text } from '@fioneer/ui5-webcomponents-react'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import {
  CartesianGrid,
  Legend,
  Line,
  LineChart,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from 'recharts'
import CheckResultsChartTooltip from 'components/domains/deals/covenants/check-results-card/CheckResultsChartTooltip'
import useYearlyTickConfig from 'components/domains/deals/covenants/check-results-card/useYearlyTickConfig'
import styles from 'components/domains/deals/deal-cashflow/result-charts/chart-components/DealCashflowLineChart.module.css'
import sharedChartStyles from 'components/ui/charts/shared-chart-styles.module.css'
import {
  useNumberFormatter,
  usePercentageFormatter,
  useShortDateFormatter,
} from 'hooks/i18n/useI18n'

const AXIS_COLOR = '#9B9B9B'
const SOFT_LIMIT_COLOR = '#C52C4A'
const HARD_LIMIT_COLOR = '#9B3285'
const RESULT_COLOR = '#772ACB'

const RotatedTick = ({ x, y, payload }) => {
  const { format: formatDate } = useShortDateFormatter()
  return (
    <g transform={`translate(${x},${y})`}>
      <text x={0} y={0} dy={12} textAnchor="end" fill="#666" transform="rotate(-45)">
        {formatDate(new Date(payload.value).toISOString())}
      </text>
    </g>
  )
}
RotatedTick.propTypes = {
  x: PropTypes.number,
  y: PropTypes.number,
  payload: PropTypes.shape({ value: PropTypes.number }),
}

const CheckResultsChart = ({ chartData = [], segmentedChartData = {} }) => {
  const { t } = useTranslation('decisionPaper', {
    keyPrefix: 'components.deals.covenants.results.check-results',
  })

  const { ticks } = useYearlyTickConfig(chartData)

  const formatPercentage = usePercentageFormatter()
  const formatNumber = useNumberFormatter({
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
    notation: 'compact',
  })

  const yTickFormatter =
    chartData[0]?.unit === 'PERCENT' ? (val) => formatPercentage(val / 100) : formatNumber

  return (
    <ResponsiveContainer width="100%" aspect={2.2} maxHeight={400}>
      <LineChart
        data={chartData}
        margin={{ left: 8, bottom: 28 }}
        className={`${sharedChartStyles.cwpChart}`}
      >
        <CartesianGrid vertical={false} />

        <YAxis axisLine={false} tickLine={false} tickFormatter={yTickFormatter} />

        <XAxis
          dataKey="keyDate"
          type="number"
          tickLine={false}
          domain={['dataMin', 'dataMax']}
          scale="time"
          ticks={ticks}
          tick={<RotatedTick />}
          interval="preserveStartEnd"
          padding={{
            left: 20,
            right: 20,
          }}
          axisLine={{
            stroke: AXIS_COLOR,
          }}
        />

        <Tooltip content={<CheckResultsChartTooltip />} />

        <Legend
          wrapperStyle={{ marginBottom: '-42px' }}
          payload={[
            {
              value: <Text className={styles.legendText}>{t('soft-limit')}</Text>,
              type: 'line',
              color: SOFT_LIMIT_COLOR,
              payload: {},
            },
            {
              value: <Text className={styles.legendText}>{t('hard-limit')}</Text>,
              type: 'line',
              color: HARD_LIMIT_COLOR,
              payload: {},
            },
            {
              value: <Text className={styles.legendText}>{t('result')}</Text>,
              type: 'line',
              color: RESULT_COLOR,
              payload: {},
            },
          ]}
        />

        {!!segmentedChartData?.chartDataBeforeLastResultDate && (
          <Line
            dataKey="softLimit"
            data={segmentedChartData?.chartDataBeforeLastResultDate}
            type="stepAfter"
            stroke={SOFT_LIMIT_COLOR}
          />
        )}
        {!!segmentedChartData?.chartDataAfterLastResultDate && (
          <Line
            dataKey="softLimit"
            data={segmentedChartData?.chartDataAfterLastResultDate}
            type="stepAfter"
            stroke={SOFT_LIMIT_COLOR}
            strokeDasharray="3 3"
          />
        )}

        {!!segmentedChartData?.chartDataBeforeLastResultDate && (
          <Line
            dataKey="hardLimit"
            data={segmentedChartData?.chartDataBeforeLastResultDate}
            type="stepAfter"
            stroke={HARD_LIMIT_COLOR}
          />
        )}
        {!!segmentedChartData?.chartDataAfterLastResultDate && (
          <Line
            dataKey="hardLimit"
            data={segmentedChartData?.chartDataAfterLastResultDate}
            type="stepAfter"
            stroke={HARD_LIMIT_COLOR}
            strokeDasharray="3 3"
          />
        )}

        <Line type="stepAfter" dataKey="result" stroke={RESULT_COLOR} />
      </LineChart>
    </ResponsiveContainer>
  )
}

const chartDataShape = PropTypes.shape({
  covenantMonitoringItemUuid: PropTypes.string,
  keyDate: PropTypes.number,
  hardLimit: PropTypes.number,
  softLimit: PropTypes.number,
  result: PropTypes.number,
  unit: PropTypes.string,
  currencyCode: PropTypes.string,
})

CheckResultsChart.propTypes = {
  chartData: PropTypes.arrayOf(chartDataShape),
  segmentedChartData: PropTypes.shape({
    chartDataBeforeLastResultDate: PropTypes.arrayOf(chartDataShape),
    chartDataAfterLastResultDate: PropTypes.arrayOf(chartDataShape),
  }),
}

export default CheckResultsChart
