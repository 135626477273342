import { useContext, useMemo } from 'react'
import {
  decisionStageStatus as possibleDecisionStageStatus,
  getButtonForDecisionStageOverarchingVoteOption,
} from 'api/decision-process/decisionProcessApi'
import { businessEventAllowedOperations } from 'api/events/eventAllowedOperations'
import useDecisionStageApprovalOverarchingVoteOptions from 'hooks/services/business-events-and-tasks/decision-process/stages/approval/useDecisionStageApprovalOverarchingVoteOptions'
import BusinessEventsAndTasksContext from 'routes/business-events-and-tasks/BusinessEventsAndTasksContext'

const useDecisionStageOverarchingVoteButtons = ({
  decisionStageId,
  decisionStageStatus,
  decisionStageType,
}) => {
  const {
    event: { id: eventId },
    allowedOperations,
  } = useContext(BusinessEventsAndTasksContext)

  const hasDecisionStageVotePermissions = useMemo(
    () =>
      allowedOperations.includes(businessEventAllowedOperations.updateDecisionStageVote) &&
      allowedOperations.includes(businessEventAllowedOperations.updateDecisionStage),
    [allowedOperations],
  )

  const { isLoading, isError, data } = useDecisionStageApprovalOverarchingVoteOptions(
    {
      eventId,
      decisionStageId,
    },
    { enabled: hasDecisionStageVotePermissions },
  )

  const isDecisionStageInProgressStatus =
    decisionStageStatus === possibleDecisionStageStatus.inProgress

  const buttons = useMemo(() => {
    if (
      isLoading ||
      isError ||
      !hasDecisionStageVotePermissions ||
      !isDecisionStageInProgressStatus
    ) {
      return []
    }

    return data.voteOptions.map(({ code }) => {
      const ButtonComponent = getButtonForDecisionStageOverarchingVoteOption(code)
      return (
        <ButtonComponent
          key={`vote-option-${code}`}
          eventId={eventId}
          decisionStageId={decisionStageId}
          decisionStageType={decisionStageType}
        />
      )
    })
  }, [
    data?.voteOptions,
    decisionStageId,
    decisionStageType,
    eventId,
    hasDecisionStageVotePermissions,
    isDecisionStageInProgressStatus,
    isError,
    isLoading,
  ])

  return useMemo(
    () => ({
      buttons,
      isLoading,
      isError,
    }),
    [buttons, isError, isLoading],
  )
}

export default useDecisionStageOverarchingVoteButtons
