import { FlexBox, FlexBoxDirection, Text, ValueState } from '@fioneer/ui5-webcomponents-react'
import { t } from 'i18next'
import compact from 'lodash.compact'
import isNil from 'lodash.isnil'
import PropTypes from 'prop-types'
import { useContext } from 'react'
import { useTranslation } from 'react-i18next'
import {
  CAG_ROW_TYPE_COLLATERAL,
  CAG_ROW_TYPE_TOTAL,
} from 'components/domains/deals/collaterals/collateral-agreements-table/constants'
import Entity from 'components/ui/data/Entity'
import EntityCell from 'components/ui/tables/cells/EntityCell'
import StatusCell from 'components/ui/tables/cells/StatusCell'
import { ConfigContext } from 'hooks/config/useConfig'
import { useCustomizableCurrencyFormatter } from 'hooks/i18n/useI18n'
import useCollateralAgreementByID from 'hooks/services/collaterals/useCollateralAgreementById'
import styles from 'routes/deals/collaterals/CollateralAgreementsTableCell.module.css'
import { EUR_CURRENCY } from 'routes/deals/syndication/syndicationStructure/utils/constants'

export const renderCagCell = ({ cell, dataSource }) => {
  const { isBold, value, displayId, hasWarning, id: cagId } = cell.value ?? {}
  const { original: { rowType } = {} } = cell.row ?? {}
  const isTotalRow = rowType === CAG_ROW_TYPE_TOTAL
  const isCollateralRow = rowType === CAG_ROW_TYPE_COLLATERAL

  return (
    <FlexBox direction={FlexBoxDirection.Column} className={styles.cagCell}>
      <EntityCell
        name={value}
        id={displayId}
        link={isCollateralRow ? `/collaterals/${String(cagId)}?dataSource=${dataSource}` : null}
        options={{
          isNameBold: (!isCollateralRow && isBold) ?? false,
        }}
        className={compact([isTotalRow && styles.totalRow]).join(' ')}
      />
      {hasWarning && (
        <div className={styles.warningLabel}>
          {t('pages.deals.collateral-agreements-table.missing-information.label')}
        </div>
      )}
    </FlexBox>
  )
}

export const renderBooleanCell = ({ cell, t: tBoolean }) => {
  const value = cell.value

  if (value === '') {
    return <></>
  }

  const text = value ? tBoolean('formatters.boolean.yes') : tBoolean('formatters.boolean.no')
  return <Text wrapping={false}>{text}</Text>
}

export const renderDefaultCell = ({ cell }) => <Text>{cell.value}</Text>

export const StatusColumnCell = ({ cell, t: tStatus, enabled = true }) => {
  /**
   * The reason why we are using isInitialLoading is that in v4 if enabled is set to false, isLoading is set to true.
   * For reference: https://github.com/TanStack/query/issues/3584
   * If we migrate to v5, we can switch back to isLoading
   * */
  const {
    data: { systemStatus: status = {} } = {},
    isInitialLoading,
    isError,
  } = useCollateralAgreementByID(cell?.row?.original?.cag?.id, { enabled })

  const { collateralAgreements: { systemStatus = {} } = {} } = useContext(ConfigContext)

  if (!cell.value) {
    return <></>
  }

  let state
  const statusId = enabled ? status.id : cell?.row?.original?.cag?.id

  switch (statusId) {
    case systemStatus.created:
      state = ValueState.Information
      break
    case systemStatus.active:
      state = ValueState.Success
      break
    case systemStatus.inactive:
      state = ValueState.Error
      break
    default:
      state = ValueState.None
      break
  }

  return (
    <StatusCell
      statusState={state}
      statusValue={tStatus(statusId, {
        keyPrefix: 'pages.deals.collateral-agreements-table',
        defaultValue: cell.value,
      })}
      isError={isError}
      isLoading={isInitialLoading}
    />
  )
}

StatusColumnCell.propTypes = {
  cell: PropTypes.object.isRequired,
  t: PropTypes.func.isRequired,
  enabled: PropTypes.bool,
}

/**
 * @param {object} params
 * @param {object} params.cell
 * @param {object} [params.cell.value]
 * @param {{value: number, currency: string}} [params.cell.value.cagValue]
 * @param {{value: number, currency: string}} [params.cell.value.cagValueInHeadQuarterCurrency]
 * @param {boolean} [params.cell.value.isBold]
 * @param {(value: number, currency: string, options?: object | undefined) => string} params.formatMoney
 */
export const renderMoneyColumnCell = ({ cell, formatMoney }) => {
  const { isBold, cagValue, cagValueInHeadQuarterCurrency } = cell.value ?? {}

  const isSameCurrency = cagValue?.currency === cagValueInHeadQuarterCurrency?.currency

  return (
    <div className={[styles.moneyCell, isBold && styles.textBold].join(' ')}>
      <div className={styles.moneyCellSingleElement}>
        <div>
          {!isNil(cagValue) && (
            <div className={styles.caretCursor}>
              {formatMoney(cagValue.value, cagValue.currency || EUR_CURRENCY, {
                currencyDisplay: 'code',
              })}
            </div>
          )}
          {!isNil(cagValueInHeadQuarterCurrency) && !isSameCurrency && (
            <div className={`${styles.textLabelColor} ${styles.caretCursor}`}>
              {formatMoney(
                cagValueInHeadQuarterCurrency.value,
                cagValueInHeadQuarterCurrency.currency,
                {
                  currencyDisplay: 'code',
                },
              )}
            </div>
          )}
        </div>
      </div>
    </div>
  )
}

export const renderValueCell = ({ cell, formatMoney }) => {
  const isBold = !!cell.value.isBold
  const value = cell.value.cagValue?.value
  const currency = cell.value.cagValue?.currency
  const hqValue = cell.value?.cagValueInHeadQuarterCurrency?.value
  const hqCurrency = cell.value?.cagValueInHeadQuarterCurrency?.currency

  const isSameCurrency = currency === hqCurrency
  const showOriginalCurrencyValue = !isNil(value) && currency
  const showHeadquarterCurrencyValue = !isNil(hqValue) && !isSameCurrency

  return (
    <div className={[styles.moneyCell, isBold && styles.textBold].join(' ')}>
      <div className={styles.moneyCellSingleElement}>
        <div>
          {showOriginalCurrencyValue && (
            <div className={styles.caretCursor}>
              {formatMoney(value, currency || EUR_CURRENCY, {
                currencyDisplay: 'code',
              })}
            </div>
          )}
          {showHeadquarterCurrencyValue && (
            <div
              className={compact([
                showOriginalCurrencyValue && styles.textLabelColor,
                styles.caretCursor,
              ]).join(' ')}
            >
              {formatMoney(hqValue, hqCurrency, {
                currencyDisplay: 'code',
              })}
            </div>
          )}
        </div>
      </div>
    </div>
  )
}

export const CollateralAgreementsTableCell = (props) => {
  const formatMoney = useCustomizableCurrencyFormatter()
  const { t: tFromHook } = useTranslation('translation')

  return props.renderCell({
    cell: props.cell,
    dataSource: props?.dataSource,
    formatMoney,
    t: tFromHook,
  })
}

CollateralAgreementsTableCell.propTypes = {
  cell: PropTypes.object.isRequired,
  row: PropTypes.object.isRequired,
  data: PropTypes.array.isRequired,
  renderCell: PropTypes.func.isRequired,
  dataSource: PropTypes.string,
}

const collateralAgreementsTableCellProps = {
  cell: PropTypes.shape({
    value: PropTypes.shape({
      noOfExistingBusinesses: PropTypes.number,
      noOfNewBusinesses: PropTypes.number,
    }),
  }).isRequired,
}

/** @param {PropTypes.InferProps<typeof collateralAgreementsTableCellProps>} props */
export const FinancialProductsCell = ({ cell }) => {
  const { t: tCagTable } = useTranslation(undefined, {
    keyPrefix: 'components.collateral-agreements.receivables-table',
  })

  if (!cell.value) return false

  return (
    <div className={styles.financialProductsCell}>
      <Text>{`${tCagTable('new-business')}:`}</Text>
      <Text>{cell?.value?.noOfNewBusinesses ?? 0}</Text>
      <Text>{`${tCagTable('existing-business')}:`}</Text>
      <Text>{cell?.value?.noOfExistingBusinesses ?? 0}</Text>
    </div>
  )
}

FinancialProductsCell.propTypes = collateralAgreementsTableCellProps

const nameCellWithLinkedEntityAndDisplayIdProps = {
  cell: PropTypes.shape({
    value: PropTypes.shape({
      name: PropTypes.string,
      displayId: PropTypes.string,
      id: PropTypes.string,
    }),
  }).isRequired,
  path: PropTypes.string.isRequired,
}

/** @param {PropTypes.InferProps<typeof nameCellWithLinkedEntityAndDisplayIdProps>} props */
export const NameCellWithLinkedEntityAndDisplayId = ({ cell: { value }, path }) => (
  <Entity
    className={styles.entityWithOverflowHandling}
    additionalClassNamesForLink={styles.entityLinkWithOverflowHandling}
    name={value?.name}
    id={value?.displayId}
    link={`${path}/${value?.id}`}
    isLinkBold={false}
    openInNewTab
  />
)

NameCellWithLinkedEntityAndDisplayId.propTypes = nameCellWithLinkedEntityAndDisplayIdProps
