import PropTypes from 'prop-types'
import { useKeyInternalContactFunctionsMapping } from 'components/domains/business-partners/tile/general-information/useKeyInternalContactFunctionsMapping'
import LoadingMultiComboBox from 'components/ui/multi-combo-box/LoadingMultiComboBox'
import { useKeyInternalContactFunctions } from 'hooks/services/business-partners/config/useKeyInternalContactFunctions'

const KeyInternalContactFunctionsMultiSelect = ({ userId, selected = [], onSelectionChange }) => {
  const { isError, isLoading, data } = useKeyInternalContactFunctions()

  const selectedIds = selected.map(({ id }) => id)

  return (
    <LoadingMultiComboBox
      id={`key-internal-contact-functions-multi-combo-box-${userId}`}
      useLoader={useKeyInternalContactFunctionsMapping}
      input={{ isError, isLoading, data }}
      onSelectionChange={onSelectionChange}
      selected={selectedIds}
    />
  )
}

KeyInternalContactFunctionsMultiSelect.propTypes = {
  userId: PropTypes.string.isRequired,
  selected: PropTypes.arrayOf(PropTypes.shape({ id: PropTypes.string, name: PropTypes.string })),
  onSelectionChange: PropTypes.func.isRequired,
}

export default KeyInternalContactFunctionsMultiSelect
