import {
  propertyMonitoringCriticalMovementDirection,
  propertyMonitoringThresholdType,
} from 'api/property/monitoring/propertyMonitoringConfiguration'

export const calculateThreshold = ({
  threshold,
  thresholdType,
  baselineDate,
  valueAtFixing,
  criticalMovementDirection,
} = {}) => {
  if (!threshold || thresholdType === propertyMonitoringThresholdType.absolute) {
    return threshold
  }
  if (!baselineDate || !valueAtFixing) {
    return
  }
  const deviationValue = (+threshold / 100) * +valueAtFixing
  if (criticalMovementDirection === propertyMonitoringCriticalMovementDirection.down) {
    return +valueAtFixing - deviationValue
  }
  return +valueAtFixing + deviationValue
}

export const displayThresholdWithUnit = ({ threshold, thresholdType, unit } = {}) => {
  if (threshold) {
    if (thresholdType === propertyMonitoringThresholdType.relative) {
      return `${threshold} %`
    }
    return `${threshold} ${unit}`
  }
  return '-'
}
