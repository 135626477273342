import { FlexBoxJustifyContent } from '@fioneer/ui5-webcomponents-react'
import { useTranslation } from 'react-i18next'
import {
  AlignedColumn,
  CenterAlignedMultiColumn,
} from 'components/domains/properties/rent-roll/overview/PropertyRentRollOverviewColumns'
import { useAreaMeasurementUnitFormatter } from 'hooks/i18n/useI18n'

export const SEGMENT_RENTAL_INCOME_TABLE_COLUMNS = {
  NAME_AND_USAGE_TYPE: 'nameAndUsageType',
  MARKET_RENT: 'marketRent',
  CONTRACTED_VALUES: 'contractedValues',
  CURRENT_VALUES: 'currentValues',
  WAULT: 'wault',
}

export const useSegmentRentalIncomeTableColumns = (property) => {
  const areaMeasureUnit = property?.area_measure_unit_code

  const formateAreaMeasure = useAreaMeasurementUnitFormatter()
  const { t: tRentalIncome } = useTranslation('translation', {
    keyPrefix: 'pages.property.rent-roll.overview.rental-income',
  })

  return [
    {
      columnKey: SEGMENT_RENTAL_INCOME_TABLE_COLUMNS.NAME_AND_USAGE_TYPE,
      renderColumnContent: () => (
        <AlignedColumn>{tRentalIncome('table.columns.name-and-usage-type')}</AlignedColumn>
      ),
    },
    {
      columnKey: SEGMENT_RENTAL_INCOME_TABLE_COLUMNS.MARKET_RENT,
      renderColumnContent: () => (
        <AlignedColumn>{`${tRentalIncome('table.columns.market-rent')}`}</AlignedColumn>
      ),
    },
    {
      columnKey: SEGMENT_RENTAL_INCOME_TABLE_COLUMNS.CONTRACTED_VALUES,
      renderColumnContent: () => (
        <CenterAlignedMultiColumn
          title={tRentalIncome('table.columns.contracted-values')}
          subTitles={[
            tRentalIncome('table.columns.total-rent'),
            `${tRentalIncome('table.columns.rent')} ${formateAreaMeasure(areaMeasureUnit)}`,
            tRentalIncome('table.columns.over-under-rented'),
          ]}
        />
      ),
    },
    {
      columnKey: SEGMENT_RENTAL_INCOME_TABLE_COLUMNS.CURRENT_VALUES,
      renderColumnContent: () => (
        <CenterAlignedMultiColumn
          title={tRentalIncome('table.columns.current-values')}
          subTitles={[
            tRentalIncome('table.columns.total-rent'),
            `${tRentalIncome('table.columns.rent')} ${formateAreaMeasure(areaMeasureUnit)}`,
            tRentalIncome('table.columns.over-under-rented'),
          ]}
        />
      ),
    },
    {
      columnKey: SEGMENT_RENTAL_INCOME_TABLE_COLUMNS.WAULT,
      renderColumnContent: () => (
        <AlignedColumn alignment={FlexBoxJustifyContent.End}>
          {tRentalIncome('table.columns.wault')}
        </AlignedColumn>
      ),
    },
  ]
}
