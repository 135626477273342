import {
  BusyIndicator,
  ButtonDesign,
  Icon,
  IconDesign,
  MessageBoxTypes,
} from '@fioneer/ui5-webcomponents-react'
import PropTypes from 'prop-types'
import { createPortal } from 'react-dom'
import { useTranslation } from 'react-i18next'
import useDownloadDocument from 'components/domains/documents/useDownloadDocument'
import LoadingButton from 'components/ui/button/LoadingButton'
import ConfirmationDialog from 'components/ui/dialog/ConfirmationDialog'

const propTypes = {
  type: PropTypes.oneOf(['icon', 'button']).isRequired,
  documentId: PropTypes.string.isRequired,
  design: PropTypes.oneOfType([
    PropTypes.oneOf(Object.values(ButtonDesign)).isRequired,
    PropTypes.oneOf(Object.values(IconDesign)).isRequired,
  ]),
  documentVersionGuid: PropTypes.string,
  fileName: PropTypes.string.isRequired,
  documentName: PropTypes.string,
  version: PropTypes.string,
}

/** @param {PropTypes.InferProps<typeof propTypes>} props */
const DocumentDownload = ({
  type,
  documentId,
  documentVersionGuid = '',
  fileName,
  documentName,
  version,
}) => {
  const { t } = useTranslation('translation', {
    keyPrefix: 'components.document-download',
  })
  const { t: tNoPrefix } = useTranslation()

  const {
    isLoading,
    isError,
    handleConfirmationDialogOpen,
    handleConfirmationDialogCancel,
    handleConfirmationDialogConfirm,
    isConfirmationDialogOpen,
  } = useDownloadDocument({
    guid: documentId,
    fileName,
    versionGuid: documentVersionGuid ?? undefined,
    documentName,
    version,
  })

  return (
    <>
      {type === 'icon' && (
        <>
          {isLoading ? (
            <BusyIndicator size="Small" delay={0} active={true} />
          ) : (
            <Icon
              name="download"
              interactive
              design={isError ? IconDesign.Negative : undefined}
              title={isError ? t('icon.error') : undefined}
              onClick={handleConfirmationDialogOpen}
            />
          )}
        </>
      )}
      {type === 'button' && (
        <LoadingButton
          isLoading={isLoading}
          design={ButtonDesign.Transparent}
          isError={isError}
          onClick={handleConfirmationDialogOpen}
          renderContent={() => <Icon name="download" />}
        />
      )}
      {createPortal(
        <ConfirmationDialog
          handleConfirmation={handleConfirmationDialogConfirm}
          handleCancel={handleConfirmationDialogCancel}
          onClose={() => {} /* WARN: handleCancel is also called onClose, which we don't want */}
          isOpen={isConfirmationDialogOpen}
          confirmationMessage={t('confirmation-dialog.description')}
          confirmationTitle={t('confirmation-dialog.title')}
          confirmButtonText={tNoPrefix('buttons.ok')}
          messageBoxType={MessageBoxTypes.Confirm}
        />,
        document.body,
      )}
    </>
  )
}

DocumentDownload.propTypes = propTypes

export default DocumentDownload
