import {
  CardHeader,
  FlexBox,
  FlexBoxDirection,
  Grid,
  Title,
  TitleLevel,
} from '@fioneer/ui5-webcomponents-react'
import isNil from 'lodash.isnil'
import PropTypes from 'prop-types'
import { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import {
  valuationClassification,
  valuationRelevance,
} from 'api/property/valuation/valuationRequests'
import { multiPropertyValuations } from 'api/property/valuation/valuations'
import PropertyValuationResultsHistoryChart from 'components/domains/properties/valuation/PropertyValuationResultsHistoryChart'
import styles from 'components/domains/properties/valuation/PropertyValuationsResults.module.css'
import PropertyValuationResultsTable from 'components/domains/properties/valuation/valuation-results/PropertyValuationResultsTable'
import Card from 'components/ui/card/Card'
import CWPLayout from 'components/ui/layout/CWPLayout'
import LoadingStateWrapper from 'components/ui/screens/LoadingStateWrapper'
import { useNumberFormatter, useShortDateFormatter } from 'hooks/i18n/useI18n'
import useMultiPropertyValuations from 'hooks/services/properties/valuations/useMultiPropertyValuations'
import usePropertyValuations from 'hooks/services/properties/valuations/usePropertyValuations'

const compareValuationResultsByAscendingDateUnixTimestamp = (valuationResult1, valuationResult2) =>
  valuationResult1.dateUnixTimestamp - valuationResult2.dateUnixTimestamp

const prepareValuationResultsData = (valuations) => {
  const filteredValuations = valuations
    .filter(
      (valuation) =>
        valuation.regulatory_relevance === valuationRelevance.relevant &&
        (valuation.classification === valuationClassification.marketValue ||
          valuation.classification === valuationClassification.mortgageLandingValue),
    )
    .map((valuation) => ({
      dateUnixTimestamp: new Date(valuation.key_date).getTime(),
      currency: valuation.value_amount.currency,
      ...(valuation.classification === valuationClassification.marketValue && {
        marketValue: valuation.value_amount.number,
        marketValueMethod: valuation.calculation_method_name,
      }),
      ...(valuation.classification === valuationClassification.mortgageLandingValue && {
        mortgageLendingValue: valuation.value_amount.number,
        mortgageLendingValueMethod: valuation.calculation_method_name,
      }),
    }))

  let preparedValuationDataArray = []
  filteredValuations.forEach(
    (a) =>
      (preparedValuationDataArray[a.dateUnixTimestamp] = {
        ...preparedValuationDataArray[a.dateUnixTimestamp],
        ...a,
      }),
  )
  preparedValuationDataArray = Object.values(preparedValuationDataArray)
  preparedValuationDataArray.sort(compareValuationResultsByAscendingDateUnixTimestamp)
  return preparedValuationDataArray
}

const PropertyValuationsResults = ({
  propertyUuid,
  propertyCurrencyCode,
  propertyAllowedOperations,
}) => {
  const { t } = useTranslation('translation', { keyPrefix: 'pages.property.valuation.results' })
  const {
    isLoading: isPropertyValuationsLoading,
    isError: isPropertyValuationsError,
    data: valuationsData,
  } = usePropertyValuations(propertyUuid)
  const {
    isLoading: isMultiPropertyValuationsLoading,
    isError: isMultiPropertyValuationsError,
    data: multiPropertyValuationsData,
  } = useMultiPropertyValuations([propertyUuid])
  const formatNumber = useNumberFormatter({
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
    notation: 'compact',
  })
  const { format: formatDate } = useShortDateFormatter()

  const preparedValuationResultData = useMemo(() => {
    if (isPropertyValuationsLoading || isPropertyValuationsError || !valuationsData) {
      return
    }
    return prepareValuationResultsData(valuationsData.valuations)
  }, [valuationsData, isPropertyValuationsLoading, isPropertyValuationsError])

  const renderHeader = () => {
    const propertyValuations = multiPropertyValuationsData?.valuations?.[propertyUuid]
    const marketValue = propertyValuations?.[multiPropertyValuations.marketValue]
    const marketCurrency = marketValue?.value_amount.currency
    const marketNumber = marketValue?.value_amount.number
    const marketDate = marketValue?.key_date
    const mortgageValue = propertyValuations?.[multiPropertyValuations.mortgageValue]
    const mortgageCurrency = mortgageValue?.value_amount.currency
    const mortgageNumber = mortgageValue?.value_amount.number
    const mortgageDate = mortgageValue?.key_date

    return (
      <>
        <CardHeader titleText={t('card.header.title')} />
        {preparedValuationResultData.length !== 0 && (
          <FlexBox className={styles.flexBoxSpacing} direction={FlexBoxDirection.Row}>
            <FlexBox direction={FlexBoxDirection.Column}>
              <Title level={TitleLevel.H6} className={styles.titleBottomSpacing}>{`${t(
                'card.header.market-value',
              )} | ${marketCurrency ? marketCurrency : '-'}`}</Title>
              <Title level={TitleLevel.H2}>
                {!isNil(marketNumber) ? formatNumber(marketNumber) : '-'}
              </Title>
              <Title level={TitleLevel.H6}>{marketDate ? formatDate(marketDate) : '-'}</Title>
            </FlexBox>
            <FlexBox className={styles.flexBoxLeftSpacing} direction={FlexBoxDirection.Column}>
              <Title level={TitleLevel.H6} className={styles.titleBottomSpacing}>{`${t(
                'card.header.mortage-landing-value',
              )} | ${mortgageCurrency ? mortgageCurrency : '-'}`}</Title>
              <Title level={TitleLevel.H2}>
                {!isNil(mortgageNumber) ? formatNumber(mortgageNumber) : '-'}
              </Title>
              <Title level={TitleLevel.H6}>{mortgageDate ? formatDate(mortgageDate) : '-'}</Title>
            </FlexBox>
          </FlexBox>
        )}
      </>
    )
  }

  const renderContent = () => {
    const valuationsWithoutClassificationOther = valuationsData?.valuations?.filter(
      (valuation) => valuation.classification !== valuationClassification.other,
    )

    return (
      <CWPLayout overview>
        <CWPLayout.OneThird>
          <Card header={renderHeader()}>
            <PropertyValuationResultsHistoryChart
              preparedValuationResultData={preparedValuationResultData}
            />
          </Card>
        </CWPLayout.OneThird>
        <CWPLayout.TwoThirds>
          <Card>
            <Grid
              vSpacing="0rem"
              defaultSpan="XL12 L12 M12 S12"
              className="markets-detail-grid"
              style={{ marginLeft: '5px', marginRight: '5px' }}
            >
              <PropertyValuationResultsTable
                valuationResults={valuationsWithoutClassificationOther}
                propertyUuid={propertyUuid}
                propertyCurrencyCode={propertyCurrencyCode}
                allowedOperations={propertyAllowedOperations}
              />
            </Grid>
          </Card>
        </CWPLayout.TwoThirds>
        <CWPLayout.Aligner />
      </CWPLayout>
    )
  }

  return (
    <LoadingStateWrapper
      isLoading={isPropertyValuationsLoading || isMultiPropertyValuationsLoading}
      isError={isPropertyValuationsError || isMultiPropertyValuationsError}
      renderContent={renderContent}
      errorDescription={t('error.description')}
      errorDetails={t('error.details')}
      errorTitle={t('error.title')}
    />
  )
}

PropertyValuationsResults.propTypes = {
  propertyUuid: PropTypes.string.isRequired,
  propertyCurrencyCode: PropTypes.string,
  propertyAllowedOperations: PropTypes.arrayOf(PropTypes.string).isRequired,
}

export default PropertyValuationsResults
