import {
  AnalyticalTable,
  AnalyticalTableScaleWidthMode,
  AnalyticalTableSelectionBehavior,
  AnalyticalTableSelectionMode,
  FlexBox,
  FlexBoxDirection,
  MessageStrip,
  MessageStripDesign,
  ValueState,
} from '@fioneer/ui5-webcomponents-react'
import isEqual from 'lodash.isequal'
import isNil from 'lodash.isnil'
import PropTypes from 'prop-types'
import { useCallback, useContext, useEffect, useMemo, useRef } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom'
import {
  conditionTypes,
  conditionsEntityTypes,
  requirementStatusTypes,
} from 'api/conditions/conditions'
import { neededOperationsForRequirementEdit } from 'api/conditions/conditionsAllowedOperations'
import { hasUserRequiredOperations } from 'api/helper'
import RequirementCommentDialog from 'components/domains/conditions/dialogs/requirements/comments/RequirementCommentDialog'
import getVisibleAndSelectedItemIds from 'components/domains/conditions/overview/getVisibleAndSelectedItemIds'
import styles from 'components/domains/conditions/overview/requirements/RequirementsTable.module.css'
import RequirementsTableDocumentFileDrop from 'components/domains/conditions/overview/requirements/RequirementsTableDocumentFileDrop'
import RequirementsTableEmpty from 'components/domains/conditions/overview/requirements/RequirementsTableEmpty'
import RequirementsTableRowWrapper from 'components/domains/conditions/overview/requirements/RequirementsTableRowWrapper'
import TableCreationDateSubcomponent from 'components/domains/conditions/overview/table-cells/generic/TableCreationDateSubcomponent'
import RequirementsTableAttachmentsSubcomponent from 'components/domains/conditions/overview/table-cells/requirements/RequirementsTableAttachmentsSubcomponent'
import RequirementsTableCommentSubcomponent from 'components/domains/conditions/overview/table-cells/requirements/RequirementsTableCommentSubcomponent'
import RequirementsTableCovenantCheckSubcomponent from 'components/domains/conditions/overview/table-cells/requirements/RequirementsTableCovenantCheckSubcomponent'
import RequirementsTableDealExternalAssigneeSubcomponent from 'components/domains/conditions/overview/table-cells/requirements/RequirementsTableDealExternalAssigneeSubcomponent'
import RequirementsTableDescriptionSubcomponent from 'components/domains/conditions/overview/table-cells/requirements/RequirementsTableDescriptionSubcomponent'
import RequirementsTableDocumentTypeSubcomponent from 'components/domains/conditions/overview/table-cells/requirements/RequirementsTableDocumentTypeSubcomponent'
import RequirementsTableDueDateSubcomponent from 'components/domains/conditions/overview/table-cells/requirements/RequirementsTableDueDateSubcomponent'
import tableSubComponentStyles from 'components/domains/conditions/overview/table-cells/view/TableSubcomponent.module.css'
import {
  conditionFilterPrefixes,
  conditionServiceTypes,
} from 'hooks/services/conditions/tables/useConditionFilterQuery'
import useTableHeightHelper from 'hooks/services/conditions/tables/useTableHeightHelper'
import { reset as resetConditionErrorState } from 'redux/slices/conditions/conditionsTableSlice'
import {
  closeRequirementCommentDialog,
  setSelectedRequirementRows,
} from 'redux/slices/conditions/requirementsTableSlice'
import { ConditionsContext } from 'routes/conditions/ConditionsContext'

const emptyRequirement = {
  info: {
    name: undefined,
    creationDate: undefined,
    dueDate: undefined,
    assignee: undefined,
    refNumber: undefined,
    description: undefined,
    documentType: undefined,
  },
  condition: {
    businessObjectRef: undefined,
    info: {
      name: undefined,
    },
  },
  status: {
    code: undefined,
    type: undefined,
  },
  numberOfComments: 0,
  numberOfAttachments: 0,
}

const rowHeight = 80
const subcomponentHeight = 252
const maximumHeight = 1200

/**
 * This just stops focus(in) events from being propagated to the analyical table.
 * This is needed because of "intelligent" focus "handling" in the analytical table which
 * sometimes aggressively takes focus away from input elements when they are clicked.
 *
 * the provided solution is to add a `data-subcomponent-active-element` html attribute
 * to each and every input or otherwise focusable element in the table. Since this table
 * is more or less just a form with many input fields distributed across many components,
 * this is not feasable.
 *
 * Instead, we can grab the focus event and make sure the analytical table never gets to
 * know about it, also eliminating this problem.
 *
 * This is a solution to the ticket CWP-15964.
 * @param {Event} evt
 * @returns {void}
 */
const stopFocusPropagation = (evt) => evt.stopPropagation()

const RequirementsTable = ({
  isLoading,
  requirements,
  columns,
  isFilterOrSearchApplied,
  fetchNextPage,
  isUsedForCovenantChecks = false,
}) => {
  const { t } = useTranslation('translation', { keyPrefix: 'components.requirements.table' })
  const dispatch = useDispatch()
  const tableRef = useRef()
  const navigate = useNavigate()
  const [queryParams, setQueryParams] = useSearchParams()
  const location = useLocation()
  const {
    entityRef: { entityId, entityType },
    allowedOperations,
  } = useContext(ConditionsContext)
  const isRequirementAddMode = useSelector(
    (state) => state.conditions.requirementsTable.isRequirementAddMode,
  )
  const showErrorStrip = useSelector((state) => state.conditions.requirementsTable.rowsHaveAnError)
  const requirementErrorRows = useSelector((state) => state.conditions.requirementsTable.errorRows)
  const requirementHighlightRows = useSelector(
    (state) => state.conditions.requirementsTable.highlightRows,
  )
  const singleEditRowId = useSelector(
    (state) => state.conditions.requirementsTable.editedRow.requirementId,
  )
  const selectedRequirementIds = useSelector(
    (state) => state.conditions.requirementsTable.selectedRows.selectedRequirementIds,
  )

  const sortedRequirements = useMemo(() => {
    const newRequirements = []
    const oldRequirements = []
    requirements?.forEach((requirement) => {
      if (requirementHighlightRows[requirement.id]) {
        newRequirements.push(requirement)
      } else {
        oldRequirements.push(requirement)
      }
    })
    return [...newRequirements, ...oldRequirements]
  }, [requirementHighlightRows, requirements])

  const {
    isOpen: isCommentDialogOpen,
    conditionId: conditionIdForCommentDialog,
    requirementId: requirementIdForCommentDialog,
  } = useSelector((state) => state.conditions.requirementsTable.commentDialog)

  const hasUserRequirementEditPermission = useMemo(
    () => hasUserRequiredOperations(neededOperationsForRequirementEdit, allowedOperations),
    [allowedOperations],
  )

  const urlSearchParams = useMemo(() => new URLSearchParams(location.search), [location.search])

  useEffect(() => {
    const querySearchString = queryParams.toString()

    for (const [key] of urlSearchParams.entries()) {
      if (key.startsWith(conditionFilterPrefixes.conditions)) queryParams.delete(key)
    }

    if (querySearchString !== queryParams.toString()) {
      //navigate because setQueryParams also removes the location hash
      navigate({
        hash: location.hash,
        search: queryParams.toString(),
      })
    }
  }, [queryParams, setQueryParams, urlSearchParams, location, navigate])

  useEffect(
    () => () => {
      dispatch(resetConditionErrorState())
    },
    [dispatch],
  )

  useEffect(() => {
    dispatch(setSelectedRequirementRows({ selectedRowIds: {}, selectedRequirementIds: {} }))
  }, [dispatch])

  useEffect(() => {
    if (!singleEditRowId) {
      return
    }
    const indexOfSelectedRow = sortedRequirements.findIndex(({ id }) => id === singleEditRowId)
    tableRef.current?.toggleRowExpanded(indexOfSelectedRow, true)
  }, [singleEditRowId, sortedRequirements])

  useEffect(() => {
    if (!isRequirementAddMode) {
      return
    }
    tableRef.current?.toggleRowExpanded(0, true)
  }, [isRequirementAddMode])

  const selectedRowIds = useMemo(() => {
    const selectedRequirementIdsArray = Object.keys(selectedRequirementIds)

    const selectedRequirementIdsResult = {}
    sortedRequirements?.forEach(({ id: requirementId }, index) => {
      if (selectedRequirementIdsArray.includes(requirementId)) {
        selectedRequirementIdsResult[index] = true
      }
    })
    return selectedRequirementIdsResult
  }, [selectedRequirementIds, sortedRequirements])

  // This is a hack for keeping the state of the Analytical Table in Sync with the
  // Redux state. Other solutions did not work as expected.
  useEffect(() => {
    tableRef.current?.toggleAllRowsSelected(false)
    Object.keys(selectedRowIds).forEach((rowId) => tableRef.current.toggleRowSelected(rowId, true))
  }, [selectedRowIds])

  const requirementsData = useMemo(() => {
    if (isLoading) {
      return undefined
    }
    if (isRequirementAddMode) {
      return [emptyRequirement, ...sortedRequirements]
    }
    return sortedRequirements
  }, [isLoading, isRequirementAddMode, sortedRequirements])

  const { onExpandChange, tableHeight } = useTableHeightHelper({
    rowHeight,
    subcomponentHeight,
    maximumHeight,
    dataLength: requirementsData?.length,
  })

  const renderSubRow = useCallback(
    ({
      original: {
        id: requirementId,
        condition: {
          id: conditionId,
          info: { covenantCheckId },
          references,
        },
        info: { description, documentType, externalAssignee, creationDate, dueDate },
        status: { type: statusType },
        numberOfComments,
      },
    }) => (
      <FlexBox
        onFocus={stopFocusPropagation} // DO NOT REMOVE. See comment on function
        direction={FlexBoxDirection.Column}
        className={tableSubComponentStyles.tableSubcomponentFlexBox}
      >
        <RequirementsTableDescriptionSubcomponent
          description={description}
          requirementId={requirementId}
        />
        <RequirementsTableDocumentTypeSubcomponent
          requirementId={requirementId}
          references={references}
          documentType={documentType}
        />
        <RequirementsTableDueDateSubcomponent dueDate={dueDate} requirementId={requirementId} />
        {!isUsedForCovenantChecks && (
          <RequirementsTableCovenantCheckSubcomponent
            requirementId={requirementId}
            covenantCheckId={covenantCheckId}
          />
        )}
        {entityType === conditionsEntityTypes.deal && (
          <RequirementsTableDealExternalAssigneeSubcomponent
            requirementId={requirementId}
            externalAssignee={externalAssignee}
          />
        )}
        <RequirementsTableCommentSubcomponent
          requirementId={requirementId}
          conditionId={conditionId}
          numberOfComments={numberOfComments}
        />
        <TableCreationDateSubcomponent creationDate={creationDate} />
        <RequirementsTableAttachmentsSubcomponent
          requirementId={requirementId}
          showDeleteDocumentButton={
            hasUserRequirementEditPermission && statusType !== requirementStatusTypes.done
          }
        />
      </FlexBox>
    ),
    [entityType, hasUserRequirementEditPermission, isUsedForCovenantChecks],
  )

  const isWorkingVersion = useMemo(
    () => queryParams.get('working-version') === 'true',
    [queryParams],
  )

  const handleRowClick = useCallback(
    (event) => {
      if (isNil(event.target.dataset.selectionCell)) {
        return
      }
      const isCheckboxClicked = event.target.dataset.selectionCell === 'true'
      // if any of the rows are in edit or the table is in add mode, do not allow navigation
      if (!singleEditRowId && !isRequirementAddMode && !isCheckboxClicked) {
        navigate({
          hash: `#${conditionServiceTypes.conditions}`,
          search: `?${conditionFilterPrefixes.conditions}externalConditionId=${event.detail.row.values.condition.condition.id}&working-version=${isWorkingVersion}`,
        })
      }
    },
    [isRequirementAddMode, isWorkingVersion, navigate, singleEditRowId],
  )

  const highlightField = useCallback(
    ({ id: requirementId }) => {
      if (requirementHighlightRows[requirementId]) {
        return ValueState.Information
      }
      if (requirementErrorRows[requirementId]) {
        return ValueState.Error
      }
      return ValueState.None
    },
    [requirementErrorRows, requirementHighlightRows],
  )
  const noDataComponent = useCallback(
    () => RequirementsTableEmpty({ isFilterOrSearchApplied }),
    [isFilterOrSearchApplied],
  )

  const onRowSelect = useCallback(
    ({ detail: { selectedRowIds: newSelectedRowIds } }) => {
      const newEntryOffset = isRequirementAddMode ? -1 : 0
      const newSelectedRequirementIds = Object.keys(newSelectedRowIds).reduce(
        (currentValue, index) => {
          const currentRequirement = sortedRequirements[String(+index + newEntryOffset)]
          return {
            ...currentValue,
            [currentRequirement.id]: {
              name: currentRequirement.info.name,
              documentType: currentRequirement.info.documentType,
              status: currentRequirement.status.type,
              references: currentRequirement.condition.references,
              conditionEntityRef: currentRequirement.condition.entityRef,
            },
          }
        },

        {},
      )

      dispatch(
        setSelectedRequirementRows({
          selectedRowIds: newSelectedRowIds,
          selectedRequirementIds: newSelectedRequirementIds,
        }),
      )
    },
    [dispatch, isRequirementAddMode, sortedRequirements],
  )

  const setIsOpenCommentDialog = useCallback(
    (isOpen) => {
      if (!isOpen) {
        dispatch(closeRequirementCommentDialog())
      }
    },
    [dispatch],
  )

  const getRowProps = useCallback(
    (props, { row }) => [
      props,
      {
        rowWrapper: RequirementsTableRowWrapper,
        rowWrapperProps: {
          requirementId: row.original.id,
          documentType: row.original.info.documentType,
        },
      },
    ],
    [],
  )

  const useGetRowPropsHook = useCallback(
    (hooks) => {
      hooks.getRowProps.push(getRowProps)
      hooks.useFinalInstance.push(onExpandChange)
    },
    [getRowProps, onExpandChange],
  )

  const tableClassName = (
    isUsedForCovenantChecks ? [styles.table, styles.tableWithoutSelections] : [styles.table]
  ).join(' ')

  useEffect(() => {
    if (requirements === undefined) {
      return
    }
    const newSelectedRequirementIds = getVisibleAndSelectedItemIds({
      visibleItems: sortedRequirements,
      selectedIds: selectedRequirementIds,
    })
    if (!isEqual(newSelectedRequirementIds, selectedRequirementIds)) {
      dispatch(setSelectedRequirementRows({ selectedRequirementIds: newSelectedRequirementIds }))
    }
  }, [dispatch, requirements, selectedRequirementIds, sortedRequirements])

  return (
    <>
      {showErrorStrip && (
        <MessageStrip
          hideCloseButton
          design={MessageStripDesign.Negative}
          className={styles.messageStrip}
        >
          {t('error.rows-have-error')}
        </MessageStrip>
      )}
      <AnalyticalTable
        tableInstance={tableRef}
        className={tableClassName}
        columns={columns}
        scaleWidthMode={AnalyticalTableScaleWidthMode.Smart}
        selectionBehavior={AnalyticalTableSelectionBehavior.RowSelector}
        minRows={1}
        onRowSelect={onRowSelect}
        onRowClick={handleRowClick}
        rowHeight={rowHeight}
        tableHeight={tableHeight}
        headerRowHeight={40}
        groupable={false}
        filterable={false}
        sortable={false}
        data={requirementsData}
        loading={isLoading}
        showOverlay={isLoading}
        isTreeTable
        infiniteScroll
        infiniteScrollThreshold={10}
        onLoadMore={() => fetchNextPage()}
        selectionMode={
          isRequirementAddMode || singleEditRowId || isUsedForCovenantChecks
            ? AnalyticalTableSelectionMode.None
            : AnalyticalTableSelectionMode.MultiSelect
        }
        renderRowSubComponent={renderSubRow}
        NoDataComponent={noDataComponent}
        reactTableOptions={{
          autoResetHiddenColumns: false,
          autoResetSelectedRows: false,
          conditionType: conditionTypes.external,
          entityId,
          useControlledState: (state) =>
            useMemo(() => ({ ...state, selectedRowIds }), [state, selectedRowIds]), // eslint-disable-line react-hooks/exhaustive-deps
        }}
        withRowHighlight
        highlightField={highlightField}
        tableHooks={[useGetRowPropsHook]}
      />
      {hasUserRequirementEditPermission && !isUsedForCovenantChecks && (
        <RequirementsTableDocumentFileDrop />
      )}
      {isCommentDialogOpen && (
        <RequirementCommentDialog
          isOpen={isCommentDialogOpen}
          setIsOpen={setIsOpenCommentDialog}
          requirementId={requirementIdForCommentDialog}
          conditionId={conditionIdForCommentDialog}
        />
      )}
    </>
  )
}

RequirementsTable.propTypes = {
  columns: PropTypes.arrayOf(PropTypes.object).isRequired, // eslint-disable-line react/forbid-prop-types
  isLoading: PropTypes.bool.isRequired,
  requirements: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      condition: PropTypes.shape({
        references: PropTypes.shape({
          entityType: PropTypes.string.isRequired,
          entityIds: PropTypes.arrayOf(PropTypes.string).isRequired,
        }),
        entityRef: PropTypes.shape({
          entityType: PropTypes.string.isRequired,
          entityId: PropTypes.string.isRequired,
        }),
        covenantCheckId: PropTypes.string,
      }).isRequired,
      info: PropTypes.shape({
        name: PropTypes.string.isRequired,
        documentType: PropTypes.string.isRequired,
        dueDate: PropTypes.string,
      }).isRequired,
      status: PropTypes.shape({
        type: PropTypes.string.isRequired,
      }).isRequired,
    }),
  ),
  isFilterOrSearchApplied: PropTypes.bool.isRequired,
  fetchNextPage: PropTypes.func.isRequired,
  isUsedForCovenantChecks: PropTypes.bool,
}

export default RequirementsTable
