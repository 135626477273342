import { IllustratedMessage } from '@fioneer/ui5-webcomponents-react'
import _ from 'lodash'
import isEqual from 'lodash.isequal'
import PropTypes from 'prop-types'
import { useCallback, useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import PropertiesSearchDialogFilterBar from 'components/domains/properties/properties-search/filterbar/PropertiesSearchDialogFilterBar'
import PropertiesTable from 'components/domains/properties/properties-search/table/PropertiesTable'
import { ErrorDataUnavailableInContent } from 'components/ui/errors/ErrorDataUnavailableInContent'
import { RequestStateResolver } from 'components/ui/loading/RequestStateResolver'
import { usePropertiesInfiniteRequest } from 'hooks/services/properties/useProperties'
import usePropertiesColumnDefinitions from 'hooks/services/properties/usePropertiesColumnDefinitions'
import { usePropertiesPermissions } from 'hooks/services/properties/usePropertiesPermissions'

const defaultInitialFilterCriteria = {
  propertyName: '',
  propertyId: '',
  country: '',
  propertyTypes: '',
  city: '',
  dealId: '',
  zipCode: '',
  marketId: '',
  financingStatusCode: '',
  cagStatusCode: '',
}

const columnKeysToShow = [
  'description',
  'typeName',
  'country',
  'zipCode',
  'city',
  'street',
  'market',
  'financingStatus',
  'cagStatus',
]

const paginationDelta = 50

const PropertiesSearchTable = ({
  allowMultiSelect = true,
  selectedProperties,
  onSelectionChange,
  initialFilterCriteria = defaultInitialFilterCriteria,
  hiddenFilters = [],
  propertyIdsToHide = [],
  startSearchOnNonEmptyFilterCriteria = false,
}) => {
  const { t } = useTranslation('translation')

  const [intermediateFilterCriteria, setIntermediateFilterCriteria] = useState({
    ...initialFilterCriteria,
  })

  const [finalFilterCriteria, setFinalFilterCriteria] = useState({ ...initialFilterCriteria })

  const [recordNumberShown, setRecordNumberShown] = useState(paginationDelta)

  const [searchStarted, setSearchStarted] = useState(false)

  useEffect(() => {
    if (
      !isEqual(finalFilterCriteria, defaultInitialFilterCriteria) &&
      startSearchOnNonEmptyFilterCriteria
    ) {
      setSearchStarted(true)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const resetSettings = useCallback(() => {
    setRecordNumberShown(paginationDelta)
    setFinalFilterCriteria({ ...initialFilterCriteria })
  }, [initialFilterCriteria])

  const onFilter = useCallback(() => {
    resetSettings()
    setFinalFilterCriteria({ ...intermediateFilterCriteria })
    setSearchStarted(true)
  }, [intermediateFilterCriteria, resetSettings])

  const {
    data: propertiesPaginated,
    isLoading: isLoadingProperties,
    isError: isErrorProperties,
    fetchNextPage,
  } = usePropertiesInfiniteRequest({
    filter: { ...finalFilterCriteria },
    options: { enabled: searchStarted },
    sort: '+description',
    paginationDelta: paginationDelta,
  })

  const propertiesData = useMemo(
    () => propertiesPaginated?.pages?.flatMap((page) => [...page.properties]),
    [propertiesPaginated?.pages],
  )

  const propertiesDataTotal = useMemo(
    () => (propertiesPaginated?.pages?.length ? propertiesPaginated.pages[0].pagination.total : 0),
    [propertiesPaginated?.pages],
  )

  const propertiesPagination = useMemo(
    () =>
      !isLoadingProperties && !isErrorProperties && propertiesData?.length
        ? { limit: Math.min(recordNumberShown, propertiesData?.length), total: propertiesDataTotal }
        : { limit: 0, total: 0 },
    [
      isErrorProperties,
      isLoadingProperties,
      propertiesData?.length,
      propertiesDataTotal,
      recordNumberShown,
    ],
  )

  const onLoadMore = useCallback(() => {
    if (recordNumberShown + paginationDelta > propertiesData?.length) {
      fetchNextPage()
    }
    setRecordNumberShown(Math.min(recordNumberShown + paginationDelta, propertiesDataTotal))
  }, [fetchNextPage, propertiesData?.length, propertiesDataTotal, recordNumberShown])

  const [columnDefinitions] = usePropertiesColumnDefinitions()

  const reducedTableColumns = useCallback(
    () => [
      ...columnKeysToShow.map((columnKey) => ({
        ...columnDefinitions?.find((columnDefinition) => columnDefinition.columnKey === columnKey),
        minWidth: 0,
      })),
    ],
    [columnDefinitions],
  )

  const {
    isLoading: isLoadingAllowedOperations,
    isError: isErrorAllowedOperations,
    data: propertyPermissions,
  } = usePropertiesPermissions()

  const shownProperties = useMemo(
    () =>
      propertiesData
        ?.slice(0, recordNumberShown)
        .filter((property) => !propertyIdsToHide.includes(property.uuid)) ?? [],
    [propertiesData, recordNumberShown, propertyIdsToHide],
  )

  const totalNonFilteredProperties = useMemo(
    () => propertiesPagination?.total - propertiesData?.length + shownProperties?.length,
    [propertiesPagination, propertiesData, shownProperties],
  )

  const getContent = useCallback(() => {
    const allowedOperations = propertyPermissions?.allowed_operations ?? []
    return (
      <div>
        {!searchStarted ? (
          <IllustratedMessage
            name="BeforeSearch"
            titleText={t('components.property-search-dialog.perform-search.title')}
            subtitleText={t('components.property-search-dialog.perform-search.subtitle')}
          />
        ) : (
          <RequestStateResolver
            isLoading={isLoadingAllowedOperations || isLoadingProperties}
            isError={isErrorAllowedOperations || isErrorProperties}
            errorToDisplay={<ErrorDataUnavailableInContent />}
            renderContent={() => (
              <PropertiesTable
                properties={shownProperties}
                tableColumns={reducedTableColumns()}
                pagination={{ ...propertiesPagination, total: totalNonFilteredProperties }}
                setSelectedProperties={onSelectionChange}
                selectedProperties={selectedProperties}
                onLoadMore={onLoadMore}
                allowedOperations={allowedOperations}
                isError={isErrorProperties}
                hasConfigurableColumns={false}
                navigateToPropertyOnRowClick={false}
                allowMultiSelect={allowMultiSelect}
              />
            )}
          />
        )}
      </div>
    )
  }, [
    propertyPermissions?.allowed_operations,
    searchStarted,
    t,
    isLoadingAllowedOperations,
    isLoadingProperties,
    isErrorAllowedOperations,
    isErrorProperties,
    shownProperties,
    reducedTableColumns,
    propertiesPagination,
    totalNonFilteredProperties,
    onSelectionChange,
    selectedProperties,
    onLoadMore,
    allowMultiSelect,
  ])

  const fixedDropdownValues = useMemo(
    () => ({
      countries:
        initialFilterCriteria.country !== defaultInitialFilterCriteria.country
          ? [initialFilterCriteria.country]
          : undefined,
      propertyTypes:
        initialFilterCriteria.propertyTypes !== defaultInitialFilterCriteria.propertyTypes
          ? initialFilterCriteria.propertyTypes?.split(',')
          : undefined,
    }),
    [initialFilterCriteria],
  )

  return (
    <>
      <PropertiesSearchDialogFilterBar
        filterCriteria={intermediateFilterCriteria}
        setFilterCriteria={setIntermediateFilterCriteria}
        onFilter={onFilter}
        fixedDropdownValues={fixedDropdownValues}
        hiddenFilters={hiddenFilters}
      />
      <RequestStateResolver
        isError={isErrorProperties}
        isLoading={isLoadingProperties && searchStarted}
        renderContent={getContent}
        errorToDisplay={<ErrorDataUnavailableInContent />}
      />
    </>
  )
}

PropertiesSearchTable.propTypes = {
  allowMultiSelect: PropTypes.bool,
  // eslint-disable-next-line react/forbid-prop-types
  selectedProperties: PropTypes.arrayOf(PropTypes.object),
  onSelectionChange: PropTypes.func.isRequired,
  initialFilterCriteria: PropTypes.shape({
    propertyName: PropTypes.string.isRequired,
    propertyId: PropTypes.string.isRequired,
    country: PropTypes.string.isRequired,
    city: PropTypes.string.isRequired,
    dealId: PropTypes.string.isRequired,
    zipCode: PropTypes.string.isRequired,
    marketId: PropTypes.string.isRequired,
  }),
  hiddenFilters: PropTypes.arrayOf(PropTypes.string),
  propertyIdsToHide: PropTypes.arrayOf(PropTypes.string),
  startSearchOnNonEmptyFilterCriteria: PropTypes.bool,
}

export default PropertiesSearchTable
