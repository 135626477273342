import { useRequest } from 'hooks/services/baseService'
import { useCamelizedResponse } from 'hooks/services/queryHelper'

/**
 * Returns all valuation create method codes, which is needed to create e.g. the first market value
 * The codes structure is as follows:
 * {
 *   "valuation_create_method_codes": [
 *        {
 *           "creation_method_code": "FIRST_MARKET_VALUE",
 *           "key": "Z910000010",
 *           "display_name": "Erster Marktwert"
 *        },
 *        {
 *           "creation_method_code": "PURCHASE_PRICE",
 *           "key": "Z910000006",
 *           "display_name": "Anschaffungswert"
 *        },
 *        {
 *           "creation_method_code": "FIRST_MORTGAGE_LENDING_VALUE",
 *           "key": "Z910000014",
 *           "display_name": "Erster BLW (Orig.)"
 *        }
 *   ]
 * }
}
 * @returns {{isLoading: boolean, isError: boolean, data: ValuationCreateMethodCodes}}
 *
 */
const emptyArray = []

export const useValuationCreateMethodCodes = (options) => {
  const { data, ...response } = useRequest({
    path: `/properties/valuation-create-method-codes`,
    translated: true,
    options,
  })

  return useCamelizedResponse({
    ...response,
    data: data?.valuation_create_method_codes ?? emptyArray,
  })
}
