import '@ui5/webcomponents-icons/dist/AllIcons.js'
import { AuthProvider } from 'oidc-react'
import 'App.css'
import 'spacing.css'
import 'icons.css'
import 'fonts.css'
import { useMemo } from 'react'
import { DndProvider } from 'react-dnd'
import { HTML5Backend } from 'react-dnd-html5-backend'
import { Provider } from 'react-redux'
import { RouterProvider, createBrowserRouter } from 'react-router-dom'
import AuthenticationWrapper from 'components/ui/authentication/AuthenticationWrapper'
import buildOidcConfiguration from 'components/ui/authentication/oidcConfigurationBuilder'
import EnvironmentIndicator from 'components/ui/environment-indicator/EnvironmentIndicator'
import ScreenSizeIndicator from 'components/ui/screen-size-indicator/ScreenSizeIndicator'
import LoadingStateWrapper from 'components/ui/screens/LoadingStateWrapper'
import { useFavIcon } from 'hooks/app/useFavIcon'
import { ConfigContext, useConfig } from 'hooks/config/useConfig'
import store from 'redux/store'
import UserContextWrapper from 'routes/UserContextWrapper'
import getRoutes from 'routes/getRoutes'
import 'i18n/setupI18n'

const App = () => {
  const router = useMemo(() => createBrowserRouter(getRoutes()), [])

  const { data: config, isLoading, isError } = useConfig()
  useFavIcon()

  const renderApp = () => {
    const { oidc: oidcConfig } = config
    const oidcConfigWithUriAndDefaults = buildOidcConfiguration(oidcConfig)

    return (
      <Provider store={store}>
        <ConfigContext.Provider value={config}>
          <AuthProvider {...oidcConfigWithUriAndDefaults}>
            <AuthenticationWrapper>
              <UserContextWrapper>
                <DndProvider backend={HTML5Backend}>
                  <EnvironmentIndicator />
                  <ScreenSizeIndicator />
                  <RouterProvider router={router} />
                </DndProvider>
              </UserContextWrapper>
            </AuthenticationWrapper>
          </AuthProvider>
        </ConfigContext.Provider>
      </Provider>
    )
  }

  return <LoadingStateWrapper isError={isError} isLoading={isLoading} renderContent={renderApp} />
}

export default App
