import { ticks as ticksCalculation } from 'd3-array'
import { DateTime } from 'luxon'
import PropTypes from 'prop-types'
import { useCallback, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { CartesianGrid, Legend, Line, XAxis, YAxis } from 'recharts'
import {
  useShortDateFormatter,
  useNumberFormatter,
} from 'components/domains/business-events-and-tasks/decision-paper/tiles/property/fact-sheet/tegovaRating/shared/i18n/useI18n'

const numberOfYAxisTicks = 10
const numberOfXAxisTicks = 10
const tenPercent = 0.1

const popoverYCalibration = 30

const valuationClassificationTranslated = {
  marketValue: 'valuation-requests.classification.marketValue',
  mortgageLandingValue: 'valuation-requests.classification.mortgageLandingValue',
}

const PropertyValuationResultsHistoryLineChartChildren = ({
  additionalLineChartArguments,
  zoomedStartEndUnixTimestamps,
}) => {
  const { format: formatDate } = useShortDateFormatter()
  const formatNumber = useNumberFormatter({ notation: 'compact' })
  const { t } = useTranslation('decisionPaper', {
    keyPrefix: 'tiles.risk-monitoring.fact-sheet-valuation.pages.property.valuation',
  })
  const { t: classificationTranslation } = useTranslation('decisionPaper')

  const { setChartPopoverValuationResultData, overallDataMin, overallDataMax } =
    additionalLineChartArguments

  const dotOnClick = useCallback(
    (valuationDotClickEvent) => {
      setChartPopoverValuationResultData({
        visibility: true,
        position: {
          x: valuationDotClickEvent.cx,
          y: valuationDotClickEvent.cy + popoverYCalibration,
        },
        payload: {
          date: formatDate(
            DateTime.fromMillis(valuationDotClickEvent.payload.dateUnixTimestamp).toISODate(),
          ),
          value: valuationDotClickEvent.value,
          currency: valuationDotClickEvent.payload.currency,
          method:
            valuationDotClickEvent.name ===
            classificationTranslation(valuationClassificationTranslated.marketValue)
              ? valuationDotClickEvent.payload.marketValueMethod
              : valuationDotClickEvent.payload.mortgageLendingValueMethod,
        },
      })
    },
    [classificationTranslation, formatDate, setChartPopoverValuationResultData],
  )

  const yAxisTicks = useMemo(() => {
    const deltaBetweenMaxAndMinValue = (overallDataMax - overallDataMin) * tenPercent

    return ticksCalculation(
      overallDataMin - deltaBetweenMaxAndMinValue,
      overallDataMax + deltaBetweenMaxAndMinValue,
      numberOfYAxisTicks - 1,
    )
  }, [overallDataMin, overallDataMax])

  return (
    <>
      <Line
        connectNulls
        key="line-marketValue"
        name={t('classification.market-value')}
        type="stepAfter"
        isAnimationActive={false}
        dataKey="marketValue"
        stroke="#E8743B"
        strokeWidth={1.5}
        dot={{ fill: '#E8743B', r: 5, onClick: dotOnClick }}
      />
      <Line
        connectNulls
        key="line-mortgageLendingValue"
        name={t('classification.mortgage-lending-value')}
        type="stepAfter"
        isAnimationActive={false}
        dataKey="mortgageLendingValue"
        stroke="#5899DA"
        strokeWidth={1.5}
        dot={{ fill: '#5899DA', r: 5, onClick: dotOnClick }}
      />
      <XAxis
        dataKey="dateUnixTimestamp"
        tickLine={true}
        interval="preserveStartEnd"
        allowDecimals={false}
        height={36}
        tickCount={numberOfXAxisTicks}
        type="number"
        tickFormatter={
          (dateUnixTimestamp) =>
            `${formatDate(DateTime.fromMillis(dateUnixTimestamp).toISODate())} ` // empty character added so that the tick at the end does not get cut off
        }
        domain={[zoomedStartEndUnixTimestamps.start, zoomedStartEndUnixTimestamps.end]}
      />
      <YAxis
        type="number"
        axisLine={false}
        tickFormatter={(value) => formatNumber(value)}
        width={60}
        tickLine={false}
        ticks={yAxisTicks}
        domain={[yAxisTicks[0], yAxisTicks[yAxisTicks.length - 1]]}
        interval={0}
        padding={{ bottom: 10 }}
      />
      <CartesianGrid vertical={false} stroke="#E5E5E5" />
      <Legend
        layout="horizontal"
        align="center"
        verticalAlign="bottom"
        iconType="line"
        formatter={(value) => <span style={{ color: '#32363A' }}>{value}</span>}
      />
    </>
  )
}

PropertyValuationResultsHistoryLineChartChildren.propTypes = {
  additionalLineChartArguments: PropTypes.shape({
    setChartPopoverValuationResultData: PropTypes.func.isRequired,
    overallDataMin: PropTypes.number.isRequired,
    overallDataMax: PropTypes.number.isRequired,
  }),
  zoomedStartEndUnixTimestamps: PropTypes.shape({
    start: PropTypes.number,
    end: PropTypes.number,
  }),
}

export default PropertyValuationResultsHistoryLineChartChildren
