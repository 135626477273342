import { useQuery } from '@tanstack/react-query'
import { responseCodes } from 'api/requests'
import { useAccessTokenRequest } from 'api/useAccessTokenRequest'

const useRentRollWorkingVersions = (propertyUuids) => {
  const { post } = useAccessTokenRequest()
  const { data, isLoading, isError } = useQuery({
    queryKey: ['rent-roll-working-version', ...propertyUuids.sort()],
    refetchOnWindowFocus: false,
    queryFn: async () =>
      post({
        path: '/properties/rent-roll/working-version',
        body: {
          property_uuids: propertyUuids,
        },
      }),
  })
  if (isLoading || isError) {
    return { isLoading, isError, data: [] }
  }
  return {
    data: data?.data || [],
    isError: data?.status !== responseCodes.OK,
    isLoading: false,
  }
}

export default useRentRollWorkingVersions
