import PropTypes from 'prop-types'
import React, { useContext } from 'react'
import { useParams } from 'react-router-dom'
import EntityTypeAndIdWithClipboard from 'components/ui/entity-info/EntityTypeAndIdWithClipboard'
import { cwpEntityTypes } from 'constants/cwpEntityTypes'
import useKpis from 'hooks/services/kpis/useKpis'
import KpiDetailsContent from 'routes/kpis/KpiDetailsContent'
import { PropertyContext } from 'routes/properties/PropertyContext'
import PropertyPage from 'routes/properties/PropertyPage'

const PropertyKpiDetailsPage = ({
  propertyDescription,
  breadcrumbs = [],
  status,
  additionalStatuses,
}) => {
  const { property } = useContext(PropertyContext)

  const referenceEntityType = cwpEntityTypes.PROPERTY
  const referenceEntityId = property.uuid
  const {
    data: { kpis = [] } = {},
    isError,
    isFetching,
  } = useKpis(referenceEntityType, referenceEntityId)

  const subtitle = <EntityTypeAndIdWithClipboard id={property.id} />

  const { kpiId } = useParams()
  const kpi = kpis.find(({ id }) => id === kpiId)

  const childBreadcrumb = {
    text: kpi?.name ?? kpiId,
  }

  const additionalBreadcrumbs = breadcrumbs.map((breadCrumb) => ({ ...breadCrumb }))
  additionalBreadcrumbs.push(childBreadcrumb)

  return (
    <PropertyPage
      pageTitle={propertyDescription}
      id={property.id}
      subtitle={subtitle}
      breadcrumbs={additionalBreadcrumbs}
      status={status}
      additionalStatuses={additionalStatuses}
    >
      <KpiDetailsContent
        referenceEntityType={referenceEntityType}
        referenceEntityId={referenceEntityId}
        kpiId={kpiId}
        isFetching={isFetching}
        isError={isError}
      />
    </PropertyPage>
  )
}

PropertyKpiDetailsPage.propTypes = {
  propertyDescription: PropTypes.string.isRequired,
  breadcrumbs: PropTypes.arrayOf(
    PropTypes.shape({
      text: PropTypes.string.isRequired,
      href: PropTypes.string,
    }),
  ),
  status: PropTypes.shape({
    text: PropTypes.string.isRequired,
    valueState: PropTypes.string,
  }),
  additionalStatuses: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      text: PropTypes.string.isRequired,
      valueState: PropTypes.string,
    }),
  ),
}

export default PropertyKpiDetailsPage
