import { useCallback } from 'react'

export const getRentalUnitWorkingVersionKey = (rentalUnitName, segmentUuid) =>
  `${rentalUnitName ?? ''}-${segmentUuid ?? ''}`

const useValidateRentalUnitIds = () => {
  const validateAndAdjustRentalUnitIds = useCallback((rentalUnits = []) => {
    const rentalUnitsWithoutSameId = []
    rentalUnits.reduce((keys, rentalUnit) => {
      if (!rentalUnit?.segment_uuid) {
        rentalUnitsWithoutSameId.push(rentalUnit)
        return keys
      }
      const key = getRentalUnitWorkingVersionKey(
        rentalUnit?.rental_unit_name,
        rentalUnit.segment_uuid,
      )
      const existingKey = keys.find((preExistingKey) => preExistingKey.key === key)
      if (!existingKey) {
        rentalUnitsWithoutSameId.push(rentalUnit)
        return [...keys, { key: key, index: 0 }]
      }
      existingKey.index += 1
      rentalUnitsWithoutSameId.push({
        ...rentalUnit,
        rental_unit_name: `${rentalUnit.rental_unit_name} (${existingKey.index})`,
      })
      return keys
    }, [])
    return rentalUnitsWithoutSameId
  }, [])

  return { validateAndAdjustRentalUnitIds }
}

export default useValidateRentalUnitIds
