import PropTypes from 'prop-types'
import React from 'react'
import { useTranslation } from 'react-i18next'
import CenteredIllustratedMessage from 'components/ui/illustrated-message/CenteredIllustratedMessage'
import { RequestStateResolver } from 'components/ui/loading/RequestStateResolver'
import useKpiAllowedOperations from 'hooks/services/kpis/useKpiAllowedOperations'

const KpiPermissionWrapper = ({ children, requiredOperation }) => {
  const { t } = useTranslation('translation')

  const {
    isLoading: isLoadingAllowedOperations,
    isError: isErrorAllowedOperations,
    data: { allowedOperations = [] } = {},
  } = useKpiAllowedOperations()

  const hasPermission = allowedOperations.includes(requiredOperation)

  const renderNoPermission = () => (
    <CenteredIllustratedMessage
      name="UnableToLoad"
      size="Spot"
      titleText={t('components.cards.not-allowed.title')}
      subtitleText={t('components.cards.not-allowed.subtitle')}
    />
  )

  const renderContent = () => {
    if (hasPermission) {
      return children
    }

    return renderNoPermission()
  }

  return (
    <RequestStateResolver
      isLoading={isLoadingAllowedOperations}
      isError={isErrorAllowedOperations}
      renderContent={renderContent}
      errorToDisplay={renderNoPermission()}
      center={true}
    />
  )
}

KpiPermissionWrapper.propTypes = {
  children: PropTypes.element.isRequired,
  requiredOperation: PropTypes.string.isRequired,
}

export default KpiPermissionWrapper
