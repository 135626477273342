import { ValueState } from '@fioneer/ui5-webcomponents-react'

export const valuationStatus = {
  draft: 'DRAFT',
  created: 'CREATED',
  instructed: 'INSTRUCTED',
  completed: 'COMPLETED',
  cancelled: 'CANCELLED',
  externalTransferFailed: 'EXTERNAL_TRANSFER_FAILED',
}

export const valuationStatusTranslated = {
  draft: 'valuation-requests.status.draft',
  created: 'valuation-requests.status.created',
  instructed: 'valuation-requests.status.instructed',
  completed: 'valuation-requests.status.completed',
  cancelled: 'valuation-requests.status.cancelled',
  externalTransferFailed: 'valuation-requests.status.external_transfer_failed',
}

export const valuationClassification = {
  marketValue: 'Market Value',
  mortgageLandingValue: 'Mortgage Lending Value',
  other: 'Other',
}

export const valuationClassificationTranslated = {
  marketValue: 'valuation-requests.classification.marketValue',
  mortgageLandingValue: 'valuation-requests.classification.mortgageLandingValue',
}

export const valuationRelevance = {
  relevant: 'Relevant',
  nonRelevant: 'Non Relevant',
}

export const objectStatusForValuationStatus = {
  [valuationStatus.draft]: ValueState.None,
  [valuationStatus.created]: ValueState.None,
  [valuationStatus.instructed]: ValueState.Information,
  [valuationStatus.completed]: ValueState.Success,
  [valuationStatus.cancelled]: ValueState.Error,
  [valuationStatus.externalTransferFailed]: ValueState.Error,
  default: ValueState.None,
}

export const valuationRequestMultiEditOperations = {
  clear: 'clear-values',
  keep: 'keep-values',
  replace: 'replace-values',
}

export const valuationRequestCreationError = {
  eventCreationFailedError: 'EVENT_CREATION_FAILED_ERROR',
}

const defaultObjectErrorForRequestCreation = {
  translationKey: 'unknown',
}
const objectErrorForRequestCreation = {
  [valuationRequestCreationError.eventCreationFailedError]: {
    translationKey: 'event-creation-failed',
  },
}

export const getObjectErrorForRequestCreation = (errorCode) =>
  objectErrorForRequestCreation[errorCode] ?? defaultObjectErrorForRequestCreation
