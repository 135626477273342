import { TableRowType } from '@fioneer/ui5-webcomponents-react'
import camelize from 'camelize'
import { useMemo } from 'react'
import styles from 'components/domains/properties/valuation/valuation-results/PropertyValuationResultsTable.module.css'
import PropertyValuationResultsKeyDateCell from 'components/domains/properties/valuation/valuation-results/cells/PropertyValuationResultsKeyDateCell'
import PropertyValuationResultsMethodCell from 'components/domains/properties/valuation/valuation-results/cells/PropertyValuationResultsMethodCell'
import PropertyValuationResultsTableActionsCell from 'components/domains/properties/valuation/valuation-results/cells/PropertyValuationResultsTableActionsCell'
import PropertyValuationResultsValidFromCell from 'components/domains/properties/valuation/valuation-results/cells/PropertyValuationResultsValidFromCell'
import PropertyValuationResultsValidToCell from 'components/domains/properties/valuation/valuation-results/cells/PropertyValuationResultsValidToCell'
import PropertyValuationResultsValueAmountCell from 'components/domains/properties/valuation/valuation-results/cells/PropertyValuationResultsValueAmountCell'
import { ValuationResultsTableColumns } from 'components/domains/properties/valuation/valuation-results/usePropertyValuationResultsTableColumns'

const useMapPropertyValuationResultsTableData = ({
  valuationResults,
  isAddMode,
  currentEditedValues,
  highlightedValuations,
  propertyUuid,
}) => {
  const valuationData = useMemo(() => {
    const camelizedValuationResults = camelize(valuationResults)
    return isAddMode
      ? [currentEditedValues, ...camelizedValuationResults]
      : camelizedValuationResults
  }, [isAddMode, currentEditedValues, valuationResults])

  const tableData = useMemo(
    () =>
      valuationData.map(
        ({
          valuationId,
          calculationMethodName,
          keyDate: initialKeyDate,
          valueAmount: { number: initialValueAmount, currency },
          validFrom: initialValidFrom,
          validTo: initialValidTo,
        }) => {
          const isCurrentRowAddMode = isAddMode && valuationId === currentEditedValues?.valuationId
          const keyDate = isCurrentRowAddMode ? currentEditedValues?.keyDate : initialKeyDate
          const validFrom = isCurrentRowAddMode ? currentEditedValues?.validFrom : initialValidFrom
          const validTo = isCurrentRowAddMode ? currentEditedValues?.validTo : initialValidTo
          const valueAmount = isCurrentRowAddMode
            ? currentEditedValues?.valueAmount?.value
            : initialValueAmount
          const isRowHighlighted = highlightedValuations.includes(valuationId)
          return {
            rowKey: `valuation-result-${valuationId}`,
            rowProperties: {
              type: TableRowType.Inactive,
              'data-result-id': valuationId,
              className: isRowHighlighted ? styles.hightlightNewOrEditedRow : null,
            },
            [ValuationResultsTableColumns.method]: {
              value: calculationMethodName,
              cellComponent: (
                <PropertyValuationResultsMethodCell
                  calculationMethodName={calculationMethodName}
                  calculationMethodCode={currentEditedValues?.calculationMethodCode}
                  isAddMode={isCurrentRowAddMode}
                />
              ),
            },
            [ValuationResultsTableColumns.keyDate]: {
              value: keyDate,
              cellComponent: (
                <PropertyValuationResultsKeyDateCell
                  keyDate={keyDate}
                  isAddMode={isCurrentRowAddMode}
                />
              ),
            },
            [ValuationResultsTableColumns.result]: {
              value: valueAmount,
              cellComponent: (
                <PropertyValuationResultsValueAmountCell
                  valueAmount={valueAmount}
                  currency={currency}
                  isAddMode={isCurrentRowAddMode}
                />
              ),
            },
            [ValuationResultsTableColumns.validFrom]: {
              value: validFrom,
              cellComponent: (
                <PropertyValuationResultsValidFromCell
                  validFrom={validFrom}
                  isAddMode={isCurrentRowAddMode}
                />
              ),
            },
            [ValuationResultsTableColumns.validTo]: {
              value: validTo,
              cellComponent: (
                <PropertyValuationResultsValidToCell
                  validTo={validTo}
                  isAddMode={isCurrentRowAddMode}
                />
              ),
            },
            [ValuationResultsTableColumns.actions]: {
              value: valuationId,
              cellComponent: (
                <PropertyValuationResultsTableActionsCell
                  isAddMode={isCurrentRowAddMode}
                  propertyUuid={propertyUuid}
                />
              ),
            },
          }
        },
      ),
    [
      currentEditedValues?.calculationMethodCode,
      currentEditedValues?.keyDate,
      currentEditedValues?.validFrom,
      currentEditedValues?.validTo,
      currentEditedValues?.valuationId,
      currentEditedValues?.valueAmount?.value,
      highlightedValuations,
      isAddMode,
      propertyUuid,
      valuationData,
    ],
  )

  return useMemo(() => ({ tableData }), [tableData])
}

export default useMapPropertyValuationResultsTableData
