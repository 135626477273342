import PropTypes from 'prop-types'
import PropertyRentRollStructureOverviewTable from 'components/domains/properties/rent-roll/overview/structure-overview/PropertyRentRollStructureOverviewTable'
import { useSegmentRentalIncomeTableColumns } from 'hooks/services/properties/segments/rental-income/useSegmentRentalIncomeTableColumns'
import { useSegmentRentalIncomeTableRows } from 'hooks/services/properties/segments/rental-income/useSegmentRentalIncomeTableRows'

const PropertyRentRollOverviewRentalIncomeTable = ({ property, segments, hasPermission }) => {
  const columnDefinitions = useSegmentRentalIncomeTableColumns(property)
  const {
    isError,
    isErrorAllSegmentsPublished,
    data: tableData,
  } = useSegmentRentalIncomeTableRows(property, segments)
  return (
    <PropertyRentRollStructureOverviewTable
      id={'property-rent-roll-segments-rental-income-table'}
      isError={isError}
      isErrorAllSegmentsPublished={isErrorAllSegmentsPublished}
      columnDefinitions={columnDefinitions}
      tableData={tableData}
      hasPermission={hasPermission}
    />
  )
}
PropertyRentRollOverviewRentalIncomeTable.propTypes = {
  property: PropTypes.object.isRequired,
  segments: PropTypes.array.isRequired,
  hasPermission: PropTypes.bool.isRequired,
}
export default PropertyRentRollOverviewRentalIncomeTable
