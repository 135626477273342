import { FlexBox } from '@fioneer/ui5-webcomponents-react'
import isEmpty from 'lodash.isempty'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import styles from 'components/domains/business-events-and-tasks/decision-paper/tiles/fact-sheet-further-details/FurtherDetailsPieChartsCard.module.css'
import PropertyPieChart from 'components/domains/business-events-and-tasks/decision-paper/tiles/fact-sheet-further-details/shared/PropertyPieChart'
import {
  CurrentRentalIncomeProvider,
  LettableAreaProvider,
  MarketValueProvider,
  MortgageLendingValueProvider,
  TotalMarketRentProvider,
  VacantAreaProvider,
} from 'components/domains/business-events-and-tasks/decision-paper/tiles/fact-sheet-further-details/shared/PropertyPortfolioOverviewPieChartDataProviders'
import Card from 'components/domains/business-events-and-tasks/decision-paper/tiles/fact-sheet-further-details/shared/ui/Card'

export const chartsNoValuations = Object.freeze({
  'current-rental-income': CurrentRentalIncomeProvider,
  'total-market-rent': TotalMarketRentProvider,
  'lettable-area': LettableAreaProvider,
  'vacant-area': VacantAreaProvider,
})

export const chartsWithValuations = Object.freeze({
  'current-rental-income': CurrentRentalIncomeProvider,
  'total-market-rent': TotalMarketRentProvider,
  'lettable-area': LettableAreaProvider,
  'vacant-area': VacantAreaProvider,
  'market-value': MarketValueProvider,
  'mortgage-lending-value': MortgageLendingValueProvider,
})

const FurtherDetailsPieChartsCard = ({
  getNameFromKpi,
  getRentRollKpis,
  getValuations,
  granularityValues,
  useColors,
  colorProvider,
  chartOptions = chartsNoValuations,
  isPdfView,
}) => {
  const { multiPropertyKpisByGranularity, multiPropertyValuationsByGranularity } = granularityValues

  const { t: tRegionCharts } = useTranslation('decisionPaper', {
    keyPrefix: 'pages.properties.portfolio.overview.pie-charts',
  })

  const rentRollKpis = getRentRollKpis(multiPropertyKpisByGranularity)

  const valuations = !isEmpty(multiPropertyValuationsByGranularity)
    ? getValuations(multiPropertyValuationsByGranularity)
    : []

  return (
    <FlexBox fitContainer className={styles.cardBorder}>
      <Card>
        <div className={styles.cardContent}>
          <PropertyPieChart
            getNameFromKpi={getNameFromKpi}
            rentRollKpis={rentRollKpis}
            valuations={valuations}
            useColors={useColors}
            colorProvider={colorProvider}
            charts={chartOptions}
            tCharts={tRegionCharts}
            isPdfView={isPdfView}
          />
        </div>
      </Card>
    </FlexBox>
  )
}

FurtherDetailsPieChartsCard.propTypes = {
  getNameFromKpi: PropTypes.func.isRequired,
  getRentRollKpis: PropTypes.func.isRequired,
  getValuations: PropTypes.func.isRequired,
  granularityValues: PropTypes.shape({
    multiPropertyKpisByGranularity: PropTypes.object,
    multiPropertyValuationsByGranularity: PropTypes.object,
  }),
  useColors: PropTypes.func.isRequired,
  colorProvider: PropTypes.func.isRequired,
  chartOptions: PropTypes.object,
  isPdfView: PropTypes.bool,
}

export default FurtherDetailsPieChartsCard
