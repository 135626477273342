import {
  Text,
  Button,
  ButtonDesign,
  CheckBox,
  DatePicker,
  FlexBox,
  FlexBoxAlignItems,
  FlexBoxDirection,
  FlexBoxJustifyContent,
  Form,
  FormItem,
  Icon,
  Input,
  Label,
  List,
  ListItemType,
  ListSeparators,
  Modals,
  MultiInput,
  StandardListItem,
  Token,
  ValueState,
  WrappingType,
} from '@fioneer/ui5-webcomponents-react'
import { isNil } from 'lodash'
import isEmpty from 'lodash.isempty'
import isEqual from 'lodash.isequal'
import PropTypes from 'prop-types'
import { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { createPortal } from 'react-dom'
import { useTranslation } from 'react-i18next'
import CancelDocumentUploadDialog, {
  discardDocumentsChoice,
} from 'components/domains/documents/CancelDocumentUploadDialog'
import DocumentTypeInput from 'components/domains/documents/DocumentTypeInput'
import DocumentTypes from 'components/domains/documents/DocumentTypes'
import DocumentUpload from 'components/domains/documents/DocumentUpload'
import styles from 'components/domains/documents/DocumentUploadDialog.module.css'
import EntitySelectionDialog from 'components/domains/documents/EntitySelectionDialog'
import LoadingButton from 'components/ui/button/LoadingButton'
import Dialog, { DialogSecondaryButton } from 'components/ui/dialog/Dialog'
import { RequestStateResolver } from 'components/ui/loading/RequestStateResolver'
import {
  MessageBoxActions,
  MessageBoxTypes,
  useShowMessageBox,
} from 'components/ui/message-box/MessageBox'
import { useShortDateFormatter } from 'hooks/i18n/useI18n'
import useDocumentUpload from 'hooks/services/documents/useDocumentUpload'

const noManualInputAllowedLength = 0

/** @param {string} filename */
const removeFileExtension = (filename) =>
  filename.includes('.') ? filename.split('.').slice(0, -1).join('.') : filename

/** @param {string} filename */
const removeFilename = (filename) =>
  filename.includes('.') ? filename.split('.').slice(-1, Infinity).join('.') : filename

/**
 * @param {{id: string, name: string, type?: string}[]} initialEntities
 * @returns {[{id: string, name: string, type?: string}[], Dispatch<SetStateAction<{id: string, name: string, type?: string}[]>>]}
 */
const useCurrentlySelectedEntities = (initialEntities = []) => {
  const [entities, setEntities] = useState(initialEntities)
  const initialRef = useRef(initialEntities)

  useEffect(() => {
    if (isEqual(initialRef.current, initialEntities)) return
    setEntities(initialEntities)
  }, [initialEntities])

  return [entities, setEntities]
}

/**
 * @param {object} props
 * @param {boolean} props.isOpen
 * @param {(newOpen: boolean) => void} props.setIsOpen
 * @param {object} props.initialState
 * @param {{id: string, name: string, type?: string}[]} props.initialState.selectedEntities
 * @param {FileList | null} [props.initialState.files]
 * @param {(newFileIds: string[]) => void} [props.onUploadFinished]
 * @param {() => void} [props.onClose]
 * @param {string} props.type Use `DocumentTypes`
 */
const DocumentUploadDialog = ({
  isOpen,
  setIsOpen = () => {},
  initialState,
  onUploadFinished,
  onClose,
  type,
}) => {
  const didShowResultRef = useRef(false)
  const isMountedRef = useRef(true)

  /** @type {Promise<{data: any, marketIds: any}>[]} */
  const initialMutations = []
  const [mutations, setMutations] = useState(initialMutations)

  const { t } = useTranslation('translation', { keyPrefix: 'components.document-upload' })
  const { t: tNoPrefix } = useTranslation()

  const [isEntitySelectionOpen, setIsEntitySelectionOpen] = useState(false)
  const [isPropertySelectionDialogOpen, setIsPropertySelectionDialogOpen] = useState(false)
  const [touchedInputs, setTouchedInputs] = useState([])
  const [documentName, setDocumentName] = useState('')
  const [keyDate, setKeyDate] = useState(/** @type {(string | undefined)} */ (undefined))
  const [fileName, setFileName] = useState('')
  const [fileSize, setFileSize] = useState('-')
  const [fileType, setFileType] = useState('-')
  const [isDocumentTypeError, setIsDocumentTypeError] = useState(undefined)
  const [selectedDocumentType, setSelectedDocumentType] = useState(initialState?.documentType ?? '')
  const [files, setFiles] = useState([...(initialState?.files ?? [])])
  const [currentIndex, setCurrentIndex] = useState(0)
  const [filesWithError, setFilesWithError] = useState(/** @type {string[]} */ ([]))
  const [uploadedFileIds, setUploadedFileIds] = useState(/** @type {string[]} */ ([]))
  const [file, setFile] = useState(initialState?.files?.[0])
  const { localePattern, format: dateFormat, parse } = useShortDateFormatter()
  const [isUploading, setIsUploading] = useState(false)
  const [isCancelDocumentUploadOpen, setIsCancelDocumentUploadOpen] = useState(false)
  const [shouldReuseMetadata, setShouldReuseMetadata] = useState(true)
  const [currentlySelectedEntities, setCurrentlySelectedEntities] = useCurrentlySelectedEntities(
    initialState?.selectedEntities ?? [],
  )
  const [selectedPropertyEntities, setSelectedPropertyEntities] = useState([])
  const showMessageBox = useShowMessageBox()

  const isKeyDateRequired = type === DocumentTypes.Market

  useEffect(() => {
    isMountedRef.current = true
    return () => {
      isMountedRef.current = false
    }
  }, [])

  const clearFields = useCallback(
    ({ keepMetadata = false } = {}) => {
      setDocumentName('')
      setKeyDate(undefined)
      setFileName('')
      setFileSize('-')
      setFileType('-')
      setFile(undefined)
      if (!keepMetadata) {
        setCurrentlySelectedEntities(initialState?.selectedEntities ?? [])
        setSelectedPropertyEntities([])
        setSelectedDocumentType('')
      }
    },
    [initialState?.selectedEntities, setCurrentlySelectedEntities, setSelectedPropertyEntities],
  )

  const showResult = useCallback(() => {
    if (isOpen || didShowResultRef.current) return
    didShowResultRef.current = true

    if (filesWithError?.length) {
      showMessageBox({
        type: MessageBoxTypes.Error,
        children: (
          <List
            header={<Text>{t('upload-completed-with-errors')}</Text>}
            separators={ListSeparators.None}
          >
            {filesWithError.map((fileWithError, index) => (
              <StandardListItem
                key={`${fileWithError}-${index}`}
                type={ListItemType.Inactive}
                className={styles.errorListItem}
              >
                {fileWithError}
              </StandardListItem>
            ))}
          </List>
        ),
      })
    }

    onUploadFinished?.(uploadedFileIds)
    setFilesWithError([])
    setUploadedFileIds([])
  }, [isOpen, filesWithError, onUploadFinished, uploadedFileIds, showMessageBox, t])

  useEffect(() => {
    if (!isMountedRef.current) return
    if (!mutations?.length) return

    let skip = false

    Promise.allSettled(mutations).then(() => {
      if (!isMountedRef.current || skip) return
      setIsUploading(false)
    })

    return () => {
      skip = true
    }
  }, [mutations])

  const closeDialog = () => {
    setIsOpen(false)
    onClose?.()
    clearFields({ keepMetadata: false })
    setCurrentIndex(0)
    setFiles([])
  }

  useEffect(() => {
    if (isOpen) {
      didShowResultRef.current = false
      setMutations([])
      setShouldReuseMetadata(true)
      setTouchedInputs([])
    }
  }, [isOpen])

  useEffect(() => {
    const isWorkInProgress = isOpen || isUploading
    if (isWorkInProgress) return

    const didNotUploadAnything = !uploadedFileIds?.length && !filesWithError?.length
    if (didNotUploadAnything) return

    showResult()
  }, [isOpen, isUploading, uploadedFileIds, filesWithError, showResult])

  /**
   * @param {object} file
   * @param {string} file.guid
   */
  const handleUploadSuccess = (file) => {
    setUploadedFileIds((prev) => [...prev, file.guid])
    Modals.showToast({
      children: <span>{t('success-message')}</span>,
    })
  }

  /**
   * @param {any} _error
   * @param {{file: File}} data
   */
  const handleUploadError = (_error, { file: fileWithError }) => {
    setFilesWithError((prev) => [...prev, fileWithError.name])
  }

  const marketReportUploadMutation = useDocumentUpload({
    onSuccess: handleUploadSuccess,
    onError: handleUploadError,
  })

  const fileSizeFormatter = Intl.NumberFormat('en-US', {
    style: 'unit',
    unit: 'byte',
    unitDisplay: 'narrow',
    notation: 'compact',
  })

  const setCurrentFile = useCallback(
    (/** @type {File} */ newFile) => {
      if (!newFile) {
        clearFields({ keepMetadata: shouldReuseMetadata })
        return
      }
      setFile(newFile)
      setFileName(newFile.name)
      setDocumentName(removeFileExtension(newFile.name))
      setFileType(removeFilename(newFile.name))
      setFileSize(fileSizeFormatter.format(newFile.size))
    },
    [fileSizeFormatter, clearFields, shouldReuseMetadata],
  )

  const entityRefs = useMemo(
    () =>
      currentlySelectedEntities
        .map(({ id, type: typeOverride }) => ({
          id,
          type: typeOverride ?? type,
        }))
        .concat(
          selectedPropertyEntities.map(({ id }) => ({
            id,
            type: DocumentTypes.Property,
          })),
        ),

    [currentlySelectedEntities, selectedPropertyEntities, type],
  )

  const handleUploadClicked = () => {
    const promise = marketReportUploadMutation.mutateAsync({
      file,
      documentName,
      keyDate,
      entityRefs,
      documentType: selectedDocumentType,
    })

    setIsUploading(true)
    setMutations((prev) => [...prev, promise])

    const isFinishedUploading = currentIndex >= files.length - 1
    if (isFinishedUploading) {
      closeDialog()
      return
    }

    clearFields({ keepMetadata: shouldReuseMetadata })
    setCurrentFile(files[currentIndex + 1])
    setCurrentIndex((prev) => prev + 1)
  }

  const handleEntitySelectionChange = useCallback(
    (/** @type {{id: string, name: string}[]} */ newSelectedEntities) => {
      setCurrentlySelectedEntities(newSelectedEntities)
    },
    [setCurrentlySelectedEntities],
  )

  const handlePropertyEntitySelectionChange = useCallback(
    (/** @type {{id: string, name: string}[]} */ newSelectedEntities) => {
      setSelectedPropertyEntities(newSelectedEntities)
    },
    [setSelectedPropertyEntities],
  )

  const handleEntitySelectionClose = () => {
    setIsEntitySelectionOpen(false)
    setIsPropertySelectionDialogOpen(false)
  }

  const handleEntitySelectionCancel = () => {
    setCurrentlySelectedEntities(initialState?.selectedEntities ?? [])
    setSelectedPropertyEntities([])
  }

  /** @param {File[]} newFiles */
  const handleFileUpload = (newFiles) => {
    setFiles(newFiles)
    setCurrentIndex(0)
    setCurrentFile(newFiles[0])
  }

  // HINT: handle external file upload (via props)
  useEffect(() => {
    if (!initialState?.files) return
    handleFileUpload(initialState.files)
    // only run this when the external files changed
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [initialState?.files])

  const handleDiscardCurrentDocument = () => {
    Modals.showToast({
      children: <span>{t('discard-document')}</span>,
    })
    if (currentIndex >= files.length - 1) {
      closeDialog()
      return
    }
    const newFiles = [...files]
    newFiles.splice(currentIndex, 1)
    setFiles(newFiles)
    setCurrentFile(newFiles[currentIndex])
  }

  const handleDiscardAllSubsequentDocuments = () => {
    Modals.showToast({
      children: <span>{t('cancel-process')}</span>,
    })
    closeDialog()
  }

  const handleCancelDocumentUploadDiscard = ({ selection }) => {
    setIsCancelDocumentUploadOpen(false)

    switch (selection) {
      case discardDocumentsChoice.current:
        handleDiscardCurrentDocument()
        break

      case discardDocumentsChoice.allSubsequent:
        handleDiscardAllSubsequentDocuments()
        break
    }
  }

  const handleCancelDocumentUploadCancel = () => {
    setIsCancelDocumentUploadOpen(false)
  }

  const uploadDisabled =
    !documentName ||
    (isKeyDateRequired && !keyDate) ||
    !fileName ||
    !selectedDocumentType ||
    isDocumentTypeError ||
    currentlySelectedEntities.length === 0

  const handleCancel = () => {
    if (files.length <= 1) {
      showMessageBox({
        type: MessageBoxTypes.Warning,
        titleText: tNoPrefix('buttons.discard'),
        children: t('cancel.message'),
        actions: [
          <Button
            key="button-document-upload-cancel"
            design={ButtonDesign.Emphasized}
            onClick={closeDialog}
          >
            {tNoPrefix('buttons.discard')}
          </Button>,
          MessageBoxActions.Cancel,
        ],
      })
      return
    }
    setIsCancelDocumentUploadOpen(true)
  }
  const handleShouldReuseMetadataChange = (event) => setShouldReuseMetadata(event.target.checked)

  const isMultipleFileUploadAndNotLastFile =
    files?.length > 1 && files[files.length - 1]?.name !== file.name

  const CustomFormItem = ({ children }) => (
    <FormItem>
      <FlexBox direction={FlexBoxDirection.Column} className={styles.uploadFormItem}>
        {children}
      </FlexBox>
    </FormItem>
  )
  CustomFormItem.propTypes = {
    children: PropTypes.node.isRequired,
  }

  const selectedEntitiesTokens = useMemo(
    () =>
      currentlySelectedEntities
        .filter(({ type: entityType }) => entityType === undefined || entityType === type)
        .map(({ id, name }) => (
          <Token key={id} text={name} data-id={id} closeIcon={<Icon name="decline" />} />
        )),
    [currentlySelectedEntities, type],
  )

  const additionalEntitiesTokens = useMemo(
    () =>
      selectedPropertyEntities.map(({ id, name }) => (
        <Token key={id} text={name} data-id={id} closeIcon={<Icon name="decline" />} />
      )),
    [selectedPropertyEntities],
  )

  const handleTokenDelete = useCallback(
    ({ detail: { token } }) => {
      const selectedId = token.dataset.id
      setCurrentlySelectedEntities((currentSelectedEntities) =>
        currentSelectedEntities.filter(({ id }) => id !== selectedId),
      )

      setSelectedPropertyEntities((currentSelectedEntities) =>
        currentSelectedEntities.filter(({ id }) => id !== selectedId),
      )
    },
    [setCurrentlySelectedEntities, setSelectedPropertyEntities],
  )

  const handleEntitySelectionValueHelpClicked = useCallback(() => {
    setIsEntitySelectionOpen(true)
  }, [])

  const handlePropertyEntityDialogClicked = useCallback(() => {
    setIsPropertySelectionDialogOpen(true)
  }, [])

  const handleDocTypeChange = useCallback((newDocumentType) => {
    setSelectedDocumentType(newDocumentType)
  }, [])

  const setErrorState = useCallback((isErrorState) => {
    setIsDocumentTypeError(isErrorState)
  }, [])

  /** @param {File[]} newFiles */
  const handleFilesChange = (newFiles) => {
    if (!newFiles?.length) return
    handleFileUpload(newFiles)
  }

  return createPortal(
    <>
      <Dialog
        className={styles.dialog}
        open={isOpen}
        headerText={t('title')}
        rightHeaderContent={
          !!files.length && (
            <Label>
              {t('part-of-total', {
                part: currentIndex + 1,
                total: files.length,
              })}
            </Label>
          )
        }
        secondaryButton={
          <LoadingButton
            className={styles.footerButton}
            design={ButtonDesign.Emphasized}
            onClick={handleUploadClicked}
            disabled={uploadDisabled}
            isLoading={isNil(isDocumentTypeError)}
            renderContent={() => t('buttons.upload')}
          />
        }
        leftFooterContent={
          isMultipleFileUploadAndNotLastFile && (
            <CheckBox
              className={styles.reuseMetadata}
              text={t('reuse-metadata')}
              wrappingType={WrappingType.None}
              checked={shouldReuseMetadata}
              onChange={handleShouldReuseMetadataChange}
            />
          )
        }
        closeButton={
          <DialogSecondaryButton onClick={handleCancel}>
            {t('buttons.cancel')}
          </DialogSecondaryButton>
        }
        onAfterClose={closeDialog}
      >
        <Form>
          <FlexBox
            justifyContent={FlexBoxJustifyContent.Start}
            direction={FlexBoxDirection.Column}
            className={styles.uploadForm}
          >
            {isOpen && (
              <FormItem>
                <DocumentUpload
                  fileName={fileName}
                  fileType={fileType}
                  fileSize={fileSize}
                  onHandleFileUpload={(e) => handleFilesChange(e.detail.files)}
                  multiple
                />
              </FormItem>
            )}
            <CustomFormItem>
              <Label showColon required>
                {t('label.document-name')}
              </Label>
              <Input
                value={documentName}
                valueState={
                  isEmpty(documentName) && touchedInputs.includes('documentName')
                    ? ValueState.Error
                    : ValueState.None
                }
                valueStateMessage={
                  <span>
                    {tNoPrefix('form.validation.input', {
                      label: t('label.document-name').toLowerCase(),
                    })}
                  </span>
                }
                onBlur={() => setTouchedInputs([...touchedInputs, 'documentName'])}
                onChange={(event) => setDocumentName(event.target.value)}
              />
            </CustomFormItem>
            <CustomFormItem>
              <Label showColon required>
                {t('label.document-type')}
              </Label>
              <DocumentTypeInput
                entityRefs={entityRefs}
                value={selectedDocumentType}
                onChange={handleDocTypeChange}
                setErrorState={setErrorState}
                onError={() => setSelectedDocumentType('')}
              />
            </CustomFormItem>
            <CustomFormItem>
              <Label showColon required={isKeyDateRequired}>
                {t('label.key-date')}
              </Label>
              <DatePicker
                value={dateFormat(keyDate)}
                onChange={(event) => setKeyDate(parse(event.detail.value, localePattern))}
                formatPattern={localePattern}
                placeholder=""
              />
            </CustomFormItem>
            {type !== DocumentTypes.Requirement && (
              <CustomFormItem>
                <Label showColon required>
                  {t('label', { context: type })}
                </Label>
                <MultiInput
                  maxlength={noManualInputAllowedLength}
                  className={styles.uploadFormItem}
                  tokens={selectedEntitiesTokens}
                  icon={<Icon name="value-help" onClick={handleEntitySelectionValueHelpClicked} />}
                  onTokenDelete={(event) => {
                    handleTokenDelete(event)
                    setTouchedInputs([...touchedInputs, 'entities'])
                  }}
                  valueState={
                    isEmpty(currentlySelectedEntities) && touchedInputs.includes('entities')
                      ? ValueState.Error
                      : ValueState.None
                  }
                  valueStateMessage={
                    <span>
                      {tNoPrefix('form.validation.input', {
                        label: t('label', { context: type }).toLowerCase(),
                      })}
                    </span>
                  }
                  onBlur={() => setTouchedInputs([...touchedInputs, 'entities'])}
                />
              </CustomFormItem>
            )}
            <CustomFormItem>
              {type === DocumentTypes.Deal && (
                <>
                  <Label showColon>{t(`label_${DocumentTypes.Property}`)}</Label>
                  <MultiInput
                    maxlength={noManualInputAllowedLength}
                    className={styles.uploadFormItem}
                    tokens={additionalEntitiesTokens}
                    icon={<Icon name="value-help" onClick={handlePropertyEntityDialogClicked} />}
                    onTokenDelete={handleTokenDelete}
                  />
                </>
              )}
            </CustomFormItem>
          </FlexBox>
        </Form>
      </Dialog>
      <Dialog
        open={isUploading && !isOpen}
        headerText={tNoPrefix('components.loading-upload-dialog.title')}
        headerClassName={styles.loadingDialogHeader}
        headerTitleClassName={styles.loadingDialogHeaderTitle}
        className={styles.loadingDialog}
      >
        <FlexBox className={styles.loadingDialogWrapper} alignItems={FlexBoxAlignItems.Center}>
          <RequestStateResolver
            center
            isLoading
            isError={false}
            errorToDisplay=""
            renderContent={() => {}}
          />
        </FlexBox>
      </Dialog>
      {type !== DocumentTypes.Requirement && isOpen && (
        <EntitySelectionDialog
          type={type}
          open={isEntitySelectionOpen}
          setIsOpen={setIsEntitySelectionOpen}
          openAdditionalDialog={type === DocumentTypes.Deal && isPropertySelectionDialogOpen}
          onChange={handleEntitySelectionChange}
          onAdditionalChange={
            type === DocumentTypes.Deal ? handlePropertyEntitySelectionChange : undefined
          }
          onClose={handleEntitySelectionClose}
          onCancel={handleEntitySelectionCancel}
          value={currentlySelectedEntities}
          additionalValue={selectedPropertyEntities}
        />
      )}
      <CancelDocumentUploadDialog
        isOpen={isCancelDocumentUploadOpen}
        onDiscard={handleCancelDocumentUploadDiscard}
        onCancel={handleCancelDocumentUploadCancel}
      />
    </>,
    document.body,
  )
}

DocumentUploadDialog.propTypes = {
  isOpen: PropTypes.bool,
  setIsOpen: PropTypes.func,
  initialState: PropTypes.shape({
    selectedEntities: PropTypes.array,
    files: PropTypes.any,
    documentType: PropTypes.string,
  }),
  onUploadFinished: PropTypes.func,
  type: PropTypes.oneOf(Object.values(DocumentTypes)),
}

export default DocumentUploadDialog
