import isEmpty from 'lodash.isempty'
import { useInfiniteRequest, useRequest } from 'hooks/services/baseService'

/**
 * Returns all properties matching the given filters
 * @returns {{isLoading: boolean, isError: boolean, data: Properties}}
 */

const DEFAULT_PAGINATION_DELTA = 50

export const useProperties = ({
  filter = {},
  sort,
  pagination: { offset, limit } = {},
  options = {},
}) => {
  const {
    propertyId,
    propertyName,
    country,
    propertyTypes,
    constructionYear,
    zipCode,
    street,
    city,
    dealId,
    marketId,
    otherExternalId,
    financingStatusCode,
    cagStatusCode,
  } = filter
  const queryParams = new URLSearchParams()
  if (propertyId) queryParams.append('property_id', propertyId)
  if (propertyName) queryParams.append('property_name', propertyName)
  if (country) queryParams.append('country', country)
  if (propertyTypes) queryParams.append('property_types', propertyTypes)
  if (constructionYear) queryParams.append('construction_year', constructionYear)
  if (zipCode) queryParams.append('zip_code', zipCode)
  if (street) queryParams.append('street', street)
  if (city) queryParams.append('city', city)
  if (dealId) queryParams.append('deal_id', dealId)
  if (marketId) queryParams.append('market_id', marketId)
  if (otherExternalId) queryParams.append('other_external_id', otherExternalId)
  if (financingStatusCode) queryParams.append('financing_status_code', financingStatusCode)
  if (cagStatusCode) queryParams.append('cag_status_code', cagStatusCode)
  if (sort) queryParams.append('sort', sort)
  if (offset) queryParams.append('offset', offset)
  if (limit) queryParams.append('limit', limit)

  // If there is no queryParam that filters/paginates, all available properties will be requested
  // So the request needs to be disabled in that case
  const isQueryEnabled = !isEmpty(filter) || !!offset || !!limit
  const isQueryEnabledExternal = typeof options?.enabled === 'boolean' ? options.enabled : true
  const request = useRequest({
    path: `/properties?${queryParams.toString()}`,
    translated: true,
    keys: ['properties', queryParams.toString()],
    options: { ...options, enabled: isQueryEnabled && isQueryEnabledExternal },
  })

  return request
}

export const usePropertiesInfiniteRequest = ({
  filter: {
    propertyId,
    propertyName,
    country,
    propertyTypes,
    constructionYear,
    zipCode,
    street,
    city,
    dealId,
    marketId,
    otherExternalId,
    financingStatusCode,
    cagStatusCode,
  } = {},
  sort,
  options,
  paginationDelta = DEFAULT_PAGINATION_DELTA,
}) => {
  const queryParams = new URLSearchParams()
  if (propertyId) queryParams.append('property_id', propertyId)
  if (propertyName) queryParams.append('property_name', propertyName)
  if (country) queryParams.append('country', country)
  if (propertyTypes) queryParams.append('property_types', propertyTypes)
  if (constructionYear) queryParams.append('construction_year', constructionYear)
  if (zipCode) queryParams.append('zip_code', zipCode)
  if (street) queryParams.append('street', street)
  if (city) queryParams.append('city', city)
  if (dealId) queryParams.append('deal_id', dealId)
  if (marketId) queryParams.append('market_id', marketId)
  if (otherExternalId) queryParams.append('other_external_id', otherExternalId)
  if (financingStatusCode) queryParams.append('financing_status_code', financingStatusCode)
  if (cagStatusCode) queryParams.append('cag_status_code', cagStatusCode)
  if (sort) queryParams.append('sort', sort)

  const request = useInfiniteRequest({
    path: `/properties`,
    translated: true,
    queryParams,
    limit: paginationDelta,
    keys: ['properties', queryParams.toString()],
    options,
  })

  return request
}
