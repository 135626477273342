import isEmpty from 'lodash.isempty'
import { useMemo } from 'react'
import { useRequests } from 'hooks/services/baseService'
import { useRequestsMemo } from 'hooks/services/queryHelper'

/**
 * Retrieve property valuation data for each propertyUuid provided
 * @param propertyUuids An array of propertyUuids to individually get the valuations from
 * @param options
 * @returns {(*&{data: *&{propertyUuid: *}})[]}
 */
const usePropertyValuationsByArray = ({ propertyUuids, options = {} }) => {
  const responses = useRequestsMemo(
    useRequests({
      requests: propertyUuids?.map((propertyUuid) => ({
        path: `/properties/${propertyUuid}/valuations`,
        keys: ['valuations', propertyUuid],
      })),
      translated: true,
      useCache: true,
      options: { enabled: !isEmpty(propertyUuids), ...options },
    }) ?? [],
  )

  return useMemo(
    () =>
      responses?.map((result, index) => ({
        ...result,
        data: { propertyUuid: propertyUuids[index], ...result.data },
      })),
    [propertyUuids, responses],
  )
}

export default usePropertyValuationsByArray
