import {
  AnalyticalTableScaleWidthMode,
  AnalyticalTableSelectionBehavior,
  AnalyticalTableSelectionMode,
} from '@fioneer/ui5-webcomponents-react'
import PropTypes from 'prop-types'
import { useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import styles from 'components/domains/business-events-and-tasks/decision-paper/tiles/ops-memo/property/OpsMemoPropertiesTable.module.css'
import useOpsMemoPropertyPortfolioOverviewTableColumns from 'components/domains/business-events-and-tasks/decision-paper/tiles/ops-memo/property/useOpsMemoPropertyPortfolioOverviewTableColumns'
import AnalyticalTableWithToolbar from 'components/domains/business-events-and-tasks/decision-paper/tiles/ops-memo/tranche/shared/ui/tables/analytical/AnalyticalTableWithToolbar'
import AnalyticalTableScalingWrapper from 'components/domains/business-events-and-tasks/decision-paper/tiles/shared/AnalyticalTableScalingWrapper'

const BASE_HEIGHT = 70
const HEIGHT_PER_ROW = 140

const OpsMemoPropertiesTable = ({ tileId, isPdfView = false }) => {
  const { t } = useTranslation('decisionPaper', {
    keyPrefix: 'pages.deals.detail.portfolio',
  })
  const tableRef = useRef()
  const [selectedPropertyUuids, setSelectedPropertyUuids] = useState([])
  const [tableWidth, setTableWidth] = useState(tableRef?.current?.totalColumnsWidth ?? 0)

  useEffect(() => {
    setTableWidth(tableRef?.current?.totalColumnsWidth ?? 0)
  }, [tableRef?.current?.totalColumnsWidth])

  const tileData = useSelector((state) => state.decisionPaper.tilesOverview.tiles[tileId])
  const properties = tileData?.data?.properties
  const valuations = tileData?.data?.valuations

  useEffect(() => {
    if (!properties.length && selectedPropertyUuids.length) {
      setSelectedPropertyUuids([])
    }
  }, [properties, setSelectedPropertyUuids, selectedPropertyUuids])

  const { data: tableColumns } = useOpsMemoPropertyPortfolioOverviewTableColumns({})

  const tableData = properties.map(({ uuid, ...property }) => ({
    uuid,
    ...property,
    valuations: valuations?.find(({ uuid: propertyUuid }) => propertyUuid === uuid) ?? {},
  }))

  const handleOnRowSelect = (event) => {
    const mappedPropertyUuids = event.detail.selectedFlatRows.map((row) => row.original.uuid)
    setSelectedPropertyUuids(mappedPropertyUuids)
  }

  return (
    <AnalyticalTableScalingWrapper isPdfView={isPdfView} tableWidth={tableWidth}>
      <div className={styles.tableWrapper}>
        <AnalyticalTableWithToolbar
          isPdfView={isPdfView}
          ref={tableRef}
          className={styles.overviewTable}
          columns={tableColumns}
          data={tableData ?? []}
          headerRowHeight={40}
          rowHeight={HEIGHT_PER_ROW}
          scaleWidthMode={AnalyticalTableScaleWidthMode.Default}
          adjustTableHeightOnPopIn
          disableColumnPopover={true}
          groupable={false}
          filterable={false}
          minRows={1}
          title={t('table.title')}
          noDataText={t('table.no-data')}
          style={{
            minHeight: BASE_HEIGHT + (tableData?.length ?? 0) * HEIGHT_PER_ROW,
          }}
          selectionMode={AnalyticalTableSelectionMode.SingleSelect}
          selectionBehavior={AnalyticalTableSelectionBehavior.RowSelector}
          onRowSelect={handleOnRowSelect}
          reactTableOptions={{ autoResetSelectedRows: false }}
        />
      </div>
    </AnalyticalTableScalingWrapper>
  )
}

export default OpsMemoPropertiesTable

OpsMemoPropertiesTable.propTypes = {
  tileId: PropTypes.string.isRequired,
  isPdfView: PropTypes.bool.isRequired,
}
