import { Grid } from '@fioneer/ui5-webcomponents-react'
import PropTypes from 'prop-types'
import { useCallback, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import styles from 'components/domains/properties/valuation/PropertyValuationsRequests.module.css'
import {
  areFiltersApplied,
  filterKeys,
  getRequestParametersForValuationRequests,
} from 'components/domains/properties/valuation/ValuationRequestsUtils'
import { propertyAddressShape } from 'components/domains/properties/valuation/panels/ValuationRequestCreationPanel'
import ValuationRequestsTable, {
  allColumns,
} from 'components/domains/properties/valuation/table/ValuationRequestsTable'
import Card from 'components/ui/card/Card'
import LoadingStateWrapper from 'components/ui/screens/LoadingStateWrapper'
import usePropertyValuationReasons from 'hooks/services/properties/valuations/usePropertyValuationReasons'
import usePropertyValuationRequests from 'hooks/services/properties/valuations/usePropertyValuationRequests'

const valuationRequestsTableViewColumns = [
  allColumns.report,
  allColumns.reason,
  allColumns.type,
  allColumns.businessEvent,
  allColumns.valuer,
  allColumns.dueDate,
  allColumns.status,
  allColumns.note,
  allColumns.reportDate,
  allColumns.editRow,
]

const valuationRequestsTableEditColumns = [
  allColumns.report,
  allColumns.reason,
  allColumns.type,
  allColumns.businessEvent,
  allColumns.valuer,
  allColumns.dueDate,
  allColumns.status,
  allColumns.note,
  allColumns.reportDate,
  allColumns.editRow,
]

const ascending = 'asc'

const PropertyValuationsRequests = ({
  propertyUuid,
  propertyName,
  propertyDisplayId,
  propertyAddress,
  allowedOperations = [],
}) => {
  const { t } = useTranslation('translation', { keyPrefix: 'pages.property.valuation.requests' })

  const [sorting, setSorting] = useState({ sortBy: filterKeys.status, orderBy: ascending })
  const [filters, setFilters] = useState([])
  const [searchParam, setSearchParam] = useState('')

  const {
    isLoading: isReasonsLoading,
    isError: isReasonsError,
    data: valuationReasonsData,
  } = usePropertyValuationReasons()

  const requestParameters = useMemo(
    () => getRequestParametersForValuationRequests(filters, searchParam),
    [filters, searchParam],
  )

  const requestSorting = useMemo(
    () => ({
      sortBy: sorting.sortBy === filterKeys.status ? filterKeys.infoStatus : sorting.sortBy,
      orderBy: sorting.orderBy,
    }),
    [sorting],
  )

  const {
    data: valuationData,
    isLoading: isRequestsLoading,
    isError: isRequestsError,
    fetchNextPage,
  } = usePropertyValuationRequests(requestSorting, requestParameters, {
    propertyId: propertyUuid,
  })

  const noDataText = useMemo(() => {
    if (areFiltersApplied(filters) || searchParam) return t('table.no-data-with-filters')
    return t('table.no-data')
  }, [filters, searchParam, t])

  const renderContent = useCallback(() => {
    const valuationRequestsTableData = valuationData.valuationRequests.map((valuationRequest) => ({
      ...valuationRequest,
      property: {
        description: propertyName,
        id: propertyDisplayId,
        uuid: propertyUuid,
        address: propertyAddress,
        allowedOperations: { allowedOperations },
      },
    }))

    const properties = [
      { propertyUuid, propertyName, propertyDisplayId, propertyAddress, allowedOperations },
    ]
    return (
      <Card>
        <Grid vSpacing="0rem" defaultSpan="XL12 L12 M12 S12" className={styles.card}>
          <ValuationRequestsTable
            valuationRequests={valuationRequestsTableData}
            sortBy={sorting.sortBy}
            orderBy={sorting.orderBy}
            filters={filters}
            onSortingChanged={setSorting}
            setFilters={setFilters}
            searchParam={searchParam}
            setSearchParam={setSearchParam}
            valuationReasons={valuationReasonsData}
            viewColumns={valuationRequestsTableViewColumns}
            editColumns={valuationRequestsTableEditColumns}
            noDataText={noDataText}
            properties={properties}
            onLoadMore={fetchNextPage}
            totalValuationRequestAmount={valuationData.size ?? 0}
          />
        </Grid>
      </Card>
    )
  }, [
    sorting,
    filters,
    searchParam,
    allowedOperations,
    propertyAddress,
    propertyDisplayId,
    propertyName,
    propertyUuid,
    valuationData?.valuationRequests,
    valuationData?.size,
    valuationReasonsData,
    fetchNextPage,
    noDataText,
  ])

  // can not return the Card here beacuse valuationData.size is not available until loaded, using a state here also doesnt work
  return (
    <LoadingStateWrapper
      isLoading={isRequestsLoading || isReasonsLoading}
      isError={isRequestsError || isReasonsError}
      renderContent={renderContent}
      errorDescription={t('error.description')}
      errorDetails={' '}
      errorTitle={t('error.title')}
    />
  )
}

PropertyValuationsRequests.propTypes = {
  propertyUuid: PropTypes.string.isRequired,
  propertyDisplayId: PropTypes.string.isRequired,
  propertyAddress: propertyAddressShape.isRequired,
  propertyName: PropTypes.string.isRequired,
  allowedOperations: PropTypes.arrayOf(PropTypes.string),
}

export default PropertyValuationsRequests
