import { ObjectStatus, Text, ValueState } from '@fioneer/ui5-webcomponents-react'
import isEmpty from 'lodash.isempty'
import isNil from 'lodash.isnil'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import EntityCell from 'components/domains/business-events-and-tasks/decision-paper/tiles/annual-review-basel-two-confirmation/annual-review/shared/ui/tables/cells/EntityCell'
import styles from 'components/domains/business-events-and-tasks/decision-paper/tiles/gcc-involved-parties/unit-overview/shared/UnitOverviewTableCell.module.css'
import {
  ROW_TYPE_BUSINESS_PARTNER,
  ROW_TYPE_COMPLIANCE_WITH_RISK_STRATEGY,
  ROW_TYPE_DATE,
  ROW_TYPE_KPI,
  ROW_TYPE_LENDERS,
  ROW_TYPE_MONETARY_VALUE,
  ROW_TYPE_PERCENTAGE,
  ROW_TYPE_SPONSORS,
  ROW_TYPE_STATUS,
  ROW_TYPE_GREEN_SCORE,
  ROW_TYPE_DEAL,
  ROW_TYPE_PROPERTY,
} from 'components/domains/business-events-and-tasks/decision-paper/tiles/gcc-involved-parties/unit-overview/shared/constants'
import ComplianceWithRiskStrategyCell from 'components/domains/business-events-and-tasks/decision-paper/tiles/gcc-involved-parties/unit-overview/shared/shared-components/ComplianceWithRiskStrategyCell'
import DateCell from 'components/domains/business-events-and-tasks/decision-paper/tiles/gcc-involved-parties/unit-overview/shared/shared-components/DateCell'
import LenderCell from 'components/domains/business-events-and-tasks/decision-paper/tiles/gcc-involved-parties/unit-overview/shared/shared-components/LenderCell'
import MoneyWithShareCell from 'components/domains/business-events-and-tasks/decision-paper/tiles/gcc-involved-parties/unit-overview/shared/shared-components/MoneyWithShareCell'
import PercentageCell from 'components/domains/business-events-and-tasks/decision-paper/tiles/gcc-involved-parties/unit-overview/shared/shared-components/PercentageCell'
import TextCell from 'components/domains/business-events-and-tasks/decision-paper/tiles/gcc-involved-parties/unit-overview/shared/shared-components/TextCell'

const renderCell = (props, tNoPrefix) => {
  const { cell, row } = props
  const { value: { value = null, isLoading = false, isError = false } = {} } = cell

  const hasSubRows = !isNil(row?.subRows) && row.subRows.length > 0

  const renderStatusColumnCell = (renderValue) => {
    let state = ValueState.Information

    switch (renderValue) {
      case 'Green':
        state = ValueState.Success
        break
      case 'Yellow':
        state = ValueState.Warning
        break
      case 'Red':
        state = ValueState.Error
        break
    }

    return (
      renderValue && (
        <ObjectStatus inverted state={state} className={styles.statusLabel}>
          {renderValue}
        </ObjectStatus>
      )
    )
  }

  switch (cell.column.id) {
    case ROW_TYPE_KPI:
      return row.original?.rowType === ROW_TYPE_KPI ? (
        <Text className={hasSubRows ? styles.kpiLabel : styles.indentedKpiLabel}>{cell.value}</Text>
      ) : (
        <Text>{cell.value}</Text>
      )
  }

  switch (row.original?.rowType) {
    case ROW_TYPE_BUSINESS_PARTNER:
      return (
        <EntityCell
          name={value?.businessPartnerName}
          link={`/business-partners/${value?.businessPartnerId}`}
          id={value?.businessPartnerId}
          options={{ isNameBold: true }}
        />
      )

    case ROW_TYPE_STATUS:
      return renderStatusColumnCell(value)

    case ROW_TYPE_MONETARY_VALUE:
      return MoneyWithShareCell({
        originalCurrency: value?.currency,
        originalAmount: value?.amount,
        headquarterCurrency: value?.currencyHeadquarter,
        headquarterAmount: value?.amountHeadquarter,
        isLoading: isLoading,
        isError: isError,
      })

    case ROW_TYPE_COMPLIANCE_WITH_RISK_STRATEGY:
      return <ComplianceWithRiskStrategyCell {...value} />

    case ROW_TYPE_LENDERS:
      return <LenderCell {...value} className={styles.rowItem} />

    case ROW_TYPE_SPONSORS:
      return (
        <div className={styles.rowItem}>
          {!isEmpty(value) &&
            value?.map((sponsor, index) => (
              <EntityCell
                key={index}
                link={`/business-partners/${sponsor?.sponsorBpId}`}
                name={sponsor?.sponsorBpName}
                id={sponsor?.sponsorBpId}
                options={{ isNameBold: true }}
              />
            ))}
        </div>
      )

    case ROW_TYPE_GREEN_SCORE:
      return typeof value === 'object' ? (
        value?.customField?.value && (
          <EntityCell
            name={value?.propertyName}
            link={`/properties/${value?.propertyId}`}
            id={value?.customField?.value}
            options={{ isNameBold: true }}
          />
        )
      ) : (
        <Text>{value}</Text>
      )

    case ROW_TYPE_DATE:
      return DateCell({
        date: value,
        isLoading: isLoading,
        isError: isError,
      })

    case ROW_TYPE_PERCENTAGE:
      return PercentageCell({ value, isLoading: isLoading, isError: isError })

    case ROW_TYPE_DEAL:
      return (
        <EntityCell
          link={`/deals/${value?.dealDisplayId}`}
          name={`${value?.dealName}${
            value?.isWorkingVersion
              ? ` [${tNoPrefix(
                  'tiles.unit-overview.table.rows.section.deal.dealVersion.workingVersion',
                )}]`
              : ''
          }`}
          id={value?.dealDisplayId}
          options={{
            isNameBold: true,
          }}
          isLoading={isLoading}
          isError={isError}
        />
      )

    case ROW_TYPE_PROPERTY:
      return (
        <EntityCell link={`/deals/${value?.dealDisplayId}/financed-objects`} name={value?.count} />
      )

    default:
      return TextCell({ value, isLoading: isLoading, isError: isError }) // TODO: CWP-11196: simplify
  }
}

const UnitOverviewTableCell = (props) => {
  const { t: tNoPrefix } = useTranslation('decisionPaper')
  return renderCell(props, tNoPrefix)
}

UnitOverviewTableCell.propTypes = {
  cell: PropTypes.shape({
    value: PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.object]),
    column: PropTypes.shape({
      id: PropTypes.string,
    }),
  }).isRequired,
  row: PropTypes.shape({
    original: PropTypes.shape({
      rowType: PropTypes.string,
    }),
  }).isRequired,
}

export default UnitOverviewTableCell
