import { IllustratedMessage } from '@fioneer/ui5-webcomponents-react'
import '@ui5/webcomponents/dist/features/InputSuggestions.js'
import uniqBy from 'lodash.uniqby'
import PropTypes from 'prop-types'
import { useCallback, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import styles from 'components/domains/business-partners/BusinessPartnerSearchDialog.module.css'
import BusinessPartnerTable from 'components/domains/business-partners/BusinessPartnerTable'
import BusinessPartnerFilterBar from 'components/domains/business-partners/filterbar/BusinessPartnerFilterBar'
import { useMatchingBusinessPartners } from 'hooks/services/business-partners/searchBusinessPartners'

const initialSorting = { columnKey: 'id', orderBy: 'asc' }

const emptyArray = []

const removeDuplicateBusinessPartners = (businessPartners) => {
  const result = []
  if (!businessPartners?.length) return result

  return uniqBy(businessPartners, 'id')
}

const BusinessPartnerSearchTable = ({
  initialSearch,
  initialId,
  initialIsBeforeSearch,
  onChange,
  initialRoles = [],
  selectedBusinessPartners,
  initialExcludeInactive = false,
  isMultiSelect = false,
}) => {
  const { t } = useTranslation(undefined, {
    keyPrefix: 'components.business-partner.dialog-search',
  })

  const [filterParams, setFilterParams] = useState({
    q: initialSearch || '',
    id: initialId || '',
    street: '',
    city: '',
    countries: [],
    roles: initialRoles,
    excludeInactive: initialExcludeInactive,
    sort: initialSorting,
  })
  const [isBeforeSearch, setIsBeforeSearch] = useState(initialIsBeforeSearch)

  const translateSortSettingToSortParameter = (sortSetting) =>
    `${sortSetting?.orderBy === 'asc' ? '+' : '-'}${sortSetting?.columnKey}`

  const {
    data: { businessPartners: suggestions = emptyArray, total } = {},
    isFetching: isSuggestionsLoading,
    isError: isSuggestionsError,
    fetchNextPage,
    hasNextPage,
  } = useMatchingBusinessPartners({
    ...filterParams,
    sort: translateSortSettingToSortParameter(filterParams.sort),
  })

  const updateFilters = useCallback(
    ({ id, q, street, city, countries, roles, excludeInactive, sort }) => {
      setFilterParams({ id, q, street, city, countries, roles, excludeInactive, sort })
    },
    [],
  )

  const handleFilterChange = useCallback(
    (newFilterParams) => {
      setIsBeforeSearch(false)
      updateFilters(newFilterParams)
    },
    [updateFilters],
  )

  const handleChange = useCallback(
    (selectedBusinessPartnerIds) => {
      const newlySelectedBusinesspartners = [
        ...selectedBusinessPartners,
        ...suggestions.filter(({ id }) => selectedBusinessPartnerIds.includes(id)),
      ]

      const businessPartnerIdsToRemoveFromSelection = newlySelectedBusinesspartners
        .map(({ id }) => id)
        .filter(
          (id) =>
            !selectedBusinessPartnerIds.includes(id) &&
            suggestions.some(({ id: bpId }) => id === bpId),
        )
      const newBusinesspartnersSelection = removeDuplicateBusinessPartners(
        newlySelectedBusinesspartners.filter(
          ({ id }) => !businessPartnerIdsToRemoveFromSelection.includes(id),
        ),
      )

      onChange(newBusinesspartnersSelection)
    },
    [suggestions, onChange, selectedBusinessPartners],
  )

  const selectedBusinessPartnerIds = useMemo(
    () => selectedBusinessPartners.map(({ id }) => id),
    [selectedBusinessPartners],
  )

  return (
    <>
      <BusinessPartnerFilterBar initialValues={filterParams} onGo={handleFilterChange} />
      {isBeforeSearch ? (
        <IllustratedMessage
          className={styles.beforeSearchMessage}
          name="BeforeSearch"
          titleText={t('title', {
            keyPrefix: 'pages.business-partner-search.perform-search',
          })}
          subtitleText={t('subtitle', {
            keyPrefix: 'pages.business-partner-search.perform-search',
          })}
        />
      ) : (
        <BusinessPartnerTable
          businessPartners={suggestions ?? emptyArray}
          isLoading={isSuggestionsLoading}
          isError={isSuggestionsError}
          onChange={handleChange}
          hasNextPage={!!hasNextPage}
          fetchNextPage={fetchNextPage}
          total={total ?? 0}
          isMultiSelect={isMultiSelect}
          selectedBusinessPartnerIds={selectedBusinessPartnerIds}
        />
      )}
    </>
  )
}

BusinessPartnerSearchTable.propTypes = {
  initialSearch: PropTypes.string,
  initialId: PropTypes.string,
  initialRoles: PropTypes.arrayOf(PropTypes.string),
  // eslint-disable-next-line react/forbid-prop-types
  selectedBusinessPartners: PropTypes.arrayOf(PropTypes.object).isRequired,
  onChange: PropTypes.func,
  translatedTypeName: PropTypes.string,
  initialExcludeInactive: PropTypes.bool,
  initialIsBeforeSearch: PropTypes.bool,
  isMultiSelect: PropTypes.bool,
}

export default BusinessPartnerSearchTable
