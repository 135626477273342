import { Label } from '@fioneer/ui5-webcomponents-react'
import PropTypes from 'prop-types'
import { useCallback, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'
import ConditionsCovenantCheckLink from 'components/domains/conditions/overview/table-cells/generic/ConditionsCovenantCheckLink'
import styles from 'components/domains/conditions/overview/table-cells/requirements/RequirementsTableCovenantCheckSubcomponent.module.css'
import { ErrorDataUnavailableInCell } from 'components/ui/errors/ErrorDataUnavailableInCell'
import { RequestStateResolver } from 'components/ui/loading/RequestStateResolver'
import useCovenantCheckById from 'hooks/services/deals/covenants/useCovenantCheckById'
import { requirementRowHasError } from 'redux/slices/conditions/requirementsTableSlice'

const RequirementsTableCovenantCheckSubcomponent = ({ requirementId, covenantCheckId }) => {
  const { t } = useTranslation('translation', {
    keyPrefix: 'components.requirements.table.subcomponents.covenant-check',
  })
  const dispatch = useDispatch()
  const { isLoading, isError, data: covenantCheck } = useCovenantCheckById(covenantCheckId)

  useEffect(() => {
    if (isError) {
      dispatch(requirementRowHasError({ id: requirementId, error: { hasError: true } }))
    }
  }, [isError, dispatch, requirementId])

  const renderContent = useCallback(
    () => (
      <ConditionsCovenantCheckLink
        covenantCheckId={covenantCheckId}
        covenantCheckName={covenantCheck?.name}
      />
    ),
    [covenantCheck?.name, covenantCheckId],
  )

  if (!covenantCheckId) {
    return (
      <>
        <Label className={styles.emptyTextPadding}>{t('label')}</Label>
      </>
    )
  }

  return (
    <>
      <Label>{t('label')}</Label>
      <RequestStateResolver
        isError={isError}
        isLoading={isLoading}
        errorToDisplay={<ErrorDataUnavailableInCell />}
        renderContent={renderContent}
      />
    </>
  )
}

RequirementsTableCovenantCheckSubcomponent.propTypes = {
  requirementId: PropTypes.string,
  covenantCheckId: PropTypes.string,
}

export default RequirementsTableCovenantCheckSubcomponent
