import { Button, ButtonDesign, TableMode } from '@fioneer/ui5-webcomponents-react'
import orderBy from 'lodash.orderby'
import PropTypes from 'prop-types'
import { useCallback, useEffect, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { propertyAllowedOperations } from 'api/property/propertyAllowedOperations'
import styles from 'components/domains/properties/valuation/valuation-results/PropertyValuationResultsTable.module.css'
import PropertyValuationResultsUploadButton from 'components/domains/properties/valuation/valuation-results/buttons/PropertyValuationResultsUploadButton'
import useMapPropertyValuationResultsTableData from 'components/domains/properties/valuation/valuation-results/useMapPropertyValuationResultsTableData'
import usePropertyValuationResultsTableColumns from 'components/domains/properties/valuation/valuation-results/usePropertyValuationResultsTableColumns'
import SortedTable from 'components/ui/tables/sorted-tables/SortedTable'
import {
  resetValuationTableState,
  startValuationResultsAddMode,
} from 'redux/slices/properties/valuationResultsTableSlice'

const PropertyValuationResultsTable = ({
  valuationResults,
  isDecisionPaperPdfView = false,
  propertyCurrencyCode,
  propertyUuid,
  allowedOperations,
}) => {
  const { t } = useTranslation('translation', { keyPrefix: 'pages.property.valuation.results' })
  const { t: tNoPrefix } = useTranslation()
  const dispatch = useDispatch()
  const isAddMode = useSelector((state) => state.properties.valuationResults.isAddMode)
  const currentEditedValues = useSelector(
    (state) => state.properties.valuationResults.editedRow?.currentValues,
  )
  const highlightedValuations = useSelector(
    (state) => state.properties.valuationResults.highlightedRows,
  )
  const isEditingEnabled = useMemo(
    () =>
      allowedOperations.some(
        (operation) => operation === propertyAllowedOperations.propertyValuationUpdate,
      ),
    [allowedOperations],
  )

  const methodEnum = useMemo(
    () =>
      Object.fromEntries(
        valuationResults.map(({ calculation_method_name: method }) => [method, method]),
      ),
    [valuationResults],
  )

  const { columnDefinitions } = usePropertyValuationResultsTableColumns({ methodEnum })

  useEffect(() => () => dispatch(resetValuationTableState()), [dispatch])

  const sortValuations = useCallback(
    (tableData, columnKey, sorting) => {
      const sortData = isAddMode ? tableData.slice(1) : tableData
      const sortKey =
        columnKey === 'validTo' || columnKey === 'validFrom' || columnKey === 'keyDate'
          ? [`${columnKey}.value`, 'method.value']
          : `${columnKey}.value`
      const sortedData = orderBy(sortData, sortKey, sorting)
      return isAddMode ? [...tableData.slice(0, 1), ...sortedData] : sortedData
    },
    [isAddMode],
  )

  const { tableData } = useMapPropertyValuationResultsTableData({
    valuationResults,
    isAddMode,
    currentEditedValues,
    highlightedValuations,
    propertyUuid,
  })

  const handleAddbuttonClick = useCallback(() => {
    if (isAddMode) {
      return
    }
    dispatch(startValuationResultsAddMode({ currency: propertyCurrencyCode }))
  }, [dispatch, isAddMode, propertyCurrencyCode])

  const toolbarConfig = useMemo(() => {
    if (isDecisionPaperPdfView || !propertyCurrencyCode) {
      return {}
    }
    const additionalActions = isEditingEnabled
      ? [
          <Button
            key="add-button"
            design={ButtonDesign.Transparent}
            onClick={handleAddbuttonClick}
            disabled={isAddMode}
          >
            {tNoPrefix('buttons.add')}
          </Button>,
          <PropertyValuationResultsUploadButton
            key="upload-button"
            propertyUuid={propertyUuid}
            propertyCurrencyCode={propertyCurrencyCode}
            disabled={isAddMode}
          />,
        ]
      : []
    return {
      additionalActions: additionalActions,
      title: t('table.toolbar.title'),
      sorting: {
        columnKey: 'validFrom',
        isSortingAscending: false,
      },
    }
  }, [
    handleAddbuttonClick,
    isAddMode,
    isDecisionPaperPdfView,
    isEditingEnabled,
    propertyUuid,
    propertyCurrencyCode,
    t,
    tNoPrefix,
  ])
  return (
    <SortedTable
      columnDefinitions={columnDefinitions}
      customOrderFunction={sortValuations}
      tableData={tableData}
      toolbarConfig={toolbarConfig}
      additionalTableProperties={{
        stickyColumnHeader: true,
        className: styles.valuationsTable,
        mode: TableMode.None,
      }}
      noDataText={t('table.no-data')}
    />
  )
}

PropertyValuationResultsTable.propTypes = {
  valuationResults: PropTypes.arrayOf(
    PropTypes.shape({
      calculation_method_name: PropTypes.string,
    }),
  ).isRequired,
  propertyUuid: PropTypes.string,
  isDecisionPaperPdfView: PropTypes.bool,
  allowedOperations: PropTypes.arrayOf(PropTypes.string).isRequired,
  propertyCurrencyCode: PropTypes.string,
}

export default PropertyValuationResultsTable
