import {
  FlexBox,
  FlexBoxAlignItems,
  FlexBoxDirection,
  Input,
  Text,
  TextArea,
  ValueState,
} from '@fioneer/ui5-webcomponents-react'
import isNil from 'lodash.isnil'
import React from 'react'
import { useTranslation } from 'react-i18next'
import useValidateKpiAdjustmentTable from 'components/domains/kpis/adjustment/useValidateKpiAdjustmentTable'
import KpiUnit from 'components/domains/kpis/chart/KpiUnit'
import DatePickerWithoutMinWidth from 'components/ui/date-picker/DatePickerWithoutMinWidth'
import FormattedNumberInput from 'components/ui/input/FormattedNumberInput'
import StaffMemberByObjectIdOrEmail from 'components/ui/staff-member/StaffMemberByObjectIdOrEmail'
import { rowKeyNewRow } from 'components/ui/tables/display-and-edit-table/constants'
import { useShortDateFormatter } from 'hooks/i18n/useI18n'

const AdjustmentType = {
  PROSPECTIVE: 'PROSPECTIVE',
  RETROSPECTIVE: 'RETROSPECTIVE',
}
const EMPTY_STRING = ''
const isEmptyOrNil = (value) => isNil(value) || value === ''

const useKpiAdjustmentTableConfig = ({
  kpi,
  allAdjustments,
  today,
  getCurrentValue,
  updateEditRow,
}) => {
  const { t } = useTranslation('translation')
  const { format: formatDate, localePattern, parse } = useShortDateFormatter()
  const {
    isDateInPast,
    isRowValid,
    isRetrospectiveAdjustment,
    isRetrospectiveAdjustmentOverlapping,
    validateDate,
    getValidFromDateLimits,
    getValidToDateLimits,
  } = useValidateKpiAdjustmentTable({ kpi, allAdjustments, today, getCurrentValue })

  const renderValueInput = (rowKey, value, unit, readonly = false) => {
    const currentVal = getCurrentValue(rowKey, 'value')
    const error = isEmptyOrNil(currentVal)
    const EMPTY_VALUE = '-'
    return (
      <>
        <FlexBox direction={FlexBoxDirection.Row} alignItems={FlexBoxAlignItems.Center}>
          <FormattedNumberInput
            valueState={error ? ValueState.Error : ValueState.None}
            value={value ?? EMPTY_STRING}
            readonly={readonly}
            onChange={(parsedValue) => updateEditRow(rowKey, 'value', parsedValue)}
          />
          <Text>{(unit?.type === 'PERCENT' ? t('unit.percent') : unit?.code) ?? EMPTY_VALUE}</Text>
        </FlexBox>
      </>
    )
  }

  const renderDatePicker = (rowKey, fieldName, disabled = false) => {
    const currentVal = getCurrentValue(rowKey, fieldName)
    const { isValid, message } = validateDate(rowKey, fieldName)

    let minDate, maxDate

    if (fieldName === 'validFrom') {
      minDate = getValidFromDateLimits(rowKey)?.minDate
    } else {
      minDate = getValidToDateLimits(rowKey)?.minDate
      maxDate = getValidToDateLimits(rowKey)?.maxDate
    }

    const renderValueState = () => {
      if (!isValid) return ValueState.Error
      if (isRetrospectiveAdjustment(rowKey)) return ValueState.Warning

      return ValueState.None
    }

    const renderValueStateMessage = () => {
      let valueStateMessage

      if (!isValid) valueStateMessage = message
      else if (isRetrospectiveAdjustment(rowKey))
        valueStateMessage = t(
          'components.kpis.details.adjustments.warning.retrospective-adjustment',
        )

      return <Text>{valueStateMessage ?? EMPTY_STRING}</Text>
    }

    return (
      <DatePickerWithoutMinWidth
        formatPattern={localePattern}
        placeholder={localePattern}
        valueState={renderValueState()}
        valueStateMessage={renderValueStateMessage()}
        value={currentVal ?? EMPTY_STRING}
        minDate={minDate}
        maxDate={maxDate}
        onChange={(event) =>
          updateEditRow(rowKey, fieldName, parse(event.detail.value, localePattern))
        }
        disabled={disabled}
      />
    )
  }

  const renderCommentArea = (rowKey, comment) => (
    <TextArea
      rows={2}
      value={comment ?? EMPTY_STRING}
      maxLength={200}
      onChange={(event) => {
        updateEditRow(rowKey, 'comment', event.target._state.value)
      }}
    />
  )

  const renderAdjustmentType = (type) => {
    switch (type) {
      case AdjustmentType.PROSPECTIVE: {
        return t('components.kpis.details.adjustments.label.prospective')
      }
      case AdjustmentType.RETROSPECTIVE: {
        return t('components.kpis.details.adjustments.label.retrospective')
      }
      default: {
        return EMPTY_STRING
      }
    }
  }

  const columnDefinition = [
    {
      columnKey: 'adjustedValue',
      title: t('components.kpis.chart.tooltip.adjusted-value'),
    },
    {
      columnKey: 'validFrom',
      title: t('components.kpis.details.adjustments.valid-from'),
    },
    {
      columnKey: 'validTo',
      title: t('components.kpis.details.adjustments.valid-to'),
    },
    {
      columnKey: 'type',
      title: t('components.kpis.details.adjustments.type'),
    },
    {
      columnKey: 'updatedBy',
      title: t('components.kpis.chart.tooltip.updated-by'),
    },
    {
      columnKey: 'updatedAt',
      title: t('components.kpis.details.adjustments.updated-at'),
    },
    {
      columnKey: 'comment',
      title: t('components.kpis.chart.tooltip.comment'),
    },
  ]

  const tableData = kpi.adjustments
    .sort((a, b) => new Date(b.validFrom) - new Date(a.validFrom))
    .map((adjustment) => {
      const rowKey = adjustment.id
      const isValidFromInThePast = isDateInPast(adjustment.validFrom)
      const isValidToInThePast = isDateInPast(adjustment.validTo)
      const isOnGoingAdjustment = isValidFromInThePast && !isValidToInThePast
      return {
        rowKey: rowKey,
        isValid: isRowValid(rowKey, isOnGoingAdjustment),
        type: {
          cellContentReadMode: <Text>{renderAdjustmentType(adjustment.type)}</Text>,
          cellContentEditMode: <Input value={renderAdjustmentType(adjustment.type)} readonly />,
        },
        isRetrospective: adjustment.type === AdjustmentType.RETROSPECTIVE,
        isOverlapping: false,
        isEditable: !isValidToInThePast,
        isDeletable: !isValidFromInThePast,
        adjustedValue: {
          cellContentReadMode: <KpiUnit unit={kpi.unit} value={adjustment.value} />,
          cellContentEditMode: renderValueInput(
            rowKey,
            adjustment.value,
            kpi.unit,
            isValidFromInThePast,
          ),
        },
        validFrom: {
          cellContentReadMode: <Text>{formatDate(adjustment.validFrom)}</Text>,
          cellContentEditMode: renderDatePicker(rowKey, 'validFrom', isValidFromInThePast),
        },
        validTo: {
          cellContentReadMode: <Text>{formatDate(adjustment.validTo)}</Text>,
          cellContentEditMode: renderDatePicker(rowKey, 'validTo', isValidToInThePast),
        },
        updatedBy: {
          cellContentReadMode: (
            <Text>
              <StaffMemberByObjectIdOrEmail objectIdOrEmail={adjustment.updatedBy} />
            </Text>
          ),
          cellContentEditMode: <Input value={EMPTY_STRING} readonly />,
        },
        updatedAt: {
          cellContentReadMode: <Text>{formatDate(adjustment.updatedAt)}</Text>,
          cellContentEditMode: <Input value={formatDate(adjustment.updatedAt)} readonly />,
        },
        comment: {
          cellContentReadMode: <Text>{adjustment.comment}</Text>,
          cellContentEditMode: renderCommentArea(rowKey, adjustment.comment),
        },
      }
    })

  const newRow = {
    rowKey: rowKeyNewRow,
    isValid: isRowValid(rowKeyNewRow),
    type: {
      cellContentEditMode: (
        <Input
          value={
            isRetrospectiveAdjustment(rowKeyNewRow)
              ? t('components.kpis.details.adjustments.label.retrospective')
              : t('components.kpis.details.adjustments.label.prospective')
          }
          readonly
        />
      ),
    },
    isRetrospective: isRetrospectiveAdjustment(rowKeyNewRow),
    isOverlapping: isRetrospectiveAdjustmentOverlapping(rowKeyNewRow),
    adjustedValue: { cellContentEditMode: renderValueInput(rowKeyNewRow, EMPTY_STRING, kpi.unit) },
    validFrom: { cellContentEditMode: renderDatePicker(rowKeyNewRow, 'validFrom') },
    validTo: { cellContentEditMode: renderDatePicker(rowKeyNewRow, 'validTo') },
    updatedBy: { cellContentEditMode: <Input value={EMPTY_STRING} readonly /> },
    updatedAt: { cellContentEditMode: <Input value={formatDate(today.toISOString())} readonly /> },
    comment: { cellContentEditMode: renderCommentArea(rowKeyNewRow, EMPTY_STRING) },
  }

  return {
    columnDefinition,
    tableData,
    newRow,
  }
}

export default useKpiAdjustmentTableConfig
