import { Select, Option } from '@fioneer/ui5-webcomponents-react'
import isNil from 'lodash.isnil'
import PropTypes from 'prop-types'
import { useEffect } from 'react'
import { useBreachTranslation } from 'components/domains/deals/covenants/shared/useBreachTranslations'

const OPTION_NO = 'NO'

const BreachSelect = ({ value, onChange, isFinancial = false, ...props }) => {
  const { getTranslationForBreach } = useBreachTranslation()

  const financialBreaches = [OPTION_NO, 'SOFT', 'HARD']
  const nonFinancialBreaches = ['YES', OPTION_NO]

  // Select NO if breach is currently not set
  useEffect(() => {
    isNil(value) && onChange(OPTION_NO)
    // Only trigger the useEffect when the passed value changes
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [value])

  return (
    <Select onChange={(event) => onChange(event.detail.selectedOption.value)} {...props}>
      {(isFinancial ? financialBreaches : nonFinancialBreaches).map((breach) => (
        <Option key={breach} value={breach} selected={value === breach}>
          {getTranslationForBreach(breach)}
        </Option>
      ))}
    </Select>
  )
}

BreachSelect.propTypes = {
  value: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  isFinancial: PropTypes.bool,
}

export default BreachSelect
