import { ObjectPageSection } from '@fioneer/ui5-webcomponents-react'
import PropType from 'prop-types'
import { useContext } from 'react'
import { useTranslation } from 'react-i18next'
import { businessEventsAllowedOperations } from 'api/events/eventAllowedOperations'
import { entityTypes } from 'api/events/status'
import { marketAllowedOperations } from 'api/markets/marketAllowedOperations'
import EventsSummaryCardWrapper from 'components/domains/business-events-and-tasks/events/events-summary-card/EventsSummaryCardWrapper'
import PropertyUnderReviewMessageStrip from 'components/domains/properties/common/PropertyUnderReviewMessageStrip'
import PropertyInsurancesCard from 'components/domains/properties/overview/PropertyInsurancesCard'
import PropertyMarketInfoCardWrapper from 'components/domains/properties/overview/PropertyMarketInfoCardWrapper'
import PropertyMonitoringSummaryCard from 'components/domains/properties/overview/PropertyMonitoringSummaryCard'
import styles from 'components/domains/properties/overview/PropertyOverview.module.css'
import PropertyRentRollInfoCard from 'components/domains/properties/overview/PropertyRentRollInfoCard'
import PropertyValuationSummaryCard from 'components/domains/properties/overview/PropertyValuationSummaryCard'
import AssociatedDealCard from 'components/domains/properties/overview/associated-deals/AssociatedDealCard'
import PropertyGeneralInformationCard from 'components/domains/properties/overview/general-information-card/PropertyGeneralInformationCard'
import PropertyKeyInternalContactsCard from 'components/domains/properties/overview/keyInternalContacts/PropertyKeyInternalContactsCard'
import CWPLayout from 'components/ui/layout/CWPLayout'
import TabPage from 'components/ui/page/TabPage'
import LoadingStateWrapper from 'components/ui/screens/LoadingStateWrapper'
import useEventsAllowedOperations from 'hooks/services/business-events-and-tasks/events/useEventsAllowedOperations'
import useMarketPropertyAllowedOperations from 'hooks/services/markets/useMarketPropertyAllowedOperations'
import { PropertyContext } from 'routes/properties/PropertyContext'

const PropertyOverview = ({ header }) => {
  const {
    allowedOperations,
    property: { uuid: propertyUuid, type_name: propertyName, id: propertyDisplayId },
  } = useContext(PropertyContext)
  const displayValuationSummaryCard = allowedOperations?.includes('PropertyValuation_Read')

  const {
    data: allowedEventsOperationsData,
    isLoading: allowedEventsOperationsIsLoading,
    isError: allowedEventsOperationsIsError,
  } = useEventsAllowedOperations()

  const eventReadAllowed = !!allowedEventsOperationsData?.allowed_operations?.includes(
    businessEventsAllowedOperations.readEvent,
  )

  const displayCreateEventButton = !!allowedEventsOperationsData?.allowed_operations.includes(
    businessEventsAllowedOperations.createEvent,
  )

  const {
    isLoading: allowedMarketPropertyOperationsIsLoading,
    isError: allowedMarketPropertyOperationsIsError,
    data: allowedMarketPropertyOperations,
  } = useMarketPropertyAllowedOperations({
    propertyUuid,
  })

  const displayMarket = !!allowedMarketPropertyOperations?.allowedOperations.includes(
    marketAllowedOperations.viewMarket,
  )

  const displayAssignButton = !!allowedMarketPropertyOperations?.allowedOperations.includes(
    marketAllowedOperations.assignPropertyToMarket,
  )

  const { t } = useTranslation('translation', {
    keyPrefix: 'pages.property-overview',
  })

  const renderSummaryTabContent = () => (
    <CWPLayout overview>
      <PropertyGeneralInformationCard />
      <PropertyRentRollInfoCard propertyUuid={propertyUuid} />
      <AssociatedDealCard propertyUuid={propertyUuid} />
      {!allowedMarketPropertyOperationsIsError && displayMarket && (
        <PropertyMarketInfoCardWrapper
          propertyId={propertyUuid}
          displayAssignButton={displayAssignButton}
        />
      )}
      {displayValuationSummaryCard && <PropertyValuationSummaryCard />}
      <PropertyInsurancesCard propertyUuid={propertyUuid} />
      <PropertyMonitoringSummaryCard />
    </CWPLayout>
  )

  const renderSummaryTab = () => (
    <ObjectPageSection id={'summary-tab'} key={'summary-tab'} titleText={t('summary-tab')}>
      <PropertyUnderReviewMessageStrip className={styles.messageStrip} />
      <LoadingStateWrapper
        isError={false}
        isLoading={allowedMarketPropertyOperationsIsLoading}
        renderContent={renderSummaryTabContent}
      />
    </ObjectPageSection>
  )

  const renderManagerTabContent = () => (
    <CWPLayout>
      {!allowedEventsOperationsIsError && eventReadAllowed && (
        <EventsSummaryCardWrapper
          entity={{
            entityDisplayId: propertyDisplayId,
            entityType: entityTypes.property,
            entityName: propertyName,
            entityId: propertyUuid,
          }}
          entityType={entityTypes.property}
          displayCreateEventButton={displayCreateEventButton}
        />
      )}
      <PropertyKeyInternalContactsCard />
    </CWPLayout>
  )

  const renderManagerTab = () => (
    <ObjectPageSection id={'manager-tab'} key={'manager-tab'} titleText={t('manager-tab')}>
      <PropertyUnderReviewMessageStrip className={styles.messageStrip} />
      <LoadingStateWrapper
        isError={false}
        isLoading={allowedEventsOperationsIsLoading}
        renderContent={renderManagerTabContent}
      />
    </ObjectPageSection>
  )

  return (
    <TabPage headerTitle={header} defaultSectionId="summary-tab" className="property-overview">
      {renderSummaryTab()}
      {renderManagerTab()}
    </TabPage>
  )
}
PropertyOverview.propTypes = {
  header: PropType.any.isRequired,
}
export default PropertyOverview
