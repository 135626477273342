import {
  FilterGroupItem,
  Input,
  MultiComboBox,
  MultiComboBoxItem,
} from '@fioneer/ui5-webcomponents-react'
import _ from 'lodash'
import isEmpty from 'lodash.isempty'
import PropTypes from 'prop-types'
import { useCallback, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import PropertiesFilterCountryCodeComboBox from 'components/domains/properties/properties-search/filterbar/PropertiesFilterCountryCodeComboBox'
import PropertiesFilterStatusComboBox from 'components/domains/properties/properties-search/filterbar/PropertiesFilterStatusComboBox'
import DealSearchMultiComboBox from 'components/domains/properties/properties-search/filterbar/properties-search-multi-combo-box/DealSearchMultiComboBox'
import MarketSearchMultiComboBox from 'components/domains/properties/properties-search/filterbar/properties-search-multi-combo-box/MarketSearchMultiComboBox'
import DashboardFilterBar from 'components/ui/page/DashboardFilterBar'
import { useTypecodes } from 'hooks/services/properties/useTypecodes'
import { usePropertyStatus } from 'routes/properties/usePropertyStatus'

export const HIDDEN_FILTER_KEYWORDS = {
  deal: 'DEAL',
}

const filterOptions = {
  property_id: { visibleOnBar: true },
  property_name: { visibleOnBar: true },
  city: { visibleOnBar: true },
  country: { visibleOnBar: true },
  zip_code: { visibleOnBar: true },
  property_types: { visibleOnBar: true },
  deal_id: { visibleOnBar: true },
  market_id: { visibleOnBar: true },
  financing_status_code: { visibleOnBar: true },
  cag_status_code: { visibleOnBar: true },
}

const isEnterButtonEvent = (event) => event.code === 'Enter' || event.code === 'NumpadEnter'

const PropertiesSearchDialogFilterBar = ({
  filterCriteria,
  setFilterCriteria,
  onFilter,
  fixedDropdownValues,
  hiddenFilters = [],
}) => {
  const {
    isLoading: isLoadingTypecodes,
    isError: isErrorTypecodes,
    data: typecodesData,
  } = useTypecodes()

  const typecodes = !isLoadingTypecodes && !isErrorTypecodes ? typecodesData.typecodes : []

  const { t: tNoPrefix } = useTranslation('translation')

  const onKeyDown = useCallback(
    (event) => {
      if (isEnterButtonEvent(event)) {
        event.stopPropagation()
        onFilter()
      }
    },
    [onFilter],
  )

  const filterChange = useCallback(
    (fieldName, value) => setFilterCriteria({ ...filterCriteria, [fieldName]: value }),
    [filterCriteria, setFilterCriteria],
  )

  const handleCountryComboboxChange = useCallback(
    (event) => {
      const items = event.detail.items
      const values = items.map((item) => item.dataset.value).filter(_.negate(_.isNil))
      setFilterCriteria({ ...filterCriteria, country: values.join(',') })
    },
    [filterCriteria, setFilterCriteria],
  )

  const handlePropertyTypeComboboxChange = useCallback(
    (event) => {
      const items = event.detail.items
      const keys = items.map((item) => item.dataset.key)
      setFilterCriteria({ ...filterCriteria, propertyTypes: keys.join(',') })
    },
    [filterCriteria, setFilterCriteria],
  )

  const handleDealIdComboboxChange = useCallback(
    (values) => {
      setFilterCriteria({
        ...filterCriteria,
        dealId: values.trim(),
      })
    },
    [filterCriteria, setFilterCriteria],
  )

  const handleMarketIdComboboxChange = useCallback(
    (values) => {
      setFilterCriteria({
        ...filterCriteria,
        marketId: values.trim(),
      })
    },
    [filterCriteria, setFilterCriteria],
  )

  const handleStatusCodeComboboxChange = useCallback(
    ({ detail: { items } }, filterFieldName) => {
      const filteredItems = items.map((element) => element.dataset.value).filter(_.negate(_.isNil))
      const filterFieldValue = !isEmpty(filteredItems) ? filteredItems : ''

      setFilterCriteria({
        ...filterCriteria,
        [filterFieldName]: filterFieldValue,
      })
    },
    [filterCriteria, setFilterCriteria],
  )
  const selectedCountries = useMemo(
    () => fixedDropdownValues?.countries ?? [],
    [fixedDropdownValues],
  )

  const { objectStatusForFinancingStatus, objectStatusForCAGStatus } = usePropertyStatus()

  const filters = [
    <FilterGroupItem
      label={`${tNoPrefix('pages.properties.table.property-name.label')}`}
      key="property_name"
      active={true}
    >
      <Input
        id="properties-table-property-name-filter"
        value={''}
        onInput={(event) => filterChange('propertyName', event.target.value.trim())}
        onFocus={(event) => event.stopPropagation()}
        onKeyDown={onKeyDown}
        showClearIcon
      />
    </FilterGroupItem>,
    <FilterGroupItem
      label={`${tNoPrefix('pages.properties.table.property-id.label')}`}
      key="property_id"
      active={true}
    >
      <Input
        id="properties-table-property-id-filter"
        value={''}
        onInput={(event) => filterChange('propertyId', event.target.value.trim())}
        onFocus={(event) => event.stopPropagation()}
        onKeyDown={onKeyDown}
        showClearIcon
      />
    </FilterGroupItem>,
    <FilterGroupItem
      label={`${tNoPrefix('pages.properties.table.city.label')}`}
      key="city"
      active={true}
    >
      <Input
        id="properties-table-city-filter"
        value={''}
        onInput={(event) => filterChange('city', event.target.value.trim())}
        onFocus={(event) => event.stopPropagation()}
        onKeyDown={onKeyDown}
        showClearIcon
      />
    </FilterGroupItem>,
    <FilterGroupItem
      label={`${tNoPrefix('pages.properties.table.country.label')}`}
      key="country"
      active={true}
    >
      <PropertiesFilterCountryCodeComboBox
        selectedCountries={selectedCountries}
        onKeyDown={onKeyDown}
        onFocus={(event) => event.stopPropagation()}
        handleMultiComboboxChange={handleCountryComboboxChange}
        disabled={fixedDropdownValues?.countries !== undefined}
      />
    </FilterGroupItem>,
    <FilterGroupItem
      label={`${tNoPrefix('pages.properties.table.zip-code.label')}`}
      key="zip_code"
      active={true}
    >
      <Input
        id="properties-table-zip-code-filter"
        value={''}
        onInput={(event) => filterChange('zipCode', event.target.value.trim())}
        onFocus={(event) => event.stopPropagation()}
        onKeyDown={onKeyDown}
        showClearIcon
      />
    </FilterGroupItem>,
    <FilterGroupItem
      label={`${tNoPrefix('pages.properties.table.property-type.label')}`}
      key="property_types"
      active
    >
      <MultiComboBox
        id="properties-table-property-type-filter"
        onSelectionChange={(event) => handlePropertyTypeComboboxChange(event)}
        onFocus={(event) => event.stopPropagation()}
        onKeyDown={onKeyDown}
        disabled={fixedDropdownValues?.propertyTypes !== undefined}
      >
        {typecodes.map(({ key, display_name }) => (
          <MultiComboBoxItem
            key={key}
            text={display_name}
            data-key={key}
            selected={fixedDropdownValues?.propertyTypes?.includes(key)}
          />
        ))}
      </MultiComboBox>
    </FilterGroupItem>,
    <FilterGroupItem
      label={`${tNoPrefix('pages.properties.table.financing-status.label')}`}
      key="financing_status_code"
      active={true}
    >
      <PropertiesFilterStatusComboBox
        id="properties-table-financing-status-filter"
        selectionName={'financingStatusCode'}
        selectedValues={filterCriteria.financingStatusCode}
        values={objectStatusForFinancingStatus}
        onKeyDown={onKeyDown}
        handleMultiComboboxChange={handleStatusCodeComboboxChange}
      />
    </FilterGroupItem>,
    <FilterGroupItem
      label={`${tNoPrefix('pages.properties.table.cag-status.label')}`}
      key="cag_status_code"
      active={true}
    >
      <PropertiesFilterStatusComboBox
        id="properties-table-cag-status-filter"
        selectionName={'cagStatusCode'}
        selectedValues={filterCriteria.cagStatusCode}
        values={objectStatusForCAGStatus}
        onKeyDown={onKeyDown}
        handleMultiComboboxChange={handleStatusCodeComboboxChange}
      />
    </FilterGroupItem>,
    ...(!hiddenFilters || !hiddenFilters.includes(HIDDEN_FILTER_KEYWORDS.deal)
      ? [
          <FilterGroupItem
            label={`${tNoPrefix('pages.properties.table.deal-id.label')}`}
            key="deal_id"
            active={true}
          >
            <DealSearchMultiComboBox
              id="properties-table-deal-id-filter"
              value={filterCriteria.dealId}
              onChange={(newValues) => handleDealIdComboboxChange(newValues)}
              onFocus={(event) => event.stopPropagation()}
              onKeyDown={onKeyDown}
              hideResultsGroupItem={true}
            />
          </FilterGroupItem>,
        ]
      : []),
    <FilterGroupItem
      label={`${tNoPrefix('pages.properties.table.market-id.label')}`}
      key="market_id"
      active={true}
    >
      <MarketSearchMultiComboBox
        id="properties-table-market-id-filter"
        value={''}
        onChange={(newValues) => handleMarketIdComboboxChange(newValues)}
        onFocus={(event) => event.stopPropagation()}
        onKeyDown={onKeyDown}
      />
    </FilterGroupItem>,
  ]

  return (
    <DashboardFilterBar
      filterOptions={filterOptions}
      activeFiltersCount={0}
      onGo={onFilter}
      setDialogOpen={() => {}}
    >
      {filters}
    </DashboardFilterBar>
  )
}

PropertiesSearchDialogFilterBar.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  filterCriteria: PropTypes.object.isRequired,
  setFilterCriteria: PropTypes.func.isRequired,
  onFilter: PropTypes.func.isRequired,
  fixedSelectedCountryDisplayNames: PropTypes.arrayOf(PropTypes.string),
  fixedDropdownValues: PropTypes.shape({
    countries: PropTypes.arrayOf(PropTypes.string),
    propertyTypes: PropTypes.arrayOf(PropTypes.string),
  }),

  hiddenFilters: PropTypes.arrayOf(PropTypes.string),
}

export default PropertiesSearchDialogFilterBar
