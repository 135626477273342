import {
  Button,
  ButtonDesign,
  FlexBox,
  FlexBoxAlignItems,
  FlexBoxJustifyContent,
  Icon,
  IconDesign,
  Menu,
  MenuItem,
  MessageBoxActions,
  MessageBoxTypes,
  Modals,
  PopoverPlacementType,
} from '@fioneer/ui5-webcomponents-react'
import { useQueryClient } from '@tanstack/react-query'
import { isNil } from 'lodash'
import isEmpty from 'lodash.isempty'
import PropTypes from 'prop-types'
import { useCallback, useContext, useMemo, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector, useStore } from 'react-redux'
import { useNavigate, useSearchParams } from 'react-router-dom'
import { conditionTypes, conditionsStatusTypes } from 'api/conditions/conditions'
import {
  neededOperationsForConditionDelete,
  neededOperationsForConditionEdit,
} from 'api/conditions/conditionsAllowedOperations'
import { hasUserRequiredOperations } from 'api/helper'
import ConditionIndividualChangelogDialog from 'components/domains/conditions/dialogs/changelog/ConditionIndividualChangelogDialog'
import styles from 'components/domains/conditions/overview/table-cells/conditions/ConditionsTableActionsCell.module.css'
import { mapChangedConditionsToBECall } from 'components/domains/conditions/overview/table-cells/conditions/ConditionsTableActionsCellHelper'
import DocumentTypes from 'components/domains/documents/DocumentTypes'
import { CancelPopoverContent } from 'components/ui/button/CancelButtonWithPopover'
import LoadingButton from 'components/ui/button/LoadingButton'
import { useShowMessageBox } from 'components/ui/message-box/MessageBox'
import useConditionsCellEditMode from 'hooks/services/conditions/edit-mode/useConditionsCellEditMode'
import useShowRequirementRefNrUpdateConfirmation from 'hooks/services/conditions/requirements/helper/useShowRequirementRefNrUpdateConfirmation'
import useGetRequirements from 'hooks/services/conditions/requirements/useGetRequirements'
import useDeleteCondition from 'hooks/services/conditions/useDeleteCondition'
import useEditCondition from 'hooks/services/conditions/useEditCondition'
import useGetDocumentTypes from 'hooks/services/documents/useGetDocumentTypes'
import {
  startConditionEditMode,
  stopConditionEditMode,
} from 'redux/slices/conditions/conditionsTableSlice'
import { ConditionsContext } from 'routes/conditions/ConditionsContext'

const conditionCancelId = 'condition-cancel-button'

const ConditionsTableActionsCell = ({
  row: {
    original: { id: conditionId, ...condition },
  },
  conditionType,
}) => {
  const {
    entityRef: { entityId, entityDisplayId, entityType },
    allowedOperations,
  } = useContext(ConditionsContext)
  const dispatch = useDispatch()
  const store = useStore()
  const { t: tNoPrefix } = useTranslation()
  const { t } = useTranslation('translation', {
    keyPrefix: 'components.conditions.table.columns.action-cell',
  })
  const { t: tInvalidDocumentTypes } = useTranslation('translation', {
    keyPrefix: 'components.conditions.table.update.invalid-document-type-dialog',
  })
  const queryClient = useQueryClient()
  const navigate = useNavigate()
  const [queryParams] = useSearchParams()
  const showMessageBox = useShowMessageBox()
  const showMessageBoxVisibility = useShowMessageBox()
  const { openConfirmationDialog } = useShowRequirementRefNrUpdateConfirmation()
  const showMessageBoxStatus = useShowMessageBox()
  const showToast = Modals.useShowToast()
  const showPopover = Modals.useShowPopover()
  const popoverRef = useRef()
  const { mutate: deleteCondition, isLoading: isDeleteLoading } = useDeleteCondition()
  const { mutate: editCondition, isLoading: isEditLoading } = useEditCondition()

  const [isMenuOpen, setIsMenuOpen] = useState(false)
  const [isChangelogDialogOpen, setIsChangelogDialogOpen] = useState(false)

  const onActionsButtonClick = useCallback(() => {
    setIsMenuOpen(true)
  }, [])

  const closeActionsButtonMenu = useCallback(() => {
    setIsMenuOpen(false)
  }, [])

  // When calling the same showMessageBox function directly after confirming on another messagebox
  // the new messagebox does not open since the "old" one does not close fast enough. Therefore we
  // create 2 showMessageBox functions here depending on the type.
  const getMessageBoxFunction = useCallback(
    (type) => {
      if (type === 'visibilities') {
        return showMessageBoxVisibility
      }
      return showMessageBoxStatus
    },
    [showMessageBoxStatus, showMessageBoxVisibility],
  )

  const { isEditModeForCurrentRow, isEditModeForAnyRow } = useConditionsCellEditMode({
    conditionId,
  })

  const hasEditRowChanges = useSelector(
    (state) => !isEmpty(state.conditions.conditionsTable.editedRow.changedFields),
  )

  const hasEditRowErrors = useSelector(
    (state) => !isEmpty(state.conditions.conditionsTable.editedRow.errorFields),
  )

  const hasStatusTypeDone = condition.status.type === conditionsStatusTypes.done

  const isSaveButtonDisabled = useMemo(
    () => !hasEditRowChanges || hasEditRowErrors,
    [hasEditRowChanges, hasEditRowErrors],
  )

  const onEditButtonClicked = useCallback(() => {
    dispatch(startConditionEditMode({ conditionId, condition }))
  }, [dispatch, conditionId, condition])

  const onEditSuccess = useCallback(
    (shouldThrowDocumentTypeInvalidError) => () => {
      if (shouldThrowDocumentTypeInvalidError) {
        showMessageBox(
          {
            type: MessageBoxTypes.Error,
            children: tInvalidDocumentTypes('message'),
          },
          document.body,
        )
      }
      dispatch(stopConditionEditMode())
      queryClient.invalidateQueries(['conditions', conditionType, entityType, entityId])
      queryClient.invalidateQueries(['conditions', 'external', conditionId, 'requirements2'])
      showToast({ children: t('edit.success') })
    },
    [
      dispatch,
      queryClient,
      conditionType,
      entityType,
      entityId,
      conditionId,
      showToast,
      t,
      showMessageBox,
      tInvalidDocumentTypes,
    ],
  )

  const { data: { requirements } = {} } = useGetRequirements({
    conditionId: conditionId,
  })

  const currentConditionReferences = useSelector(
    (state) => state.conditions.conditionsTable.editedRow.currentValues?.references,
  )

  const documentTypesEntityRefsForAfterEditMode = useMemo(() => {
    switch (entityType) {
      case DocumentTypes.BusinessPartner:
        return [
          { type: DocumentTypes.Requirement, id: 'no-id' },
          { type: entityType, id: entityId },
        ]

      case DocumentTypes.Deal:
        if (
          !isNil(currentConditionReferences?.entityIds) &&
          currentConditionReferences?.entityIds.length > 0
        ) {
          return [
            { type: DocumentTypes.Requirement, id: 'no-id' },
            ...currentConditionReferences.entityIds.map((id) => ({
              type: currentConditionReferences.entityType,
              id,
            })),
          ]
        }

        return [
          { type: DocumentTypes.Requirement, id: 'no-id' },
          { type: entityType, id: entityId },
        ]
      default:
        break
    }
  }, [
    entityType,
    entityId,
    currentConditionReferences?.entityIds,
    currentConditionReferences?.entityType,
  ])

  const { data: { documentTypes } = {} } = useGetDocumentTypes({
    entityRefs: documentTypesEntityRefsForAfterEditMode,
  })

  const checkIfDocumentTypeExistsInAllValidTypes = useCallback(
    (selectedDocumentType) => {
      if (selectedDocumentType === undefined) {
        return true
      }
      return documentTypes?.includes(selectedDocumentType)
    },
    [documentTypes],
  )

  const onEditError = useCallback(() => {
    showMessageBox({
      type: MessageBoxTypes.Error,
      children: t('edit.error'),
    })
  }, [showMessageBox, t])

  const callEditCondition = useCallback(
    ({ updateRefNumberForRequirements = false } = {}) => {
      const currentConditionValues =
        store.getState().conditions.conditionsTable.editedRow.currentValues
      const changedFields = store.getState().conditions.conditionsTable.editedRow.changedFields

      const selectedDocumentType = requirements?.[0]?.info?.documentType

      const shouldThrowDocumentTypeInvalidError =
        !checkIfDocumentTypeExistsInAllValidTypes(selectedDocumentType)

      const mappedConditionForMutationCall = mapChangedConditionsToBECall({
        changedFields,
        currentValues: currentConditionValues,
        conditionType,
        updateRefNumberForRequirements,
      })

      editCondition(
        { conditionId, conditionType, condition: mappedConditionForMutationCall },
        { onSuccess: onEditSuccess(shouldThrowDocumentTypeInvalidError), onError: onEditError },
      )
    },
    [
      editCondition,
      conditionId,
      conditionType,
      store,
      onEditSuccess,
      onEditError,
      checkIfDocumentTypeExistsInAllValidTypes,
      requirements,
    ],
  )

  const onConfirmRefNumber = useCallback(() => {
    callEditCondition({ updateRefNumberForRequirements: true })
  }, [callEditCondition])

  const checkRefNumberChangedConfirmationRequired = useCallback(
    ({ editedRow }) => {
      const refNumberChanged = editedRow.changedFields.refNumber === true
      if (refNumberChanged && condition.requirements?.total > 0) {
        openConfirmationDialog({
          onConfirm: onConfirmRefNumber,
          onReject: callEditCondition,
          body: t('ref-number.confirmation', { refNumber: editedRow.currentValues.refNumber }),
        })
        return
      }
      callEditCondition()
      return
    },
    [
      callEditCondition,
      condition.requirements?.total,
      onConfirmRefNumber,
      openConfirmationDialog,
      t,
    ],
  )

  const onConfirmationRequired = useCallback(
    ({ requiredConfirmations, editedRow }) =>
      () => {
        if (requiredConfirmations.length === 0) {
          checkRefNumberChangedConfirmationRequired({ editedRow })
          return
        }
        const newRequiredConfirmations = [...requiredConfirmations]

        const { bodyText, type } = newRequiredConfirmations.pop()

        const internalShowMessageBox = getMessageBoxFunction(type)

        internalShowMessageBox({
          type: MessageBoxTypes.Confirm,
          titleText: tNoPrefix('buttons.save'),
          children: bodyText,
          actions: [
            <Button
              key="button-publish"
              design={ButtonDesign.Emphasized}
              onClick={onConfirmationRequired({
                requiredConfirmations: newRequiredConfirmations,
                editedRow,
              })}
            >
              {tNoPrefix('buttons.save')}
            </Button>,
            <Button
              key="button-cancel"
              data-action={MessageBoxActions.Cancel}
              design={ButtonDesign.Transparent}
            >
              {tNoPrefix('buttons.cancel')}
            </Button>,
          ],
        })
      },
    [checkRefNumberChangedConfirmationRequired, getMessageBoxFunction, tNoPrefix],
  )

  const onSaveButtonClicked = useCallback(() => {
    const editedRow = store.getState().conditions.conditionsTable.editedRow

    const visibilityChanged = editedRow.changedFields.visibilities === true

    const isStatusChangedToDoneType =
      !!editedRow.changedFields.status &&
      editedRow.currentValues.status.type === conditionsStatusTypes.done

    const requiredConfirmations = []

    if (isStatusChangedToDoneType) {
      requiredConfirmations.push({
        bodyText: t('edit.confirmation-status-change.text'),
        type: 'status',
      })
    }

    if (conditionType === conditionTypes.external && visibilityChanged) {
      requiredConfirmations.push({
        bodyText: t('edit.confirmation-visibility-change.text'),
        type: 'visibilities',
      })
    }

    onConfirmationRequired({ requiredConfirmations, editedRow })()
  }, [store, conditionType, onConfirmationRequired, t])

  const dispatchStopConditionEditMode = useCallback(() => {
    dispatch(stopConditionEditMode(conditionId))
  }, [dispatch, conditionId])

  const onCancelConfirmed = useCallback(() => {
    popoverRef.current.close()
    dispatchStopConditionEditMode()
  }, [dispatchStopConditionEditMode])

  const showArrowButton = useMemo(() => {
    if (conditionType === conditionTypes.external) {
      return condition.requirements.total !== 0
    }
  }, [conditionType, condition?.requirements?.total])

  const onSaveCancelButtonClicked = useCallback(() => {
    if (hasEditRowChanges) {
      popoverRef.current = showPopover({
        opener: conditionCancelId,
        placementType: PopoverPlacementType.Top,
        children: <CancelPopoverContent onCancelClicked={onCancelConfirmed} />,
      })
      return
    }
    dispatchStopConditionEditMode()
  }, [hasEditRowChanges, dispatchStopConditionEditMode, showPopover, onCancelConfirmed])

  const onDeleteSuccess = useCallback(() => {
    queryClient.invalidateQueries(['conditions', conditionType, entityType, entityId])
    showToast({ children: t('delete.success') })
  }, [queryClient, conditionType, entityType, entityId, showToast, t])

  const onDeleteError = useCallback(() => {
    showMessageBox({
      type: MessageBoxTypes.Error,
      children: t('delete.error'),
    })
  }, [showMessageBox, t])

  const onDeleteConfirmed = useCallback(() => {
    deleteCondition(
      { conditionId, conditionType },
      { onSuccess: onDeleteSuccess, onError: onDeleteError },
    )
  }, [deleteCondition, conditionId, conditionType, onDeleteSuccess, onDeleteError])

  const isWorkingVersion = useMemo(
    () => queryParams.get('working-version') === 'true',
    [queryParams],
  )

  const onArrowButtonClicked = useCallback(() => {
    !isEditModeForAnyRow &&
      navigate(
        `?external_condition_id=${conditionId}&working-version=${isWorkingVersion}#requirements`,
      )
  }, [isEditModeForAnyRow, navigate, conditionId, isWorkingVersion])

  const onDeleteButtonClicked = useCallback(() => {
    showMessageBox({
      type: MessageBoxTypes.Warning,
      titleText: t('delete.confirmation.title'),
      children: t('delete.confirmation.text'),
      actions: [
        <Button key="button-delete" design={ButtonDesign.Emphasized} onClick={onDeleteConfirmed}>
          {tNoPrefix('buttons.delete')}
        </Button>,
        <Button
          key="button-cancel"
          data-action={MessageBoxActions.Cancel}
          design={ButtonDesign.Transparent}
        >
          {tNoPrefix('buttons.cancel')}
        </Button>,
      ],
    })
  }, [showMessageBox, t, tNoPrefix, onDeleteConfirmed])

  const hasUserDeletePermission = useMemo(
    () => hasUserRequiredOperations(neededOperationsForConditionDelete, allowedOperations),
    [allowedOperations],
  )
  const buttonId = `action-button-${conditionId}`
  const editButtonId = `edit-button-${conditionId}`
  const deleteButtonId = `delete-button-${conditionId}`
  const changelogButtonId = `changelog-button-${conditionId}`

  const onMenuItemClick = useCallback(
    ({ detail: { item: pressedMenuItem } }) => {
      setIsMenuOpen(false)

      const pressedIdentifier = pressedMenuItem.getAttribute('data-button-id')
      switch (pressedIdentifier) {
        case editButtonId:
          onEditButtonClicked()
          break
        case deleteButtonId:
          onDeleteButtonClicked()
          break
        case changelogButtonId:
          setIsChangelogDialogOpen(true)
          break
      }
    },
    [changelogButtonId, deleteButtonId, editButtonId, onDeleteButtonClicked, onEditButtonClicked],
  )

  const hasUserEditPermission = useMemo(
    () => hasUserRequiredOperations(neededOperationsForConditionEdit, allowedOperations),
    [allowedOperations],
  )

  const renderContentLoadingButton = useCallback(() => tNoPrefix('buttons.save'), [tNoPrefix])

  if (isEditModeForCurrentRow) {
    return (
      <FlexBox
        fitContainer
        justifyContent={FlexBoxJustifyContent.End}
        alignItems={FlexBoxAlignItems.Center}
        className={styles.buttonContainer}
      >
        <LoadingButton
          design={ButtonDesign.Emphasized}
          onClick={onSaveButtonClicked}
          disabled={isSaveButtonDisabled}
          name="save-button"
          isLoading={isEditLoading || isDeleteLoading}
          renderContent={renderContentLoadingButton}
        />
        <Button
          id={conditionCancelId}
          design={ButtonDesign.Transparent}
          onClick={onSaveCancelButtonClicked}
          name="cancel-button"
        >
          {tNoPrefix('buttons.cancel')}
        </Button>
        {showArrowButton && (
          <Icon name="navigation-right-arrow" design={IconDesign} role="button" />
        )}
      </FlexBox>
    )
  }

  return (
    <FlexBox
      fitContainer
      justifyContent={FlexBoxJustifyContent.End}
      alignItems={FlexBoxAlignItems.Center}
    >
      <Button
        id={buttonId}
        role="button"
        name="more-actions-button"
        onClick={onActionsButtonClick}
        icon="overflow"
        design={ButtonDesign.Transparent}
      />
      {isMenuOpen && (
        <Menu
          opener={buttonId}
          open={isMenuOpen}
          onAfterClose={closeActionsButtonMenu}
          onItemClick={onMenuItemClick}
        >
          {hasUserEditPermission && !hasStatusTypeDone && (
            <MenuItem
              text={tNoPrefix('buttons.edit')}
              name="edit-button"
              data-button-id={editButtonId}
              disabled={isEditModeForAnyRow}
            />
          )}
          <MenuItem
            text={t('menu.changelog')}
            name="changelog-button"
            data-button-id={changelogButtonId}
          />
          {hasUserDeletePermission && !hasStatusTypeDone && (
            <MenuItem
              text={tNoPrefix('buttons.delete')}
              name="delete-button"
              data-button-id={deleteButtonId}
              disabled={isEditModeForAnyRow}
            />
          )}
        </Menu>
      )}
      {showArrowButton && (
        <Icon name="navigation-right-arrow" onClick={onArrowButtonClicked} design={IconDesign} />
      )}
      {isChangelogDialogOpen && (
        <ConditionIndividualChangelogDialog
          isOpen={isChangelogDialogOpen}
          setIsOpen={setIsChangelogDialogOpen}
          entityType={conditionType}
          entityId={conditionId}
          relatedEntityType={entityType}
          relatedEntityId={entityId}
          relatedEntityDisplayId={entityDisplayId}
        />
      )}
    </FlexBox>
  )
}

ConditionsTableActionsCell.propTypes = {
  row: PropTypes.shape({
    original: PropTypes.shape({
      id: PropTypes.string.isRequired,
      info: PropTypes.shape({
        name: PropTypes.string.isRequired,
        description: PropTypes.string,
        refNumber: PropTypes.string,
      }).isRequired,
      status: PropTypes.shape({
        type: PropTypes.string.isRequired,
      }).isRequired,
    }).isRequired,
  }).isRequired,
  conditionType: PropTypes.string.isRequired,
}

export default ConditionsTableActionsCell
