import {
  CardHeader,
  IllustrationMessageSize,
  IllustrationMessageType,
} from '@fioneer/ui5-webcomponents-react'
import camelize from 'camelize'
import PropTypes from 'prop-types'
import { useCallback, useContext, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import PropertyPieChart from 'components/domains/properties/portfolio/overview/pie-charts/PropertyPieChart'
import {
  ContractedRentProvider,
  CurrentRentalIncomeProvider,
  LetAreaProvider,
  LetMarketRentProvider,
  LettableAreaProvider,
  MarketValueProvider,
  MortgageLendingValueProvider,
  PurchasePriceProvider,
  TotalMarketRentProvider,
  VacantAreaProvider,
} from 'components/domains/properties/portfolio/overview/pie-charts/PropertyPortfolioOverviewPieChartDataProviders'
import styles from 'components/domains/properties/portfolio/overview/pie-charts/PropertyPortfolioOverviewPieChartsCard.module.css'
import {
  useUsageTypeColors,
  UsageTypeColorProvider,
} from 'components/domains/properties/rent-roll/overview/chart-colors/UsageTypeColorContext'
import Card from 'components/ui/card/Card'
import EmptyCardContent from 'components/ui/card/EmptyCardContent'
import LoadingStateWrapper from 'components/ui/screens/LoadingStateWrapper'
import usePropertyPortfolioOverviewPieChartCardData from 'hooks/services/properties/kpis/charts/usePropertyPortfolioOverviewPieChartCardData'
import { PropertyPortfolioContext } from 'routes/properties/portfolio/PropertyPortfolioContext'

export const chartsNoValuations = Object.freeze({
  'current-rental-income': CurrentRentalIncomeProvider,
  'contracted-rent': ContractedRentProvider,
  'total-market-rent': TotalMarketRentProvider,
  'let-market-rent': LetMarketRentProvider,
  'lettable-area': LettableAreaProvider,
  'let-area': LetAreaProvider,
  'vacant-area': VacantAreaProvider,
})

export const chartsWithValuations = Object.freeze({
  'current-rental-income': CurrentRentalIncomeProvider,
  'contracted-rent': ContractedRentProvider,
  'total-market-rent': TotalMarketRentProvider,
  'let-market-rent': LetMarketRentProvider,
  'lettable-area': LettableAreaProvider,
  'vacant-area': VacantAreaProvider,
  'let-area': LetAreaProvider,
  'market-value': MarketValueProvider,
  'mortgage-lending-value': MortgageLendingValueProvider,
  'purchase-price': PurchasePriceProvider,
})

export const chartGranularities = Object.freeze({
  city: 'city',
  country: 'countryName',
  'property-type': 'propertyTypeName',
  'tegova-rating': 'tegovaRating',
  'segment-usage-type': 'segmentUsageTypeName',
  'tenant-industry': 'tenantIndustryKeyDescription',
})

const PropertyPortfolioOverviewPieChartsCard = ({ titleKey, className = '' }) => {
  const [activeGranularity, setActiveGranularity] = useState(titleKey)
  const selectChartsPerGranularity = (granularity) =>
    ['segment-usage-type', 'tenant-industry'].some((type) => type === granularity)
      ? chartsNoValuations
      : chartsWithValuations

  const [charts, setCharts] = useState(selectChartsPerGranularity(activeGranularity))

  const { t: tRegionCharts } = useTranslation('translation', {
    keyPrefix: 'pages.properties.portfolio.overview.pie-charts',
  })

  const {
    data: { multiPropertyKpisByGranularity, multiPropertyValuationsByGranularity },
    isLoading,
    isError,
  } = usePropertyPortfolioOverviewPieChartCardData()

  const { properties } = useContext(PropertyPortfolioContext)

  const rentRollKpis = useMemo(
    () =>
      (multiPropertyKpisByGranularity &&
        multiPropertyKpisByGranularity[`${camelize(activeGranularity)}RentRollKpis`]) ??
      [],
    [multiPropertyKpisByGranularity, activeGranularity],
  )

  const valuations = useMemo(() => {
    const multiPropertyValuationsBySelectedGranularity =
      (multiPropertyValuationsByGranularity?.granularities &&
        multiPropertyValuationsByGranularity.granularities[camelize(activeGranularity)]) ??
      []

    if (activeGranularity === 'tegova-rating') {
      return multiPropertyValuationsBySelectedGranularity.map((valuation) => ({
        ...valuation,
        displayName: valuation.code
          ? `${valuation.code} - ${tRegionCharts(`tegova-rating.${valuation.code}`)}`
          : tRegionCharts('none'),
      }))
    }

    return multiPropertyValuationsBySelectedGranularity
  }, [multiPropertyValuationsByGranularity?.granularities, activeGranularity, tRegionCharts])

  const getNameFromKpi = useCallback(
    (rentRollKpi) => {
      if (activeGranularity === 'tegova-rating') {
        const ratingCode = rentRollKpi?.tegovaRatingCode
        return ratingCode
          ? `${ratingCode} - ${tRegionCharts(`tegova-rating.${ratingCode}`)}`
          : tRegionCharts('none')
      }

      return (
        (rentRollKpi && rentRollKpi[chartGranularities[activeGranularity]]) ?? tRegionCharts('none')
      )
    },
    [activeGranularity, tRegionCharts],
  )

  const setChartOptions = useCallback((granularity) => {
    setActiveGranularity(granularity)
    setCharts(selectChartsPerGranularity(granularity))
  }, [])

  return (
    <Card
      header={<CardHeader titleText={tRegionCharts('title.property-kpis')} />}
      className={className}
    >
      <div className={styles.cardContent}>
        {properties.length === 0 ? (
          <EmptyCardContent
            illustrationName={IllustrationMessageType.UnableToLoad}
            size={IllustrationMessageSize.Scene}
            title={tRegionCharts('no-properties.title')}
            subtitle={tRegionCharts('no-properties.subtitle')}
          />
        ) : (
          <LoadingStateWrapper
            isLoading={isLoading}
            isError={isError}
            errorTitle={tRegionCharts('error.title')}
            errorDescription={tRegionCharts('error.subtitle')}
            errorDetails=" "
            loadingDescription={tRegionCharts('loading')}
          >
            <PropertyPieChart
              getNameFromKpi={getNameFromKpi}
              rentRollKpis={rentRollKpis}
              valuations={valuations}
              useColors={useUsageTypeColors}
              colorProvider={UsageTypeColorProvider}
              charts={charts}
              granularities={chartGranularities}
              activeGranularity={activeGranularity}
              setChartOptions={setChartOptions}
              tCharts={tRegionCharts}
            />
          </LoadingStateWrapper>
        )}
      </div>
    </Card>
  )
}

PropertyPortfolioOverviewPieChartsCard.propTypes = {
  titleKey: PropTypes.string.isRequired,
  className: PropTypes.string,
}

export default PropertyPortfolioOverviewPieChartsCard
