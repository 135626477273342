import { MessageStrip, MessageStripDesign, Link } from '@fioneer/ui5-webcomponents-react'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import {
  propertyMonitoringStatus,
  propertyMonitoringPermissions,
} from 'api/property/monitoring/propertyMonitoringConfiguration'

const PropertyMonitoringMessage = ({
  monitoringStatus,
  monitoringPermissions,
  onResumeMonitoring,
}) => {
  const { t } = useTranslation('translation', {
    keyPrefix: 'components.property-monitoring.info-card',
  })
  const hasResumeAutomatedMonitoringPermission = monitoringPermissions?.includes(
    propertyMonitoringPermissions.resumeAutomated,
  )
  const hasEditSettingsPermissions = monitoringPermissions?.includes(
    propertyMonitoringPermissions.editSettings,
  )
  switch (monitoringStatus) {
    case propertyMonitoringStatus.paused:
      return (
        <MessageStrip design={MessageStripDesign.Warning} hideCloseButton>
          {t('message-strip.paused')}
          {hasResumeAutomatedMonitoringPermission && (
            <Link onClick={onResumeMonitoring}>{t('message-strip.paused.resume')}</Link>
          )}
        </MessageStrip>
      )
    case propertyMonitoringStatus.activeManualForced:
      return (
        <MessageStrip design={MessageStripDesign.Warning} hideCloseButton>
          {t('message-strip.forced')}
        </MessageStrip>
      )
    case propertyMonitoringStatus.inactive:
    case undefined:
    case null:
      return (
        hasEditSettingsPermissions && (
          <MessageStrip design={MessageStripDesign.Information} hideCloseButton>
            {t('message-strip.inactive')}
          </MessageStrip>
        )
      )
    default:
      return null
  }
}

PropertyMonitoringMessage.propTypes = {
  onResumeMonitoring: PropTypes.func.isRequired,
  monitoringStatus: PropTypes.string,
  monitoringPermissions: PropTypes.arrayOf(PropTypes.string).isRequired,
}

export default PropertyMonitoringMessage
