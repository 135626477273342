import { Text, Title, TitleLevel } from '@fioneer/ui5-webcomponents-react'
import isNil from 'lodash.isnil'
import { DateTime } from 'luxon'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import {
  ResponsiveContainer,
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ReferenceLine,
} from 'recharts'
import styles from 'components/domains/deals/deal-cashflow/result-charts/chart-components/DealCashflowLineChart.module.css'
import { getCustomXAxisTick } from 'components/domains/deals/deal-cashflow/result-charts/chart-components/getCustomXAxisTick'
import { getTicks } from 'components/domains/deals/deal-cashflow/result-charts/chart-components/getTicks'
import { usePercentageFormatter, useShortDateFormatter } from 'hooks/i18n/useI18n'

const STROKE_WIDTH = 1.5

export const DealCashflowLineChart = ({ data, maturityData, config }) => {
  const { t } = useTranslation('translation', {
    keyPrefix: 'components.deals.cashflow.financial-ratios.chart',
  })
  const { format: formatDate } = useShortDateFormatter()

  const formatPercentageAbsolut = usePercentageFormatter({
    maximumFractionDigits: 0,
    notation: 'compact',
  })

  const formatPercentage = usePercentageFormatter({
    maximumFractionDigits: 2,
    minimumFractionDigits: 2,
    notation: 'compact',
  })

  const getTooltipValue = (value) =>
    !isNil(value) ? formatPercentage(value / 100) : t('empty-value')

  const getTooltip = ({ payload = [] }) => {
    if (!payload[0]?.payload) {
      return <></>
    }

    return (
      <div className={styles.tooltip}>
        <Title className={styles.tooltipTitle} level={TitleLevel.H6}>
          {formatDate(payload[0].payload.endDate)}
        </Title>
        <div>
          {config.map((item) => (
            <div key={item.key} className={styles.tooltipItem}>
              <Text className={styles.tooltipTextLabel}>{t(item.legend)}</Text>
              <Text key={item.key} className={styles.tooltipTextValue}>
                {getTooltipValue(payload[0].payload[item.key])}
              </Text>
            </div>
          ))}
        </div>
      </div>
    )
  }

  if (!data) {
    return <></>
  }

  // first value (dayOne) should not be part of the graph
  const rawValues = data.rawValues?.slice(1)
  const dayOne = rawValues[0].endDate
  const lastDate = rawValues?.slice(-1)[0].endDate
  const refDayOne = DateTime.fromISO(dayOne)

  const { years, ticks } = getTicks(data, dayOne)

  const maturityIndex = rawValues?.findIndex((item) => item.endDate === maturityData?.endDate)

  const beforeMaturity = maturityIndex > 0 ? rawValues?.slice(0, maturityIndex + 1) : rawValues
  const afterMaturity = maturityIndex > 0 ? rawValues?.slice(maturityIndex) : []

  return (
    <ResponsiveContainer className={styles.chartContainer} height={'100%'}>
      <LineChart width={500} margin={{ bottom: 60 }}>
        <CartesianGrid vertical={false} />
        <XAxis
          dataKey={(item) =>
            Math.abs(refDayOne.diff(DateTime.fromISO(item.endDate), 'years').years)
          }
          allowDuplicatedCategory={false}
          tickLine={false}
          tick={(payload) =>
            getCustomXAxisTick(payload, years, formatDate, styles.tickStyle, dayOne, lastDate)
          }
          type="number"
          ticks={ticks}
          interval={0}
          domain={[0, Math.abs(refDayOne.diff(DateTime.fromISO(lastDate), 'years').years)]}
        />
        <YAxis
          className={styles.tickStyle}
          tickLine={false}
          axisLine={false}
          padding={{ top: 5 }}
          tickFormatter={(val) => formatPercentageAbsolut(val / 100)}
          tickCount={7}
        />
        <Tooltip content={getTooltip} />
        <Legend
          wrapperStyle={{ marginBottom: '-50px' }}
          payload={[
            ...config.map((item) => ({
              ...item,
              value: <Text className={styles.legendText}>{t(item.legend)}</Text>,
              type: t(item.legendType),
              payload: {},
            })),
            {
              value: t('legend.maturity'),
              type: 'plainline',
              color: '#000',
              payload: {},
            },
          ]}
        />

        {config.map((item) => (
          <Line
            type="linear"
            data={beforeMaturity}
            dataKey={item.key}
            stroke={item.color}
            strokeWidth={STROKE_WIDTH}
            dot={false}
            key={item.key + 'before'}
            id={item.key + 'before'}
          />
        ))}
        {config.map((item) => (
          <Line
            type="linear"
            data={afterMaturity}
            dataKey={item.key}
            stroke={item.color}
            strokeWidth={STROKE_WIDTH}
            dot={false}
            key={item.key + 'after'}
            id={item.key + 'after'}
            strokeDasharray="5 5"
          />
        ))}

        {maturityData && (
          <ReferenceLine
            x={Math.abs(refDayOne.diff(DateTime.fromISO(maturityData.endDate), 'years').years)}
            stroke="black"
            strokeWidth={2}
          />
        )}
      </LineChart>
    </ResponsiveContainer>
  )
}

DealCashflowLineChart.propTypes = {
  data: PropTypes.object,
  maturityData: PropTypes.object,
  config: PropTypes.array,
}
