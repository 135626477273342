import {
  FlexBox,
  Text,
  FlexBoxJustifyContent,
  ListMode,
  SelectDialog,
  StandardListItem,
  ListItemType,
} from '@fioneer/ui5-webcomponents-react'
import isEmpty from 'lodash.isempty'
import PropTypes from 'prop-types'
import { useState } from 'react'
import { createPortal } from 'react-dom'
import { useTranslation } from 'react-i18next'
import LoadingStateWrapper from 'components/ui/screens/LoadingStateWrapper'
import useFinancingMini from 'hooks/services/deals/financing/useFinancingMini'
import { DATA_SOURCES } from 'routes/deals/financing/financingConstants'
import styles from 'routes/deals/financing/shared-components/TranchesSelectDialog/TranchesSelectDialog.module.css'

const TranchesSelectDialog = ({
  isDialogOpen,
  setIsDialogOpen,
  preselectedTrancheIds = [],
  disabledTrancheIds = [],
  onConfirmSelection,
  dealUuid,
  rememberSelections = true,
  forceDataSource,
}) => {
  const { t } = useTranslation()

  const [selectedTrancheIds, setSelectedTrancheIds] = useState(preselectedTrancheIds)
  const [searchInput, setSearchInput] = useState('')
  const {
    data: financingData = [],
    isLoading,
    isError,
  } = useFinancingMini(dealUuid, forceDataSource)

  const onSearch = (event) => {
    const newSearchInput = event.detail.value
    setSearchInput(newSearchInput)
  }

  const onSearchReset = () => {
    setSearchInput('')
  }

  const onConfirm = () => onConfirmSelection(selectedTrancheIds)

  const onItemClick = (e) => {
    const selectedItemTrancheId = e.detail.item.dataset.description

    if (selectedTrancheIds.includes(selectedItemTrancheId)) {
      setSelectedTrancheIds(
        selectedTrancheIds.filter((trancheId) => trancheId !== selectedItemTrancheId),
      )
    } else {
      setSelectedTrancheIds([...selectedTrancheIds, selectedItemTrancheId])
    }
  }

  const onClose = () => {
    if (!rememberSelections) {
      setSelectedTrancheIds([])
    }
  }

  const tranchesToShow = financingData?.tranchesMinis?.filter(
    (tranche) =>
      tranche.trancheName.includes(searchInput) ||
      tranche.displayId.includes(searchInput) ||
      tranche.externalContractIds?.[0]?.includes(searchInput),
  )

  return (
    <>
      {createPortal(
        <SelectDialog
          headerText={t('pages.deals.role.tranches-table.write-basic-agreement.dialog-title')}
          open={isDialogOpen}
          mode={!isEmpty(tranchesToShow) ? ListMode.MultiSelect : ListMode.SingleSelect}
          showClearButton={false}
          draggable
          resizable
          onConfirm={onConfirm}
          onSearch={onSearch}
          onSearchReset={onSearchReset}
          onBeforeClose={onClose}
          numberOfSelectedItems={selectedTrancheIds.length}
          className={styles.selectDialog}
          onAfterClose={() => setIsDialogOpen(false)}
          listProps={{ onItemClick }}
          rememberSelections={rememberSelections}
        >
          <LoadingStateWrapper isLoading={isLoading} isError={isError}>
            {!isEmpty(tranchesToShow) ? (
              tranchesToShow.map((tranche) => (
                <StandardListItem
                  description={
                    tranche.externalContractIds?.length > 0 ? tranche.externalContractIds[0] : null
                  }
                  data-description={tranche.trancheId}
                  key={tranche.trancheId}
                  selected={selectedTrancheIds.includes(tranche.trancheId)}
                  type={
                    disabledTrancheIds.includes(tranche.trancheId)
                      ? ListItemType.Inactive
                      : ListItemType.Active
                  }
                  className={`${
                    disabledTrancheIds.includes(tranche.trancheId) ? styles.readonlyListItem : ''
                  } ${styles.dynamicHeight}`}
                >
                  <FlexBox justifyContent={FlexBoxJustifyContent.SpaceBetween}>
                    <Text className={styles.trancheNameCell}>{tranche.trancheName}</Text>
                    <Text className={styles.trancheIdCell}>{tranche.displayId}</Text>
                  </FlexBox>
                </StandardListItem>
              ))
            ) : (
              <StandardListItem
                data-description={'no-tranches'}
                data-testid={'no-tranches-list-item'}
                selected={false}
                type={ListItemType.Inactive}
                className={styles.noTranchesListItem}
              >
                <FlexBox alignItems={'Center'} justifyContent={FlexBoxJustifyContent.Center}>
                  <Text className={styles.noTranchesListItemText}>
                    {t('pages.deals.role.tranches-table.write-basic-agreement.dialog.no-tranches')}
                  </Text>
                </FlexBox>
              </StandardListItem>
            )}
          </LoadingStateWrapper>
        </SelectDialog>,
        document.body,
      )}
    </>
  )
}

TranchesSelectDialog.propTypes = {
  isDialogOpen: PropTypes.bool.isRequired,
  setIsDialogOpen: PropTypes.func.isRequired,
  mode: PropTypes.oneOf([ListMode.MultiSelect, ListMode.SingleSelect]),
  preselectedTrancheIds: PropTypes.arrayOf(PropTypes.string),
  disabledTrancheIds: PropTypes.arrayOf(PropTypes.string),
  onConfirmSelection: PropTypes.func,
  dealUuid: PropTypes.string.isRequired,
  rememberSelections: PropTypes.bool,
  forceDataSource: PropTypes.oneOf([DATA_SOURCES.NEW_BUSINESS, DATA_SOURCES.EXISTING_BUSINESS]),
}

export default TranchesSelectDialog
