import { FlexBox, FlexBoxDirection } from '@fioneer/ui5-webcomponents-react'
import camelize from 'camelize'
import PropTypes from 'prop-types'
import { useMemo, useRef } from 'react'
import { useTranslation } from 'react-i18next'
import PropertiesOverviewTableCardHeader from 'components/domains/properties/PropertiesOverviewTableCardHeader'
import styles from 'components/domains/properties/portfolio/overview/PropertyPortfolioOverviewTableCard.module.css'
import usePropertyPortfolioOverviewTableColumns from 'components/domains/properties/portfolio/overview/usePropertyPortfolioOverviewTableColumns'
import Card from 'components/ui/card/Card'
import EmptyCardContent from 'components/ui/card/EmptyCardContent'
import CloseableMessageStrip from 'components/ui/feedback/CloseableMessageStrip'
import AnalyticalTableWithToolbar, {
  buildSortingWithHiddenColumns,
} from 'components/ui/tables/analytical/AnalyticalTableWithToolbar'
import useCurrentMultiPropertyKpis from 'hooks/services/properties/kpis/useCurrentMultiPropertyKpis'
import usePropertiesKpis, {
  getFirstKeyDatePropertiesKpis,
} from 'hooks/services/properties/kpis/usePropertiesKpis'
import { useEnrichPropertyWithStatus } from 'hooks/services/properties/useEnrichPropertyWithStatus'
import useMultiPropertyValuationsDecisionPaper from 'hooks/services/properties/valuations/useMultiPropertyValuationsDecisionPaper'

const PropertyPortfolioOverviewTableCard = ({ properties, className: customClassName = '' }) => {
  const { t } = useTranslation('translation', {
    keyPrefix: 'pages.properties.portfolio.overview',
  })

  const tableRef = useRef()

  const className = `${styles.card} ${customClassName}`

  const propertyUuids = properties.map(({ uuid }) => uuid)

  // CWP-16174: Improve performance and decrease load on backend system by using common queries for multiple use cases
  const { data: multiPropertyKpis, isLoading: isMultiPropertyKpisLoading } =
    useCurrentMultiPropertyKpis(propertyUuids, {
      withCityGranularity: true,
      withCountryGranularity: true,
      withPropertyTypeGranularity: true,
      withTenantIndustryGranularity: true,
      withSegmentUsageTypeGranularity: true,
      withTegovaRatingGranularity: true,
    })

  const keyDatesListOrUndefined = multiPropertyKpis?.keyDate
    ? [multiPropertyKpis?.keyDate]
    : undefined

  const { data: multiKeyDatePropertiesKpis, isLoading: isPropertiesKpisLoading } =
    usePropertiesKpis(propertyUuids, {
      keyDates: keyDatesListOrUndefined,
    })
  const propertiesKpis = useMemo(
    () => getFirstKeyDatePropertiesKpis(multiKeyDatePropertiesKpis),
    [multiKeyDatePropertiesKpis],
  )

  // CWP-16174: Improve performance and decrease load on backend system for Deal -> Financed objects page
  // Different tiles on the Deal -> Financed objects page require similar valuations and kpi data.
  // There are slightly different requirements for each tile with respect to needed aggregations and granularities.
  // We now request the same superset of valuation and kpi data at each location, so the result is just one single request
  // A further improvement idea might be: Move the valuation and kpi data to a context.
  // Look for similar comments like this to find other consolidated hook usages.
  // Some examples are:  DealPropertiesHeader, DealPropertiesTable, PropertyPortfolioInformationCard,
  // PropertyPortfolioOverviewTableCard and usePropertyPortfolioOverviewPieChartCardData
  const {
    data: valuationsData = {},
    isLoading: isValuationsLoading,
    isError: isValuationsError,
  } = useMultiPropertyValuationsDecisionPaper(
    propertyUuids,
    ['sum'],
    undefined,
    {
      enabled: !!propertyUuids.length,
    },
    ['city', 'country', 'propertyType', 'tegovaRating'],
  )
  const { valuations = [], sum: valuationSum = {} } = valuationsData

  const propertiesWithStatus = useEnrichPropertyWithStatus(camelize(properties))

  valuations.forEach((valuation) => {
    const valuationWithDefault = {
      marketValue: {
        ...valuation.marketValue,
        valueAmount: {
          ...valuation.marketValue?.valueAmount,
          number: valuation.marketValue?.valueAmount?.number || 0,
        },
      },
      mortgageLendingValue: {
        ...valuation.mortgageLendingValue,
        valueAmount: {
          ...valuation.mortgageLendingValue?.valueAmount,
          number: valuation.mortgageLendingValue?.valueAmount?.number || 0,
        },
      },
    }
    valuation = valuationWithDefault
  })

  const { data: tableColumns } = usePropertyPortfolioOverviewTableColumns({
    multiPropertyKpis,
    valuationSum,
    properties: camelize(properties),
  })

  if (isValuationsError) {
    return (
      <Card className={className}>
        <EmptyCardContent
          title={t('table.error')}
          subtitle=" "
          illustrationName="UnableToLoad"
          size="Spot"
        />
      </Card>
    )
  }

  const tableData = propertiesWithStatus.map(({ uuid, ...property }) => ({
    uuid,
    ...property,
    kpis: propertiesKpis?.find(({ propertyUuid }) => propertyUuid === uuid) || {},
    valuations: valuations?.find(({ uuid: propertyUuid }) => propertyUuid === uuid) ?? {},
    isValuationsLoading: isValuationsLoading,
    isKpisLoading: isMultiPropertyKpisLoading || isPropertiesKpisLoading,
  }))

  const isInvalidConversion = () => {
    const conversionResults = Object.keys(valuations)
      .map((propertyUuid) => {
        const propertyValuations = valuations[propertyUuid]
        const conversionResult = Object.keys(propertyValuations).map((classification) => {
          const valuationByClassification = propertyValuations[classification]
          const isValid = valuationByClassification?.isValidConversion
          if (typeof isValid === 'boolean') {
            return isValid
          } else {
            return true
          }
        })
        return conversionResult
      })
      .flat()
    return conversionResults.includes(false)
  }

  return (
    <Card
      header={
        <>
          <PropertiesOverviewTableCardHeader
            multiPropertyKpis={multiPropertyKpis}
            totalValuation={valuationSum}
          />
        </>
      }
      className={className}
    >
      <FlexBox direction={FlexBoxDirection.Column}>
        {isInvalidConversion() && (
          <CloseableMessageStrip className={styles.conversionErrorMessageStrip} design="Warning">
            {t('conversion-error')}
          </CloseableMessageStrip>
        )}
        <div className={styles.tableWrapper}>
          <AnalyticalTableWithToolbar
            ref={tableRef}
            className={styles.overviewTable}
            columns={tableColumns}
            data={tableData}
            visibleRows={tableData.length}
            headerRowHeight={40}
            rowHeight={140}
            adjustTableHeightOnPopIn
            disableColumnPopover={true}
            groupable
            filterable
            minRows={0}
            title={t('table.title')}
            customSorting={buildSortingWithHiddenColumns(tableRef)}
          />
        </div>
      </FlexBox>
    </Card>
  )
}

PropertyPortfolioOverviewTableCard.propTypes = {
  properties: PropTypes.array,
  className: PropTypes.string,
}

export default PropertyPortfolioOverviewTableCard
