import get from 'lodash.get'
import sortBy from 'lodash.sortby'
import uniqBy from 'lodash.uniqby'
import { useTranslation } from 'react-i18next'
import { TILE_VERSION as tileVersion } from 'components/domains/business-events-and-tasks/decision-paper/tiles/constant'
import {
  AssetValuationOverviewAddressCell,
  AssetValuationOverviewAggregatedCurrentRentalIncomeDecisionPaperCell,
  AssetValuationOverviewAggregatedLettableAreaCell,
  AssetValuationOverviewAggregatedMarketRentDecisionPaperCell,
  AssetValuationOverviewAggregatedMoneyValueCell,
  AssetValuationOverviewAggregatedWaultDecisionPaperCell,
  AssetValuationOverviewAllocatedLoanAmountCell,
  AssetValuationOverviewCityCell,
  AssetValuationOverviewCountryCell,
  AssetValuationOverviewCurrentRentalIncomeCell,
  AssetValuationOverviewDealPropertyDescriptionCell,
  AssetValuationOverviewGreenDataCell,
  AssetValuationOverviewLettableAreaCell,
  AssetValuationOverviewMarketRentCell,
  AssetValuationOverviewMoneyValueCell,
  AssetValuationOverviewPropertyKpisCell,
  AssetValuationOverviewPropertyStatusCell,
  AssetValuationOverviewPropertyTypeCell,
  AssetValuationOverviewSubPortfolioCell,
  AssetValuationOverviewWaultCell,
  AssetValuationOverviewWaultExpiryCell,
} from 'components/domains/business-events-and-tasks/decision-paper/tiles/property/asset-valuation-overview/shared/AssetValuationOverviewTableCells'
import { renderAssetValuationOverviewAnalyticalTableCell } from 'components/domains/business-events-and-tasks/decision-paper/tiles/property/asset-valuation-overview/shared/analytical/AssetValuationOverviewAnalyticalTableCell'
import {
  useAssetValuationOverviewAreaMeasurementUnitFormatter,
  useAssetValuationOverviewCustomizableCurrencyFormatter,
  useAssetValuationOverviewNumberFormatter,
  useAssetValuationOverviewPercentageFormatter,
} from 'components/domains/business-events-and-tasks/decision-paper/tiles/property/asset-valuation-overview/shared/i18n/useAssetValuationOverviewI18n'

const useDealPropertiesTableColumns = ({
  multiPropertyKpis,
  valuationSum,
  properties,
  subPortfolioKpis = [],
  isPdfViewOnDecisionPaper = false,
  version = tileVersion.V1,
}) => {
  const MARKET_VALUE = 'marketValue'
  const MORTGAGE_LENDING_VALUE = 'mortgageLendingValue'

  const { t } = useTranslation('decisionPaper', {
    keyPrefix: 'pages.asset-valuation-overview.properties.portfolio.overview',
  })
  const { t: tNoPrefix } = useTranslation('decisionPaper')
  const formatNumber = useAssetValuationOverviewNumberFormatter()
  const formatNumberWithDigits = useAssetValuationOverviewNumberFormatter({
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  })

  const formatWault = useAssetValuationOverviewNumberFormatter({
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  })
  const formatPercent = useAssetValuationOverviewPercentageFormatter({ maximumFractionDigits: 0 })
  const formatPercentWithDigits = useAssetValuationOverviewPercentageFormatter({
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  })
  const formatAreaUnit = useAssetValuationOverviewAreaMeasurementUnitFormatter()
  const doFormatCurrency = useAssetValuationOverviewCustomizableCurrencyFormatter()
  const formatCurrency = (value, currency) => currency && doFormatCurrency(value, currency)

  const formatBoolean = (value) => {
    if (value !== null) {
      if (value) {
        return t('green-data.bool.true')
      } else {
        return t('green-data.bool.false')
      }
    }
  }

  const extractCodesFromProperty = (codeAccessor, nameAccessor) =>
    sortBy(
      uniqBy(
        properties
          ?.map((property) => {
            const countryCode = get(property, codeAccessor, null)
            const countryName = get(property, nameAccessor, null)
            if (countryCode && countryName) {
              return {
                key: countryCode,
                displayName: countryName,
              }
            } else {
              return null
            }
          })
          .filter((countryCode) => countryCode !== null),
        'key',
      ),
      'displayName',
    )

  const relatedCountryCodes = extractCodesFromProperty('address.countryCode', 'address.countryName')

  const relatedTypeCodes = extractCodesFromProperty('typeCode', 'typeName')

  const getEnumValuesFromCodes = (codes) => {
    const enumValues = {}
    codes.forEach((code) => {
      if (code.key && code.displayName) {
        enumValues[code.key] = code.displayName
      }
    })
    return enumValues
  }

  const tableHelpers = {
    formatNumber,
    formatNumberWithDigits,
    formatCurrency,
    formatPercent,
    formatPercentWithDigits,
    formatAreaUnit,
    formatWault,
    formatBoolean,
    multiPropertyKpis,
    valuationSum,
    t,
    tNoPrefix,
    subPortfolioKpis, // For decision paper groupBy subPortfolio
    isPdfViewOnDecisionPaper,
  }

  const defaultDisables = {
    disableResizing: isPdfViewOnDecisionPaper,
    disableDragAndDrop: true,
    disableGlobalFilter: true,
  }

  const aggregateValues = (leafValues) => [...new Set(leafValues)].filter((v) => v).join(', ')

  //if accessor is null, no data is set as default value
  const getValueByAccessorOrDefault = (accessor) => (row) =>
    get(row, accessor) || t('table.columns.no-data')

  const getFilteredValuationValue = (classificationAccessor, valueAccessor) => (row) => {
    const valuationByClassification = get(row, classificationAccessor)
    const isValidConversion = valuationByClassification?.isValidConversion
    if (typeof isValidConversion === 'boolean' && !isValidConversion) {
      return 0
    } else {
      return get(valuationByClassification, valueAccessor) || 0
    }
  }

  const columnsV1 = [
    {
      Header: t('table.columns.property'),
      filterLabel: t('table.columns.property-name'),
      sortByLabel: t('table.columns.property-name'),
      id: 'description',
      accessor: 'description',
      Cell: renderAssetValuationOverviewAnalyticalTableCell(
        AssetValuationOverviewDealPropertyDescriptionCell,
        tableHelpers,
      ),
      Aggregated: renderAssetValuationOverviewAnalyticalTableCell(
        AssetValuationOverviewDealPropertyDescriptionCell,
        tableHelpers,
        true,
      ),
      minWidth: 250,
      filterType: 'CONTAINS',
      disableGroupBy: true,
      dialogOrder: 0,
      ...defaultDisables,
    },
    {
      Header: t('table.columns.property-status'),
      sortByLabel: t('table.columns.property-status'),
      groupByLabel: t('table.columns.property-status'),
      id: 'property_status',
      accessor: 'propertyStatus.text',
      Cell: renderAssetValuationOverviewAnalyticalTableCell(
        AssetValuationOverviewPropertyStatusCell,
        tableHelpers,
      ),
      Aggregated: renderAssetValuationOverviewAnalyticalTableCell(
        AssetValuationOverviewPropertyStatusCell,
        tableHelpers,
        true,
      ),
      hide: true,
      disableFilters: true,
      dialogOrder: 1,
      ...defaultDisables,
    },
    {
      Header: t('table.columns.property-type'),
      filterLabel: t('table.columns.property-type'),
      groupByLabel: t('table.columns.property-type'),
      sortByLabel: t('table.columns.property-type'),
      accessor: getValueByAccessorOrDefault('typeName'),
      id: 'type_name',
      filterType: 'OF_ENUM_TYPE',
      additionalFilterOptions: {
        enumValues: getEnumValuesFromCodes(relatedTypeCodes),
      },
      Cell: renderAssetValuationOverviewAnalyticalTableCell(
        AssetValuationOverviewPropertyTypeCell,
        tableHelpers,
      ),
      Aggregated: renderAssetValuationOverviewAnalyticalTableCell(
        AssetValuationOverviewPropertyTypeCell,
        tableHelpers,
        true,
      ),
      aggregate: aggregateValues,
      dialogOrder: 1,
      disableSortBy: true,
      hide: true,
      ...defaultDisables,
    },
    {
      Header: t('table.columns.subportfolio'),
      filterLabel: t('table.columns.subportfolio'),
      groupByLabel: t('table.columns.subportfolio'),
      accessor: getValueByAccessorOrDefault('subPortfolio'),
      id: 'sub_portfolio',
      filterType: 'CONTAINS',
      Cell: renderAssetValuationOverviewAnalyticalTableCell(
        AssetValuationOverviewSubPortfolioCell,
        tableHelpers,
      ),
      dialogOrder: 1,
      disableSortBy: true,
      hide: true,
      ...defaultDisables,
    },
    {
      Header: t('table.columns.address'),
      id: 'address',
      Cell: renderAssetValuationOverviewAnalyticalTableCell(
        AssetValuationOverviewAddressCell,
        tableHelpers,
      ),
      Aggregated: renderAssetValuationOverviewAnalyticalTableCell(
        AssetValuationOverviewAddressCell,
        tableHelpers,
        true,
      ),
      minWidth: 250,
      disableSortBy: true,
      disableFilters: true,
      disableGroupBy: true,
      ...defaultDisables,
    },

    {
      Header: t('table.columns.country'),
      sortByLabel: t('table.columns.country'),
      groupByLabel: t('table.columns.country'),
      filterLabel: t('table.columns.country'),
      accessor: getValueByAccessorOrDefault('address.countryName'),
      id: 'country',
      Cell: renderAssetValuationOverviewAnalyticalTableCell(
        AssetValuationOverviewCountryCell,
        tableHelpers,
      ),
      Aggregated: renderAssetValuationOverviewAnalyticalTableCell(
        AssetValuationOverviewCountryCell,
        tableHelpers,
        true,
      ),
      filterType: 'OF_ENUM_TYPE',
      additionalFilterOptions: {
        enumValues: getEnumValuesFromCodes(relatedCountryCodes),
      },
      dialogOrder: 2,
      disableSortBy: true,
      hide: true,
      ...defaultDisables,
    },
    {
      Header: t('table.columns.city'),
      sortByLabel: t('table.columns.city'),
      groupByLabel: t('table.columns.city'),
      filterLabel: t('table.columns.city'),
      accessor: getValueByAccessorOrDefault('address.cityName'),
      id: 'city',
      filterType: 'CONTAINS',
      Cell: renderAssetValuationOverviewAnalyticalTableCell(
        AssetValuationOverviewCityCell,
        tableHelpers,
      ),
      Aggregated: renderAssetValuationOverviewAnalyticalTableCell(
        AssetValuationOverviewCityCell,
        tableHelpers,
        true,
      ),
      dialogOrder: 3,
      disableSortBy: true,
      hide: true,
      ...defaultDisables,
    },
    {
      Header: t('table.columns.allocated-loan-amount'),
      id: 'allocated_loan_amount',
      accessor: (row) => get(row, 'allocatedLoanAmountHeadquarter.number') || 0,
      Cell: renderAssetValuationOverviewAnalyticalTableCell(
        AssetValuationOverviewAllocatedLoanAmountCell,
        {
          ...tableHelpers,
        },
      ),
      aggregate: 'sum',
      hAlign: 'right',
      vAlign: 'top',
      filterType: 'BETWEEN_NUMBERS',
      disableGroupBy: true,
      dialogOrder: 6,
      minWidth: 200,
      ...defaultDisables,
    },
    {
      Header: t('table.columns.market-value'),
      id: 'market_value',
      accessor: getFilteredValuationValue('valuations.marketValue', 'valueAmount.number'),
      Cell: renderAssetValuationOverviewAnalyticalTableCell(AssetValuationOverviewMoneyValueCell, {
        ...tableHelpers,
        classification: MARKET_VALUE,
      }),
      Aggregated: renderAssetValuationOverviewAnalyticalTableCell(
        AssetValuationOverviewAggregatedMoneyValueCell,
        {
          ...tableHelpers,
          classification: MARKET_VALUE,
        },
      ),
      aggregate: 'sum',
      hAlign: 'right',
      vAlign: 'top',
      filterType: 'BETWEEN_NUMBERS',
      dialogOrder: 4,
      disableGroupBy: true,
      minWidth: 200,
      ...defaultDisables,
    },
    {
      Header: t('table.columns.mortgage-lending-value'),
      id: 'mortgage_lending_value',
      accessor: getFilteredValuationValue('valuations.mortgageLendingValue', 'valueAmount.number'),
      Cell: renderAssetValuationOverviewAnalyticalTableCell(AssetValuationOverviewMoneyValueCell, {
        ...tableHelpers,
        classification: MORTGAGE_LENDING_VALUE,
      }),
      Aggregated: renderAssetValuationOverviewAnalyticalTableCell(
        AssetValuationOverviewAggregatedMoneyValueCell,
        {
          ...tableHelpers,
          classification: MORTGAGE_LENDING_VALUE,
        },
      ),
      aggregate: 'sum',
      hAlign: 'right',
      vAlign: 'top',
      filterType: 'BETWEEN_NUMBERS',
      dialogOrder: 5,
      disableGroupBy: true,
      minWidth: 200,
      ...defaultDisables,
    },
    {
      Header: t('table.columns.lettable-area'),
      id: 'lettable_area',
      accessor: 'kpis.totalAreaSurface.value',
      Cell: renderAssetValuationOverviewAnalyticalTableCell(
        AssetValuationOverviewLettableAreaCell,
        {
          ...tableHelpers,
        },
      ),
      Aggregated: renderAssetValuationOverviewAnalyticalTableCell(
        AssetValuationOverviewAggregatedLettableAreaCell,
        {
          ...tableHelpers,
        },
      ),
      aggregate: 'sum',
      hAlign: 'right',
      vAlign: 'top',
      filterType: 'BETWEEN_NUMBERS',
      dialogOrder: 6,
      disableGroupBy: true,
      minWidth: 200,
      ...defaultDisables,
    },
    {
      Header: t('table.columns.current-rental-income'),
      id: 'rental_income',
      accessor: 'kpis.annualizedCurrentRent.number',
      Cell: renderAssetValuationOverviewAnalyticalTableCell(
        AssetValuationOverviewCurrentRentalIncomeCell,
        {
          ...tableHelpers,
        },
      ),
      Aggregated: renderAssetValuationOverviewAnalyticalTableCell(
        AssetValuationOverviewAggregatedCurrentRentalIncomeDecisionPaperCell,
        {
          ...tableHelpers,
        },
      ),
      aggregate: 'sum',
      hAlign: 'right',
      vAlign: 'top',
      filterType: 'BETWEEN_NUMBERS',
      dialogOrder: 8,
      disableGroupBy: true,
      minWidth: 200,
      ...defaultDisables,
    },
    {
      hide: false,
      Header: t('table.columns.market-rent'),
      id: 'market_rent',
      accessor: 'kpis.totalMarketRent.number',
      Cell: renderAssetValuationOverviewAnalyticalTableCell(AssetValuationOverviewMarketRentCell, {
        ...tableHelpers,
      }),
      Aggregated: renderAssetValuationOverviewAnalyticalTableCell(
        AssetValuationOverviewAggregatedMarketRentDecisionPaperCell,
        {
          ...tableHelpers,
        },
      ),
      minWidth: 200,
      hAlign: 'right',
      vAlign: 'top',
      filterType: 'BETWEEN_NUMBERS',
      dialogOrder: 10,
      disableGroupBy: true,
      disableFilters: true,
      disableSortBy: true,
      ...defaultDisables,
    },
    {
      Header: t('table.columns.wault-break'),
      id: 'wault_break',
      accessor: 'kpis.waultToBreakInYears',
      Cell: renderAssetValuationOverviewAnalyticalTableCell(AssetValuationOverviewWaultCell, {
        ...tableHelpers,
      }),
      Aggregated: renderAssetValuationOverviewAnalyticalTableCell(
        AssetValuationOverviewAggregatedWaultDecisionPaperCell,
        {
          ...tableHelpers,
        },
      ),
      aggregate: 'sum',
      hAlign: 'right',
      vAlign: 'top',
      filterType: 'BETWEEN_NUMBERS',
      dialogOrder: 9,
      disableGroupBy: true,
      minWidth: 200,
      ...defaultDisables,
    },
    {
      hide: true,
      Header: t('table.columns.wault-expiry'),
      id: 'wault_expiry',
      accessor: 'kpis.waultToExpiryInYears',
      Cell: renderAssetValuationOverviewAnalyticalTableCell(AssetValuationOverviewWaultExpiryCell, {
        ...tableHelpers,
      }),
      Aggregated: () => {},
      hAlign: 'right',
      filterType: 'BETWEEN_NUMBERS',
      disableFilters: true,
      dialogOrder: 10,
      disableGroupBy: true,
      disableSortBy: true,
      ...defaultDisables,
    },
  ]

  const additionalColumnsV2 = [
    {
      Header: t('table.columns.property-kpis'),
      id: 'property_kpis',
      accessor: 'keyData',
      Cell: renderAssetValuationOverviewAnalyticalTableCell(
        AssetValuationOverviewPropertyKpisCell,
        { ...tableHelpers },
      ),
      Aggregated: () => {},
      disableFilters: true,
      dialogOrder: 12,
      disableGroupBy: true,
      disableSortBy: true,
      minWidth: 250,
      ...defaultDisables,
    },
    {
      Header: t('table.columns.green-data'),
      id: 'green_data',
      accessor: 'greenData',
      Cell: renderAssetValuationOverviewAnalyticalTableCell(AssetValuationOverviewGreenDataCell, {
        ...tableHelpers,
      }),
      Aggregated: () => {},
      disableFilters: true,
      dialogOrder: 13,
      disableGroupBy: true,
      disableSortBy: true,
      minWidth: 250,
      ...defaultDisables,
    },
  ]

  let data
  switch (version) {
    case tileVersion.V2:
      data = [...columnsV1, ...additionalColumnsV2]
      break
    default:
      data = columnsV1
  }

  return { data }
}

export default useDealPropertiesTableColumns
