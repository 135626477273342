import { Label, Text } from '@fioneer/ui5-webcomponents-react'
import isNil from 'lodash.isnil'
import PropTypes from 'prop-types'
import { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import styles from 'components/domains/business-partners/tile/tenancy/tenant-unit-table/table-cells/TenancyValueWithUnitAndShareCell.module.css'
import { useTenancyKpiValueFormatter } from 'components/domains/business-partners/tile/tenancy/useTenancyKpiValueFormatter'
import { usePercentageFormatter } from 'hooks/i18n/useI18n'
import { tenancyHeaderInformationValueType } from 'hooks/services/business-partners/tenancy/tenancyHeaderInformationValueType'

const propTypes = {
  cell: PropTypes.shape({
    value: PropTypes.shape({
      data: PropTypes.shape({
        value: PropTypes.number,
        share: PropTypes.number,
        unit: PropTypes.string,
      }).isRequired,
      isBold: PropTypes.bool,
      kpiValueType: PropTypes.oneOf(Object.values(tenancyHeaderInformationValueType)),
    }),
  }).isRequired,
}

/**
 * @param {PropTypes.InferProps<typeof propTypes>} props
 */
const TenancyValueWithUnitAndShareCell = ({
  cell: {
    value: {
      data: { value, unit, share },
      kpiValueType,
      isBold,
    },
  },
}) => {
  const { t } = useTranslation('translation', {
    keyPrefix: 'pages.business-partner.tenancy.as-tenant',
  })
  const formatPercent = usePercentageFormatter({
    maximumFractionDigits: 2,
    minimumFractionDigits: 1,
  })
  const formatValue = useTenancyKpiValueFormatter()
  const formattedValue = useMemo(
    () => formatValue({ kpiValue: value, unit, kpiValueType }),
    [formatValue, kpiValueType, unit, value],
  )

  const formattedShare = useMemo(() => {
    if (isNaN(share)) {
      return '-'
    }
    return formatPercent(share)
  }, [formatPercent, share])
  const textClassName = isBold ? 'sapFontBoldFamily' : ''

  return (
    <div className={styles.valueWithUnitAndShareCell}>
      <Text className={textClassName}>{formattedValue}</Text>
      {!isNil(share) && (
        <div className={styles.keyValuePair}>
          <Label showColon>{t('share')}</Label>{' '}
          <Text className={textClassName}>{formattedShare}</Text>
        </div>
      )}
    </div>
  )
}

TenancyValueWithUnitAndShareCell.propTypes = propTypes

export default TenancyValueWithUnitAndShareCell
