import { MessageBoxTypes, Modals } from '@fioneer/ui5-webcomponents-react'
import { useQueryClient } from '@tanstack/react-query'
import '@ui5/webcomponents-icons/dist/error.js'
import PropTypes from 'prop-types'
import { useCallback, useContext, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { isEventClosed } from 'api/events/status'
import styles from 'components/domains/business-events-and-tasks/decision-paper/comments/DecisionPaperSubsectionCommentDialog.module.css'
import CommentDialog from 'components/ui/comment/CommentDialog'
import CommentEntry from 'components/ui/comment/CommentEntry'
import 'components/ui/dialog/ErrorDialog.css'
import useCreateDecisionPaperSubsectionComment from 'hooks/services/business-events-and-tasks/decision-papers/comments/useCreateDecisionPaperSubsectionComment'
import useDeleteDecisionPaperSubsectionComment from 'hooks/services/business-events-and-tasks/decision-papers/comments/useDeleteDecisionPaperSubsectionComment'
import useEditDecisionPaperSubsectionComment from 'hooks/services/business-events-and-tasks/decision-papers/comments/useEditDecisionPaperSubsectionComment'
import BusinessEventsAndTasksContext from 'routes/business-events-and-tasks/BusinessEventsAndTasksContext'

const DecisionPaperSubsectionCommentDialog = ({
  isOpen,
  setIsOpen,
  version,
  subsectionId,
  comments,
  userMail,
}) => {
  const { id: eventId, status: eventStatus } = useContext(BusinessEventsAndTasksContext).event
  const { mutate: createComment } = useCreateDecisionPaperSubsectionComment()
  const { mutate: deleteComment } = useDeleteDecisionPaperSubsectionComment()
  const { mutate: editComment } = useEditDecisionPaperSubsectionComment()
  const queryClient = useQueryClient()
  const { t } = useTranslation('translation', {
    keyPrefix: 'components.business-events-and-tasks.decision-papers.subsections.comments',
  })
  const showMessageBox = Modals.useShowMessageBox()

  const [isEditMode, setIsEditMode] = useState(false)
  const [commentEditModeState, setCommentEditModeState] = useState({})
  const isCommentEditMode = useCallback(
    (commentId) => commentEditModeState[commentId] ?? false,
    [commentEditModeState],
  )
  const setIsCommentEditMode = useCallback(
    (commentId, isEditing) =>
      setCommentEditModeState({ ...commentEditModeState, [commentId]: isEditing }),
    [commentEditModeState],
  )

  const handleDeleteComment = useCallback(
    (commentId) => {
      deleteComment(
        { commentId, version, subsectionId, eventId },
        {
          onSuccess: () => {
            queryClient.invalidateQueries([
              'events',
              eventId,
              'decision-paper',
              'versions',
              version,
              'subsections',
              subsectionId,
              'comments',
            ])
          },
          onError: () => {
            showMessageBox({ type: MessageBoxTypes.Error, children: t('delete.error.description') })
          },
        },
      )
    },
    [t, eventId, version, subsectionId, deleteComment, queryClient, showMessageBox],
  )

  const handleUpdateComment = useCallback(
    ({ commentId, newComment }) => {
      editComment(
        {
          eventId,
          subsectionId,
          version,
          commentId,
          comment: newComment,
        },
        {
          onSuccess: () => {
            queryClient.invalidateQueries([
              'events',
              eventId,
              'decision-paper',
              'versions',
              version,
              'subsections',
              subsectionId,
              'comments',
            ])
            setIsCommentEditMode(commentId, false)
          },
          onError: (_error) => {
            showMessageBox({ type: MessageBoxTypes.Error, children: t('edit.error.description') })
          },
        },
      )
    },
    [
      editComment,
      eventId,
      subsectionId,
      version,
      queryClient,
      setIsCommentEditMode,
      showMessageBox,
      t,
    ],
  )

  const showNewCommentCard = useMemo(() => !isEventClosed(eventStatus), [eventStatus])

  const handleCreateComment = useCallback(
    (comment) => {
      createComment(
        { eventId, version, subsectionId, comment },
        {
          onSuccess: () => {
            queryClient.invalidateQueries([
              'events',
              eventId,
              'decision-paper',
              'versions',
              version,
              'subsections',
              subsectionId,
              'comments',
            ])
            setIsEditMode(false)
          },
          onError: () => {
            showMessageBox({ type: MessageBoxTypes.Error, children: t('create.error.description') })
          },
        },
      )
    },
    [eventId, version, subsectionId, createComment, queryClient, showMessageBox, t],
  )

  const listOfComments = useMemo(
    () =>
      comments.map(({ ownerId, creationTime, comment, id: commentId, lastModifiedAt }, index) => (
        <div key={index} className={styles.comment}>
          <CommentEntry
            ownerId={ownerId}
            commentText={comment}
            creationTime={creationTime}
            lastModifiedAt={lastModifiedAt}
            showCommentActions={userMail === ownerId && !isEventClosed(eventStatus)}
            onDeleteComment={() => handleDeleteComment(commentId)}
            onUpdateComment={(newComment) => handleUpdateComment({ commentId, newComment })}
            isEditMode={isCommentEditMode(commentId)}
            setIsEditMode={(isEditing) => setIsCommentEditMode(commentId, isEditing)}
          />
        </div>
      )),
    [
      comments,
      userMail,
      eventStatus,
      isCommentEditMode,
      handleDeleteComment,
      handleUpdateComment,
      setIsCommentEditMode,
    ],
  )

  return (
    <CommentDialog
      isEditMode={isEditMode}
      setIsEditMode={setIsEditMode}
      isOpen={isOpen}
      setIsOpen={setIsOpen}
      onCreateComment={handleCreateComment}
      showNewCommentCard={showNewCommentCard}
    >
      {listOfComments}
    </CommentDialog>
  )
}

DecisionPaperSubsectionCommentDialog.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  setIsOpen: PropTypes.func.isRequired,
  version: PropTypes.string.isRequired,
  subsectionId: PropTypes.string.isRequired,
  comments: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      comment: PropTypes.string,
      creationTime: PropTypes.string,
      ownerEmail: PropTypes.string,
    }),
  ).isRequired,
  userMail: PropTypes.string,
}

export default DecisionPaperSubsectionCommentDialog
