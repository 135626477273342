import isNil from 'lodash.isnil'

/**
 * @enum
 */
const validBreachRules = {
  LIMIT_LESS_THAN_RESULT: 'LIMIT_LESS_THAN_RESULT',
  LIMIT_GREATER_THAN_RESULT: 'LIMIT_GREATER_THAN_RESULT',
  LIMIT_LESS_EQUAL_THAN_RESULT: 'LIMIT_LESS_EQUAL_THAN_RESULT',
  LIMIT_GREATER_EQUAL_THAN_RESULT: 'LIMIT_GREATER_EQUAL_THAN_RESULT',
}

const useCalculateBreach = () => {
  const getCalculatedBreach = (breachRule, hardLimitValue, softLimitValue, result) => {
    if (isNil(result) || isNil(breachRule)) return null
    if (!Object.values(validBreachRules).includes(breachRule)) return null

    if (breachRule)
      switch (breachRule) {
        case validBreachRules.LIMIT_LESS_THAN_RESULT: {
          if (!!hardLimitValue && hardLimitValue < result) return 'HARD'
          if (!!softLimitValue && softLimitValue < result) return 'SOFT'
          return 'NO'
        }
        case validBreachRules.LIMIT_GREATER_THAN_RESULT: {
          if (!!hardLimitValue && hardLimitValue > result) return 'HARD'
          if (!!softLimitValue && softLimitValue > result) return 'SOFT'
          return 'NO'
        }
        case validBreachRules.LIMIT_LESS_EQUAL_THAN_RESULT: {
          if (!!hardLimitValue && hardLimitValue <= result) return 'HARD'
          if (!!softLimitValue && softLimitValue <= result) return 'SOFT'
          return 'NO'
        }
        case validBreachRules.LIMIT_GREATER_EQUAL_THAN_RESULT: {
          if (!!hardLimitValue && hardLimitValue >= result) return 'HARD'
          if (!!softLimitValue && softLimitValue >= result) return 'SOFT'
          return 'NO'
        }
        default: {
          return 'NO'
        }
      }
  }
  return { getCalculatedBreach }
}

export default useCalculateBreach
