import { FlexBox, Table, TableCell, TableMode, TableRow } from '@fioneer/ui5-webcomponents-react'
import PropTypes, { shape } from 'prop-types'
import React from 'react'
import { useTranslation } from 'react-i18next'
import styles from 'components/domains/deals/covenants/limit-cards/LimitBaseTable.module.css'
import createColumns from 'components/ui/tables/sorted-tables/createColumns'
import TablesToolbar from 'components/ui/tables/toolbar/TablesToolbar'

const LimitBaseTable = ({
  columnDefinitions = [],
  tableData = [],
  actions = [],
  title,
  isMultiSelect = false,
  onSelectionChange,
}) => {
  const { t } = useTranslation('translation')

  const onSelection = ({ detail }) =>
    onSelectionChange?.(
      detail.selectedRows
        ?.map((row) => row.attributes?.['data-key']?.value)
        .filter((rowKey) => !!rowKey),
    )

  const alignContent = (content, alignment) =>
    alignment ? <FlexBox justifyContent={alignment}>{content}</FlexBox> : content

  const renderCells = (tableRow) =>
    columnDefinitions.map((colDef) => (
      <TableCell
        key={tableRow.rowKey + '-' + colDef.columnKey}
        {...tableRow[colDef.columnKey].props}
      >
        {alignContent(tableRow[colDef.columnKey].content, colDef.alignment)}
      </TableCell>
    ))

  const mapTableRows = () =>
    tableData.map((tableRow) => (
      <TableRow key={tableRow.rowKey} data-key={tableRow.rowKey} className={styles.defaultCursor}>
        {renderCells(tableRow)}
      </TableRow>
    ))

  return (
    <>
      <TablesToolbar title={title} nrOfEntries={tableData.length} additionalActions={actions} />
      <Table
        columns={createColumns(columnDefinitions)}
        className={`${styles.table}`}
        noDataText={t('components.cards.empty.title')}
        mode={isMultiSelect ? TableMode.MultiSelect : TableMode.None}
        onSelectionChange={onSelection}
      >
        {mapTableRows()}
      </Table>
    </>
  )
}

LimitBaseTable.propTypes = {
  columnDefinitions: PropTypes.arrayOf(
    shape({
      columnKey: PropTypes.string,
      title: PropTypes.string,
      alignment: PropTypes.string,
    }),
  ).isRequired,
  tableData: PropTypes.arrayOf(
    shape({
      [PropTypes.string]: PropTypes.objectOf({
        content: PropTypes.oneOfType([
          PropTypes.string,
          PropTypes.number,
          PropTypes.bool,
          PropTypes.element,
        ]),
      }),
    }),
  ).isRequired,
  actions: PropTypes.arrayOf(PropTypes.element),
  title: PropTypes.string.isRequired,
  isMultiSelect: PropTypes.bool,
  onSelectionChange: PropTypes.func,
}

export default LimitBaseTable
