import { FlexBox, FlexBoxJustifyContent, Text } from '@fioneer/ui5-webcomponents-react'
import PropTypes from 'prop-types'
import { SETTINGS } from 'components/ui/charts/kpi-chart/kpiChartUtil'
import { useNumberFormatter } from 'hooks/i18n/useI18n'

const KpiChartStartEndValueLabel = ({ kpiUnit, startValue, endValue }) => {
  const formatNumber = useNumberFormatter({
    notation: 'compact',
    maximumFractionDigits: 2,
    minimumFractionDigits: 2,
    trailingZeroDisplay: 'stripIfInteger',
  })

  if (startValue === undefined) {
    return <></>
  }

  if (endValue === undefined) {
    return (
      <FlexBox justifyContent={FlexBoxJustifyContent.Start}>
        <Text
          style={{
            textAlign: 'center',
            color: startValue >= 0 ? SETTINGS.GREEN : SETTINGS.RED,
          }}
        >
          {`${formatNumber(startValue)} ${kpiUnit}`}
        </Text>
      </FlexBox>
    )
  }

  return (
    <FlexBox style={{ width: '100%' }} justifyContent={FlexBoxJustifyContent.SpaceBetween}>
      <Text style={{ color: startValue >= 0 ? SETTINGS.GREEN : SETTINGS.RED }}>
        {`${formatNumber(startValue)} ${kpiUnit}`}
      </Text>
      <Text
        style={{
          color: endValue >= 0 ? SETTINGS.GREEN : SETTINGS.RED,
        }}
      >
        {`${formatNumber(endValue)} ${kpiUnit}`}
      </Text>
    </FlexBox>
  )
}

KpiChartStartEndValueLabel.propTypes = {
  kpiUnit: PropTypes.string.isRequired,
  startValue: PropTypes.number,
  endValue: PropTypes.number,
}

export default KpiChartStartEndValueLabel
