import isNil from 'lodash.isnil'
import { isMissingPermissionError } from 'api/requests'
import { useRequest } from 'hooks/services/baseService'
import { useCamelizedResponse } from 'hooks/services/queryHelper'
import useDataSourceQueryParams from 'routes/deals/financing/utils/useDataSourceQueryParams'

const useFinancing = (dealId, options = {}) => {
  const { dataSourceQueryKeys, dataSourceQueryParam } = useDataSourceQueryParams()

  const result = useCamelizedResponse(
    useRequest({
      path: `/financing-structures/deals/${dealId}/tranches?${dataSourceQueryParam}`,
      translated: true,
      keys: ['deals', dealId, 'tranches', ...dataSourceQueryKeys],
      options: {
        retry: (_, error) => !isMissingPermissionError(error),
        enabled: !isNil(dealId),
        ...options,
      },
    }),
  )

  return {
    ...result,
    data: result?.data,
  }
}

export default useFinancing
