import { Bar, Button, Dialog } from '@fioneer/ui5-webcomponents-react'
import PropTypes from 'prop-types'
import { useContext, useState } from 'react'
import { useTranslation } from 'react-i18next'
import SegmentDescriptionInput from 'components/domains/properties/rent-roll/segment/segment-create/SegmentDescriptionInput'
import SegmentMarketRentInput from 'components/domains/properties/rent-roll/segment/segment-create/SegmentMarketRentInput'
import SegmentNameInput from 'components/domains/properties/rent-roll/segment/segment-create/SegmentNameInput'
import SegmentTotalAreaInput from 'components/domains/properties/rent-roll/segment/segment-create/SegmentTotalAreaInput'
import SegmentUsageTypeSelect from 'components/domains/properties/rent-roll/segment/segment-create/SegmentUsageTypeSelect'
import SegmentVacancyCostsInput from 'components/domains/properties/rent-roll/segment/segment-create/SegmentVacancyCostsInput'
import ErrorMessageBoxWithExpandableDetails from 'components/ui/dialog/ErrorMessageBoxWithExpandableDetails'
import LoadingStateWrapper from 'components/ui/screens/LoadingStateWrapper'
import { useCreateSegment } from 'hooks/services/properties/segments/useCreateSegment'
import { useProperty } from 'hooks/services/properties/useProperty'
import 'components/domains/properties/rent-roll/segment/segment-create/SegmentCreationDialog.css'
import { formatHookError } from 'hooks/services/useHookErrorResponseFormatter'
import { PropertyContext } from 'routes/properties/PropertyContext'

/**
 * use ui-components dialog | https://fioneer.atlassian.net/browse/CWP-13200
 */
const SegmentCreationDialog = ({ showDialog, onCloseDialog, onSegmentCreate, propertyUuid }) => {
  const { t } = useTranslation('translation', {
    keyPrefix: 'pages.rent-roll-working-version.segment-creation',
  })
  const propertyContext = useContext(PropertyContext)
  const isMultiProperty = !propertyContext

  const {
    data: propertyFromHook,
    isLoading,
    isError,
  } = useProperty(propertyUuid, false, { enabled: isMultiProperty && !!propertyUuid })

  const property = isMultiProperty ? propertyFromHook : propertyContext.property

  const [name, setName] = useState()
  const [description, setDescription] = useState()
  const [usageTypeCode, setUsageTypeCode] = useState()
  const [marketRent, setMarketRent] = useState()
  const [vacancyCosts, setVacancyCosts] = useState()
  const [totalArea, setTotalArea] = useState()
  const [isValidName, setIsValidName] = useState(false)
  const [isValidUsageTypeCode, setIsValidUsageTypeCode] = useState(false)
  const isValid = () => isValidName && isValidUsageTypeCode

  const [isErrorDialogOpen, setIsErrorDialogOpen] = useState(false)
  const [errorDetails, setErrorDetails] = useState('')

  const resetState = () => {
    setName('')
    setDescription('')
    setUsageTypeCode('')
    setIsValidName(false)
    setIsValidUsageTypeCode(false)
    setMarketRent(null)
    setVacancyCosts(null)
    setTotalArea(null)
  }

  const onClose = () => {
    resetState()
    onCloseDialog()
  }

  const onError = async (error) => {
    const errorMessageString = await formatHookError(error)
    setIsErrorDialogOpen(true)
    setErrorDetails(errorMessageString)
  }

  const onErrorDialogClose = () => {
    setIsErrorDialogOpen(false)
  }

  const createSegment = useCreateSegment({
    onSuccess: (segment) => {
      onClose()
      onSegmentCreate(segment)
    },
    onError: (error) => {
      onError(error)
    },
  })

  const onCreate = (e) => {
    e.preventDefault()
    if (isValid()) {
      const segment = {
        name: name,
        description: description,
        usage_type_code: usageTypeCode,
        market_rent_amount: {
          number: marketRent,
          currency: property.currency_code,
        },
        vacancy_cost_amount: {
          number: vacancyCosts,
          currency: property.currency_code,
        },
        total_area: totalArea,
        area_measure_unit_code: property.area_measure_unit_code,
      }
      const createPayload = {
        property_uuid: property.uuid,
        segment: segment,
      }
      createSegment.mutate(createPayload)
    }
  }

  const renderSegmentCreationDialogContent = () => (
    <>
      <SegmentNameInput name={name} setName={setName} onValidationChange={setIsValidName} />
      <SegmentUsageTypeSelect
        usageType={usageTypeCode}
        setUsageType={setUsageTypeCode}
        onValidationChange={setIsValidUsageTypeCode}
      />
      <SegmentDescriptionInput description={description} setDescription={setDescription} />
      <SegmentTotalAreaInput
        totalArea={totalArea}
        setTotalArea={setTotalArea}
        areaMeasureUnit={property.area_measure_unit_code}
      />
      <SegmentMarketRentInput
        annuallyMarketRent={marketRent}
        setAnnuallyMarketRent={setMarketRent}
        currencyCode={property.currency_code}
        areaMeasureUnit={property.area_measure_unit_code}
      />
      <SegmentVacancyCostsInput
        annuallyVacancyCosts={vacancyCosts}
        setAnnuallyVacancyCosts={setVacancyCosts}
        currencyCode={property.currency_code}
        areaMeasureUnit={property.area_measure_unit_code}
      />
    </>
  )

  return (
    <>
      <Dialog
        className="segment-creation-dialog"
        open={showDialog}
        onAfterClose={onClose}
        footer={
          <Bar
            className="segment-creation-dialog-footer"
            design="Footer"
            endContent={
              <>
                <Button
                  design="Emphasized"
                  id="segment-creation-dialog-create"
                  disabled={!isValid()}
                  onClick={onCreate}
                >
                  {t('create')}
                </Button>
                <Button id="segment-creation-dialog-close" onClick={onClose}>
                  {t('close')}
                </Button>
              </>
            }
          />
        }
        headerText={t('header')}
      >
        <LoadingStateWrapper
          isLoading={isMultiProperty && isLoading}
          isError={isMultiProperty && isError}
          renderContent={renderSegmentCreationDialogContent}
        />
      </Dialog>
      {isErrorDialogOpen && (
        <ErrorMessageBoxWithExpandableDetails
          messageSummary={t('error-text')}
          messageDetails={errorDetails}
          isOpen={isErrorDialogOpen}
          onClose={onErrorDialogClose}
        />
      )}
    </>
  )
}
SegmentCreationDialog.propTypes = {
  onCloseDialog: PropTypes.func.isRequired,
  showDialog: PropTypes.bool.isRequired,
  onSegmentCreate: PropTypes.func.isRequired,
  propertyUuid: PropTypes.string,
}

export default SegmentCreationDialog
