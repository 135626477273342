import { useQuery } from '@tanstack/react-query'
import { createContext } from 'react'
import { getConfig } from 'api/config'

/** @typedef {import('../../../public/config/config.json')} config */

/**
 * Hook API for accessing the configuration
 */
export const useConfig = () => {
  const destination =
    process.env.NODE_ENV === 'production' ? '/config/config.json' : '/config/local.config.json'

  return useQuery(['config'], () => getConfig(destination), {
    staleTime: Infinity,
    refetchInterval: false,
  })
}

const initialConfig = /** @type {config} */ (/** @type {unknown} */ ({}))
export const ConfigContext = createContext(initialConfig)
