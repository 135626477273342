import PropTypes from 'prop-types'
import { useMemo } from 'react'
import { useLocation } from 'react-router-dom'
import CreatePropertyEventAction from 'components/domains/properties/header-actions/CreatePropertyEventAction'
import { PROPERTY_RENT_ROLL_SECTIONS } from 'components/domains/properties/rent-roll/PropertyRentRoll'
import PropertyRentRollCurrencyButton from 'components/domains/properties/rent-roll/PropertyRentRollCurrencyButton'
import styles from 'components/domains/properties/rent-roll/PropertyRentRollHeaderActions.module.css'
import RentRollOpenInCmsButton from 'components/domains/properties/rent-roll/overview/cms/RentRollOpenInCmsButton'
import UserFavoriteIcon from 'components/ui/user-favorite/UserFavoriteIcon'
import { cwpEntityTypes } from 'constants/cwpEntityTypes'

const PROPERTY_RENT_ROLL_SECTIONS_EXCLUDING_CURRENCY_BUTTON = [
  PROPERTY_RENT_ROLL_SECTIONS.segments,
  PROPERTY_RENT_ROLL_SECTIONS.details,
]

const PropertyRentRollHeaderActions = ({ property }) => {
  const { hash } = useLocation()

  const showCurrencyButton = useMemo(
    () => !PROPERTY_RENT_ROLL_SECTIONS_EXCLUDING_CURRENCY_BUTTON.includes(hash?.substring(1)),
    [hash],
  )

  const markFavoriteAction = useMemo(
    () => (
      <UserFavoriteIcon
        key="property-details-user-favorite-icon"
        entityId={property.uuid}
        entityType={cwpEntityTypes.PROPERTY}
      />
    ),
    [property.uuid],
  )

  return (
    <>
      <CreatePropertyEventAction />
      <div className={styles.spacer} />
      <RentRollOpenInCmsButton propertyId={property.id} />
      {showCurrencyButton && (
        <>
          <div className={styles.spacer} />
          <PropertyRentRollCurrencyButton key={`propertyRentRollCurrencyButton-${hash}`} />
        </>
      )}
      <>
        <div className={styles.spacer} />
        {markFavoriteAction}
      </>
    </>
  )
}

PropertyRentRollHeaderActions.propTypes = {
  property: PropTypes.object.isRequired,
}

export default PropertyRentRollHeaderActions
