import { FlexBox, Option, Select } from '@fioneer/ui5-webcomponents-react'
import { t } from 'i18next'
import { useContext } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { propertyAllowedOperations } from 'api/property/propertyAllowedOperations'
import { PERIODS } from 'components/domains/properties/rent-roll/overview/structure-overview/constants'
import styles from 'components/domains/properties/rent-roll/overview/structure-overview/rental-income/PropertyRentRollOverviewRentalIncomeCard.module.css'
import PropertyRentRollOverviewRentalIncomeTable from 'components/domains/properties/rent-roll/overview/structure-overview/rental-income/PropertyRentRollOverviewRentalIncomeTable'
import Card from 'components/ui/card/Card'
import CardHeaderWithButtons from 'components/ui/card/CardHeaderWithButtons'
import EmptyCardContent from 'components/ui/card/EmptyCardContent'
import LoadingStateWrapper from 'components/ui/screens/LoadingStateWrapper'
import { useSegments } from 'hooks/services/properties/segments/useSegments'
import { usePropertiesPermissions } from 'hooks/services/properties/usePropertiesPermissions'
import { setPeriod } from 'redux/slices/properties/segmentsRentalIncomeSlice'
import { PropertyContext } from 'routes/properties/PropertyContext'

const PropertyRentRollOverviewRentalIncomeCard = () => {
  const { isLoading: isLoadingPermissions, data: propertyPermissions } = usePropertiesPermissions()
  const allowedPropertyOperations = propertyPermissions?.allowed_operations ?? []
  const isAllowedToReadProperty = allowedPropertyOperations.includes(
    propertyAllowedOperations.propertyRead,
  )

  const { property } = useContext(PropertyContext)

  const propertyUuid = property?.uuid

  const { t: tRentalIncome } = useTranslation('translation', {
    keyPrefix: 'pages.property.rent-roll.overview.rental-income',
  })

  const selectedCurrency = useSelector(
    (state) => state.properties.commonCurrency.selectedCommonCurrency,
  )

  const {
    isLoading: isLoadingSegments,
    isError: isErrorSegments,
    data: segmentsData,
  } = useSegments(
    { propertyUuid, preferredCurrency: selectedCurrency ?? property?.currency_code },
    {
      enabled: isAllowedToReadProperty,
    },
  )
  const segments = segmentsData?.segments ?? []

  const dispatch = useDispatch()

  const handlePeriodChange = (event) => {
    dispatch(setPeriod(event?.detail?.selectedOption?.value || PERIODS.ANNUALLY))
  }

  return (
    <Card
      header={
        <CardHeaderWithButtons title={tRentalIncome('card.title')}>
          <Select id="rental-income-period-select" onChange={handlePeriodChange}>
            <Option value={PERIODS.ANNUALLY}>{t('labels.annually')}</Option>
            <Option value={PERIODS.MONTHLY}>{t('labels.monthly')}</Option>
          </Select>
        </CardHeaderWithButtons>
      }
    >
      <LoadingStateWrapper
        isLoading={isLoadingSegments || isLoadingPermissions}
        isError={isErrorSegments}
        renderContent={() => (
          <FlexBox className={styles.tablePadding}>
            <PropertyRentRollOverviewRentalIncomeTable
              property={property}
              segments={segments}
              hasPermission={isAllowedToReadProperty}
            />
          </FlexBox>
        )}
        renderCustomErrorScreen={() => (
          <EmptyCardContent
            title={t('components.cards.error.title')}
            subtitle={t('components.cards.error.subtitle')}
          />
        )}
      />
    </Card>
  )
}

export default PropertyRentRollOverviewRentalIncomeCard
