import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import { propertyMonitoringCriticalMovementDirection } from 'api/property/monitoring/propertyMonitoringConfiguration'
import {
  calculateThreshold,
  displayThresholdWithUnit,
} from 'components/domains/properties/monitoring/monitoringProxyCalculations'
import ClickableKpiChart from 'components/ui/charts/kpi-chart/ClickableKpiChart'
import SmallLoadingWrapper from 'components/ui/loading/SmallLoadingWrapper'
import { useNumberFormatter } from 'hooks/i18n/useI18n'
import useMarketKpis from 'hooks/services/markets/useMarketKpis'

const PropertyMonitoringMacroKpiChartCell = ({
  kpiName,
  marketId,
  threshold,
  thresholdType,
  criticalMovementDirection,
  valueAtFixing,
  baselineDate,
}) => {
  const { t } = useTranslation('translation', {
    keyPrefix: 'components.property-monitoring-proxies.table.kpi-chart',
  })
  const formatNumber = useNumberFormatter({
    maximumFractionDigits: 2,
    minimumFractionDigits: 2,
    trailingZeroDisplay: 'stripIfInteger',
  })
  const {
    isLoading,
    isError,
    data: marketKpiData,
  } = useMarketKpis({ marketId, filterKpisForMonitoring: true })

  const renderCell = () => {
    const relevantKpi = marketKpiData.kpis[kpiName]

    if (!relevantKpi) {
      return t('error')
    }

    const sortedKpiData = [...relevantKpi.values].reverse()
    const kpiUnit = relevantKpi.unit
    const kpiNameTranslated = relevantKpi.name
    const calculatedThreshold = calculateThreshold({
      threshold,
      thresholdType,
      criticalMovementDirection,
      baselineDate,
      valueAtFixing,
    })
    return (
      <ClickableKpiChart
        kpiName={kpiNameTranslated}
        kpiData={sortedKpiData}
        kpiUnit={kpiUnit}
        threshold={calculatedThreshold}
        thresholdLabel={displayThresholdWithUnit({
          threshold: formatNumber(calculatedThreshold),
          unit: kpiUnit,
        })}
        baselineDate={baselineDate}
      />
    )
  }

  return (
    <SmallLoadingWrapper
      isLoading={isLoading}
      isError={isError}
      renderContent={renderCell}
      error={t('error')}
    />
  )
}

PropertyMonitoringMacroKpiChartCell.propTypes = {
  kpiName: PropTypes.string.isRequired,
  marketId: PropTypes.string.isRequired,
  threshold: PropTypes.number.isRequired,
  thresholdType: PropTypes.string.isRequired,
  criticalMovementDirection: PropTypes.oneOf(
    Object.values(propertyMonitoringCriticalMovementDirection),
  ).isRequired,
  valueAtFixing: PropTypes.number,
  baselineDate: PropTypes.string,
}

export default PropertyMonitoringMacroKpiChartCell
