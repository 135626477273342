import {
  BusyIndicatorSize,
  FlexBox,
  Icon,
  Link,
  ObjectStatus,
  Text,
  ValueState,
} from '@fioneer/ui5-webcomponents-react'
import get from 'lodash.get'
import styles from 'components/domains/properties/portfolio/overview/PropertyPortfolioOverviewTableCard.module.css'
import PortfolioTableBusinessPartnerPopover from 'components/domains/properties/portfolio/overview/portfolio-table-bp-popover/PortfolioTableBusinessPartnerPopover'
import LoadingContent from 'components/ui/content/LoadingContent'
import Labeled from 'components/ui/data/Labeled'
import { RequestStateResolver } from 'components/ui/loading/RequestStateResolver'
import AnalyticalTableCell from 'components/ui/tables/analytical/AnalyticalTableCell'
import useCurrentMultiPropertyKpis from 'hooks/services/properties/kpis/useCurrentMultiPropertyKpis'
import { getYearOrYears } from 'utils/YearUnitUtil'

const renderCellWithGroupSize = (value, _rowData, _additionalData, { groupedRows }) => {
  const typeNameGroup = groupedRows?.find((group) => group.groupByVal === value)
  if (typeNameGroup?.isGrouped) {
    const groupSize = typeNameGroup.leafRows?.length
    return groupSize ? `${value} (${groupSize})` : value
  } else {
    return value
  }
}

const getPropertyUuidsFromAggregatedCell = (cell) =>
  cell?.row?.leafRows?.flatMap((leafRow) => leafRow?.original?.uuid)

const getGroupByValFromAggregatedCell = (cell) => cell?.row?.groupByVal
const getGroupByIdFromAggregatedCell = (cell) => cell?.row?.groupByID

const getGranularityValue = (groupById, groupByVal, multiPropertyKpis, subPortfolioKpis) => {
  let multiPropertyKpisValueGroupByVal
  switch (groupById) {
    case 'country':
      multiPropertyKpisValueGroupByVal = multiPropertyKpis?.countryRentRollKpis?.filter(
        (value) => value.countryName === groupByVal,
      )?.[0]
      break
    case 'city':
      multiPropertyKpisValueGroupByVal = multiPropertyKpis?.cityRentRollKpis?.filter(
        (value) => value.city === groupByVal,
      )?.[0]
      break
    case 'type_name':
      multiPropertyKpisValueGroupByVal = multiPropertyKpis?.propertyTypeRentRollKpis?.filter(
        (value) => value.propertyTypeName === groupByVal,
      )?.[0]
      break
    case 'sub_portfolio':
      multiPropertyKpisValueGroupByVal =
        groupByVal === 'No Data'
          ? subPortfolioKpis?.filter((value) => value.subPortfolio === null)?.[0]?.kpis
          : subPortfolioKpis?.filter((value) => value.subPortfolio === groupByVal)?.[0]?.kpis
      break
  }
  return multiPropertyKpisValueGroupByVal
}

export const DescriptionCell = (
  <AnalyticalTableCell aggregatedValue={renderCellWithGroupSize}>
    {({ description, id, typeName, propertyStatus }, { t }) => (
      <>
        <Link href={`/properties/${id}`} design="Emphasized">
          {description}
        </Link>
        <span>{id}</span>
        <Labeled
          label={t('table.columns.property-status')}
          showColon
          className={styles.extraTopMargin}
        >
          <span>
            <RequestStateResolver
              isLoading={propertyStatus?.isLoading ?? false}
              isError={false}
              errorToDisplay={<></>}
              busyIndicatorSize={BusyIndicatorSize.Small}
              center
              renderContent={() => (
                <ObjectStatus
                  inverted={
                    propertyStatus?.valueState && propertyStatus?.valueState !== ValueState.None
                  }
                  state={propertyStatus?.valueState ?? ValueState.None}
                >
                  {propertyStatus?.text ?? '-'}
                </ObjectStatus>
              )}
            />
          </span>
        </Labeled>
        <Labeled
          label={t('table.columns.property-type')}
          showColon
          className={styles.extraTopMargin}
        />
        <span>{typeName}</span>
      </>
    )}
  </AnalyticalTableCell>
)

export const DealPropertyDescriptionCell = (
  <AnalyticalTableCell aggregatedValue={renderCellWithGroupSize}>
    {(
      { description, id, typeName, subPortfolio, propertyStatus, uuid },
      { t, isPdfViewOnDecisionPaper },
    ) => (
      <>
        {!isPdfViewOnDecisionPaper ? (
          <Link href={`/properties/${id}`} target="_blank" design="Emphasized">
            {description}
          </Link>
        ) : (
          <Text>{description}</Text>
        )}
        <span>{id}</span>
        <Labeled
          label={t('table.columns.property-status')}
          showColon
          className={styles.extraTopMargin}
        >
          <span>
            <RequestStateResolver
              isLoading={propertyStatus?.isLoading ?? false}
              isError={false}
              errorToDisplay={<></>}
              busyIndicatorSize={BusyIndicatorSize.Small}
              center
              renderContent={() => (
                <ObjectStatus
                  inverted={
                    propertyStatus?.valueState && propertyStatus?.valueState !== ValueState.None
                  }
                  state={propertyStatus?.valueState ?? ValueState.None}
                >
                  {propertyStatus?.text ?? ''}
                </ObjectStatus>
              )}
            />
          </span>
        </Labeled>
        <Labeled
          label={t('table.columns.property-type')}
          showColon
          className={styles.extraTopMargin}
        >
          <span>{typeName}</span>
        </Labeled>
        <Labeled
          label={t('table.columns.subportfolio')}
          showColon
          className={styles.extraTopMargin}
        >
          <span>{subPortfolio || ''}</span>
        </Labeled>
        <Labeled
          label={t('table.columns.business-partner')}
          showColon
          className={styles.extraTopMargin}
        >
          <PortfolioTableBusinessPartnerPopover propertyUuid={uuid} />
        </Labeled>
      </>
    )}
  </AnalyticalTableCell>
)

export const PropertyStatusCell = (
  <AnalyticalTableCell aggregatedValue={renderCellWithGroupSize}>{() => <></>}</AnalyticalTableCell>
)

export const PropertyTypeCell = (
  <AnalyticalTableCell aggregatedValue={renderCellWithGroupSize}>{() => <></>}</AnalyticalTableCell>
)

export const SubPortfolioCell = (
  <AnalyticalTableCell aggregatedValue={renderCellWithGroupSize}>{() => <></>}</AnalyticalTableCell>
)

const calculateShare = (value, sum) => {
  if (!value || !sum) {
    return 0
  }
  return value / sum
}

export const AllocatedLoanAmountCell = (
  <AnalyticalTableCell alignRight>
    {(
      {
        allocatedLoanAmount,
        allocatedLoanAmountHeadquarter,
        allocatedLoanAmountHeadquarterSum,
        releasePremium,
      },
      { t, formatCurrency, formatPercent },
    ) => (
      <>
        <span>
          {formatCurrency(
            allocatedLoanAmountHeadquarter?.number,
            allocatedLoanAmountHeadquarter?.currencyCode,
          ) || ''}
        </span>
        {allocatedLoanAmount?.currencyCode !== allocatedLoanAmountHeadquarter?.currencyCode && (
          <Labeled label={t('table.columns.original')} showColon>
            {formatCurrency(allocatedLoanAmount?.number, allocatedLoanAmount?.currencyCode) || '-'}
          </Labeled>
        )}
        {allocatedLoanAmountHeadquarter && (
          <Labeled label={t('table.columns.share')} showColon>
            {formatPercent(
              calculateShare(
                allocatedLoanAmountHeadquarter?.number,
                allocatedLoanAmountHeadquarterSum,
              ),
            )}
          </Labeled>
        )}
        {releasePremium !== null && (
          <Labeled label={t('table.columns.release-premium')} showColon>
            {formatPercent(releasePremium) || ''}
          </Labeled>
        )}
      </>
    )}
  </AnalyticalTableCell>
)

export const AggregatedAllocatedLoanAmountCell = (
  <AnalyticalTableCell alignRight renderAggregateRegularly>
    {(
      { allocatedLoanAmountHeadquarter, allocatedLoanAmountHeadquarterSum },
      { t, formatCurrency, formatPercent },
      { cell },
    ) =>
      cell?.value > 0 && (
        <>
          <b>{formatCurrency(cell.value, allocatedLoanAmountHeadquarter?.currencyCode)}</b>
          <Labeled label={t('table.columns.share')} showColon>
            {formatPercent(calculateShare(cell?.value, allocatedLoanAmountHeadquarterSum))}
          </Labeled>
        </>
      )
    }
  </AnalyticalTableCell>
)

export const AddressCell = (
  <AnalyticalTableCell aggregatedValue={renderCellWithGroupSize}>
    {({ address }, { t }) => (
      <>
        <span>{address?.countryName}</span>
        <Labeled label={t('table.columns.postal-code')} showColon>
          {address?.postalCode}
        </Labeled>
        <Labeled label={t('table.columns.city')} showColon>
          {address?.cityName}
        </Labeled>
        <Labeled label={t('table.columns.street')} showColon>
          {address?.streetName}
        </Labeled>
        <Labeled label={t('table.columns.house-no')} showColon>
          {address?.houseId}
        </Labeled>
      </>
    )}
  </AnalyticalTableCell>
)

export const CountryCell = (
  <AnalyticalTableCell aggregatedValue={renderCellWithGroupSize}>{() => <></>}</AnalyticalTableCell>
)

export const CityCell = (
  <AnalyticalTableCell aggregatedValue={renderCellWithGroupSize}>{() => <></>}</AnalyticalTableCell>
)

export const MoneyValueCell = (
  <AnalyticalTableCell alignRight>
    {(
      { valuations, isValuationsLoading },
      { t, formatCurrency, formatPercent, classification },
    ) => {
      const value = get(valuations, classification, null)
      const valueAmount = value?.valueAmount
      const originValueAmount = value?.originValueAmount
      const shares = value?.shares?.sum
      const isValidConversion = value?.isValidConversion
      return (
        <RequestStateResolver
          isError={false}
          errorToDisplay={<></>}
          busyIndicatorSize={BusyIndicatorSize.Small}
          isLoading={isValuationsLoading}
          renderContent={() =>
            valueAmount?.number > 0 && (
              <>
                {isValidConversion && (
                  <span>{formatCurrency(valueAmount.number, valueAmount.currency)} </span>
                )}
                {originValueAmount?.number && (
                  <FlexBox>
                    <Labeled label={t('table.columns.original')} showColon>
                      {formatCurrency(originValueAmount.number, originValueAmount.currency)}
                    </Labeled>
                    {!isValidConversion && (
                      <Icon name="alert" className={styles.conversionErrorIcon} />
                    )}
                  </FlexBox>
                )}
                <Labeled label={t('table.columns.share')} showColon>
                  {formatPercent(shares)}
                </Labeled>
              </>
            )
          }
        />
      )
    }}
  </AnalyticalTableCell>
)

export const AggregatedMoneyValueCell = (
  <AnalyticalTableCell alignRight renderAggregateRegularly>
    {(
      { currencyCode },
      { t, formatCurrency, formatPercent, valuationSum, classification },
      { cell },
    ) => {
      const valuationByClassification = get(valuationSum, classification, null)
      return cell?.value ? (
        <>
          <b>{formatCurrency(cell.value, valuationByClassification?.currency || currencyCode)}</b>
          <Labeled label={t('table.columns.share')} showColon>
            {formatPercent(calculateShare(cell?.value, valuationByClassification?.number))}
          </Labeled>
        </>
      ) : (
        <></>
      )
    }}
  </AnalyticalTableCell>
)

export const LettableAreaCell = (
  <AnalyticalTableCell alignRight>
    {(
      { kpis, isKpisLoading },
      { t, formatNumber, formatPercent, formatAreaUnit, multiPropertyKpis },
    ) => (
      <RequestStateResolver
        busyIndicatorSize={BusyIndicatorSize.Small}
        isError={false}
        errorToDisplay={<></>}
        isLoading={isKpisLoading}
        renderContent={() =>
          kpis?.totalAreaSurface?.value ? (
            <>
              <span>
                {formatNumber(kpis?.totalAreaSurface?.value)}&nbsp;
                {formatAreaUnit(kpis?.totalAreaSurface?.measurementUnit)}
              </span>
              <Labeled label={t('table.columns.share')} showColon>
                {formatPercent(
                  kpis?.totalAreaSurface?.value / multiPropertyKpis?.totalAreaSurface?.value,
                )}
              </Labeled>
              <Labeled label={t('table.columns.rented')} showColon>
                {formatPercent(kpis?.letSurface?.percent)}
              </Labeled>
              <Labeled label={t('table.columns.number-of-units')} showColon>
                {formatNumber(kpis?.totalNumberOfUnits)}
              </Labeled>
            </>
          ) : (
            <></>
          )
        }
      />
    )}
  </AnalyticalTableCell>
)

export const AggregatedLettableAreaCell = (
  <AnalyticalTableCell alignRight renderAggregateRegularly>
    {(
      { kpis, isKpisLoading },
      { t, formatNumber, formatPercent, formatAreaUnit, multiPropertyKpis },
      { cell },
    ) => (
      <RequestStateResolver
        busyIndicatorSize={BusyIndicatorSize.Small}
        isError={false}
        errorToDisplay={<></>}
        isLoading={isKpisLoading}
        renderContent={() => {
          if (cell?.value) {
            return (
              <>
                <b>
                  {formatNumber(cell.value)}&nbsp;
                  {formatAreaUnit(kpis?.totalAreaSurface?.measurementUnit)}
                </b>
                <Labeled label={t('table.columns.share')} showColon>
                  {formatPercent(cell.value / multiPropertyKpis?.totalAreaSurface?.value)}
                </Labeled>
              </>
            )
          } else {
            return <></>
          }
        }}
      />
    )}
  </AnalyticalTableCell>
)

export const CurrentRentalIncomeCell = (
  <AnalyticalTableCell alignRight>
    {({ kpis, isKpisLoading }, { t, formatCurrency, formatPercent, multiPropertyKpis }) => (
      <RequestStateResolver
        busyIndicatorSize={BusyIndicatorSize.Small}
        isError={false}
        errorToDisplay={<></>}
        isLoading={isKpisLoading}
        renderContent={() =>
          kpis?.annualizedCurrentRent?.number ? (
            <>
              <span>
                {formatCurrency(
                  kpis?.annualizedCurrentRent?.number,
                  kpis?.annualizedCurrentRent?.currency,
                )}
              </span>
              {kpis?.originalAnnualizedCurrentRent?.number && (
                <FlexBox>
                  <Labeled label={t('table.columns.original')} showColon>
                    {formatCurrency(
                      kpis?.originalAnnualizedCurrentRent?.number,
                      kpis?.originalAnnualizedCurrentRent?.currency,
                    )}
                  </Labeled>
                </FlexBox>
              )}
              <Labeled label={t('table.columns.share')} showColon>
                {formatPercent(
                  kpis?.annualizedCurrentRent?.number /
                    multiPropertyKpis?.annualizedCurrentRent?.number,
                )}
              </Labeled>
            </>
          ) : (
            <></>
          )
        }
      />
    )}
  </AnalyticalTableCell>
)

export const AggregatedCurrentRentalIncomeCell = (
  <AnalyticalTableCell alignRight renderAggregateRegularly>
    {(
      { _ },
      { t, formatCurrency, formatPercent, multiPropertyKpis, portfolioCurrency },
      { cell },
    ) => {
      const propertyUuids = getPropertyUuidsFromAggregatedCell(cell)
      const {
        isLoading: isLoadingMultiPropertyKpis,
        isError: isErrorMultiPropertyKpis,
        data: aggregatedPropertyKpis,
      } = useCurrentMultiPropertyKpis(
        propertyUuids,
        { keyDates: [multiPropertyKpis?.keyDate], currency: portfolioCurrency },
        { enabled: !!multiPropertyKpis?.keyDate },
      )
      return (
        <LoadingContent
          contentKey={`aggregated-lettable-area-${propertyUuids}`}
          isError={isErrorMultiPropertyKpis}
          isLoading={isLoadingMultiPropertyKpis && !!multiPropertyKpis?.keyDate}
        >
          {aggregatedPropertyKpis?.annualizedCurrentRent?.number ? (
            <>
              <b>
                {formatCurrency(
                  aggregatedPropertyKpis?.annualizedCurrentRent?.number,
                  aggregatedPropertyKpis?.annualizedCurrentRent?.currency,
                )}
              </b>
              <Labeled label={t('table.columns.share')} showColon>
                {formatPercent(
                  aggregatedPropertyKpis?.annualizedCurrentRent?.number /
                    multiPropertyKpis?.annualizedCurrentRent?.number,
                )}
              </Labeled>
            </>
          ) : (
            <></>
          )}
        </LoadingContent>
      )
    }}
  </AnalyticalTableCell>
)

export const AggregatedCurrentRentalIncomeDecisionPaperCell = (
  <AnalyticalTableCell alignRight renderAggregateRegularly>
    {(
      { _ },
      { t, formatCurrency, formatPercent, multiPropertyKpis, subPortfolioKpis },
      { cell },
    ) => {
      const groupByVal = getGroupByValFromAggregatedCell(cell)
      const groupById = getGroupByIdFromAggregatedCell(cell)
      const multiPropertyKpisValueGroupByVal = getGranularityValue(
        groupById,
        groupByVal,
        multiPropertyKpis,
        subPortfolioKpis,
      )
      return multiPropertyKpisValueGroupByVal?.annualizedCurrentRent?.number ? (
        <>
          <b>
            {formatCurrency(
              multiPropertyKpisValueGroupByVal?.annualizedCurrentRent?.number,
              multiPropertyKpisValueGroupByVal?.annualizedCurrentRent?.currency,
            )}
          </b>
          <Labeled label={t('table.columns.share')} showColon>
            {formatPercent(
              multiPropertyKpisValueGroupByVal?.annualizedCurrentRent?.number /
                multiPropertyKpis?.annualizedCurrentRent?.number,
            )}
          </Labeled>
        </>
      ) : null
    }}
  </AnalyticalTableCell>
)

export const MarketRentCell = (
  <AnalyticalTableCell alignRight>
    {({ kpis, isKpisLoading, tegovaRating }, { t, formatCurrency }) => (
      <RequestStateResolver
        busyIndicatorSize={BusyIndicatorSize.Small}
        isError={false}
        errorToDisplay={<></>}
        isLoading={isKpisLoading}
        renderContent={() =>
          kpis?.totalMarketRent?.number || tegovaRating ? (
            <>
              <span>
                {kpis?.totalMarketRent?.number && kpis?.totalMarketRent?.currency
                  ? formatCurrency(kpis?.totalMarketRent?.number, kpis?.totalMarketRent?.currency)
                  : '-'}
              </span>
              {kpis?.originalTotalMarketRent?.number && (
                <FlexBox>
                  <Labeled label={t('table.columns.original')} showColon>
                    {formatCurrency(
                      kpis?.originalAnnualizedCurrentRent?.number,
                      kpis?.originalAnnualizedCurrentRent?.currency,
                    )}
                  </Labeled>
                </FlexBox>
              )}
              <Labeled label={t('table.columns.tegova-rating')} showColon>
                <span>{tegovaRating || '-'}</span>
              </Labeled>
              <span>{tegovaRating ? `- ${t(`rating.label.${tegovaRating}`)}` : ''}</span>
            </>
          ) : null
        }
      />
    )}
  </AnalyticalTableCell>
)

export const AggregatedMarketRentDecisionPaperCell = (
  <AnalyticalTableCell alignRight renderAggregateRegularly>
    {({ _ }, { multiPropertyKpis, formatCurrency, subPortfolioKpis }, { cell }) => {
      const groupByVal = getGroupByValFromAggregatedCell(cell)
      const groupById = getGroupByIdFromAggregatedCell(cell)
      const multiPropertyKpisValueGroupByVal = getGranularityValue(
        groupById,
        groupByVal,
        multiPropertyKpis,
        subPortfolioKpis,
      )
      return multiPropertyKpisValueGroupByVal?.totalMarketRent?.number ? (
        <b>
          {formatCurrency(
            multiPropertyKpisValueGroupByVal?.totalMarketRent?.number,
            multiPropertyKpisValueGroupByVal?.totalMarketRent?.currency,
          )}
        </b>
      ) : null
    }}
  </AnalyticalTableCell>
)

export const WaultCell = (
  <AnalyticalTableCell alignRight>
    {({ kpis, isKpisLoading }, { t, formatWault }) => (
      <RequestStateResolver
        busyIndicatorSize={BusyIndicatorSize.Small}
        isError={false}
        errorToDisplay={<></>}
        isLoading={isKpisLoading}
        renderContent={() =>
          kpis?.waultToBreakInYears ? (
            <>
              <span>{`${formatWault(kpis?.waultToBreakInYears)} ${getYearOrYears(
                kpis?.waultToBreakInYears,
              )}`}</span>
              <Labeled label={t('table.columns.wault-expiry')} showColon />
              <span>{`${formatWault(kpis?.waultToExpiryInYears)} ${getYearOrYears(
                kpis?.waultToExpiryInYears,
              )}`}</span>
            </>
          ) : (
            <></>
          )
        }
      />
    )}
  </AnalyticalTableCell>
)

export const AggregatedWaultCell = (
  <AnalyticalTableCell alignRight renderAggregateRegularly>
    {({ _ }, { t, formatWault, multiPropertyKpis, portfolioCurrency }, { cell }) => {
      const propertyUuids = getPropertyUuidsFromAggregatedCell(cell)

      const {
        isLoading: isLoadingMultiPropertyKpis,
        isError: isErrorMultiPropertyKpis,
        data: aggregatedPropertyKpis,
      } = useCurrentMultiPropertyKpis(
        propertyUuids,
        { keyDates: [multiPropertyKpis?.keyDate], currency: portfolioCurrency },
        { enabled: !!multiPropertyKpis?.keyDate },
      )
      return (
        <LoadingContent
          contentKey={`aggregated-wault-${propertyUuids}`}
          isError={isErrorMultiPropertyKpis}
          isLoading={isLoadingMultiPropertyKpis && !!multiPropertyKpis?.keyDate}
        >
          {aggregatedPropertyKpis?.waultToBreakInYears ? (
            <>
              <b>
                {`${formatWault(aggregatedPropertyKpis?.waultToBreakInYears)} ${getYearOrYears(
                  aggregatedPropertyKpis?.waultToBreakInYears,
                )}`}
              </b>
              <Labeled label={t('table.columns.wault-expiry')} showColon />
              <span>
                {`${formatWault(aggregatedPropertyKpis?.waultToExpiryInYears)} ${getYearOrYears(
                  aggregatedPropertyKpis?.waultToExpiryInYears,
                )}`}
              </span>
            </>
          ) : (
            <></>
          )}
        </LoadingContent>
      )
    }}
  </AnalyticalTableCell>
)

export const AggregatedWaultDecisionPaperCell = (
  <AnalyticalTableCell alignRight renderAggregateRegularly>
    {({ _ }, { t, formatWault, multiPropertyKpis, subPortfolioKpis }, { cell }) => {
      const groupByVal = getGroupByValFromAggregatedCell(cell)
      const groupById = getGroupByIdFromAggregatedCell(cell)
      const multiPropertyKpisValueGroupByVal = getGranularityValue(
        groupById,
        groupByVal,
        multiPropertyKpis,
        subPortfolioKpis,
      )
      return multiPropertyKpisValueGroupByVal?.waultToBreakInYears ? (
        <>
          <b>
            {`${formatWault(
              multiPropertyKpisValueGroupByVal?.waultToBreakInYears,
            )} ${getYearOrYears(multiPropertyKpisValueGroupByVal?.waultToBreakInYears)}`}
          </b>
          <Labeled label={t('table.columns.wault-expiry')} showColon />
          <span>
            {`${formatWault(
              multiPropertyKpisValueGroupByVal?.waultToExpiryInYears,
            )} ${getYearOrYears(multiPropertyKpisValueGroupByVal?.waultToExpiryInYears)}`}
          </span>
        </>
      ) : null
    }}
  </AnalyticalTableCell>
)
