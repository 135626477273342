import {
  Input,
  InputType,
  Label,
  Link,
  Table,
  TableCell,
  TableColumn,
  TableRow,
  TableRowType,
} from '@fioneer/ui5-webcomponents-react'
import _ from 'lodash'
import PropTypes from 'prop-types'
import { useRef } from 'react'
import { useTranslation } from 'react-i18next'
import { useLocation } from 'react-router-dom'
import styles from 'components/domains/properties/general-information/keyData/PropertyKeyDataTable.module.css'
import FormattedNumberInput from 'components/ui/input/FormattedNumberInput'
import { useCustomizableCurrencyFormatter, useNumberFormatter } from 'hooks/i18n/useI18n'

const PropertyKeyDataTable = ({
  isEditMode,
  rentRollValues,
  valuationResultsValues,
  handleOnChange,
}) => {
  const { t: tRentRollValueTypes } = useTranslation('translation', {
    keyPrefix: 'pages.properties.key-data-card.table',
  })
  const formattedNumberInputRef = useRef()

  const kebabizeValueTypes = (str) =>
    str.replace(/[A-Z]+(?![a-z])|[A-Z]/g, ($, ofs) => (ofs ? '-' : '') + $.toLowerCase())

  const { pathname: currentPathname } = useLocation()
  const pathname = currentPathname.replace(/\/general-information\/?.*/, '')

  const formatCurrency = useCustomizableCurrencyFormatter()
  const formatNumber = useNumberFormatter({
    maximumFractionDigits: 2,
  })

  const { rentRollCurrency, rentRollMeasurementUnit, rentRollValueTypes } = rentRollValues
  const { valuationCurrency, valuationMeasurementUnit, valuationValueTypes } =
    valuationResultsValues

  const renderInput = (
    unit = '',
    value = '',
    key = '',
    isFormattedNumber = false,
    readOnly = false,
    isInteger = false,
  ) => {
    const iconProp = unit
      ? {
          icon: <Label id={unit}>{unit}</Label>,
        }
      : {}
    const integerProps = isInteger
      ? { maximumFractionDigits: 0, minimumFractionDigits: 0 }
      : { maximumFractionDigits: 2, minimumFractionDigits: 0 }
    const additionalProps = { ...iconProp, ...integerProps }

    const input = isFormattedNumber ? (
      <FormattedNumberInput
        id={`property-key-data-table-input-read-${readOnly}-${value}${unit}`}
        value={value}
        className={styles.cellInput}
        type={InputType.Text}
        readonly={readOnly}
        onChange={(parsedValue) => {
          const validationFunction = () => formattedNumberInputRef?.current?.isValid
          handleOnChange(key, parsedValue, undefined, undefined, validationFunction)
        }}
        ref={formattedNumberInputRef}
        {...additionalProps}
      />
    ) : (
      <Input
        id={`property-key-data-table-input-read-${readOnly}-${value}${unit}`}
        value={value}
        className={styles.cellInput}
        type={readOnly ? InputType.Text : InputType.Number}
        placeholder=""
        readonly={readOnly}
        onInput={(event) => handleOnChange(key, event.target.value)}
        {...iconProp}
      />
    )
    return input
  }

  const isTruthyOrZero = (input) => !!input || input === 0

  const renderTableRow = () => {
    const tableRows = (
      <>
        {Object.keys(rentRollValueTypes).map((rentRollValue) => {
          let rentRollValueCell, valuationResultsValueCell
          const valuationInput = valuationValueTypes[rentRollValue]
          const rentRollInput = rentRollValueTypes[rentRollValue]

          const renderActualRentAmount = (input, currency) =>
            isTruthyOrZero(input) && currency ? formatCurrency(input, currency) : ''
          const renderTotalArea = (input, measureUnit) =>
            isTruthyOrZero(input) && measureUnit ? formatNumber(input) + ' ' + measureUnit : ''
          const renderNumberOfUnits = (input) => (isTruthyOrZero(input) ? formatNumber(input) : '')
          const renderOtherValues = (input) => (input ? input : '')
          const renderInputWithValueOrDefault = (
            unit,
            value,
            key,
            isFormattedNumber = false,
            readOnly = false,
            isInteger = false,
          ) =>
            isTruthyOrZero(value)
              ? renderInput(unit, value, key, isFormattedNumber, readOnly, isInteger)
              : renderInput(unit, '', key, isFormattedNumber, readOnly, isInteger)

          switch (rentRollValue) {
            case 'totalArea':
              valuationResultsValueCell = isEditMode
                ? renderInputWithValueOrDefault(
                    valuationMeasurementUnit,
                    valuationInput,
                    rentRollValue,
                    true,
                    false,
                  )
                : renderTotalArea(valuationInput, valuationMeasurementUnit)
              rentRollValueCell = isEditMode
                ? renderInputWithValueOrDefault(
                    rentRollMeasurementUnit,
                    rentRollInput,
                    '',
                    false,
                    true,
                  )
                : renderTotalArea(rentRollInput, rentRollMeasurementUnit)
              break
            case 'totalUnitsNumber':
              valuationResultsValueCell = isEditMode
                ? renderInputWithValueOrDefault(
                    '',
                    valuationInput,
                    'numberOfUnits',
                    true,
                    false,
                    true,
                  )
                : renderNumberOfUnits(valuationInput)
              rentRollValueCell = isEditMode
                ? renderInputWithValueOrDefault('', rentRollInput, '', false, true)
                : renderNumberOfUnits(rentRollInput)
              break
            case 'actualRentAmount':
              valuationResultsValueCell = isEditMode
                ? renderInputWithValueOrDefault(
                    valuationCurrency,
                    valuationInput,
                    rentRollValue,
                    true,
                    false,
                  )
                : renderActualRentAmount(valuationInput, valuationCurrency)
              rentRollValueCell = isEditMode
                ? renderInputWithValueOrDefault(rentRollCurrency, rentRollInput, '', false, true)
                : renderActualRentAmount(rentRollInput, rentRollCurrency)
              break
            case 'keyDate':
              valuationResultsValueCell = ''
              rentRollValueCell = isEditMode
                ? (rentRollValueCell = renderInputWithValueOrDefault(
                    '',
                    rentRollInput,
                    '',
                    false,
                    true,
                  ))
                : renderOtherValues(rentRollInput)
              break
            default:
              valuationResultsValueCell = isEditMode
                ? renderInputWithValueOrDefault('', valuationInput, rentRollValue, false, true)
                : renderOtherValues(valuationInput)
              rentRollValueCell = isEditMode
                ? renderInputWithValueOrDefault('', rentRollInput, '', false, true)
                : renderOtherValues(rentRollInput)
          }

          return (
            <TableRow id={rentRollValue} key={rentRollValue} type={TableRowType.Inactive}>
              <TableCell>{tRentRollValueTypes(kebabizeValueTypes(rentRollValue))}</TableCell>
              <TableCell className={styles.valueColumn}>{valuationResultsValueCell}</TableCell>
              <TableCell className={styles.valueColumn}>{rentRollValueCell}</TableCell>
            </TableRow>
          )
        })}
      </>
    )
    return tableRows
  }
  const tableStyle = !isEditMode ? styles.separatorReadMode : styles.separatorEditMode
  return (
    <>
      <div className={tableStyle}>
        <Table
          columns={
            <>
              <TableColumn>{tRentRollValueTypes('value-type')}</TableColumn>
              <TableColumn>
                <div className={styles.valueColumn}>
                  <Link id="valuationLink" href={pathname + '/valuation'}>
                    {tRentRollValueTypes('valuation-results')}
                  </Link>
                </div>
              </TableColumn>
              <TableColumn>
                <div className={styles.valueColumn}>
                  <Link id="rentRollLink" href={pathname + '/rent-roll'}>
                    {tRentRollValueTypes('rent-roll-value')}
                  </Link>
                </div>
              </TableColumn>
            </>
          }
        >
          {renderTableRow()}
        </Table>
      </div>
    </>
  )
}

PropertyKeyDataTable.propTypes = {
  isEditMode: PropTypes.bool.isRequired,
  rentRollValues: PropTypes.object,
  valuationResultsValues: PropTypes.object,
  handleOnChange: PropTypes.func,
}
export default PropertyKeyDataTable
