import PropTypes from 'prop-types'
import React from 'react'
import { kpisAllowedOperations } from 'api/kpis/kpisAllowedOperations'
import KpiOverviewChartCard from 'components/domains/kpis/KpiOverviewChartCard'
import KpiAdjustmentCards from 'components/domains/kpis/adjustment/KpiAdjustmentCards'
import Card from 'components/ui/card/Card'
import { ErrorDataUnavailableInContent } from 'components/ui/errors/ErrorDataUnavailableInContent'
import CWPLayout from 'components/ui/layout/CWPLayout'
import { RequestStateResolver } from 'components/ui/loading/RequestStateResolver'
import KpiPermissionWrapper from 'routes/kpis/KpiPermissionWrapper'

const KpiDetailsContent = ({
  referenceEntityType,
  referenceEntityId,
  kpiId,
  isFetching = false,
  isError = false,
}) => (
  <KpiPermissionWrapper requiredOperation={kpisAllowedOperations.readKpi}>
    <RequestStateResolver
      isLoading={isFetching}
      isError={isError}
      errorToDisplay={
        <Card>
          <ErrorDataUnavailableInContent />
        </Card>
      }
      renderContent={() => (
        <CWPLayout>
          <CWPLayout.Full>
            <KpiOverviewChartCard
              referenceEntityType={referenceEntityType}
              referenceEntityId={referenceEntityId}
              kpiId={kpiId}
            />
          </CWPLayout.Full>
          <KpiAdjustmentCards
            referenceEntityType={referenceEntityType}
            referenceEntityId={referenceEntityId}
            kpiId={kpiId}
          />
        </CWPLayout>
      )}
    />
  </KpiPermissionWrapper>
)

KpiDetailsContent.propTypes = {
  referenceEntityType: PropTypes.string.isRequired,
  referenceEntityId: PropTypes.string.isRequired,
  kpiId: PropTypes.string.isRequired,
  isFetching: PropTypes.bool,
  isError: PropTypes.bool,
}

export default KpiDetailsContent
