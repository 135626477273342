import { MessageStrip } from '@fioneer/ui5-webcomponents-react'
import PropTypes from 'prop-types'
import { useContext, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import documentPermissions from 'api/documents/DocumentPermissions'
import DocumentTypes from 'components/domains/documents/DocumentTypes'
import UploadDocumentButton from 'components/domains/documents/UploadDocumentButton'
import DocumentsTable from 'components/domains/documents/documents-table/DocumentsTable'
import mapDocumentsForTable from 'components/domains/documents/documents-table/mapDocumentsForTable'
import {
  labelNewDocuments,
  labelReferencedDocuments,
} from 'components/domains/markets/detail/cards/MarketDocumentTableFunctions'
import styles from 'components/domains/properties/documents/PropertyDocuments.module.css'
import CreatePropertyEventAction from 'components/domains/properties/header-actions/CreatePropertyEventAction'
import Card from 'components/ui/card/Card'
import EntityTypeAndIdWithClipboard from 'components/ui/entity-info/EntityTypeAndIdWithClipboard'
import createSegmentedHeaderActions from 'components/ui/page/createSegmentedHeaderActions'
import LoadingStateWrapper from 'components/ui/screens/LoadingStateWrapper'
import UserFavoriteIcon from 'components/ui/user-favorite/UserFavoriteIcon'
import { cwpEntityTypes } from 'constants/cwpEntityTypes'
import { useDocumentsTableColumns } from 'hooks/services/documents/documents-table/useDocumentsTableColumnDefinitions'
import useDocuments from 'hooks/services/documents/useDocuments'
import useDocumentsAllowedOperations from 'hooks/services/documents/useDocumentsAllowedOperations'
import { PropertyContext } from 'routes/properties/PropertyContext'
import PropertyPage from 'routes/properties/PropertyPage'

const PropertyDocuments = ({ propertyDescription, breadcrumbs, status, additionalStatuses }) => {
  const [showMessageStrip, setShowMessageStrip] = useState(true)
  /** @type {string[]} */
  const initialUploadedDocumentIds = []
  const [uploadedDocumentIds, setUploadedDocumentIds] = useState(initialUploadedDocumentIds)
  const { property } = useContext(PropertyContext)
  const { t } = useTranslation('translation', {
    keyPrefix: 'pages.property-overview.documents',
  })
  const {
    data: documentRoot = [],
    isLoading,
    isError,
  } = useDocuments({ entityId: property.uuid, entityType: DocumentTypes.Property })

  const labeledDocuments = useMemo(
    () => labelNewDocuments(documentRoot, uploadedDocumentIds),
    [documentRoot, uploadedDocumentIds],
  )

  const referencedDocuments = useMemo(
    () => labelReferencedDocuments(labeledDocuments),
    [labeledDocuments],
  )

  const preparedDocumentRoot = useMemo(
    () => mapDocumentsForTable(referencedDocuments),
    [referencedDocuments],
  )

  const {
    data: allowedOperationsData,
    isLoading: isLoadingAllowedOperations,
    isError: isAllowedOperationsError,
  } = useDocumentsAllowedOperations()

  const hasDocumentReadPermission =
    allowedOperationsData?.allowedOperations.includes(documentPermissions.Read) ?? false
  const hasDocumentUploadPermission =
    allowedOperationsData?.allowedOperations.includes(documentPermissions.Create) ?? false

  const { columns, formatDate } = useDocumentsTableColumns()
  const [files, setFiles] = useState(undefined)

  const handleOnClose = () => {
    setFiles(undefined)
  }

  const handleOnDrop = ({ files }) => {
    setFiles(files)
  }

  const subtitle = <EntityTypeAndIdWithClipboard id={property.id} />

  const markFavoriteAction = useMemo(
    () => (
      <UserFavoriteIcon
        key="property-details-user-favorite-icon"
        entityId={property.uuid}
        entityType={cwpEntityTypes.PROPERTY}
      />
    ),
    [property.uuid],
  )

  if (!hasDocumentReadPermission) return <></>
  return (
    <PropertyPage
      pageTitle={propertyDescription}
      id={property.id}
      subtitle={subtitle}
      breadcrumbs={breadcrumbs}
      status={status}
      additionalStatuses={additionalStatuses}
      actions={createSegmentedHeaderActions(
        [
          hasDocumentUploadPermission && (
            <UploadDocumentButton
              type={DocumentTypes.Property}
              initialState={{
                open: !!files?.length,
                files,
                selectedEntities: [
                  { id: property.uuid, name: property.description, displayId: property.id },
                ],
              }}
              onUploadFinished={(newDocumentIds) => setUploadedDocumentIds(newDocumentIds)}
              onClose={handleOnClose}
              key="upload-document"
            />
          ),
        ],
        [<CreatePropertyEventAction key="create-event-action" />, markFavoriteAction],
      )}
    >
      {showMessageStrip && (
        <MessageStrip className={styles.infoStrip} onClose={() => setShowMessageStrip(false)}>
          {t('maybe-not-all-document-are-shown')}
        </MessageStrip>
      )}
      <Card>
        <LoadingStateWrapper
          isLoading={isLoading || isLoadingAllowedOperations}
          isError={isError || isAllowedOperationsError}
        >
          <div className={styles.content}>
            <div>
              <DocumentsTable
                columns={columns}
                folders={preparedDocumentRoot.subFolders}
                title={t('title')}
                formatDate={formatDate}
                handleOnDrop={handleOnDrop}
                hasDocumentUploadPermission={hasDocumentUploadPermission}
              />
            </div>
          </div>
        </LoadingStateWrapper>
      </Card>
    </PropertyPage>
  )
}

PropertyDocuments.propTypes = {
  propertyDescription: PropTypes.string.isRequired,
  breadcrumbs: PropTypes.arrayOf(
    PropTypes.shape({
      text: PropTypes.string.isRequired,
      href: PropTypes.string,
    }),
  ),
  status: PropTypes.shape({
    text: PropTypes.string.isRequired,
    valueState: PropTypes.string,
  }),
  additionalStatuses: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      text: PropTypes.string.isRequired,
      valueState: PropTypes.string,
    }),
  ),
}

export default PropertyDocuments
