import isNil from 'lodash.isnil'
import PropTypes from 'prop-types'
import React from 'react'
import styles from 'components/domains/kpis/chart/KpiUnit.module.css'
import { useCustomizableCurrencyFormatter, usePercentageFormatter } from 'hooks/i18n/useI18n'

const EMPTY_VALUE = '-'

const KpiUnit = ({ unit, value }) => {
  const formatCurrency = useCustomizableCurrencyFormatter()
  const formatPercentage = usePercentageFormatter({
    maximumFractionDigits: 2,
    minimumFractionDigits: 2,
  })

  if (!isNil(value) && unit?.type === 'CURRENCY') {
    return (
      <span className={styles.noWrap}>
        {formatCurrency(value, unit.code, {
          currencyDisplay: 'code',
        })}
      </span>
    )
  }

  if (!isNil(value) && unit?.type === 'PERCENT') {
    return <span>{formatPercentage(value / 100)}</span>
  }
  return <>{EMPTY_VALUE}</>
}

KpiUnit.propTypes = {
  unit: PropTypes.shape({
    type: PropTypes.string,
    code: PropTypes.string,
  }).isRequired,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
}

export default KpiUnit
