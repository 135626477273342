import { AnalyticalTableScaleWidthMode } from '@fioneer/ui5-webcomponents-react'
import PropTypes from 'prop-types'
import { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { TILE_VERSION as tileVersion } from 'components/domains/business-events-and-tasks/decision-paper/tiles/constant'
import NoPropertyAssignedToDealInfo from 'components/domains/business-events-and-tasks/decision-paper/tiles/property/NoPropertyAssignedToDealInfo'
import PropertiesOverviewTableCardHeader from 'components/domains/business-events-and-tasks/decision-paper/tiles/property/asset-valuation-overview/shared/AssetValuationOverviewPropertiesOverviewTableCardHeader'
import AnalyticalTableWithToolbar from 'components/domains/business-events-and-tasks/decision-paper/tiles/property/asset-valuation-overview/shared/analytical/AssetValuationOverviewAnalyticalTableWithToolbar'
import useDealPropertiesTableColumns from 'components/domains/business-events-and-tasks/decision-paper/tiles/property/asset-valuation-overview/shared/useAssetValuationOverviewDealPropertiesTableColumns'

const AssetValuationOverviewTileViewModeV2 = ({ tileId, isPdfView }) => {
  const tileState = useSelector((state) => state.decisionPaper.tilesOverview.tiles[tileId])
  const {
    data: {
      tableData,
      multiPropertyKpis,
      valuationSum,
      subPortfolioKpis,
      properties,
      noProperties,
    },
  } = tileState

  const { t: tTable } = useTranslation('decisionPaper', {
    keyPrefix: 'tiles.asset-valuation-overview.table',
  })

  const { data: tableColumns } = useDealPropertiesTableColumns({
    multiPropertyKpis,
    valuationSum,
    properties,
    isDecisionPaperAssetValuationOverviewTile: true,
    subPortfolioKpis,
    isPdfViewOnDecisionPaper: isPdfView,
    version: tileVersion.V2,
  })

  const pdfViewTableColumns = useMemo(() => {
    const propertyInfoColumnsLength = 4
    const propertyInfo = tableColumns.slice(0, propertyInfoColumnsLength)

    const propertyAddressColumnsLength = 3
    const propertyAddress = tableColumns.slice(
      propertyInfoColumnsLength,
      propertyInfoColumnsLength + propertyAddressColumnsLength,
    )

    const pdfViewColumns = []
    pdfViewColumns.push([...propertyInfo, ...propertyAddress])

    const columnLength = 2
    for (
      let i = propertyInfoColumnsLength + propertyAddressColumnsLength;
      i < tableColumns.length;
      i = i + columnLength
    ) {
      pdfViewColumns.push([...propertyInfo, ...tableColumns.slice(i, i + columnLength)])
    }

    return pdfViewColumns
  }, [tableColumns])

  const assetValuationOverviewTable = (
    <>
      <PropertiesOverviewTableCardHeader
        totalValuation={valuationSum}
        multiPropertyKpis={multiPropertyKpis}
        isDecisionPaper={true}
      />
      <AnalyticalTableWithToolbar
        columns={tableColumns}
        data={tableData ?? []}
        headerRowHeight={40}
        rowHeight={180}
        scaleWidthMode={AnalyticalTableScaleWidthMode.Default}
        adjustTableHeightOnPopIn
        disableColumnPopover={true}
        groupable={!isPdfView}
        filterable={!isPdfView}
        isPdfView={isPdfView}
        minRows={1}
        title={tTable('title')}
        noDataText={tTable('no-data')}
      />
    </>
  )

  const pdfViewTable = (
    <>
      {pdfViewTableColumns.map((subTableColumns, index) => (
        <div key={`asset-valuation-overview-table-pdf-view-part-${index}`}>
          {index === 0 && (
            <PropertiesOverviewTableCardHeader
              totalValuation={valuationSum}
              multiPropertyKpis={multiPropertyKpis}
              isDecisionPaper={true}
            />
          )}
          <AnalyticalTableWithToolbar
            columns={subTableColumns}
            data={tableData ?? []}
            headerRowHeight={40}
            rowHeight={180}
            adjustTableHeightOnPopIn
            disableColumnPopover={true}
            groupable={!isPdfView}
            filterable={!isPdfView}
            isPdfView={isPdfView}
            minRows={1}
            title={tTable('title')}
            noDataText={tTable('no-data')}
          />
        </div>
      ))}
    </>
  )

  if (noProperties) {
    return <NoPropertyAssignedToDealInfo />
  }
  return !isPdfView ? assetValuationOverviewTable : pdfViewTable
}

AssetValuationOverviewTileViewModeV2.propTypes = {
  tileId: PropTypes.string.isRequired,
  isPdfView: PropTypes.bool.isRequired,
}

export default AssetValuationOverviewTileViewModeV2
