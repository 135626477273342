import {
  IllustratedMessage,
  IllustrationMessageType,
  Title,
  TitleLevel,
} from '@fioneer/ui5-webcomponents-react'
import PropTypes from 'prop-types'
import { useCallback, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { getKpiForestFromKpiList } from 'components/domains/kpis/KpiUtils'
import KpiAdjustmentCard from 'components/domains/kpis/adjustment/KpiAdjustmentCard'
import styles from 'components/domains/kpis/adjustment/KpiAdjustmentCards.module.css'
import CWPLayout from 'components/ui/layout/CWPLayout'
import { RequestStateResolver } from 'components/ui/loading/RequestStateResolver'
import useKpis from 'hooks/services/kpis/useKpis'

const KpiAdjustmentCards = ({ referenceEntityType, referenceEntityId, kpiId }) => {
  const { t } = useTranslation('translation')

  const {
    data: { kpis: kpiList = [] } = {},
    isError,
    isFetching,
  } = useKpis(referenceEntityType, referenceEntityId)

  const kpiForest = useMemo(() => getKpiForestFromKpiList(kpiList), [kpiList])

  const kpiAdjustmentsMapper = useCallback(
    ({ code, name, adjustments }) =>
      adjustments.map((adjustment) => ({
        id: adjustment.id,
        kpiCode: code,
        kpiName: name,
        validFrom: adjustment.validFrom,
        validTo: adjustment.validTo,
      })),
    [],
  )

  const resolveParentAdjustments = useCallback(
    (parentIds) => {
      const parents = kpiForest.filter(({ id }) => parentIds.includes(id) && id !== kpiId) || []
      return parents.flatMap(kpiAdjustmentsMapper)
    },
    [kpiAdjustmentsMapper, kpiForest, kpiId],
  )

  const selectedKpi = useMemo(() => kpiForest.find(({ id }) => id === kpiId), [kpiForest, kpiId])
  const selectedSubKpis = useMemo(
    () =>
      selectedKpi?.children?.map((child) => ({
        ...child,
        parentAdjustments: resolveParentAdjustments(child.parents),
      })) || [],
    [resolveParentAdjustments, selectedKpi],
  )

  const [allAdjustments, mainKpiAdjustments] = useMemo(() => {
    const _mainKpiAdjustments = selectedKpi.adjustments.map((adjustment) => ({
      id: adjustment.id,
      kpiCode: selectedKpi.code,
      kpiName: selectedKpi.name,
      validFrom: adjustment.validFrom,
      validTo: adjustment.validTo,
    }))
    const subKpiAdjustments = selectedSubKpis.flatMap(kpiAdjustmentsMapper)
    return [_mainKpiAdjustments.concat(subKpiAdjustments), _mainKpiAdjustments]
  }, [selectedKpi, selectedSubKpis, kpiAdjustmentsMapper])

  return (
    <RequestStateResolver
      center
      isLoading={isFetching}
      isError={isError}
      errorToDisplay={
        <IllustratedMessage
          name={IllustrationMessageType.UnableToLoad}
          titleText={t('app.loading.error.data-unavailable')}
        />
      }
      renderContent={() => (
        <>
          {selectedKpi && (
            <>
              <CWPLayout.Full>
                <Title level={TitleLevel.H3} className={styles.sectionHeader}>
                  {selectedKpi.name}
                </Title>
              </CWPLayout.Full>
              <CWPLayout.Full>
                <KpiAdjustmentCard
                  kpi={selectedKpi}
                  referenceEntityType={referenceEntityType}
                  referenceEntityId={referenceEntityId}
                  allAdjustments={allAdjustments} // including Main KPI + all Sub KPI adjustments
                />
              </CWPLayout.Full>
            </>
          )}
          {selectedSubKpis && selectedSubKpis.length > 0 && (
            <>
              <CWPLayout.Full>
                <Title level={TitleLevel.H3} className={styles.sectionHeader}>{`${t(
                  'components.kpis.details.adjustments.sub-kpis-of',
                )} ${selectedKpi.name}`}</Title>
              </CWPLayout.Full>
              {selectedSubKpis.map((kpi) => (
                <CWPLayout.Full key={kpi.code}>
                  <KpiAdjustmentCard
                    kpi={kpi}
                    referenceEntityType={referenceEntityType}
                    referenceEntityId={referenceEntityId}
                    allAdjustments={mainKpiAdjustments
                      .concat(kpi.parentAdjustments)
                      .concat(kpi.adjustments)} // including the Main KPI + all other Main KPI adjustments where Sub KPI is referenced
                  />
                </CWPLayout.Full>
              ))}
            </>
          )}
        </>
      )}
    />
  )
}

KpiAdjustmentCards.propTypes = {
  referenceEntityType: PropTypes.string.isRequired,
  referenceEntityId: PropTypes.string.isRequired,
  kpiId: PropTypes.string.isRequired,
}

export default KpiAdjustmentCards
