import {
  Button,
  ButtonDesign,
  FlexBox,
  FlexBoxAlignItems,
  Icon,
  MultiInput,
  Token,
} from '@fioneer/ui5-webcomponents-react'
import '@ui5/webcomponents/dist/features/InputSuggestions.js'
import PropTypes from 'prop-types'
import { useCallback, useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import BusinessPartnerCreateDialog from 'components/domains/business-partners/BusinessPartnerCreateDialog'
import styles from 'components/domains/business-partners/BusinessPartnerSearchDialog.module.css'
import BusinessPartnerSearchTable from 'components/domains/business-partners/BusinessPartnerSearchTable'
import Dialog, {
  DialogPrimaryButton,
  DialogSecondaryButton,
  DialogSize,
} from 'components/ui/dialog/Dialog'

const noManualInputAllowedLength = 0

const BusinessPartnerSearchDialog = ({
  open,
  initialSearch,
  onClose,
  onChange,
  withCreateOption = true,
  createDialogOptions = {},
  translatedTypeName,
  initialRoles = [],
  initialBusinessPartners = [],
  initialExcludeInactive = false,
  isMultiSelect = false,
}) => {
  const { t } = useTranslation(undefined, {
    keyPrefix: 'components.business-partner.dialog-search',
  })
  const { t: tNoPrefix } = useTranslation()
  const [selectedBusinessPartners, setSelectedBusinessPartners] = useState(
    initialBusinessPartners ?? [],
  )

  const [isBeforeSearch, setIsBeforeSearch] = useState(!initialSearch)
  const [isCreateDialogOpen, setIsCreateDialogOpen] = useState(false)

  const translatedType = translatedTypeName ?? t('business-partner')

  const handleSelect = useCallback(
    (newSelection) => {
      setIsBeforeSearch(true)
      onChange?.(newSelection)
      onClose?.()
    },
    [onChange, onClose],
  )

  const handleCancel = useCallback(() => {
    setIsBeforeSearch(true)
    onClose?.()
  }, [onClose])

  const handleCreateClick = useCallback(() => {
    setIsCreateDialogOpen(true)
  }, [])

  const handleCreateChange = useCallback(
    (newBusinessPartner) => {
      const newSelectedBusinessPartners = [...selectedBusinessPartners, newBusinessPartner]
      setSelectedBusinessPartners(newSelectedBusinessPartners)
      handleSelect(newSelectedBusinessPartners)
    },
    [handleSelect, selectedBusinessPartners],
  )

  useEffect(() => {
    if (open) {
      setSelectedBusinessPartners(initialBusinessPartners ?? [])
    }
    // since this dialog is not always rendered conditionally we need to trigger this effect
    // when the open flag changes but not necessarily when the
    // initialBusinessPartners change (that would magically select or deselect BPs without the user clicking somewhere)
  }, [open])

  const selectedBusinessPartnersToken = useMemo(
    () =>
      selectedBusinessPartners.map(({ id, name }) => (
        <Token key={id} text={name} data-id={id} closeIcon={<Icon name="decline" />} />
      )),
    [selectedBusinessPartners],
  )

  const handleTokenDelete = useCallback(({ detail: { token } }) => {
    const idToDelete = token.dataset.id
    setSelectedBusinessPartners((prev) => [...prev.filter(({ id }) => id !== idToDelete)])
  }, [])

  const handleClearAllSelectedBusinessPartners = useCallback(() => {
    setSelectedBusinessPartners([])
  }, [])

  return (
    <>
      <Dialog
        open={open && !isCreateDialogOpen}
        size={DialogSize.XL}
        headerText={t('title', {
          type: translatedType,
        })}
        initialFocus="rent-roll-bp-filterbar-initial-focus"
        onBeforeClose={(e) => e.detail.escPressed && handleCancel()}
        primaryButton={
          <>
            <DialogPrimaryButton
              onClick={() => handleSelect(selectedBusinessPartners)}
              disabled={!selectedBusinessPartners?.length}
            >
              {isMultiSelect ? tNoPrefix('buttons.ok') : t('select-button')}
            </DialogPrimaryButton>
          </>
        }
        secondaryButton={
          <>
            {withCreateOption && (
              <DialogSecondaryButton onClick={handleCreateClick}>
                {tNoPrefix('buttons.create')}
              </DialogSecondaryButton>
            )}
          </>
        }
        closeButton={
          <DialogSecondaryButton onClick={handleCancel}>{t('cancel-button')}</DialogSecondaryButton>
        }
      >
        <BusinessPartnerSearchTable
          initialSearch={initialSearch}
          initialIsBeforeSearch={isBeforeSearch}
          onChange={setSelectedBusinessPartners}
          initialRoles={initialRoles}
          selectedBusinessPartners={selectedBusinessPartners}
          initialExcludeInactive={initialExcludeInactive}
          isMultiSelect={isMultiSelect}
        />
        {isMultiSelect && (
          <FlexBox className={styles.selectionRow} alignItems={FlexBoxAlignItems.Center}>
            <MultiInput
              className={styles.multiInput}
              maxlength={noManualInputAllowedLength}
              tokens={selectedBusinessPartnersToken}
              onTokenDelete={handleTokenDelete}
            />
            <Button
              icon="decline"
              design={ButtonDesign.Transparent}
              onClick={handleClearAllSelectedBusinessPartners}
            />
          </FlexBox>
        )}
      </Dialog>

      {withCreateOption && (
        <BusinessPartnerCreateDialog
          {...createDialogOptions}
          open={isCreateDialogOpen}
          onClose={() => setIsCreateDialogOpen(false)}
          onAfterCreate={handleCreateChange}
        />
      )}
    </>
  )
}

BusinessPartnerSearchDialog.propTypes = {
  open: PropTypes.bool,
  createDialogOptions: PropTypes.shape({
    title: PropTypes.string,
    creationRole: PropTypes.string,
  }),
  initialSearch: PropTypes.string,
  initialRoles: PropTypes.arrayOf(PropTypes.string),
  onClose: PropTypes.func,
  onChange: PropTypes.func,
  withCreateOption: PropTypes.bool,
  translatedTypeName: PropTypes.string,
  initialExcludeInactive: PropTypes.bool,
  isMultiSelect: PropTypes.bool,
  initialBusinessPartners: PropTypes.array,
}

export default BusinessPartnerSearchDialog
