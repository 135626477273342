import { useContext } from 'react'
import PeriodicalCheckGeneralInformationCard from 'components/domains/business-partners/periodical-checks/general-information/PeriodicalCheckGeneralInformationCard'
import CheckDocumentsCard from 'components/domains/deals/covenant-check-detail/check-documents/CheckDocumentsCard'
import CovenantCommentCard from 'components/domains/deals/covenant-check-detail/comment/CovenantCommentCard'
import MonitoringEventCard from 'components/domains/deals/covenant-check-detail/monitoring-event/MonitoringEventCard'
import CovenantCheckMonitoringRequirementsCard from 'components/domains/deals/covenant-check-detail/monitoring-requirements/CovenantCheckMonitoringRequirementsCard'
import documentTypes from 'components/domains/documents/DocumentTypes'
import { cwpEntityTypes } from 'constants/cwpEntityTypes'
import { mainEntityTypes } from 'hooks/services/deals/covenants/MainEntityTypes'
import { CovenantContext } from 'routes/business-partners/CovenantContext'
import { editButtonStateEnum } from 'routes/deals/covenant-checks/useCalculateEditButtonState'

const useMapDetailTileCodes = (
  covenantCheckUuid,
  businessPartnerId,
  businessPartnerName,
  covenantDefinition,
) => {
  const { editButtonState } = useContext(CovenantContext) ?? {}

  const mapDetailTileCodeToComponent = (tileCode) =>
    ({
      CHECK_INFORMATION: (
        <PeriodicalCheckGeneralInformationCard
          covenantCheckUuid={covenantCheckUuid}
          hasDocumentRequirements={covenantDefinition?.withDocumentsRequest}
          showBreach={covenantDefinition?.breachIsDisplayed}
          editButtonState={editButtonState}
        />
      ),
      MONITORING_EVENT: <MonitoringEventCard covenantCheckUuid={covenantCheckUuid} />,
      DOCUMENT_REQUIREMENTS: (
        <CovenantCheckMonitoringRequirementsCard covenantCheckUuid={covenantCheckUuid} />
      ),
      CHECK_DOCUMENTS: (
        <CheckDocumentsCard
          covenantCheckUuid={covenantCheckUuid}
          referenceEntity={{
            id: businessPartnerId,
            name: businessPartnerName,
            entityType: cwpEntityTypes.BUSINESS_PARTNER,
            documentType: documentTypes.BusinessPartner,
          }}
        />
      ),
      COMMENT_WITH_HISTORY: (
        <CovenantCommentCard
          linkedEntityId={covenantCheckUuid}
          linkedEntityType={mainEntityTypes.BUSINESSPARTNER}
          isEditEnabled={editButtonState === editButtonStateEnum.VISIBLE}
        />
      ),
    }[tileCode] ?? null)

  return { mapDetailTileCodeToComponent }
}

export default useMapDetailTileCodes
