import {
  CheckBox,
  FlexBoxAlignItems,
  TableGrowingMode,
  TableRowType,
  ToolbarSeparator,
  ToolbarSpacer,
} from '@fioneer/ui5-webcomponents-react'
import addressFormatter from '@fragaria/address-formatter'
import _ from 'lodash'
import PropTypes from 'prop-types'
import { useCallback, useContext, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { propertyAllowedOperations } from 'api/property/propertyAllowedOperations'
import ModularTable from 'components/domains/properties/modular-table/ModularTable'
import ModularTableCell from 'components/domains/properties/modular-table/ModularTableCell'
import ModularTableColumn from 'components/domains/properties/modular-table/ModularTableColumn'
import ModularTableRow from 'components/domains/properties/modular-table/ModularTableRowWithCheckbox'
import ModularTableTitle from 'components/domains/properties/modular-table/ModularTableTitle'
import ModularTableToolbar from 'components/domains/properties/modular-table/toolbar/ModularToolbar'
import ModularToolbarColumnSortSettingsOnSearchParams from 'components/domains/properties/modular-table/toolbar/ModularToolbarColumnSortSettingsOnSearchParams'
import PortfolioShowButton from 'components/domains/properties/portfolio/PortfolioShowButton'
import styles from 'components/domains/properties/properties-search/table/PropertiesTable.module.css'
import PropertyCreatedAt from 'components/domains/properties/properties-search/table/PropertyCreatedAt'
import PropertyCreatedByName from 'components/domains/properties/properties-search/table/PropertyCreatedByName'
import PropertyDealCell from 'components/domains/properties/properties-search/table/PropertyDealCell'
import PropertyMarketName from 'components/domains/properties/properties-search/table/PropertyMarketName'
import PropertyStatusCell from 'components/domains/properties/properties-search/table/PropertyStatusCell'
import PropertyValuationValue, {
  MARKET_VALUE,
  MORTGAGE_LENDING_VALUE,
} from 'components/domains/properties/properties-search/table/PropertyValuationValue'
import EntityCell from 'components/ui/tables/cells/EntityCell'
import ToolbarColumnSelection from 'components/ui/tables/toolbar/ToolbarColumnSelection'
import { ConfigContext } from 'hooks/config/useConfig'
import { useExternalIdTypeConfigValuesWithTranslation } from 'hooks/services/properties/external-ids/useExternalIdTypeConfigValuesWithTranslation'
import { toCamelCaseFromSentenceCase } from 'lib/format/caseConversion'
import paths from 'routes/paths'
import { usePropertyStatus } from 'routes/properties/usePropertyStatus'

export const MARKET_ID_TYPE = 'Market ID'
export const DEAL_ID_TYPE = 'CWP Deal ID'

const PropertiesTable = ({
  properties,
  tableColumns,
  setTableColumns,
  pagination,
  onLoadMore,
  setSelectedProperties,
  selectedProperties,
  allowedOperations,
  isError,
  hasConfigurableColumns = true,
  navigateToPropertyOnRowClick = true,
  allowMultiSelect = true,
}) => {
  const config = useContext(ConfigContext)

  const selectedPropertyUuids = useMemo(
    () => selectedProperties.map(({ uuid }) => uuid),
    [selectedProperties],
  )
  const { objectStatusForFinancingStatus, objectStatusForCAGStatus } = usePropertyStatus()
  const { t } = useTranslation()
  const navigate = useNavigate()

  const externalIdTypeConfigValues = useExternalIdTypeConfigValuesWithTranslation()

  const isAllowedToPropertyRead = () =>
    allowedOperations?.includes(propertyAllowedOperations.propertyRead)

  const uploadedPropertyIds = useSelector(
    (state) => state.properties.uploadedProperties.uploadedPropertyIds,
  )
  const selectAllProperties = () => {
    setSelectedProperties((oldState) => _.uniqBy([...oldState, ...properties], 'uuid'))
  }

  const deselectAllProperties = () => {
    setSelectedProperties((oldState) => _.differenceBy(oldState, properties, 'uuid'))
  }

  const toggleRowSelected = (property) => {
    if (selectedPropertyUuids.includes(property.uuid)) {
      setSelectedProperties((oldState) => oldState.filter(({ uuid }) => uuid !== property.uuid))
    } else {
      allowMultiSelect
        ? setSelectedProperties((oldState) => [...oldState, property])
        : setSelectedProperties([property])
    }
  }

  const handleColumnHeaderCheckBoxClick = (isChecked) => {
    isChecked ? selectAllProperties() : deselectAllProperties()
  }

  const getRowProperties = (property) => {
    const { id, uuid } = property
    const handleOnClick = ({ ctrlKey, metaKey } = {}) => {
      if (navigateToPropertyOnRowClick) {
        const shouldOpenInNewTab = !!ctrlKey || !!metaKey
        if (shouldOpenInNewTab) window.open(`/${paths.properties}/${id}`, '_blank')
        else navigate(`/${paths.properties}/${id}`)
      } else {
        toggleRowSelected(property)
      }
    }
    if (isAllowedToPropertyRead()) {
      return {
        className: uploadedPropertyIds.includes(id) ? styles.highlightedRow : undefined,
        onClick: handleOnClick,
        selected: selectedPropertyUuids.includes(uuid),
        type: TableRowType.Active,
        'data-property-id': id,
        'data-property-uuid': uuid,
      }
    }
    return {
      className: uploadedPropertyIds.includes(id) ? styles.highlightedRow : undefined,
      onClick: handleOnClick,
      type: TableRowType.Inactive,
      'data-property-id': id,
      'data-property-uuid': uuid,
    }
  }

  const filterExternalIdsByEnv = useCallback(
    (externalIds) => {
      const environment = config?.properties?.externalid?.environment
      if (!environment) {
        return externalIds ?? []
      }
      return externalIds?.filter((id) => id.ext_description === environment) ?? []
    },
    [config?.properties?.externalid?.environment],
  )

  const findExternalIdsByType = (externalIds, type) => {
    const externalIdsList = externalIds?.filter((externalId) => externalId?.type === type)
    return externalIdsList?.map((externalId) => externalId?.id)
  }

  const getFormattedStreet = (address) => {
    const addressNotExist = !address || !Object.keys(address).length

    if (addressNotExist) return ''

    const { house_id, street_name, country_code } = address

    if (!street_name || !house_id) return `${street_name} ${house_id}`

    return addressFormatter.format({
      houseNumber: house_id,
      road: street_name,
      countryCode: country_code,
    })
  }

  const tableData = properties.map((property) => {
    const {
      id,
      uuid,
      description,
      address,
      type_name,
      construction_completion_date,
      external_ids: externalIds,
      system_administrative_data,
      financing_status_code,
      cag_status_code,
    } = property
    const externalIdsByEnv = filterExternalIdsByEnv(externalIds)
    const marketId = findExternalIdsByType(externalIdsByEnv, MARKET_ID_TYPE)?.[0]
    const dealIds = findExternalIdsByType(externalIdsByEnv, DEAL_ID_TYPE) ?? []
    const dealIdCell = !_.isEmpty(dealIds)
      ? dealIds.map((dealId, i) => [
          i > 0 && <span key={`deal-number-span-${i}`}>, </span>,
          <PropertyDealCell key={`${dealId}-${i}`} dealId={dealId} />,
        ])
      : null

    let row = {}

    row = {
      original: property,
      rowKey: `property-table-row-${id}`,
      rowProperties: getRowProperties(property),
      description: {
        cellComponent: (
          <EntityCell name={description} id={id} options={{ isNameBold: true }} />

          // <FlexBox direction="Column">
          //   <Label wrappingType="Normal" className={styles.propertyName}>
          //     {description}
          //   </Label>
          //   <Label wrappingType="None" className={styles.propertyId}>
          //     {id}
          //   </Label>
          // </FlexBox>
        ),
      },
      street: {
        cellComponent: (
          <ModularTableCell wrapText={true}>{getFormattedStreet(address)}</ModularTableCell>
        ),
      },
      city: {
        cellComponent: (
          <ModularTableCell wrapText={true}>{address?.city_name || ''}</ModularTableCell>
        ),
      },
      country: {
        cellComponent: <ModularTableCell>{address?.country_name || ''}</ModularTableCell>,
      },
      zipCode: {
        cellComponent: <ModularTableCell>{address?.postal_code || ''}</ModularTableCell>,
      },
      typeName: {
        cellComponent: <ModularTableCell wrapText={true}>{type_name}</ModularTableCell>,
      },
      constructionYear: {
        cellComponent: (
          <ModularTableCell>
            {construction_completion_date ? construction_completion_date.split('-')[0] : ''}
          </ModularTableCell>
        ),
      },
      marketValue: {
        cellComponent: <PropertyValuationValue valuationClass={MARKET_VALUE} propertyUuid={uuid} />,
      },
      mortgageLendingValue: {
        cellComponent: (
          <PropertyValuationValue valuationClass={MORTGAGE_LENDING_VALUE} propertyUuid={uuid} />
        ),
      },
      market: {
        cellComponent: marketId ? <PropertyMarketName marketId={marketId} /> : <></>,
      },
      financingStatus: {
        cellComponent: (
          <PropertyStatusCell
            statusCode={financing_status_code}
            objectStatusMap={objectStatusForFinancingStatus}
          />
        ),
      },
      cagStatus: {
        cellComponent: (
          <PropertyStatusCell
            statusCode={cag_status_code}
            objectStatusMap={objectStatusForCAGStatus}
          />
        ),
      },
      dealId: {
        cellComponent: dealIdCell,
      },
      createdBy: {
        cellComponent: (
          <PropertyCreatedByName
            userId={system_administrative_data?.creation_user_account_id ?? ''}
          />
        ),
      },
      createdAt: {
        cellComponent: (
          <PropertyCreatedAt createdAt={system_administrative_data?.creation_date_time ?? ''} />
        ),
      },
    }

    // Dynamically append row cells for all other external ids with display=true
    if (externalIdTypeConfigValues.length) {
      externalIdTypeConfigValues
        .filter(({ display }) => display)
        .map(({ type: extIdType }) => {
          const extIdValues = findExternalIdsByType(externalIdsByEnv, extIdType)?.join(', ') ?? ''
          const columnKey = toCamelCaseFromSentenceCase(extIdType)

          row[columnKey] = {
            cellComponent: <ModularTableCell>{extIdValues}</ModularTableCell>,
          }
        })
    }

    return row
  })

  const showMoreButton = pagination.limit < pagination.total
  const growingMode = showMoreButton ? TableGrowingMode.Button : TableGrowingMode.None
  const growingButtonText = t('components.ui.tables.sorted-tables.growing-button-text')
  const growingButtonSubtext = '[ ' + pagination.limit + ' / ' + pagination.total + ' ]'

  const noDataText = isError
    ? t('pages.properties.table.error')
    : t('pages.properties.table.no-data')

  const visibleColumns = tableColumns.filter(
    ({ isVisible, isSelectableForHiding }) => isVisible || !isSelectableForHiding,
  )

  const paginationConfig = {
    growing: growingMode,
    growingButtonText: growingButtonText,
    growingButtonSubtext: growingButtonSubtext,
    totalNumberOfItems: pagination.total,
    loadMore: onLoadMore,
  }

  const tableColumnsComponents = [
    <ModularTableColumn
      key={'select'}
      alignment={FlexBoxAlignItems.Start}
      customComponent={
        allowMultiSelect ? (
          <CheckBox
            id="select-all-checkbox"
            checked={
              properties?.every(({ uuid }) => selectedPropertyUuids.includes(uuid)) &&
              tableData?.length
            }
            onChange={(event) => handleColumnHeaderCheckBoxClick(event.target.checked)}
            disabled={!tableData?.length}
          />
        ) : (
          <></>
        )
      }
    />,
    ...visibleColumns.map(({ columnKey, title, alignment, wrapText, ...additionalColumnProps }) => (
      <ModularTableColumn
        key={columnKey}
        title={title}
        alignment={alignment}
        wrapText={wrapText}
        {...additionalColumnProps}
      />
    )),
    <ModularTableColumn key={'arrow'} title="" alignment={FlexBoxAlignItems.End} />,
  ]

  return (
    <>
      <ModularTableToolbar>
        <ModularTableTitle
          title={t('pages.properties.table.toolbar.title')}
          nrOfEntries={paginationConfig.totalNumberOfItems}
        />
        {hasConfigurableColumns ? (
          <>
            <ToolbarSpacer />
            <PortfolioShowButton
              key="portfolio-show-button"
              selectedProperties={selectedPropertyUuids}
            />
            <ToolbarSeparator />
            <ModularToolbarColumnSortSettingsOnSearchParams columns={tableColumns} />
            <ToolbarColumnSelection
              columnSelection={tableColumns}
              setColumnSelection={setTableColumns}
            />
          </>
        ) : (
          <></>
        )}
      </ModularTableToolbar>
      <ModularTable
        additionalTableProperties={{
          id: 'properties-table',
          className: styles.propertiesTable,
        }}
        columns={tableColumnsComponents}
        noDataText={noDataText}
        paginationConfig={paginationConfig}
      >
        {tableData.map((row) => {
          const propertyUuid = row.rowProperties['data-property-uuid']
          return (
            <ModularTableRow
              key={propertyUuid}
              rowData={row}
              columns={visibleColumns}
              checkboxComponent={
                allowMultiSelect ? (
                  <CheckBox
                    checked={selectedPropertyUuids.includes(propertyUuid)}
                    onChange={() => toggleRowSelected(row.original)}
                    onClick={(e) => e.stopPropagation()}
                  />
                ) : (
                  <></>
                )
              }
              withArrow={navigateToPropertyOnRowClick}
            />
          )
        })}
      </ModularTable>
    </>
  )
}

PropertiesTable.propTypes = {
  properties: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      uuid: PropTypes.string,
      description: PropTypes.string,
      address: PropTypes.shape({
        country_code: PropTypes.string,
        country_name: PropTypes.string,
        street_postal_code: PropTypes.string,
        city_name: PropTypes.string,
        street_name: PropTypes.string,
        house_id: PropTypes.string,
      }),
      construction_completion_date: PropTypes.string,
    }),
  ).isRequired,
  uploadedProperties: PropTypes.array,
  tableColumns: PropTypes.array,
  setTableColumns: PropTypes.func,
  pagination: PropTypes.shape({
    limit: PropTypes.number,
    total: PropTypes.number,
  }).isRequired,
  setSelectedProperties: PropTypes.func.isRequired,
  selectedProperties: PropTypes.array.isRequired,
  onLoadMore: PropTypes.func,
  allowedOperations: PropTypes.arrayOf(PropTypes.string),
  isLoading: PropTypes.bool,
  isError: PropTypes.bool,
  hasConfigurableColumns: PropTypes.bool,
  navigateToPropertyOnRowClick: PropTypes.bool,
  maxSelectAmount: PropTypes.number,
  allowMultiSelect: PropTypes.bool,
}

export default PropertiesTable
