import {
  Button,
  ButtonDesign,
  FlexBox,
  FlexBoxDirection,
  IllustratedMessage,
  Link,
  MessageStrip,
  MessageStripDesign,
  Text,
} from '@fioneer/ui5-webcomponents-react'
import { useQueryClient } from '@tanstack/react-query'
import '@ui5/webcomponents-fiori/dist/illustrations/NoTasks'
import '@ui5/webcomponents-fiori/dist/illustrations/sapIllus-Spot-UnableToLoad.js'
import isNil from 'lodash.isnil'
import PropTypes from 'prop-types'
import { useMemo, useState } from 'react'
import { createPortal } from 'react-dom'
import { useTranslation } from 'react-i18next'
import {
  propertyMonitoringPermissions,
  propertyMonitoringProxiesAllowedOperations,
  propertyMonitoringStatus,
} from 'api/property/monitoring/propertyMonitoringConfiguration'
import { isNotFoundError } from 'api/requests'
import CreatePropertyEventAction from 'components/domains/properties/header-actions/CreatePropertyEventAction'
import PropertyMonitoringExportLogDialog from 'components/domains/properties/monitoring/PropertyMonitoringExportLogDialog'
import PropertyMonitoringInfoCard from 'components/domains/properties/monitoring/PropertyMonitoringInfoCard'
import PropertyMonitoringMessage from 'components/domains/properties/monitoring/PropertyMonitoringMessage'
import PropertyMonitoringProxies from 'components/domains/properties/monitoring/PropertyMonitoringProxies'
import MarketValueConfirmationCard from 'components/domains/properties/monitoring/market-valuation/MarketValueConfirmationCard'
import PropertyMonitoringSettingsDialogLoadingWrapper from 'components/domains/properties/monitoring/settings/PropertyMonitoringSettingsDialogLoadingWrapper'
import PropertyTile from 'components/domains/properties/tile/PropertyTile'
import ErrorDialog from 'components/ui/dialog/ErrorDialog'
import EntityTypeAndIdWithClipboard from 'components/ui/entity-info/EntityTypeAndIdWithClipboard'
import { RequestStateResolver } from 'components/ui/loading/RequestStateResolver'
import createSegmentedHeaderActions from 'components/ui/page/createSegmentedHeaderActions'
import UserFavoriteIcon from 'components/ui/user-favorite/UserFavoriteIcon'
import { cwpEntityTypes } from 'constants/cwpEntityTypes'
import usePropertyIdForAssignedMarket from 'hooks/services/markets/usePropertyIdForAssignedMarket'
import usePropertyMonitoringProxiesAllowedOperations from 'hooks/services/properties/monitoring/proxies/usePropertyMonitoringProxiesAllowedOperations'
import { usePropertyMonitoring } from 'hooks/services/properties/monitoring/usePropertyMonitoring'
import usePropertyMonitoringPermissions from 'hooks/services/properties/monitoring/usePropertyMonitoringPermissions'
import useUpdatePropertyMonitoring from 'hooks/services/properties/monitoring/useUpdatePropertyMonitoring'
import paths from 'routes/paths'
import PropertyPage from 'routes/properties/PropertyPage'
import styles from 'routes/properties/monitoring/PropertyMonitoring.module.css'

const PropertyMonitoring = ({
  propertyDescription,
  propertyId,
  propertyUuid,
  breadcrumbs,
  status,
  additionalStatuses,
}) => {
  const { t } = useTranslation('translation', { keyPrefix: 'pages.property-monitoring' })
  const { t: tCommon } = useTranslation()
  const [isErrorDialogOpen, setIsErrorDialogOpen] = useState(false)
  const [isSettingsDialogOpen, setIsSettingsDialogOpen] = useState(false)
  const [isExportLogDialogOpen, setIsExportLogDialogOpen] = useState(false)
  const {
    isFetching: isPropertyMonitoringFetching,
    isError: isPropertyMonitoringError,
    error: propertyMonitoringError,
    data: propertyMonitoringData = {},
  } = usePropertyMonitoring(propertyUuid)
  const {
    isFetching: isPermissionsFetching,
    isError: isPermissionsError,
    data: permissions,
  } = usePropertyMonitoringPermissions(propertyUuid)
  const {
    data: proxiesAllowedOperationsData,
    isFetching: isProxiesAllowedOperationsFetching,
    isError: isProxiesAllowedOperationsError,
  } = usePropertyMonitoringProxiesAllowedOperations()
  const {
    data: assignedMarketData,
    isFetching: isAssignedMarketFetching,
    isError: isAssignedMarketError,
  } = usePropertyIdForAssignedMarket([propertyUuid])
  const assignedMarket = assignedMarketData?.markets?.[0] ?? {}

  const showProxyMessage = useMemo(
    () =>
      (proxiesAllowedOperationsData ?? []).includes(
        propertyMonitoringProxiesAllowedOperations.proxySettingsRead,
      ) && !isNil(assignedMarket.id),
    [assignedMarket.id, proxiesAllowedOperationsData],
  )

  const queryClient = useQueryClient()

  const updatePropertyMonitoring = useUpdatePropertyMonitoring({
    onSuccess: () => queryClient.invalidateQueries(['property-monitoring']),
    onError: () => setIsErrorDialogOpen(true),
  })

  const handleOnResumeMonitoring = () => {
    updatePropertyMonitoring.mutate({
      monitoringPropertyId: propertyMonitoringData.id,
      monitoringStatus: propertyMonitoringStatus.activeAutomated,
      baselineDate: propertyMonitoringData.baseline_date,
    })
    setIsErrorDialogOpen(false)
  }
  const renderContent = () => {
    const hasReadPermissions = permissions?.includes(propertyMonitoringPermissions.read)
    if (!hasReadPermissions) {
      return (
        <IllustratedMessage
          name="UnableToLoad"
          titleText={t('not-allowed.title')}
          subtitleText={t('not-allowed.subtitle')}
        />
      )
    }

    return (
      <FlexBox direction={FlexBoxDirection.Column} className={styles.pageWrapper}>
        <PropertyMonitoringMessage
          monitoringStatus={propertyMonitoringData.monitoring_status}
          monitoringPermissions={permissions}
          onResumeMonitoring={handleOnResumeMonitoring}
        />
        <PropertyTile
          id="property-monitoring-tile"
          columnsS={2}
          columnsM={4}
          columnsL={6}
          columnsXL={6}
        >
          <div style={{ gridColumn: 'span 2' }}>
            <PropertyMonitoringInfoCard
              marketId={assignedMarket.id}
              marketName={assignedMarket.info?.name}
              monitoringStatus={propertyMonitoringData.monitoring_status}
              proxySettings={propertyMonitoringData.proxy_settings}
              baselineDate={propertyMonitoringData.baseline_date}
              monitoringDate={propertyMonitoringData.monitoring_date}
              nextManualMonitoringDate={propertyMonitoringData.next_manual_monitoring_date}
              handleOnResumeMonitoring={handleOnResumeMonitoring}
              monitoringPermissions={permissions}
            />
          </div>
          <div style={{ gridColumn: 'span 4' }}>
            <MarketValueConfirmationCard propertyUuid={propertyUuid} />
          </div>
        </PropertyTile>
        {showProxyMessage && (
          <MessageStrip
            design={MessageStripDesign.Information}
            className={styles.proxyMessageStrip}
          >
            <Text>
              {t('proxies.message')}{' '}
              <Link
                target="_blank"
                href={`/${paths.markets}/${assignedMarket.id}#market-overview-manager`}
              >
                {t('proxies.link')}
              </Link>
            </Text>
          </MessageStrip>
        )}
        <PropertyTile
          id="property-monitoring-tile"
          columnsS={1}
          columnsM={1}
          columnsL={1}
          columnsXL={1}
        >
          <PropertyMonitoringProxies
            propertyUuid={propertyUuid}
            marketId={assignedMarket.id}
            macroProxies={!isNil(assignedMarket.id) ? propertyMonitoringData.macro_proxies : {}}
            microProxies={propertyMonitoringData.micro_proxies}
            baselineDate={propertyMonitoringData.baseline_date}
            monitoringStatus={propertyMonitoringData.monitoring_status}
            hasNoMonitoringData={isPropertyMonitoringError}
          />
        </PropertyTile>
        <ErrorDialog
          isOpen={isErrorDialogOpen}
          setIsOpen={setIsErrorDialogOpen}
          texts={{
            retryButton: tCommon('buttons.try-again'),
            cancelButton: tCommon('buttons.cancel'),
            title: t('error.title'),
            description: t('error.description'),
          }}
          onRetry={handleOnResumeMonitoring}
        />
      </FlexBox>
    )
  }

  const markFavoriteAction = useMemo(
    () => (
      <UserFavoriteIcon
        key="property-details-user-favorite-icon"
        entityId={propertyUuid}
        entityType={cwpEntityTypes.PROPERTY}
      />
    ),
    [propertyUuid],
  )

  const eventCreationButton = <CreatePropertyEventAction key="create-event-action" />

  const propertyMonitoringSettingsButton = (
    <Button
      design={ButtonDesign.Default}
      id="properties-monitoring-settings-button"
      key="monitoring-settings"
      onClick={() => setIsSettingsDialogOpen(true)}
    >
      {t('buttons.settings')}
    </Button>
  )

  const exportLogButton = (
    <Button
      design={ButtonDesign.Default}
      onClick={() => {
        setIsExportLogDialogOpen(true)
      }}
      key="export-log"
    >
      {t('buttons.export-log')}
    </Button>
  )

  const showPropertyMonitoringSettingsButton = useMemo(
    () => permissions && permissions.includes(propertyMonitoringPermissions.editSettings),
    [permissions],
  )
  const showPropertyMonitoringExportLogButton = useMemo(
    () => permissions && permissions.includes(propertyMonitoringPermissions.exportLog),
    [permissions],
  )

  const subtitle = <EntityTypeAndIdWithClipboard id={propertyId} />
  const isError =
    isPropertyMonitoringError ||
    isPermissionsError ||
    isProxiesAllowedOperationsError ||
    isAssignedMarketError
  const isPropertyMonitoringNotFoundError =
    isPropertyMonitoringError && isNotFoundError(propertyMonitoringError)
  return (
    <PropertyPage
      pageTitle={propertyDescription}
      id={propertyId}
      subtitle={subtitle}
      breadcrumbs={breadcrumbs}
      actions={
        <>
          {createSegmentedHeaderActions(
            [showPropertyMonitoringSettingsButton && propertyMonitoringSettingsButton],
            [
              eventCreationButton,
              showPropertyMonitoringExportLogButton && exportLogButton,
              markFavoriteAction,
            ],
          )}
        </>
      }
      status={status}
      additionalStatuses={additionalStatuses}
    >
      <RequestStateResolver
        isLoading={
          isPropertyMonitoringFetching ||
          isPermissionsFetching ||
          isProxiesAllowedOperationsFetching ||
          isAssignedMarketFetching
        }
        isError={isError && !isPropertyMonitoringNotFoundError}
        errorToDisplay={
          <IllustratedMessage
            name="UnableToLoad"
            size="Dialog"
            titleText={t('error.title')}
            subtitleText={t('error.description')}
          />
        }
        renderContent={renderContent}
      />
      {showPropertyMonitoringSettingsButton && (
        <PropertyMonitoringSettingsDialogLoadingWrapper
          propertyUuid={propertyUuid}
          setIsDialogOpen={setIsSettingsDialogOpen}
          isDialogOpen={isSettingsDialogOpen}
          monitoringPermissions={permissions}
          isPropertyAssignedToMarket={!isNil(assignedMarket?.id)}
        />
      )}
      {showPropertyMonitoringExportLogButton &&
        createPortal(
          <PropertyMonitoringExportLogDialog
            open={isExportLogDialogOpen}
            setIsOpen={setIsExportLogDialogOpen}
            propertyUuid={propertyUuid}
          />,
          document.body,
        )}
    </PropertyPage>
  )
}

PropertyMonitoring.propTypes = {
  propertyDescription: PropTypes.string,
  propertyId: PropTypes.string.isRequired,
  propertyUuid: PropTypes.string.isRequired,
  breadcrumbs: PropTypes.arrayOf(
    PropTypes.shape({
      text: PropTypes.string.isRequired,
      href: PropTypes.string,
    }),
  ).isRequired,
  status: PropTypes.shape({
    text: PropTypes.string.isRequired,
    valueState: PropTypes.string,
  }).isRequired,
  additionalStatuses: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      text: PropTypes.string.isRequired,
      valueState: PropTypes.string,
    }),
  ),
}

export default PropertyMonitoring
