import isNil from 'lodash.isnil'
import PropTypes from 'prop-types'
import { useEffect, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import FurtherDetailsPieChartsCard from 'components/domains/business-events-and-tasks/decision-paper/tiles/fact-sheet-further-details/FurtherDetailsPieChartsCard'
import {
  TenantColorProvider,
  useTenantColors,
} from 'components/domains/business-events-and-tasks/decision-paper/tiles/fact-sheet-further-details/shared/TenantColorContext'
import NoPropertyAssignedToDealInfo from 'components/domains/business-events-and-tasks/decision-paper/tiles/property/NoPropertyAssignedToDealInfo'
import { setSourceRender } from 'redux/slices/decision-paper/tilesOverviewSlice'

const MultipleFurtherDetailsGranularityTenantIndustryTile = ({
  tileId,
  isPdfView,
  selectedDealIndex,
}) => {
  const dispatch = useDispatch()

  const { t: tTenantIndustry } = useTranslation('decisionPaper', {
    keyPrefix: 'tiles.annual-review.further-details-granularity',
  })

  const {
    data: {
      multiplePropertyGranularityPieChartData = [],
      sourcePaths = [],
      sourceRender,
      dealsData,
    },
  } = useSelector((state) => state.decisionPaper.tilesOverview.tiles[tileId])

  const selectedDealDisplayId = dealsData[selectedDealIndex]?.dealDisplayId

  useEffect(() => {
    dispatch(setSourceRender({ tileId, sourceRender: { path: sourcePaths?.[selectedDealIndex] } }))
  }, [dispatch, selectedDealDisplayId, selectedDealIndex, sourcePaths, tileId])

  useEffect(() => {
    if (isNil(sourceRender)) {
      dispatch(
        setSourceRender({ tileId, sourceRender: { path: sourcePaths?.[selectedDealIndex] } }),
      )
    }
  }, [dispatch, selectedDealDisplayId, selectedDealIndex, sourcePaths, sourceRender, tileId])

  const { multiPropertyKpisByGranularity, noProperties } = useMemo(() => {
    const propertyGranularityPieChartData =
      multiplePropertyGranularityPieChartData?.[selectedDealIndex] ?? {}

    return {
      multiPropertyKpisByGranularity:
        propertyGranularityPieChartData?.multiPropertyKpisByGranularity
          ?.keyDateToMultiPropertyRentRollKpis?.[0]?.kpis,
      noProperties: !propertyGranularityPieChartData?.properties,
    }
  }, [multiplePropertyGranularityPieChartData, selectedDealIndex])

  const getTenantIndustryRentRollKpis = (multiPropertyKpis) =>
    multiPropertyKpis?.tenantIndustryRentRollKpis ?? []

  const getTenantIndustryName = (rentRollKpi) =>
    rentRollKpi?.tenantIndustryKeyDescription ?? tTenantIndustry('none')

  if (noProperties) {
    return <NoPropertyAssignedToDealInfo />
  }
  return (
    <FurtherDetailsPieChartsCard
      colorProvider={TenantColorProvider}
      useColors={useTenantColors}
      getRentRollKpis={getTenantIndustryRentRollKpis}
      getValuations={() => {}}
      getNameFromKpi={getTenantIndustryName}
      granularityValues={{
        multiPropertyKpisByGranularity,
        multiPropertyValuationsByGranularity: {},
      }}
      isPdfView={isPdfView}
    />
  )
}

MultipleFurtherDetailsGranularityTenantIndustryTile.propTypes = {
  tileId: PropTypes.string.isRequired,
  isPdfView: PropTypes.bool.isRequired,
  selectedDealIndex: PropTypes.number.isRequired,
}

export default MultipleFurtherDetailsGranularityTenantIndustryTile
