import { ObjectPageSection } from '@fioneer/ui5-webcomponents-react'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import PropertyRentRollComparison from 'components/domains/properties/rent-roll/comparison/PropertyRentRollComparison'
import PropertyRentRollDetails from 'components/domains/properties/rent-roll/details/PropertyRentRollDetails'
import renderPropertyRentRollOverview from 'components/domains/properties/rent-roll/overview/PropertyRentRollOverview'
import PropertyRentRollSegments from 'components/domains/properties/rent-roll/segment/PropertyRentRollSegments'
import TabPage from 'components/ui/page/TabPage'

export const PROPERTY_RENT_ROLL_SECTIONS = Object.freeze({
  overview: 'overview',
  segments: 'segments',
  details: 'details',
  analysis: 'analysis',
})

const PropertyRentRoll = ({ header }) => {
  const { t: tRentRollTabs } = useTranslation('translation', {
    keyPrefix: 'pages.properties.rent-roll.tabs',
  })

  return (
    <TabPage headerTitle={header} defaultSectionId={PROPERTY_RENT_ROLL_SECTIONS.overview}>
      <ObjectPageSection
        key={PROPERTY_RENT_ROLL_SECTIONS.overview}
        id={PROPERTY_RENT_ROLL_SECTIONS.overview}
        titleText={tRentRollTabs(PROPERTY_RENT_ROLL_SECTIONS.overview)}
      >
        {renderPropertyRentRollOverview()}
      </ObjectPageSection>
      <ObjectPageSection
        key={PROPERTY_RENT_ROLL_SECTIONS.segments}
        id={PROPERTY_RENT_ROLL_SECTIONS.segments}
        titleText={tRentRollTabs(PROPERTY_RENT_ROLL_SECTIONS.segments)}
      >
        <PropertyRentRollSegments />
      </ObjectPageSection>
      <ObjectPageSection
        key={PROPERTY_RENT_ROLL_SECTIONS.details}
        id={PROPERTY_RENT_ROLL_SECTIONS.details}
        titleText={tRentRollTabs(PROPERTY_RENT_ROLL_SECTIONS.details)}
      >
        <PropertyRentRollDetails />
      </ObjectPageSection>
      <ObjectPageSection
        key={PROPERTY_RENT_ROLL_SECTIONS.analysis}
        id={PROPERTY_RENT_ROLL_SECTIONS.analysis}
        titleText={tRentRollTabs(PROPERTY_RENT_ROLL_SECTIONS.analysis)}
      >
        <PropertyRentRollComparison />
      </ObjectPageSection>
    </TabPage>
  )
}
PropertyRentRoll.propTypes = {
  header: PropTypes.node.isRequired,
}
export default PropertyRentRoll
