import { SegmentedButton, SegmentedButtonItem } from '@fioneer/ui5-webcomponents-react'
import { useContext } from 'react'
import { useTranslation } from 'react-i18next'
import { WorkingVersionType } from 'components/domains/deals/deal-adjustment/model/WorkingVersionType'
import { DealContext } from 'routes/deals/DealContext'
import useNavigateToWorkingVersion from 'routes/deals/useNavigateToWorkingVersion'

/**
 * Toggle button to switch between working version types of a deal: { WORKING_VERSION, LIVE }
 */
const DealToggleWorkingVersionButton = () => {
  const { t } = useTranslation('translation', {
    keyPrefix: 'components.deals.working-version-types',
  })
  const {
    deal: { workingVersion },
  } = useContext(DealContext)

  const switchToWorkingVersion = useNavigateToWorkingVersion({ displayToast: true })

  return (
    <SegmentedButton>
      <SegmentedButtonItem
        pressed={workingVersion === WorkingVersionType.LIVE}
        onClick={() => switchToWorkingVersion(WorkingVersionType.LIVE)}
      >
        {t('live')}
      </SegmentedButtonItem>
      <SegmentedButtonItem
        pressed={workingVersion === WorkingVersionType.WORKING_VERSION}
        onClick={() => switchToWorkingVersion(WorkingVersionType.WORKING_VERSION)}
      >
        {t('working-version')}
      </SegmentedButtonItem>
    </SegmentedButton>
  )
}

export default DealToggleWorkingVersionButton

DealToggleWorkingVersionButton.propTypes = {}
