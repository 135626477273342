import { ResponsiveGridLayout } from '@fioneer/ui5-webcomponents-react'
import PropType from 'prop-types'

const DEFAULT_COLUMNS_S = 1
const DEFAULT_COLUMNS_M = 2
const DEFAULT_COLUMNS_L = 3
const DEFAULT_COLUMNS_XL = 3

const PropertyTile = ({
  columnsS = DEFAULT_COLUMNS_S,
  columnsM = DEFAULT_COLUMNS_M,
  columnsL = DEFAULT_COLUMNS_L,
  columnsXL = DEFAULT_COLUMNS_XL,
  id,
  children,
}) => (
  <ResponsiveGridLayout
    columnsXL={columnsXL}
    columnsL={columnsL}
    columnsM={columnsM}
    columnsS={columnsS}
    columnGap="1rem"
    rowGap="1rem"
    id={id}
  >
    {children}
  </ResponsiveGridLayout>
)

PropertyTile.propTypes = {
  id: PropType.string.isRequired,
  children: PropType.node,
  columnsXL: PropType.number,
  columnsL: PropType.number,
  columnsM: PropType.number,
  columnsS: PropType.number,
}
export default PropertyTile
