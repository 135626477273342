import {
  Button,
  ButtonDesign,
  DynamicPageHeader,
  FlexBox,
  FlexBoxAlignItems,
  Label,
  MultiInput,
  Token,
} from '@fioneer/ui5-webcomponents-react'
import PropTypes from 'prop-types'
import { useCallback, useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { dealsPermissions } from 'api/deals/dealsAllowedOperations'
import { WorkingVersionType } from 'components/domains/deals/deal-adjustment/model/WorkingVersionType'
import { DealFilterBar, DealFilterKeys } from 'components/domains/deals/deal-search/DealFilterBar'
import styles from 'components/domains/deals/deal-search/DealSearchDialog.module.css'
import DealSearchTable, {
  DealSearchTableColumn,
  DealSearchTableMode,
} from 'components/domains/deals/deal-search/DealSearchTable'
import Dialog, {
  DialogPrimaryButton,
  DialogSecondaryButton,
  DialogSize,
} from 'components/ui/dialog/Dialog'
import CenteredIllustratedMessage from 'components/ui/illustrated-message/CenteredIllustratedMessage'
import { useDealPermissions } from 'hooks/services/deals/useDealPermissions'
import useDeals from 'hooks/services/deals/useDeals'
import { useInfiniteLoading } from 'hooks/services/useInfiniteLoading'

const buildInitialSorting = () => ({
  orderField: DealFilterKeys.CreatedAt,
  orderDirection: 'desc',
})

/**
 * DealSearchDialog
 * - columnsRestrictedTo: null | Array<DealSearchTableColumn>
 * - initialFilterValues: null | {[key in DealFilterKeys]: string }
 * - visibleFilters: null | Array<DealFilterKeys>
 * - readOnlyFilters: null | Array<DealFilterKeys>
 */
const DealSearchDialog = ({
  isOpen,
  setIsOpen,
  onClose = () => {},
  onSelected,
  isMultiSelect,
  columnsRestrictedTo,
  initialFilterValues: passedInitialFilterValues = {},
  visibleFilters,
  readOnlyFilters = [],
  initiallySelectedDeals = [],
}) => {
  const { t } = useTranslation('translation', { keyPrefix: 'components.deals.deal-search.dialog' })
  const { t: tNoPrefix } = useTranslation()

  const liveAndWorkingVersionFilter = {
    [DealFilterKeys.WorkingVersion]: Object.values(WorkingVersionType).join(','),
  }
  const initialFilterValues = useMemo(
    () => ({
      ...liveAndWorkingVersionFilter,
      ...passedInitialFilterValues,
    }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [passedInitialFilterValues],
  )

  const [filterParams, setFilterParams] = useState(initialFilterValues)
  const [sortingParams, setSortingParams] = useState(buildInitialSorting())

  // selected dialog deals: all deals selected throughout the whole interaction with the deal popup
  const [selectedDialogDeals, setSelectedDialogDeals] = useState(initiallySelectedDeals ?? [])
  const selectedDialogDealUuids = useMemo(
    () => selectedDialogDeals.map((deal) => deal.dealUuid),
    [selectedDialogDeals],
  )

  useEffect(() => {
    if (isOpen) {
      setSelectedDialogDeals(initiallySelectedDeals ?? [])
    }
    // since this dialog is not always rendered conditionally we need to trigger this effect
    // when the open flag changes but not necessarily when the
    // initialBusinessPartners change (that would magically select or deselect BPs without the user clicking somewhere)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isOpen])

  const {
    isLoading: isLoadingAllowedOperations,
    isError: isErrorAllowedOperations,
    data: { allowedOperations = [] } = {},
  } = useDealPermissions()

  const isAllowedToReadDeal = allowedOperations.includes(dealsPermissions.readDeal)

  // table deals: deals currently visible in the search results
  const workingVersionFilter = filterParams[DealFilterKeys.WorkingVersion]
    ? filterParams[DealFilterKeys.WorkingVersion]
    : liveAndWorkingVersionFilter[DealFilterKeys.WorkingVersion]
  const {
    isLoading: isLoadingDeals,
    isError: isErrorDeals,
    data: { deals: tableDeals = [], pagination = { offset: 0, limit: 0, total: 0 } } = {},
    loadMore: loadMoreDeals,
  } = useInfiniteLoading({
    requestHook: useDeals,
    requestHookArgs: {
      filter: {
        dealId: filterParams[DealFilterKeys.Id],
        dealName: filterParams[DealFilterKeys.Name],
        accountManager: filterParams[DealFilterKeys.AccountManager],
        businessSegments: filterParams[DealFilterKeys.BusinessSegments],
        dealTypes: filterParams[DealFilterKeys.Type],
        originationPlatform: filterParams[DealFilterKeys.OriginationPlatform],
        originationTeam: filterParams[DealFilterKeys.OriginationTeam],
        status: filterParams[DealFilterKeys.Status],
        borrowerId: filterParams[DealFilterKeys.Borrower],
        workingVersion: workingVersionFilter,
      },
      sort: sortingParams,
    },
    dataListElement: 'deals',
    paginationDelta: 50,
  })

  const updateFilters = (_filterParams, _sortingParams) => {
    setFilterParams(_filterParams)
    setSortingParams(_sortingParams)
  }

  const handleOnClose = () => {
    setIsOpen(false)
    onClose()
  }
  const handleOnOkButton = () => {
    onSelected(selectedDialogDeals)
    handleOnClose()
  }
  const isAnyDealSelected = () => selectedDialogDealUuids.length > 0

  const handleFilterbarGo = (newFilters) => {
    updateFilters(newFilters, sortingParams)
  }

  const onSortingChanged = (orderField, orderDirection) => {
    const newSortingParams = { orderField, orderDirection }
    updateFilters(filterParams, newSortingParams)
  }

  const onSelectionChanged = (selectionEvent) => {
    const selectedTableRows = selectionEvent.detail.selectedRows
    const selectedTableDeals = selectedTableRows.map(
      ({ dataset: { dealId, dealUuid, dealName } }) => ({
        dealId,
        dealUuid,
        dealName,
      }),
    )

    // previous deals:
    // (dialog) deals that were selected from the deal table before & are currently NOT visible in the (table) deals
    // (example: the user did a search - selected some deals - then did a completely different search)
    // since the propagated selectedTableDeals only operate on the currently visible (table) deals,
    // all previously selected deals need to be preserved
    const currentlyVisibleDealUuids = tableDeals.map((d) => d.dealUuid)
    const previouslySelectedDeals = selectedDialogDeals.filter(
      (selectedDeal) => !currentlyVisibleDealUuids.includes(selectedDeal.dealUuid),
    )

    setSelectedDialogDeals([...previouslySelectedDeals, ...selectedTableDeals])
  }

  const handleFooterTokenDelete = useCallback((deleteEvent) => {
    const deleteDealId = deleteEvent.detail.token.dataset.dealId
    setSelectedDialogDeals((prev) => [...prev.filter(({ dealUuid }) => dealUuid !== deleteDealId)])
  }, [])

  const handleFooterTokenClearAll = useCallback(() => {
    setSelectedDialogDeals([])
  }, [])

  const renderTableAndMultiFooter = () => {
    /* Deal Search Table */
    const table = (
      <DealSearchTable
        deals={tableDeals}
        mode={isMultiSelect ? DealSearchTableMode.MultiSelect : DealSearchTableMode.SingleSelect}
        pagination={pagination}
        orderField={sortingParams.orderField}
        orderDirection={sortingParams.orderDirection}
        onSortingChanged={onSortingChanged}
        onSelectionChanged={onSelectionChanged}
        selectedDealUuids={selectedDialogDealUuids}
        columnsRestrictedTo={columnsRestrictedTo}
        isError={isErrorDeals || isErrorAllowedOperations}
        isLoading={isLoadingDeals || isLoadingAllowedOperations}
        isAllowedToReadDeal={isAllowedToReadDeal}
        loadMore={loadMoreDeals}
      />
    )

    /* Table Footer for Multi Select */
    const tableMultiFooter =
      isMultiSelect &&
      !isLoadingDeals &&
      !isLoadingAllowedOperations &&
      !isErrorDeals &&
      !isErrorAllowedOperations ? (
        <>
          <Label className={styles.titleLabel}>
            {t('selected-deals', { count: selectedDialogDeals.length })}
          </Label>
          <FlexBox className={styles.selectionRow} alignItems={FlexBoxAlignItems.Center}>
            <MultiInput
              maxlength={0}
              onTokenDelete={handleFooterTokenDelete}
              style={{
                width: '100%',
              }}
              tokens={
                <>
                  {selectedDialogDeals.map((deal) => (
                    <Token
                      key={deal.dealUuid}
                      text={`${deal.dealName} (${deal.dealId})`}
                      data-deal-id={deal.dealUuid}
                    />
                  ))}
                </>
              }
            />
            <Button
              icon="decline"
              design={ButtonDesign.Transparent}
              onClick={handleFooterTokenClearAll}
            />
          </FlexBox>
        </>
      ) : (
        <></>
      )

    return (
      <>
        {table}
        {tableMultiFooter}
      </>
    )
  }

  const noPermissionsIllustratedMessage = (
    <CenteredIllustratedMessage
      name="UnableToLoad"
      size="Spot"
      titleText={t('not-allowed.title')}
      subtitleText={t('not-allowed.subtitle')}
    />
  )
  return (
    <Dialog
      draggable
      resizable={false}
      open={isOpen}
      size={DialogSize.XL}
      className={styles['deal-search-dialog']}
      headerText={t('title')}
      primaryButton={
        <DialogPrimaryButton
          onClick={handleOnOkButton}
          design={ButtonDesign.Emphasized}
          disabled={!isAnyDealSelected()}
        >
          {tNoPrefix('buttons.ok')}
        </DialogPrimaryButton>
      }
      onBeforeClose={(e) => e?.detail?.escPressed && handleOnClose()}
      closeButton={
        <DialogSecondaryButton onClick={handleOnClose}>
          {tNoPrefix('buttons.cancel')}
        </DialogSecondaryButton>
      }
    >
      {!isLoadingAllowedOperations && !isAllowedToReadDeal ? (
        noPermissionsIllustratedMessage
      ) : (
        <>
          <DynamicPageHeader>
            <DealFilterBar
              onGo={handleFilterbarGo}
              isOpen={isOpen}
              initialValues={initialFilterValues}
              visibleFilters={visibleFilters}
              readOnlyFilters={readOnlyFilters}
            />
          </DynamicPageHeader>
          <div className={styles['table-wrapper']}>{renderTableAndMultiFooter()}</div>
        </>
      )}
    </Dialog>
  )
}

DealSearchDialog.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  setIsOpen: PropTypes.func.isRequired,
  onClose: PropTypes.func,
  onSelected: PropTypes.func.isRequired,
  isMultiSelect: PropTypes.bool.isRequired,
  columnsRestrictedTo: PropTypes.arrayOf(PropTypes.oneOf(Object.values(DealSearchTableColumn))),
  initialFilterValues: PropTypes.object,
  visibleFilters: PropTypes.arrayOf(PropTypes.oneOf(Object.values(DealFilterKeys))),
  readOnlyFilters: PropTypes.arrayOf(PropTypes.oneOf(Object.values(DealFilterKeys))),
  initiallySelectedDeals: PropTypes.arrayOf(
    PropTypes.shape({
      dealId: PropTypes.string.isRequired,
      dealName: PropTypes.string.isRequired,
      dealUuid: PropTypes.string.isRequired,
    }),
  ),
}

export default DealSearchDialog
