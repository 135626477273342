import { Button, ButtonDesign } from '@fioneer/ui5-webcomponents-react'
import PropTypes from 'prop-types'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import DocumentTypes from 'components/domains/documents/DocumentTypes'
import DocumentUploadDialog from 'components/domains/documents/DocumentUploadDialog'

/**
 * @param {object} props
 * @param {string} props.type Use `DocumentTypes`
 * @param {object} props.initialState Check the `DocumentUploadDialog` for available fields; Additionally there's `open`
 * @param {boolean} props.initialState.open
 * @param {(newFileIds: string[]) => void} props.onUploadFinished
 * @param {() => void} props.onClose
 */
const UploadDocumentButton = ({ type, initialState, onUploadFinished, onClose }) => {
  const [isOpen, setIsOpen] = useState(!!initialState?.open)
  const { t } = useTranslation(undefined, { keyPrefix: 'components.documents' })

  useEffect(() => {
    setIsOpen(!!initialState?.open)
  }, [initialState?.open])

  return (
    <>
      <Button onClick={() => setIsOpen(true)} design={ButtonDesign.Emphasized}>
        {t('upload')}
      </Button>
      <DocumentUploadDialog
        isOpen={isOpen}
        setIsOpen={setIsOpen}
        type={type}
        initialState={{ ...initialState, open: undefined }}
        onUploadFinished={onUploadFinished}
        onClose={onClose}
      />
    </>
  )
}

UploadDocumentButton.propTypes = {
  type: PropTypes.oneOf(Object.values(DocumentTypes)).isRequired,
  initialState: PropTypes.object,
  onUploadFinished: PropTypes.func,
  onClose: PropTypes.func,
}

export default UploadDocumentButton
