import { Label, WrappingType } from '@fioneer/ui5-webcomponents-react'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import { isMissingPermissionError, isNotFoundError } from 'api/requests'
import styles from 'components/domains/properties/properties-search/table/PropertyMarketName.module.css'
import Entity from 'components/ui/data/Entity'
import useDealMini from 'hooks/services/deals/useDealMini'

const PropertyDealCell = ({ dealId }) => {
  const { isLoading, isError, error, data: deal = {} } = useDealMini(dealId)
  const { t } = useTranslation()

  const renderErrorLabel = (value) => (
    <Label className={styles.errorLabel} wrappingType={WrappingType.Normal}>
      {value}
    </Label>
  )

  if (isLoading) return null
  if (isError && isMissingPermissionError(error))
    return renderErrorLabel(t('pages.properties.access-denied'))
  if (isError && isNotFoundError(error)) return renderErrorLabel(t('pages.properties.data-error'))
  if (isError) return renderErrorLabel(t('pages.properties.service-error'))

  const dealNotExist = !deal || !Object.keys(deal).length
  if (dealNotExist) return renderErrorLabel(t('pages.properties.data-error'))

  const dealNumber = deal.dealId

  return <Entity name={deal?.name} id={dealNumber} link={`/deals/${dealNumber}`} openInNewTab />
}

PropertyDealCell.propTypes = {
  dealId: PropTypes.string.isRequired,
}

export default PropertyDealCell
