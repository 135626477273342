import { FlexBox, FlexBoxDirection, Input, Label } from '@fioneer/ui5-webcomponents-react'
import { t } from 'i18next'
import PropTypes from 'prop-types'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import 'components/domains/properties/rent-roll/segment/segment-create/SegmentMarketRentInput.css'
import { useAreaMeasurementUnitFormatter } from 'hooks/i18n/useI18n'

const MONTHS_PER_YEAR = 12

const SegmentMarketRentInput = ({
  annuallyMarketRent,
  setAnnuallyMarketRent,
  currencyCode,
  areaMeasureUnit,
}) => {
  const NONE = 'none'
  const ANNUALLY = 'annually'
  const MONTHLY = 'monthly'

  const { t: tSegmentCreation } = useTranslation('translation', {
    keyPrefix: 'pages.rent-roll-working-version.segment-creation',
  })
  const formatArea = useAreaMeasurementUnitFormatter()

  const toFixedNumber = (number) => Number(number.toFixed(2))

  const [editMode, setEditMode] = useState(NONE)
  const [monthlyMarketRent, setMonthlyMarketRent] = useState(
    annuallyMarketRent ? toFixedNumber(Number(annuallyMarketRent) / MONTHS_PER_YEAR) : null,
  )
  const [isInitial, setIsInitial] = useState(true)

  useEffect(() => {
    if (annuallyMarketRent && editMode === ANNUALLY) {
      setMonthlyMarketRent(toFixedNumber(Number(annuallyMarketRent) / MONTHS_PER_YEAR))
      setIsInitial(false)
    } else if (!annuallyMarketRent) {
      setMonthlyMarketRent(null)
    }
  }, [annuallyMarketRent, editMode, isInitial])

  const getPerMeasurementLabel = () =>
    `${tSegmentCreation('market-rent-per-measure-unit')} ${formatArea(areaMeasureUnit)}:`

  const setOrCalculateAnnuallyMarketRent = (changedMarketRent, mode) => {
    if (mode === ANNUALLY) {
      setAnnuallyMarketRent(Number(changedMarketRent))
    } else if (mode === MONTHLY) {
      const fixedAnnualMarketRent = toFixedNumber(Number(changedMarketRent) * MONTHS_PER_YEAR)
      setAnnuallyMarketRent(fixedAnnualMarketRent)
    }
  }

  const handleOnInput = (e, mode) => {
    const changedMarketRent = e.target.value
    if (changedMarketRent) {
      setEditMode(mode)
      setOrCalculateAnnuallyMarketRent(changedMarketRent, mode)
    } else {
      setEditMode(NONE)
      setAnnuallyMarketRent(null)
    }
  }

  return (
    <FlexBox
      direction={FlexBoxDirection.Column}
      style={{ marginTop: '10px', gridColumn: 'span 4' }}
    >
      <Label className="segment-grouped-label">{getPerMeasurementLabel()}</Label>
      <Label>{`${t('labels.annually')}:`}</Label>
      <Input
        id="segment-market-rent-input-annually"
        disabled={editMode === MONTHLY}
        icon={<Label className="segment-market-rent-input-label">{currencyCode}</Label>}
        valueState={'None'}
        className="sapUiTinyMarginTop"
        value={annuallyMarketRent}
        onInput={(e) => handleOnInput(e, ANNUALLY)}
        onChange={() => setEditMode(NONE)}
        type="Number"
      />
      <Label>{`${t('labels.monthly')}:`}</Label>
      <Input
        id="segment-market-rent-input-monthly"
        disabled={editMode === ANNUALLY}
        icon={<Label className="segment-market-rent-input-label">{currencyCode}</Label>}
        valueState={'None'}
        className="sapUiTinyMarginTop"
        value={monthlyMarketRent}
        onInput={(e) => handleOnInput(e, MONTHLY)}
        onChange={() => setEditMode(NONE)}
        type="Number"
      />
    </FlexBox>
  )
}
SegmentMarketRentInput.propTypes = {
  annuallyMarketRent: PropTypes.number,
  setAnnuallyMarketRent: PropTypes.func.isRequired,
  currencyCode: PropTypes.string.isRequired,
  areaMeasureUnit: PropTypes.string.isRequired,
}
export default SegmentMarketRentInput
