import {
  FlexBox,
  Link,
  Text,
  Label,
  Icon,
  Popover,
  ObjectStatus,
} from '@fioneer/ui5-webcomponents-react'
import PropTypes from 'prop-types'
import { useMemo, useState } from 'react'
import { createPortal } from 'react-dom'
import { useTranslation } from 'react-i18next'
import 'components/domains/properties/PropertyCards.css'
import {
  propertyMonitoringStatus,
  monitoringStatusTranslationKeys,
  proxySettingsTranslationKeys,
} from 'api/property/monitoring/propertyMonitoringConfiguration'
import determineValueStateBasedOnMonitoringStatus from 'components/domains/properties/monitoring/determineValueStateBasedOnMonitoringStatus'
import DisplayCardView from 'components/ui/card/DisplayCardView'
import { useShortDateFormatter } from 'hooks/i18n/useI18n'

const hyphen = '-'

const PropertyMonitoringInfoCard = ({
  marketId,
  marketName,
  monitoringStatus,
  proxySettings,
  baselineDate,
  monitoringDate,
  nextManualMonitoringDate,
}) => {
  const { t } = useTranslation('translation', {
    keyPrefix: 'components.property-monitoring.info-card',
  })
  const { t: tNoPrefix } = useTranslation()
  const { format } = useShortDateFormatter()
  const [isInfoPopoverOpen, setIsInfoPopoverOpen] = useState(false)

  const marketValue = useMemo(() => {
    if (marketId) {
      const marketLink = `/markets/${marketId}`

      return (
        <Link href={marketLink} target="_blank">
          {marketName}
        </Link>
      )
    }
    return <Label>{hyphen}</Label>
  }, [marketId, marketName])

  const monitoringBaseline = useMemo(() => {
    if (
      monitoringStatus === propertyMonitoringStatus.activeManual ||
      monitoringStatus === propertyMonitoringStatus.activeManualForced ||
      monitoringStatus === propertyMonitoringStatus.inactive
    ) {
      return <Label>{hyphen}</Label>
    }
    if (baselineDate) {
      return <Label>{t('monitoring-baseline', { date: format(baselineDate) })}</Label>
    }
    return (
      <FlexBox>
        <Label>-</Label>
        <Icon
          id="property-monitoring-information-icon"
          name="information"
          onMouseEnter={() => {
            setIsInfoPopoverOpen(true)
          }}
          onMouseLeave={() => {
            setIsInfoPopoverOpen(false)
          }}
          style={{ paddingLeft: '8px' }}
        />
        {createPortal(
          <Popover opener="property-monitoring-information-icon" open={isInfoPopoverOpen}>
            <Text wrapping>{t('monitoring-baseline.info-popover')}</Text>
          </Popover>,
          document.body,
        )}
      </FlexBox>
    )
  }, [baselineDate, format, isInfoPopoverOpen, monitoringStatus, t])

  const macroProxies = useMemo(() => {
    if (proxySettings?.macro_proxies) {
      return marketId
        ? tNoPrefix(proxySettingsTranslationKeys[proxySettings?.macro_proxies])
        : tNoPrefix('property-monitoring.proxies.no-monitoring')
    }
    return hyphen
  }, [marketId, proxySettings?.macro_proxies, tNoPrefix])

  const nextMonitoringDate = useMemo(
    () =>
      nextManualMonitoringDate && monitoringStatus !== propertyMonitoringStatus.inactive
        ? format(nextManualMonitoringDate)
        : hyphen,
    [format, monitoringStatus, nextManualMonitoringDate],
  )

  const microProxySettings = useMemo(
    () =>
      proxySettings?.micro_proxies
        ? tNoPrefix(proxySettingsTranslationKeys[proxySettings?.micro_proxies])
        : hyphen,
    [proxySettings?.micro_proxies, tNoPrefix],
  )

  const monitoringDateFormattedValue = useMemo(
    () => (monitoringDate ? format(monitoringDate) : hyphen),
    [format, monitoringDate],
  )

  const objectStatus = useMemo(() => {
    const usedStatus = monitoringStatus ?? propertyMonitoringStatus.inactive
    return (
      <ObjectStatus inverted state={determineValueStateBasedOnMonitoringStatus(usedStatus)}>
        {tNoPrefix(monitoringStatusTranslationKeys[usedStatus])}
      </ObjectStatus>
    )
  }, [monitoringStatus, tNoPrefix])

  const noOp = () => {}
  return (
    <DisplayCardView
      cardHeaderTitle={t('title')}
      isEditable={false}
      setEditMode={noOp}
      fieldDefinitions={[
        { label: t('monitoring-status.label'), value: objectStatus },
        { label: t('monitoring-date.label'), value: monitoringDateFormattedValue },
        { label: t('next-monitoring-date.label'), value: nextMonitoringDate },
        { label: t('monitoring-baseline.label'), value: monitoringBaseline },
        { label: t('associated-to-market.label'), value: marketValue },
        { label: t('microeconomic-proxy-setting.label'), value: microProxySettings },
        { label: t('macroecenemic-proxy-setting.label'), value: macroProxies },
      ]}
    />
  )
}
PropertyMonitoringInfoCard.propTypes = {
  marketId: PropTypes.string,
  marketName: PropTypes.string,
  monitoringStatus: PropTypes.oneOf(Object.values(propertyMonitoringStatus)),
  proxySettings: PropTypes.shape({
    micro_proxies: PropTypes.oneOf(Object.keys(proxySettingsTranslationKeys)).isRequired,
    macro_proxies: PropTypes.oneOf(Object.keys(proxySettingsTranslationKeys)).isRequired,
  }),
  baselineDate: PropTypes.string,
  monitoringDate: PropTypes.string,
  nextManualMonitoringDate: PropTypes.string,
}

export default PropertyMonitoringInfoCard
