import { isMissingPermissionError } from 'api/requests'
import { useRequest } from 'hooks/services/baseService'
import { useCamelizedResponse } from 'hooks/services/queryHelper'
import useDataSourceQueryParams from 'routes/deals/financing/utils/useDataSourceQueryParams'

const useFinancingMini = (dealUuid, forceDataSource) => {
  const {
    dataSourceQueryKeys: automaticDataSourceQueryKeys,
    dataSourceQueryParam: automaticDataSourceQueryParam,
  } = useDataSourceQueryParams()

  let dataSourceQueryKeys, dataSourceQueryParam
  if (forceDataSource) {
    const queryParams = new URLSearchParams()
    queryParams.append('dataSource', forceDataSource)
    dataSourceQueryParam = queryParams.toString()
    dataSourceQueryKeys = queryParams.toString().split('=')
  } else {
    dataSourceQueryKeys = automaticDataSourceQueryKeys
    dataSourceQueryParam = automaticDataSourceQueryParam
  }

  const result = useCamelizedResponse(
    useRequest({
      path: `/financing-structures/deals/${dealUuid}/tranches/mini?${dataSourceQueryParam}`,
      translated: true,
      keys: ['deals', dealUuid, 'tranches', 'mini', ...dataSourceQueryKeys],
      options: {
        enabled: !!dealUuid,
        retry: (_, error) => !isMissingPermissionError(error),
      },
    }),
  )

  return {
    ...result,
    data: result?.data,
  }
}

export default useFinancingMini
