import { ObjectPageSection, Title, TitleLevel } from '@fioneer/ui5-webcomponents-react'
import PropType from 'prop-types'
import { useCallback, useContext } from 'react'
import { useTranslation } from 'react-i18next'
import CustomAssessments from 'components/domains/custom-assessments/CustomAssessments'
import PropertyUnderReviewMessageStrip from 'components/domains/properties/common/PropertyUnderReviewMessageStrip'
import styles from 'components/domains/properties/general-information/PropertyGeneralInformation.module.css'
import PropertyAddressCard from 'components/domains/properties/general-information/address/PropertyAddressCard'
import PropertyBusinessPartnerCard from 'components/domains/properties/general-information/businessPartners/PropertyBusinessPartnerCard'
import PropertyCommentCard from 'components/domains/properties/general-information/comment/PropertyCommentCard'
import ConstructionDatesCard from 'components/domains/properties/general-information/construction/ConstructionDatesCard'
import PropertyCostsCard from 'components/domains/properties/general-information/costs/PropertyCostsCard'
import PropertyCustomFieldCard from 'components/domains/properties/general-information/customfield/PropertyCustomFieldCard'
import PropertyExternalIdCard from 'components/domains/properties/general-information/external-ids/PropertyExternalIdCard'
import PropertyImageCard from 'components/domains/properties/general-information/image/PropertyImageCard'
import PropertyInfoCard from 'components/domains/properties/general-information/info/PropertyInfoCard'
import PropertyKeyDataCard from 'components/domains/properties/general-information/keyData/PropertyKeyDataCard'
import PropertyRatingCard from 'components/domains/properties/general-information/rating/PropertyRatingCard'
import PropertyStatusCard from 'components/domains/properties/general-information/status/PropertyStatusCard'
import PropertyValuationCard from 'components/domains/properties/general-information/valuation/PropertyValuationCard'
import CWPLayout from 'components/ui/layout/CWPLayout'
import TabPage from 'components/ui/page/TabPage'
import { cwpEntityTypes } from 'constants/cwpEntityTypes'
import useCustomAssessmentsHelper from 'hooks/services/central-data/custom-assessments/useCustomAssessmentsHelper'
import { PropertyContext } from 'routes/properties/PropertyContext'

const PropertyGeneralInformation = ({ header }) => {
  const { t } = useTranslation('translation', {
    keyPrefix: 'pages.property.general-information',
  })
  const { allowedOperations, property } = useContext(PropertyContext)

  const {
    isFetching: isCustomAssessmentsFetching,
    isError: isCustomAssessmentsError,
    showCustomAssessments,
  } = useCustomAssessmentsHelper({
    entityType: cwpEntityTypes.PROPERTY,
  })

  const displayValuationCard = allowedOperations?.includes('PropertyValuation_Read')
  const isCommentEditAllowed =
    property?.change_request_exist_indicator === false &&
    allowedOperations?.includes('PropertyComment_Update')

  const renderDescriptionTab = () => (
    <ObjectPageSection
      id={'description-tab'}
      key={'description-tab'}
      titleText={t('description-tab')}
    >
      <PropertyUnderReviewMessageStrip className={styles.messageStrip} />
      <CWPLayout>
        <PropertyInfoCard />
        <PropertyCustomFieldCard />
        <ConstructionDatesCard />
        <PropertyStatusCard />
        <PropertyImageCard />
        <PropertyAddressCard />
        <PropertyBusinessPartnerCard />
        <PropertyExternalIdCard />
      </CWPLayout>
    </ObjectPageSection>
  )

  const renderValuationTab = () => (
    <ObjectPageSection id={'valuation-tab'} key={'valuation-tab'} titleText={t('valuation-tab')}>
      <PropertyUnderReviewMessageStrip className={styles.messageStrip} />
      <CWPLayout>
        <CWPLayout.Full>
          <PropertyCostsCard />
        </CWPLayout.Full>

        {displayValuationCard && <PropertyValuationCard />}
        <PropertyKeyDataCard />
      </CWPLayout>
    </ObjectPageSection>
  )

  const renderRatingTab = () => (
    <ObjectPageSection id={'rating-tab'} key={'rating-tab'} titleText={t('rating-tab')}>
      <PropertyUnderReviewMessageStrip className={styles.messageStrip} />
      <PropertyRatingCard />
    </ObjectPageSection>
  )

  const renderAssessmentsTab = useCallback(() => {
    const standardAssessmentCard = (
      <CWPLayout>
        <CWPLayout.Full>
          <PropertyCommentCard propertyUuid={property.uuid} isEditEnabled={isCommentEditAllowed} />
        </CWPLayout.Full>
      </CWPLayout>
    )
    // show custom assessments subsection when custom assessments are loading or have an error to display loading/error screen
    const showCustomAssessmentsSubsection =
      showCustomAssessments || isCustomAssessmentsFetching || isCustomAssessmentsError
    const standardAssessmentSubsection = showCustomAssessmentsSubsection ? (
      <>
        <Title level={TitleLevel.H3} className={styles.subsectionTitle}>
          {t('assessments-tab.subsections.standard-assessments.title')}
        </Title>
        {standardAssessmentCard}
      </>
    ) : (
      standardAssessmentCard
    )
    const customAssessmentsSubsection = showCustomAssessmentsSubsection && (
      <>
        <Title level={TitleLevel.H3} className={styles.subsectionTitle}>
          {t('assessments-tab.subsections.custom-assessments.title')}
        </Title>
        <CustomAssessments entityId={property.uuid} entityType={cwpEntityTypes.PROPERTY} />
      </>
    )
    return (
      <ObjectPageSection id={'assessments-tab'} titleText={t('assessments-tab.title')}>
        {standardAssessmentSubsection}
        {customAssessmentsSubsection}
      </ObjectPageSection>
    )
  }, [
    isCommentEditAllowed,
    isCustomAssessmentsError,
    isCustomAssessmentsFetching,
    property.uuid,
    showCustomAssessments,
    t,
  ])

  return (
    <TabPage headerTitle={header} defaultSectionId="description-tab">
      {renderDescriptionTab()}
      {renderValuationTab()}
      {renderRatingTab()}
      {renderAssessmentsTab()}
    </TabPage>
  )
}
PropertyGeneralInformation.propTypes = {
  header: PropType.any.isRequired, // eslint-disable-line react/forbid-prop-types
}

export default PropertyGeneralInformation
