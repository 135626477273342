import { WrappingType } from '@fioneer/ui5-webcomponents-react'
import PropTypes from 'prop-types'
import { useContext } from 'react'
import Link from 'components/ui/link/Link'
import { cwpEntityTypes } from 'constants/cwpEntityTypes'
import { ConditionsContext } from 'routes/conditions/ConditionsContext'
import { DealContext } from 'routes/deals/DealContext'
import paths from 'routes/paths'

const ConditionsCovenantCheckLink = ({ covenantCheckId, covenantCheckName }) => {
  const {
    entityRef: { entityId, entityType },
  } = useContext(ConditionsContext)
  const dealContext = useContext(DealContext)

  const isDeal = entityType === cwpEntityTypes.DEAL
  const dealId = dealContext?.deal?.displayId
  const covenantCheckPart = `covenants/covenant-checks/${covenantCheckId}`
  const href = isDeal
    ? `/${paths.deals}/${dealId}/${covenantCheckPart}`
    : `/${paths.businessPartners}/${entityId}/${covenantCheckPart}`

  return (
    <Link href={href} wrappingType={WrappingType.Normal}>
      {covenantCheckName}
    </Link>
  )
}

ConditionsCovenantCheckLink.propTypes = {
  covenantCheckId: PropTypes.string,
  covenantCheckName: PropTypes.string,
}

export default ConditionsCovenantCheckLink
