import {
  FlexBoxJustifyContent,
  ObjectStatus,
  TableGrowingMode,
  TableMode,
} from '@fioneer/ui5-webcomponents-react'
import PropTypes from 'prop-types'
import { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import caseInsensitiveOrderBy from 'components/domains/business-partners/caseInsensitiveOrderBy'
import BusinessPartnerRolesView from 'components/domains/business-partners/roles/BusinessPartnerRolesView'
import IndustryCellComponent from 'components/domains/rentroll/IndustryCellComponent'
import Entity from 'components/ui/data/Entity'
import SortedTable from 'components/ui/tables/sorted-tables/SortedTable'
import SortedTableErrorMessage from 'components/ui/tables/sorted-tables/SortedTableErrorMessage'
import { useNumberFormatter } from 'hooks/i18n/useI18n'
import determineValueStateBasedOnStatus from 'routes/business-partners/determineValueState'

const BusinessPartnerTable = ({
  businessPartners,
  onChange,
  isLoading,
  isError,
  hasNextPage,
  fetchNextPage,
  total,
  isMultiSelect = false,
  selectedBusinessPartnerIds = [],
}) => {
  const { t } = useTranslation(undefined, { keyPrefix: 'pages.business-partner-search' })
  const { t: noPrefixT } = useTranslation()
  const numberFormatter = useNumberFormatter()
  const disabledSort = true

  const columnDefinitions = useMemo(
    () => [
      {
        title: t('table.score.label'),
        columnKey: 'score',
      },
      {
        title: t('table.toolbar.title', { count: 1 }),
        columnKey: 'businessPartner',
      },
      {
        title: t('table.country.label'),
        columnKey: 'countries',
        sortingDisabled: disabledSort,
      },
      {
        title: t('table.postal-code.label'),
        columnKey: 'postalCode',
        sortingDisabled: disabledSort,
        alignment: FlexBoxJustifyContent.End,
      },
      {
        title: t('table.city.label'),
        columnKey: 'city',
        sortingDisabled: disabledSort,
      },
      {
        title: t('table.street.label'),
        columnKey: 'streetName',
        sortingDisabled: disabledSort,
      },
      {
        title: t('table.roles.label'),
        columnKey: 'roles',
        sortingDisabled: disabledSort,
      },
      {
        title: t('table.industry.label'),
        columnKey: 'industry',
        sortingDisabled: disabledSort,
      },
      {
        title: t('table.status.label'),
        columnKey: 'status',
        sortingDisabled: disabledSort,
      },
    ],
    [t],
  )

  const tableData = useMemo(
    () =>
      businessPartners.map(
        ({
          id,
          name,
          address: { country, postalCode, city, streetName },
          status,
          roles,
          searchScore,
        }) => ({
          rowKey: id,
          score: {
            cellComponent: <>{numberFormatter(searchScore)}</>,
            value: searchScore,
          },
          businessPartner: {
            cellComponent: (
              <Entity name={name} id={id} link={`/business-partners/${id}`} openInNewTab />
            ),
            value: name,
          },
          countries: {
            cellComponent: <>{country?.name}</>,
            value: country?.name,
          },
          postalCode: {
            cellComponent: <>{postalCode}</>,
            value: postalCode,
          },
          city: {
            cellComponent: <>{city}</>,
            value: city,
          },
          streetName: {
            cellComponent: <>{streetName}</>,
            value: streetName,
          },
          rowProperties: {
            name,
            id,
            selected: selectedBusinessPartnerIds.includes(id),
          },
          roles: {
            cellComponent: (
              <BusinessPartnerRolesView
                roles={
                  roles?.map(({ name: roleName, ...role }) => ({
                    ...role,
                    name: noPrefixT(`components.business-partner.roles.${roleName}`),
                  })) ?? []
                }
              />
            ),
          },
          industry: { cellComponent: <IndustryCellComponent id={id} /> },
          status: {
            cellComponent: (
              <ObjectStatus inverted state={determineValueStateBasedOnStatus(status)}>
                {t(status, { keyPrefix: 'pages.business-partner.general-information.status' })}
              </ObjectStatus>
            ),
            value: status,
          },
        }),
      ),
    [businessPartners, numberFormatter, selectedBusinessPartnerIds, t, noPrefixT],
  )

  const handleSelection = ({ detail }) => {
    onChange(
      detail.selectedRows?.map(({ attributes }) => attributes?.id?.value).filter((id) => !!id),
    )
  }

  return (
    <>
      {isError ? (
        <SortedTableErrorMessage />
      ) : (
        <SortedTable
          columnDefinitions={columnDefinitions}
          tableData={tableData}
          customOrderFunction={caseInsensitiveOrderBy}
          noDataText={t('no-results')}
          additionalTableProperties={{
            onSelectionChange: handleSelection,
            mode: isMultiSelect ? TableMode.MultiSelect : TableMode.SingleSelect,
            onFocus: (event) => event.stopPropagation(),
            busy: isLoading,
          }}
          toolbarConfig={{
            title: t('table.toolbar.title', { count: tableData.length }),
            sorting: {
              isSortingAscending: false,
              columnKey: 'score',
            },
          }}
          paginationConfig={{
            growing: hasNextPage ? TableGrowingMode.Button : undefined,
            growingButtonText: t('growing-button-text'),
            growingButtonSubtext: '[ ' + businessPartners.length + ' / ' + total + ' ]',
            totalNumberOfItems: total,
            loadMore: () => fetchNextPage(),
          }}
        />
      )}
    </>
  )
}

BusinessPartnerTable.propTypes = {
  businessPartners: PropTypes.array.isRequired,
  onChange: PropTypes.func,
  hasNextPage: PropTypes.bool.isRequired,
  fetchNextPage: PropTypes.func.isRequired,
  total: PropTypes.number.isRequired,
  isLoading: PropTypes.bool,
  isError: PropTypes.bool,
  isMultiSelect: PropTypes.bool,
  selectedBusinessPartnerIds: PropTypes.array,
}

export default BusinessPartnerTable
