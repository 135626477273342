import get from 'lodash.get'
import sortBy from 'lodash.sortby'
import uniqBy from 'lodash.uniqby'
import { useTranslation } from 'react-i18next'
import {
  AddressCell,
  AggregatedCurrentRentalIncomeCell,
  AggregatedLettableAreaCell,
  AggregatedMoneyValueCell,
  AggregatedWaultCell,
  CityCell,
  CountryCell,
  CurrentRentalIncomeCell,
  DescriptionCell,
  LettableAreaCell,
  MoneyValueCell,
  PropertyStatusCell,
  PropertyTypeCell,
  WaultCell,
} from 'components/domains/properties/portfolio/overview/TableCells'
import { renderAnalyticalTableCell } from 'components/ui/tables/analytical/AnalyticalTableCell'
import {
  useAreaMeasurementUnitFormatter,
  useCustomizableCurrencyFormatter,
  useNumberFormatter,
  usePercentageFormatter,
} from 'hooks/i18n/useI18n'
import useMultiPropertiesKpis from 'hooks/services/properties/kpis/useMultiPropertiesKpis'

const usePropertyPortfolioOverviewTableColumns = ({
  multiPropertyKpis,
  valuationSum,
  properties,
}) => {
  const MARKET_VALUE = 'marketValue'
  const MORTGAGE_LENDING_VALUE = 'mortgageLendingValue'

  const { t } = useTranslation('translation', {
    keyPrefix: 'pages.properties.portfolio.overview',
  })
  const formatNumber = useNumberFormatter()
  const formatWault = useNumberFormatter({
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  })
  const formatPercent = usePercentageFormatter({ maximumFractionDigits: 2 })
  const formatAreaUnit = useAreaMeasurementUnitFormatter()
  const doFormatCurrency = useCustomizableCurrencyFormatter()
  const formatCurrency = (value, currency) => currency && doFormatCurrency(value, currency)

  const extractCodesFromProperty = (codeAccessor, nameAccessor) =>
    sortBy(
      uniqBy(
        properties
          ?.map((property) => {
            const countryCode = get(property, codeAccessor, null)
            const countryName = get(property, nameAccessor, null)
            if (countryCode && countryName) {
              return {
                key: countryCode,
                displayName: countryName,
              }
            } else {
              return null
            }
          })
          .filter((countryCode) => countryCode !== null),
        'key',
      ),
      'displayName',
    )

  const relatedCountryCodes = extractCodesFromProperty('address.countryCode', 'address.countryName')

  const relatedTypeCodes = extractCodesFromProperty('typeCode', 'typeName')

  const getEnumValuesFromCodes = (codes) => {
    const enumValues = {}
    codes.forEach((code) => {
      if (code.key && code.displayName) {
        enumValues[code.key] = code.displayName
      }
    })
    return enumValues
  }

  const findPortfolioCurrencyByKpis = (kpis) => {
    for (const key in kpis) {
      if (typeof kpis[key] === 'object') {
        const result = findPortfolioCurrencyByKpis(kpis[key])
        if (result) {
          return result
        }
      } else if (key === 'currency' && kpis[key] !== undefined) {
        return kpis[key]
      }
    }
    return undefined
  }

  const portfolioCurrency = findPortfolioCurrencyByKpis(multiPropertyKpis)

  const tableHelpers = {
    portfolioCurrency,
    formatNumber,
    formatCurrency,
    formatPercent,
    formatAreaUnit,
    formatWault,
    multiPropertyKpis,
    valuationSum,
    t,
    useMultiPropertiesKpis, // For aggregation requests
  }

  const defaultDisables = {
    disableDragAndDrop: true,
    disableGlobalFilter: true,
  }

  const aggregateValues = (leafValues) => [...new Set(leafValues)].filter((v) => v).join(', ')

  const getValueByAccessorOrDefault = (accessor) => (row) =>
    get(row, accessor) || t('table.columns.no-data')

  const getFilteredValuationValue = (classificationAccessor, valueAccessor) => (row) => {
    const valuationByClassification = get(row, classificationAccessor)
    const isValidConversion = valuationByClassification?.isValidConversion
    if (typeof isValidConversion === 'boolean' && !isValidConversion) {
      return 0
    } else {
      return get(valuationByClassification, valueAccessor) || 0
    }
  }

  return {
    data: [
      {
        Header: t('table.columns.property'),
        filterLabel: t('table.columns.property-name'),
        sortByLabel: t('table.columns.property-name'),
        id: 'description',
        accessor: 'description',
        Cell: renderAnalyticalTableCell(DescriptionCell, tableHelpers),
        Aggregated: renderAnalyticalTableCell(DescriptionCell, tableHelpers, true),
        filterType: 'CONTAINS',
        disableGroupBy: true,
        dialogOrder: 0,
        minWidth: 250,
        ...defaultDisables,
      },
      {
        Header: t('table.columns.property-status'),
        sortByLabel: t('table.columns.property-status'),
        groupByLabel: t('table.columns.property-status'),
        id: 'property_status',
        accessor: 'propertyStatus.text',
        Cell: renderAnalyticalTableCell(PropertyStatusCell, tableHelpers),
        Aggregated: renderAnalyticalTableCell(PropertyStatusCell, tableHelpers, true),
        hide: true,
        disableFilters: true,
        dialogOrder: 1,
        ...defaultDisables,
      },
      {
        Header: t('table.columns.property-type'),
        filterLabel: t('table.columns.property-type'),
        groupByLabel: t('table.columns.property-type'),
        sortByLabel: t('table.columns.property-type'),
        accessor: getValueByAccessorOrDefault('typeName'),
        id: 'type_name',
        filterType: 'OF_ENUM_TYPE',
        additionalFilterOptions: {
          enumValues: getEnumValuesFromCodes(relatedTypeCodes),
        },
        Cell: renderAnalyticalTableCell(PropertyTypeCell, tableHelpers),
        Aggregated: renderAnalyticalTableCell(PropertyTypeCell, tableHelpers, true),
        aggregate: aggregateValues,
        dialogOrder: 1,
        disableSortBy: true,
        hide: true,
        ...defaultDisables,
      },
      {
        Header: t('table.columns.address'),
        id: 'address',
        Cell: renderAnalyticalTableCell(AddressCell, tableHelpers),
        Aggregated: renderAnalyticalTableCell(AddressCell, tableHelpers, true),
        disableSortBy: true,
        disableFilters: true,
        disableGroupBy: true,
        minWidth: 250,
        ...defaultDisables,
      },

      {
        Header: t('table.columns.country'),
        sortByLabel: t('table.columns.country'),
        groupByLabel: t('table.columns.country'),
        filterLabel: t('table.columns.country'),
        accessor: getValueByAccessorOrDefault('address.countryName'),
        id: 'country',
        Cell: renderAnalyticalTableCell(CountryCell, tableHelpers),
        Aggregated: renderAnalyticalTableCell(CountryCell, tableHelpers, true),
        filterType: 'OF_ENUM_TYPE',
        additionalFilterOptions: {
          enumValues: getEnumValuesFromCodes(relatedCountryCodes),
        },
        dialogOrder: 2,
        hide: true,
        disableSortBy: true,
        ...defaultDisables,
      },
      {
        Header: t('table.columns.city'),
        sortByLabel: t('table.columns.city'),
        groupByLabel: t('table.columns.city'),
        filterLabel: t('table.columns.city'),
        accessor: getValueByAccessorOrDefault('address.cityName'),
        id: 'city',
        filterType: 'CONTAINS',
        Cell: renderAnalyticalTableCell(CityCell, tableHelpers),
        Aggregated: renderAnalyticalTableCell(CityCell, tableHelpers, true),
        dialogOrder: 3,
        hide: true,
        disableSortBy: true,
        ...defaultDisables,
      },
      {
        Header: t('table.columns.market-value'),
        id: 'market_value',
        accessor: getFilteredValuationValue('valuations.marketValue', 'valueAmount.number'),
        Cell: renderAnalyticalTableCell(MoneyValueCell, {
          ...tableHelpers,
          classification: MARKET_VALUE,
        }),
        Aggregated: renderAnalyticalTableCell(AggregatedMoneyValueCell, {
          ...tableHelpers,
          classification: MARKET_VALUE,
        }),
        aggregate: 'sum',
        hAlign: 'right',
        vAlign: 'top',
        filterType: 'BETWEEN_NUMBERS',
        dialogOrder: 4,
        disableGroupBy: true,
        minWidth: 200,
        ...defaultDisables,
      },
      {
        Header: t('table.columns.mortgage-lending-value'),
        id: 'mortgage_lending_value',
        accessor: getFilteredValuationValue(
          'valuations.mortgageLendingValue',
          'valueAmount.number',
        ),
        Cell: renderAnalyticalTableCell(MoneyValueCell, {
          ...tableHelpers,
          classification: MORTGAGE_LENDING_VALUE,
        }),
        Aggregated: renderAnalyticalTableCell(AggregatedMoneyValueCell, {
          ...tableHelpers,
          classification: MORTGAGE_LENDING_VALUE,
        }),
        aggregate: 'sum',
        hAlign: 'right',
        vAlign: 'top',
        filterType: 'BETWEEN_NUMBERS',
        dialogOrder: 5,
        disableGroupBy: true,
        minWidth: 200,
        ...defaultDisables,
      },
      {
        Header: t('table.columns.lettable-area'),
        id: 'lettable_area',
        accessor: 'kpis.totalAreaSurface.value',
        Cell: renderAnalyticalTableCell(LettableAreaCell, {
          ...tableHelpers,
        }),
        Aggregated: renderAnalyticalTableCell(AggregatedLettableAreaCell, {
          ...tableHelpers,
        }),
        aggregate: 'sum',
        hAlign: 'right',
        vAlign: 'top',
        filterType: 'BETWEEN_NUMBERS',
        dialogOrder: 6,
        disableGroupBy: true,
        minWidth: 200,
        ...defaultDisables,
      },
      // {
      //   hide: true,
      //   Header: t('table.columns.number-of-units'),
      //   id: 'number_of_units',
      //   accessor: 'kpis.totalNumberOfUnits',
      //   Cell: () => {},
      //   Aggregated: () => {},
      //   filterType: 'BETWEEN_NUMBERS',
      //   dialogOrder: 7,
      //   disableGroupBy: true,
      //   ...defaultDisables,
      // },
      {
        Header: t('table.columns.current-rental-income'),
        id: 'rental_income',
        accessor: 'kpis.annualizedCurrentRent.number',
        Cell: renderAnalyticalTableCell(CurrentRentalIncomeCell, {
          ...tableHelpers,
        }),
        Aggregated: renderAnalyticalTableCell(AggregatedCurrentRentalIncomeCell, {
          ...tableHelpers,
        }),
        aggregate: 'sum',
        hAlign: 'right',
        vAlign: 'top',
        filterType: 'BETWEEN_NUMBERS',
        dialogOrder: 8,
        disableGroupBy: true,
        minWidth: 200,
        ...defaultDisables,
      },
      {
        Header: t('table.columns.wault-break'),
        id: 'wault_break',
        accessor: 'kpis.waultToBreakInYears',
        Cell: renderAnalyticalTableCell(WaultCell, {
          ...tableHelpers,
        }),
        Aggregated: renderAnalyticalTableCell(AggregatedWaultCell, {
          ...tableHelpers,
        }),
        aggregate: 'sum',
        hAlign: 'right',
        vAlign: 'top',
        filterType: 'BETWEEN_NUMBERS',
        dialogOrder: 9,
        disableGroupBy: true,
        minWidth: 200,
        ...defaultDisables,
      },
      {
        hide: true,
        Header: t('table.columns.wault-expiry'),
        id: 'wault_expiry',
        accessor: 'kpis.waultToExpiryInYears',
        Cell: () => {},
        Aggregated: () => {},
        filterType: 'BETWEEN_NUMBERS',
        dialogOrder: 10,
        disableGroupBy: true,
        disableSortBy: true,
        ...defaultDisables,
      },
    ],
  }
}

export default usePropertyPortfolioOverviewTableColumns
