import '@ui5/webcomponents-icons/dist/AllIcons.js'
import { FlexBox, Table } from '@fioneer/ui5-webcomponents-react'
import { PropTypes } from 'prop-types'

export const alignTableContent = (content, alignment) =>
  alignment ? <FlexBox justifyContent={alignment}>{content}</FlexBox> : content

const ModularTable = ({
  columns,
  noDataText,
  paginationConfig,
  additionalTableProperties,
  children: tableRows,
}) => (
  <Table
    columns={columns}
    growing={paginationConfig.growing}
    growingButtonText={paginationConfig.growingButtonText}
    growingButtonSubtext={paginationConfig.growingButtonSubtext}
    noDataText={noDataText}
    onLoadMore={paginationConfig.loadMore}
    {...additionalTableProperties}
  >
    {tableRows}
  </Table>
)

ModularTable.propTypes = {
  columns: PropTypes.node,
  noDataText: PropTypes.string,
  children: PropTypes.node,
  additionalTableProperties: PropTypes.object,
  paginationConfig: PropTypes.shape({
    growing: PropTypes.string,
    growingButtonText: PropTypes.string,
    growingButtonSubtext: PropTypes.string,
    totalNumberOfItems: PropTypes.number,
    loadMore: PropTypes.func,
  }),
}

export default ModularTable
