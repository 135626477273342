import '@ui5/webcomponents-fiori/dist/illustrations/EmptyList.js'
import { Button, List } from '@fioneer/ui5-webcomponents-react'
import PropTypes from 'prop-types'
import { useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import KeyInternalContact from 'components/domains/business-partners/tile/general-information/keyInternalContact/KeyInternalContact'
import KeyInternalContactEdit from 'components/domains/business-partners/tile/general-information/keyInternalContact/KeyInternalContactEdit'
import Card from 'components/ui/card/Card'
import CardHeaderWithEditMode from 'components/ui/card/CardHeaderWithEditMode'
import EmptyCardContent from 'components/ui/card/EmptyCardContent'
import ErrorDialog from 'components/ui/dialog/ErrorDialog'
import { ErrorDataUnavailableInContent } from 'components/ui/errors/ErrorDataUnavailableInContent'
import { RequestStateResolver } from 'components/ui/loading/RequestStateResolver'

function KeyInternalContactsTile({
  hasEditPermissions = false,
  keyInternalContacts,
  addEmptyKeyInternalContact,
  saveKeyInternalContacts,
  editKeyInternalContact,
  removeKeyInternalContact,
  resetKeyInternalContacts,
  isErrorDialogOpen,
  setIsErrorDialogOpen,
  errorMessage,
  isLoading,
  renderMultiSelectComponent,
}) {
  const { t } = useTranslation('translation')
  const [isEditMode, setIsEditMode] = useState(false)

  // Keep edit mode open if there is an error onSave
  useEffect(() => {
    if (isErrorDialogOpen) {
      setIsEditMode(true)
    }
  }, [isErrorDialogOpen])

  const handleToggle = (isEditOpen) => {
    setIsEditMode(isEditOpen)
  }

  const handleCancel = () => {
    resetKeyInternalContacts()
  }

  const handleSave = () => {
    saveKeyInternalContacts()
  }

  // Closure for holding index for editKeyInternalContact
  const createEditKeyInternalContactFn = (index) => {
    const editKeyInternalContactWithIndex = (...params) => editKeyInternalContact(...params, index)
    return editKeyInternalContactWithIndex
  }

  // Closure for holding index for removeKeyInternalContact
  const createRemoveKeyInternalContactFn = (index) => {
    const removeKeyInternalContactWithIndex = () => removeKeyInternalContact(index)
    return removeKeyInternalContactWithIndex
  }

  const keyInternalContactUserIds = useMemo(
    () => keyInternalContacts.map(({ userId }) => userId).filter((userId) => !!userId),
    [keyInternalContacts],
  )

  const isSaveDisabled = () => keyInternalContacts.some(({ userId }) => !userId)

  return (
    <Card
      header={
        <CardHeaderWithEditMode
          showActions={hasEditPermissions}
          isEditMode={isEditMode}
          titleText={t(
            'components.business-partners.general-information.key-internal-contacts.title',
          )}
          onSave={handleSave}
          onToggle={handleToggle}
          onCancel={handleCancel}
          checkSaveDisabled={isSaveDisabled}
          additionalActions={
            <Button onClick={addEmptyKeyInternalContact} design="Transparent">
              {t('buttons.add')}
            </Button>
          }
        />
      }
    >
      <RequestStateResolver
        isLoading={isLoading}
        isError={false}
        errorToDisplay={<ErrorDataUnavailableInContent />}
        center={true}
        renderContent={() => (
          <>
            {isEditMode ? (
              <List>
                {keyInternalContacts.map(({ userId, functions = [] }, index) => (
                  <KeyInternalContactEdit
                    key={userId || index}
                    id={userId}
                    functions={functions}
                    editKeyInternalContact={createEditKeyInternalContactFn(index)}
                    removeKeyInternalContact={createRemoveKeyInternalContactFn(index)}
                    renderMultiSelectComponent={renderMultiSelectComponent}
                    userIdsToExcludeFromSuggestions={keyInternalContactUserIds}
                  />
                ))}
              </List>
            ) : (
              keyInternalContacts.map(({ userId, functions = [] }) => (
                <KeyInternalContact key={userId} id={userId} functions={functions} />
              ))
            )}

            {!keyInternalContacts.length && <EmptyCardContent />}
            <ErrorDialog
              isOpen={isErrorDialogOpen}
              setIsOpen={setIsErrorDialogOpen}
              texts={{
                cancelButton: t('buttons.close'),
                title: t(
                  'components.business-partners.general-information.key-internal-contacts.error.title',
                ),
                description:
                  errorMessage ||
                  t(
                    'components.business-partners.general-information.key-internal-contacts.update.error',
                  ),
              }}
            />
          </>
        )}
      />
    </Card>
  )
}

KeyInternalContactsTile.propTypes = {
  keyInternalContacts: PropTypes.arrayOf(
    PropTypes.shape({
      userId: PropTypes.string.isRequired,
      functions: PropTypes.arrayOf(
        PropTypes.shape({
          id: PropTypes.string.isRequired,
          modifiable: PropTypes.bool.isRequired,
          name: PropTypes.string.isRequired,
        }),
      ),
    }),
  ),
  hasEditPermissions: PropTypes.bool,
  addEmptyKeyInternalContact: PropTypes.func.isRequired,
  saveKeyInternalContacts: PropTypes.func.isRequired,
  editKeyInternalContact: PropTypes.func.isRequired,
  removeKeyInternalContact: PropTypes.func.isRequired,
  resetKeyInternalContacts: PropTypes.func.isRequired,
  isErrorDialogOpen: PropTypes.bool.isRequired,
  setIsErrorDialogOpen: PropTypes.func.isRequired,
  isLoading: PropTypes.bool.isRequired,
  errorMessage: PropTypes.string,
  renderMultiSelectComponent: PropTypes.func.isRequired,
}

export default KeyInternalContactsTile
