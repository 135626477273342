import {
  FlexBox,
  FlexBoxAlignItems,
  FlexBoxDirection,
  FlexBoxJustifyContent,
} from '@fioneer/ui5-webcomponents-react'
import PropTypes from 'prop-types'
import styles from 'components/domains/properties/rent-roll/overview/PropertyRentRollOverviewColumns.module.css'

export const AlignedColumn = ({ children, alignment }) => (
  <FlexBox
    className={styles.tableColumnPadding}
    direction={FlexBoxDirection.Row}
    justifyContent={alignment ?? FlexBoxJustifyContent.Start}
    alignItems={FlexBoxAlignItems.End}
  >
    {children}
  </FlexBox>
)
AlignedColumn.propTypes = {
  children: PropTypes.any,
  alignment: PropTypes.any,
}

export const CenterAlignedMultiColumn = ({ title, subTitles }) => (
  <FlexBox
    className={styles.tableColumnPadding}
    direction={FlexBoxDirection.Column}
    justifyContent={FlexBoxJustifyContent.Start}
    alignItems={FlexBoxAlignItems.Stretch}
  >
    <FlexBox
      className={styles.tableMultiColumnHeader}
      direction={FlexBoxDirection.Row}
      justifyContent={FlexBoxJustifyContent.Center}
      alignItems={FlexBoxAlignItems.Center}
    >
      <b>{title}</b>
    </FlexBox>
    {subTitles && subTitles.length > 0 && (
      <FlexBox
        className={styles.tableMultiColumnItems}
        direction={FlexBoxDirection.Row}
        justifyContent={FlexBoxJustifyContent.End}
      >
        {subTitles?.map((subTitle) => (
          <FlexBox
            key={subTitle}
            className={styles.tableMultiColumnItem}
            direction={FlexBoxDirection.Row}
            justifyContent={FlexBoxJustifyContent.Center}
            alignItems={FlexBoxAlignItems.Center}
          >
            <span>{subTitle}</span>
          </FlexBox>
        ))}
      </FlexBox>
    )}
  </FlexBox>
)
CenterAlignedMultiColumn.propTypes = {
  title: PropTypes.string,
  subTitles: PropTypes.arrayOf(PropTypes.string),
}
