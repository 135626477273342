import get from 'lodash.get'
import PropTypes from 'prop-types'
import { useCallback, useMemo } from 'react'

import {
  useAreaMeasurementUnitFormatter,
  useCurrencyFormatter,
  useNumberFormatter,
} from 'hooks/i18n/useI18n'
import useGroupedChartData from 'hooks/services/properties/kpis/charts/useGroupedChartData'

const dataProviderPropTypes = {
  valuations: PropTypes.arrayOf(PropTypes.object).isRequired,
  rentRollKpis: PropTypes.arrayOf(PropTypes.object).isRequired,
  getNameFromKpi: PropTypes.func.isRequired,
  useColors: PropTypes.func.isRequired,
}

const createKpiCurrencyValueProvider = (fieldName) => {
  const Provider = ({ rentRollKpis, getNameFromKpi, useColors, children }) => {
    const { chartData, currency } = useMemo(
      () => ({
        chartData: rentRollKpis.map((rentRollKpi) => ({
          name: getNameFromKpi(rentRollKpi),
          value: get(rentRollKpi, [fieldName, 'number'], 0),
        })),
        currency: get(rentRollKpis?.[0], [fieldName, 'currency']),
      }),
      [getNameFromKpi, rentRollKpis],
    )

    const groupedChartData = useGroupedChartData(chartData, useColors)

    const valueFormatter = useCurrencyFormatter({ currency })

    return children(groupedChartData, valueFormatter)
  }

  Provider.propTypes = dataProviderPropTypes

  return Provider
}

const createKpiAreaValueProvider = (fieldName) => {
  const Provider = ({ rentRollKpis, getNameFromKpi, useColors, children }) => {
    const formatNumber = useNumberFormatter({ maximumFractionDigits: 2, minimumFractionDigits: 2 })
    const formatAreaUnit = useAreaMeasurementUnitFormatter()

    const { chartData, areaUnit } = useMemo(
      () => ({
        chartData: rentRollKpis.map((rentRollKpi) => ({
          name: getNameFromKpi(rentRollKpi),
          value: get(rentRollKpi, [fieldName, 'value'], 0),
        })),
        areaUnit: get(rentRollKpis, ['0', fieldName, 'measurementUnit']),
      }),
      [getNameFromKpi, rentRollKpis],
    )

    const groupedChartData = useGroupedChartData(chartData, useColors)

    const valueFormatter = useCallback(
      (value) => `${formatNumber(value)} ${formatAreaUnit(areaUnit)}`,
      [areaUnit, formatAreaUnit, formatNumber],
    )

    return children(groupedChartData, valueFormatter)
  }

  Provider.propTypes = dataProviderPropTypes

  return Provider
}

const createValuationsCurrencyValueProvider = (fieldName) => {
  const Provider = ({ valuations, useColors, children }) => {
    const { chartData, currency } = useMemo(
      () => ({
        chartData: valuations.map((valuation) => ({
          name: valuation.displayName,
          value: get(valuation, ['valuations', fieldName, 'number'], 0),
        })),
        currency: get(valuations, ['0', 'valuations', fieldName, 'currency']),
      }),
      [valuations],
    )

    const groupedChartData = useGroupedChartData(chartData, useColors)

    const valueFormatter = useCurrencyFormatter({ currency })

    return children(groupedChartData, valueFormatter)
  }

  Provider.propTypes = dataProviderPropTypes

  return Provider
}

const CurrentRentalIncomeProvider = createKpiCurrencyValueProvider('annualizedCurrentRent')

const LettableAreaProvider = createKpiAreaValueProvider('totalAreaSurface')

const MarketValueProvider = createValuationsCurrencyValueProvider('marketValue')

const MortgageLendingValueProvider = createValuationsCurrencyValueProvider('mortgageLendingValue')

const PurchasePriceProvider = createValuationsCurrencyValueProvider('purchasePrice')

const VacantAreaProvider = createKpiAreaValueProvider('vacancySurface')

const LetAreaProvider = createKpiAreaValueProvider('letSurface')

const ContractedRentProvider = createKpiCurrencyValueProvider('annualizedContractedRent')

const TotalMarketRentProvider = createKpiCurrencyValueProvider('totalMarketRent')

const LetMarketRentProvider = createKpiCurrencyValueProvider('letMarketRent')

export {
  createKpiAreaValueProvider,
  createKpiCurrencyValueProvider,
  createValuationsCurrencyValueProvider,
  CurrentRentalIncomeProvider,
  LettableAreaProvider,
  MarketValueProvider,
  MortgageLendingValueProvider,
  PurchasePriceProvider,
  VacantAreaProvider,
  LetAreaProvider,
  ContractedRentProvider,
  TotalMarketRentProvider,
  LetMarketRentProvider,
}
