import { Button, ButtonDesign, MessageBox, MessageBoxTypes } from '@fioneer/ui5-webcomponents-react'
import PropTypes from 'prop-types'
import { useCallback, useContext, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { propertyAllowedOperations } from 'api/property/propertyAllowedOperations'
import CreatePropertyEventAction from 'components/domains/properties/header-actions/CreatePropertyEventAction'
import PropertyValuations from 'components/domains/properties/valuation/PropertyValuations'
import EntityTypeAndIdWithClipboard from 'components/ui/entity-info/EntityTypeAndIdWithClipboard'
import UserFavoriteIcon from 'components/ui/user-favorite/UserFavoriteIcon'
import { cwpEntityTypes } from 'constants/cwpEntityTypes'
import useExportValuationInformation from 'hooks/services/properties/valuations/useExportValuationInformation'
import { PropertyContext } from 'routes/properties/PropertyContext'
import PropertyPage from 'routes/properties/PropertyPage'

const PropertyValuationsWrapper = ({ status, additionalStatuses, breadcrumbs }) => {
  const { property, allowedOperations } = useContext(PropertyContext)
  const { t } = useTranslation('translation', { keyPrefix: 'pages.property.valuation' })
  const [isDownloadInformationErrorDialogOpen, setIsDownloadInformationErrorDialogOpen] =
    useState(false)
  const { download } = useExportValuationInformation()

  const showValuationDownloadButton = useMemo(
    () =>
      allowedOperations.includes(propertyAllowedOperations.propertyRead) &&
      allowedOperations.includes(propertyAllowedOperations.propertyValuationRead),
    [allowedOperations],
  )

  const onValuationDownloadButtonClicked = useCallback(() => {
    download(
      { propertyUuid: property.uuid },
      {
        onSuccess: () => {},
        onError: () => {
          setIsDownloadInformationErrorDialogOpen(true)
        },
      },
    )
  }, [download, property])

  const onCloseDownloadInformationErrorDialog = useCallback(() => {
    setIsDownloadInformationErrorDialogOpen(false)
  }, [])

  const markFavoriteAction = useMemo(
    () => (
      <UserFavoriteIcon
        key="property-details-user-favorite-icon"
        entityId={property.uuid}
        entityType={cwpEntityTypes.PROPERTY}
      />
    ),
    [property.uuid],
  )

  const headerActions = useMemo(
    () => (
      <>
        <CreatePropertyEventAction key="create-event-action" />
        {showValuationDownloadButton && (
          <Button
            design={ButtonDesign.Default}
            onClick={onValuationDownloadButtonClicked}
            key="download"
          >
            {t('button.download')}
          </Button>
        )}
        {markFavoriteAction}
      </>
    ),
    [showValuationDownloadButton, onValuationDownloadButtonClicked, t, markFavoriteAction],
  )
  const subtitle = <EntityTypeAndIdWithClipboard id={property.id} />
  return (
    <>
      <PropertyPage
        pageTitle={property.description}
        id={property.id}
        subtitle={subtitle}
        breadcrumbs={breadcrumbs}
        status={status}
        additionalStatuses={additionalStatuses}
        actions={headerActions}
      >
        <PropertyValuations propertyId={property.uuid} />
      </PropertyPage>
      {isDownloadInformationErrorDialogOpen && (
        <MessageBox
          open={isDownloadInformationErrorDialogOpen}
          onClose={onCloseDownloadInformationErrorDialog}
          type={MessageBoxTypes.Error}
        >
          {t('download.error')}
        </MessageBox>
      )}
    </>
  )
}

PropertyValuationsWrapper.propTypes = {
  breadcrumbs: PropTypes.arrayOf(
    PropTypes.shape({
      text: PropTypes.string.isRequired,
      href: PropTypes.string,
    }),
  ).isRequired,
  status: PropTypes.shape({
    text: PropTypes.string.isRequired,
    valueState: PropTypes.string,
  }).isRequired,
  additionalStatuses: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      text: PropTypes.string.isRequired,
      valueState: PropTypes.string,
    }),
  ),
}

export default PropertyValuationsWrapper
