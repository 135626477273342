import { FlexBox, FlexBoxDirection, Input, Label } from '@fioneer/ui5-webcomponents-react'
import PropTypes from 'prop-types'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'

const SegmentNameInput = ({ name, setName, onValidationChange }) => {
  const { t } = useTranslation('translation', {
    keyPrefix: 'pages.rent-roll-working-version.segment-creation',
  })

  const [isValid, setIsValid] = useState(true)

  function validate(name) {
    const newIsValid = !!name
    setIsValid(newIsValid)
    onValidationChange(newIsValid)
  }

  function handleOnInput(e) {
    const name = e.target.value
    validate(name)
    setName(name)
  }

  return (
    <FlexBox direction={FlexBoxDirection.Column} style={{ gridColumn: 'span 4' }}>
      <Label required>{t('name')}</Label>
      <Input
        id="segment-name-input"
        valueState={isValid ? 'None' : 'Error'}
        className="sapUiTinyMarginTop"
        value={name}
        onInput={handleOnInput}
        type="Text"
      />
    </FlexBox>
  )
}
SegmentNameInput.propTypes = {
  name: PropTypes.string,
  setName: PropTypes.func.isRequired,
  onValidationChange: PropTypes.func.isRequired,
}
export default SegmentNameInput
