import { Label } from '@fioneer/ui5-webcomponents-react'
import PropTypes from 'prop-types'
import { useCallback, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'
import BusinessObjectSelectionInput from 'components/domains/conditions/business-object/BusinessObjectSelectionInput'
import CovenantCheckSelectionHelperDialog from 'components/domains/conditions/covenant-check-selection/CovenantCheckSelectionHelperDialog'
import styles from 'components/domains/conditions/overview/table-cells/conditions/ConditionsTableCovenantCheckSubcomponent.module.css'
import ConditionsCovenantCheckLink from 'components/domains/conditions/overview/table-cells/generic/ConditionsCovenantCheckLink'
import { ErrorDataUnavailableInCell } from 'components/ui/errors/ErrorDataUnavailableInCell'
import { RequestStateResolver } from 'components/ui/loading/RequestStateResolver'
import useConditionsCellEditMode from 'hooks/services/conditions/edit-mode/useConditionsCellEditMode'
import useCovenantCheckById from 'hooks/services/deals/covenants/useCovenantCheckById'
import {
  conditionCovenantCheckChanged,
  conditionRowHasError,
} from 'redux/slices/conditions/conditionsTableSlice'

const ConditionsTableCovenantCheckSubcomponent = ({ conditionId, covenantCheckId }) => {
  const { t } = useTranslation('translation', {
    keyPrefix: 'components.conditions.table.subcomponents.covenant-check',
  })
  const [isHelperDialogOpen, setIsHelperDialogOpen] = useState(false)
  const dispatch = useDispatch()

  const { isEditModeForCurrentRow } = useConditionsCellEditMode({
    conditionId,
  })
  const { isLoading, isError, data: covenantCheck } = useCovenantCheckById(covenantCheckId)
  const [selectedCovenantCheck, setSelectedCovenantCheck] = useState()

  useEffect(() => {
    setSelectedCovenantCheck(covenantCheck)
  }, [covenantCheck])

  useEffect(() => {
    if (isError) {
      dispatch(conditionRowHasError({ id: conditionId, error: { hasError: true } }))
    }
  }, [isError, dispatch, conditionId])

  const onClickCovenantCheckSelection = useCallback(() => {
    setIsHelperDialogOpen(true)
  }, [])

  const onCancelCovenantCheckSelection = useCallback(() => {
    setIsHelperDialogOpen(false)
  }, [])

  const onClearSelection = useCallback(() => {
    setSelectedCovenantCheck(undefined)
    dispatch(
      conditionCovenantCheckChanged({
        conditionId,
        covenantCheckId: null,
      }),
    )
  }, [dispatch, conditionId])

  const onSelectionChanged = useCallback(
    ({ covenantCheck: newCovenantCheck }) => {
      setSelectedCovenantCheck(newCovenantCheck)
      dispatch(
        conditionCovenantCheckChanged({
          conditionId,
          covenantCheckId: newCovenantCheck?.covenantCheckUuid,
        }),
      )
      setIsHelperDialogOpen(false)
    },
    [dispatch, conditionId],
  )

  const renderContent = useCallback(
    () => (
      <ConditionsCovenantCheckLink
        covenantCheckId={covenantCheckId}
        covenantCheckName={covenantCheck?.name}
      />
    ),
    [covenantCheck?.name, covenantCheckId],
  )

  if (isEditModeForCurrentRow) {
    return (
      <>
        <Label>{t('label')}</Label>
        <BusinessObjectSelectionInput
          name={selectedCovenantCheck?.name}
          onClick={onClickCovenantCheckSelection}
          onClear={onClearSelection}
          fallbackText={'covenant-fallback'}
        />
        <CovenantCheckSelectionHelperDialog
          isOpen={isHelperDialogOpen}
          onCancel={onCancelCovenantCheckSelection}
          onSelect={onSelectionChanged}
        />
      </>
    )
  }

  if (!covenantCheckId) {
    return (
      <>
        <Label className={styles.emptyTextPadding}>{t('label')}</Label>
      </>
    )
  }
  return (
    <>
      <Label>{t('label')}</Label>
      <RequestStateResolver
        isError={isError}
        isLoading={isLoading}
        errorToDisplay={<ErrorDataUnavailableInCell />}
        renderContent={renderContent}
      />
    </>
  )
}

ConditionsTableCovenantCheckSubcomponent.propTypes = {
  conditionId: PropTypes.string.isRequired,
  covenantCheckId: PropTypes.string,
}

export default ConditionsTableCovenantCheckSubcomponent
