import {
  CheckBox,
  FlexBox,
  FlexBoxAlignItems,
  FlexBoxDirection,
  FlexBoxJustifyContent,
  Icon,
  Input,
  Label,
  Modals,
  ResponsiveGridLayout,
  ToolbarSeparator,
  ValueState,
} from '@fioneer/ui5-webcomponents-react'
import PropTypes from 'prop-types'
import { forwardRef, useCallback, useEffect, useImperativeHandle, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import PropertyCreateAssignMarketInput from 'components/domains/properties/property-create/PropertyCreateAssignMarketInput'
import styles from 'components/domains/properties/property-create/PropertyCreateDialogBasics.module.css'
import { getGeoLocationOfCompleteAddress } from 'components/domains/util/AddressUtil'
import ExistingPropertyMessageBox from 'components/ui/feedback/ExistingPropertyInfoMessage'
import AddressAutocompleteInput from 'components/ui/input/AddressAutocompleteInput'
import FormattedNumberInput from 'components/ui/input/FormattedNumberInput'
import NumberInput from 'components/ui/input/NumberInput'
import LoadingSelect from 'components/ui/select/LoadingSelect'
import { useAreaMeasurementUnitFormatter } from 'hooks/i18n/useI18n'
import { useAreaUnitOfMeasureCodesNoPcs } from 'hooks/services/properties/useAreaUnitOfMeasureCodesNoPcs'
import { useCountryCodes } from 'hooks/services/properties/useCountryCodes'
import { useCurrencyCodes } from 'hooks/services/properties/useCurrencyCodes'
import { useTypecodes } from 'hooks/services/properties/useTypecodes'
import DealInput from 'routes/deals/collaterals/DealInput'
import {
  EXISTING_PROPERTY_INDICATOR,
  generatePropertyFilterObject,
  generatePropertyInfoMessage,
} from 'utils/propertyAddressCheck'
import { stringContainsNoSpecialCharacters } from 'utils/specialCharacters'

const PropertyCreateDialogBasics = forwardRef(
  (
    {
      property,
      propertiesWithSameAddressFilter,
      addressFilter,
      onChange,
      onChangeMany,
      onUpdateAddressFilter,
      deal = {},
    },
    ref,
  ) => {
    const {
      description,
      typeCode,
      countryCode,
      postalCode,
      cityName,
      streetName,
      houseNumber,
      currencyCode,
      areaMeasureUnitCode,
      dealId: _dealId,
      latitude,
      longitude,
      purchasePrice,
      marketValue,
      totalArea,
      marketName,
    } = property
    const { t } = useTranslation()
    const formatAreaUnit = useAreaMeasurementUnitFormatter()

    const [addressEditDisabled, setAddressEditDisabled] = useState(true)
    const [dirtyInputs, setDirtyInputs] = useState([])
    const [addressInputValue, setAddressInputValue] = useState()
    const [infoMessage, setInfoMessage] = useState(EXISTING_PROPERTY_INDICATOR.NONE)
    const messageContainerRef = useRef(null)
    const purchasePriceRef = useRef(null)
    const marketValueRef = useRef(null)
    const totalAreaRef = useRef(null)

    const generateInfoMessage = useCallback(
      ({ zipCode, country, street, city, houseId }) => {
        setInfoMessage(
          generatePropertyInfoMessage(
            zipCode,
            country,
            street,
            city,
            houseId,
            propertiesWithSameAddressFilter,
          ),
        )
      },
      [propertiesWithSameAddressFilter],
    )

    const triggerAddressCheck = useCallback(() => {
      generateInfoMessage(addressFilter)
    }, [addressFilter, generateInfoMessage])

    useEffect(() => {
      triggerAddressCheck()
    }, [addressFilter, propertiesWithSameAddressFilter, generateInfoMessage, triggerAddressCheck])

    const resetState = () => {
      setDirtyInputs([])
      setAddressEditDisabled(true)
      setAddressInputValue('')
      setInfoMessage(EXISTING_PROPERTY_INDICATOR.NONE)
    }

    useImperativeHandle(ref, () => ({
      resetState,
    }))

    const isDirty = (attribute) => {
      if (!attribute) {
        return false
      }
      return dirtyInputs.filter((item) => item === attribute).length > 0
    }

    const setPropertyAttribute = useCallback(
      (attribute, value, isValidInput) => {
        setDirtyInputs((prevDirtyInputs) => [...new Set([...prevDirtyInputs, attribute])])
        onChange(attribute, value, isValidInput)
      },
      [onChange],
    )

    const onBlur = useCallback((attribute) => {
      setDirtyInputs((prevDirtyInputs) => [...new Set([...prevDirtyInputs, attribute])])
    }, [])

    const toggleAddressEditDisabled = () => setAddressEditDisabled(!addressEditDisabled)

    const isEmpty = (value) => value === ''

    const isError = (attribute, attributeName) => isEmpty(attribute) && isDirty(attributeName)

    const isErrorOrNone = (attribute, attributeName) =>
      isError(attribute, attributeName) ? ValueState.Error : ValueState.None

    const isGreaterEqualZero = (value) => !isNaN(value) && Number(value) >= 0

    const isNegativeNumber = (attribute) => {
      if (isEmpty(attribute)) {
        return false
      } else {
        return !isGreaterEqualZero(attribute)
      }
    }
    const renderValueStateMessage = (message) => <span>{message}</span>

    const isNegativeNumberErrorOrNone = (attribute, attributeName) =>
      isNegativeNumber(attribute) && isDirty(attributeName) ? ValueState.Error : ValueState.None

    const isInvalidAddress = () =>
      addressEditDisabled && (isError(countryCode, 'countryCode') || isError(cityName, 'cityName'))

    const getValueStateAddress = (attribute) =>
      stringContainsNoSpecialCharacters(attribute) ? ValueState.None : ValueState.Error

    const getValueStateAddressMandatoryField = (attribute, attributeName) => {
      if (isError(attribute, attributeName)) {
        return ValueState.Error
      }
      return getValueStateAddress(attribute)
    }

    const getValueStateMessageAddress = (attribute, attributeName, emptyMessage) =>
      isError(attribute, attributeName)
        ? renderValueStateMessage(emptyMessage)
        : renderValueStateMessage(
            t('pages.property-create.basic-information.validation.no-special-char'),
          )

    const onAddressFieldUpdateHandler = (key, value) => {
      onUpdateAddressFilter(generatePropertyFilterObject(key, value, addressFilter))
      messageContainerRef?.current?.scrollIntoView()
    }

    const onAddressInput = (address) => {
      const geoLocation = getGeoLocationOfCompleteAddress(address, '')
      onChangeMany({
        cityName: address.city || '',
        postalCode: address.postcode || '',
        streetName: address.road || address.name || '',
        houseNumber: address.houseNumber || '',
        countryCode: address.countryCode || '',
        longitude: geoLocation.longitude,
        latitude: geoLocation.latitude,
      })
      setDirtyInputs([
        ...new Set([
          ...dirtyInputs,
          'cityName',
          'postalCode',
          'streetName',
          'houseNumber',
          'countryCode',
          'longitude',
          'latitude',
        ]),
      ])
      onAddressFieldUpdateHandler('address', address)
    }

    const showToast = Modals.useShowToast()

    const onDealSuggestionSelected = useCallback(
      ({ dealUuid = undefined, dealId = undefined }) => {
        setPropertyAttribute('dealUuid', dealUuid)
        setPropertyAttribute('dealId', dealId)
      },
      [setPropertyAttribute],
    )

    const isDealDataValid = !!Object.keys(deal).length

    return (
      <ResponsiveGridLayout
        columnsXL={8}
        columnsL={8}
        columnsM={4}
        columnsS={4}
        columnGap="10px"
        rowGap="10px"
      >
        <FlexBox direction={FlexBoxDirection.Column} className={styles.gridColumnSpan4}>
          <Label showColon required>
            {t('pages.property-create.basic-information.property-name')}
          </Label>
          <Input
            id="property-name"
            required
            value={description}
            maxlength={30}
            onChange={(e) => setPropertyAttribute('description', e.target.value)}
            onBlur={() => onBlur('description')}
            valueState={isErrorOrNone(description, 'description')}
            valueStateMessage={renderValueStateMessage(
              t('pages.property-create.basic-information.validation.property-name'),
            )}
          />
        </FlexBox>
        <FlexBox direction={FlexBoxDirection.Column} className={styles.gridColumnSpan4}>
          <Label showColon required>
            {t('pages.property-create.basic-information.property-type')}
          </Label>
          <LoadingSelect
            onChange={(e) => {
              setPropertyAttribute('typeCode', e.detail.selectedOption.dataset.id)
              if (property.marketId) {
                showToast({
                  children: t(
                    'pages.property-create.basic-information.market-assignment-change-message',
                  ),
                })
              }
              setPropertyAttribute('marketId', undefined, false)
              setPropertyAttribute('marketName', undefined, false)
            }}
            onBlur={() => onBlur('typeCode')}
            loadingHook={useTypecodes}
            selectionName="typecodes"
            selectedKey={typeCode}
            isErrorState={isError(typeCode, 'typeCode')}
            valueStateMessage={renderValueStateMessage(
              t('pages.property-create.basic-information.validation.property-type'),
            )}
          />
        </FlexBox>
        <FlexBox direction={FlexBoxDirection.Column} className={styles.gridColumnSpan4}>
          <Label showColon>{t('pages.property-create.basic-information.deal-id')}</Label>
          <DealInput
            onChange={onDealSuggestionSelected}
            value={
              isDealDataValid
                ? { id: deal.dealUuid, name: deal.dealName, displayId: deal.dealId }
                : undefined
            }
            isAllowedToEdit={!isDealDataValid}
            placeholder={''}
          />
        </FlexBox>
        <FlexBox direction={FlexBoxDirection.Column} className={styles.gridColumnSpan4}>
          <Label showColon required>
            {t('pages.property-create.currency')}
          </Label>
          <LoadingSelect
            onChange={(e) => {
              setPropertyAttribute('currencyCode', e.detail.selectedOption.dataset.id)
            }}
            onBlur={() => onBlur('currencyCode')}
            loadingHook={useCurrencyCodes}
            selectionName="currency_codes"
            selectedKey={currencyCode}
            isErrorState={isError(currencyCode, 'currencyCode')}
            valueStateMessage={renderValueStateMessage(
              t('pages.property-create.basic-information.validation.currency'),
            )}
          />
        </FlexBox>
        <FlexBox direction={FlexBoxDirection.Column} className={styles.gridColumnSpan4}>
          <Label showColon required>
            {t('pages.property-create.basic-information.area-measure-unit')}
          </Label>
          <LoadingSelect
            onChange={(e) => {
              setPropertyAttribute('areaMeasureUnitCode', e.detail.selectedOption.dataset.id)
            }}
            onBlur={() => onBlur('areaMeasureUnitCode')}
            loadingHook={useAreaUnitOfMeasureCodesNoPcs}
            selectedKey={areaMeasureUnitCode}
            optionDisplayName={'key'}
            formatDisplayName={formatAreaUnit}
            isErrorState={isError(areaMeasureUnitCode, 'areaMeasureUnitCode')}
            valueStateMessage={renderValueStateMessage(
              t('pages.property-create.basic-information.validation.area-measure-unit'),
            )}
          />
        </FlexBox>
        <FlexBox direction={FlexBoxDirection.Column} className={styles.gridColumnSpan4}>
          <Label showColon>{t('pages.property-create.basic-information.purchase-price')}</Label>
          <FormattedNumberInput
            icon={<Label style={{ color: 'var(--sapContent_LabelColor)' }}>{currencyCode}</Label>}
            id="property-purchase-price"
            required
            value={purchasePrice}
            onChange={(parsedNumericValue) => {
              setPropertyAttribute(
                'purchasePrice',
                parsedNumericValue || '',
                purchasePriceRef?.current?.isValid,
              )
            }}
            valueState={isNegativeNumberErrorOrNone(purchasePrice, 'purchasePrice')}
            valueStateMessage={renderValueStateMessage(
              t('pages.property-create.basic-information.validation.purchase-price'),
            )}
            ref={purchasePriceRef}
          />
        </FlexBox>
        <FlexBox direction={FlexBoxDirection.Column} className={styles.gridColumnSpan4}>
          <Label showColon>{t('pages.property-create.basic-information.market-value')}</Label>
          <FormattedNumberInput
            icon={<Label style={{ color: 'var(--sapContent_LabelColor)' }}>{currencyCode}</Label>}
            id="property-market-value"
            required
            value={marketValue}
            onChange={(parsedNumericValue) => {
              setPropertyAttribute(
                'marketValue',
                parsedNumericValue || '',
                marketValueRef?.current?.isValid,
              )
            }}
            valueState={isNegativeNumberErrorOrNone(marketValue, 'marketValue')}
            valueStateMessage={renderValueStateMessage(
              t('pages.property-create.basic-information.validation.market-value'),
            )}
            ref={marketValueRef}
          />
        </FlexBox>
        <FlexBox direction={FlexBoxDirection.Column} className={styles.gridColumnSpan4}>
          <Label showColon>{t('pages.property-create.basic-information.total-area')}</Label>
          <FormattedNumberInput
            icon={
              <Label style={{ color: 'var(--sapContent_LabelColor)' }}>
                {formatAreaUnit(areaMeasureUnitCode)}
              </Label>
            }
            id="property-total-area"
            required
            value={totalArea}
            onChange={(parsedNumericValue) =>
              setPropertyAttribute(
                'totalArea',
                parsedNumericValue || '',
                totalAreaRef?.current?.isValid,
              )
            }
            valueState={isNegativeNumberErrorOrNone(totalArea, 'totalArea')}
            valueStateMessage={renderValueStateMessage(
              t('pages.property-create.basic-information.validation.total-area'),
            )}
            ref={totalAreaRef}
          />
        </FlexBox>
        <FlexBox direction={FlexBoxDirection.Column} className={styles.gridColumnSpan8}>
          <ToolbarSeparator className={styles.horizontalSeparator} />
        </FlexBox>
        <FlexBox direction={FlexBoxDirection.Column} className={styles.gridColumnSpan5}>
          <Label showColon>{t('pages.property-create.basic-information.search')}</Label>
          <AddressAutocompleteInput
            id="property-address-autocomplete"
            icon={<Icon name="search" />}
            suggestionItemProps={{ icon: 'map' }}
            onAddressSelected={onAddressInput}
            placeholder={t('pages.property-create.basic-information.search.placeholder')}
            value={addressInputValue}
            onInput={(e) => setAddressInputValue(e.target.value)}
          />
        </FlexBox>
        <FlexBox
          direction={FlexBoxDirection.Column}
          alignItems={FlexBoxAlignItems.End}
          justifyContent={FlexBoxJustifyContent.End}
          className={styles.gridColumnSpan3}
        >
          <CheckBox
            id="edit-manually-checkbox"
            onChange={toggleAddressEditDisabled}
            checked={!addressEditDisabled}
            valueState={isInvalidAddress() ? ValueState.Error : ValueState.None}
            text={t('pages.property-create.basic-information.edit-manually')}
          />
        </FlexBox>

        <FlexBox direction={FlexBoxDirection.Column} className={styles.gridColumnSpan8}>
          <Label showColon required>
            {t('pages.property-create.basic-information.country')}
          </Label>
          <LoadingSelect
            onChange={(e) => {
              setPropertyAttribute('countryCode', e.detail.selectedOption.dataset.id)
              onAddressFieldUpdateHandler('countryCode', e.detail.selectedOption.dataset.id)
              if (property.marketId) {
                showToast({
                  children: t(
                    'pages.property-create.basic-information.market-assignment-change-message',
                  ),
                })
              }
              setPropertyAttribute('marketId', undefined, false)
              setPropertyAttribute('marketName', undefined, false)
            }}
            onBlur={() => onBlur('countryCode')}
            loadingHook={useCountryCodes}
            selectionName="country_codes"
            selectedKey={countryCode}
            isErrorState={isError(countryCode, 'countryCode')}
            disabled={addressEditDisabled}
            valueStateMessage={renderValueStateMessage(
              t('pages.property-create.basic-information.validation.country'),
            )}
          />
        </FlexBox>

        <FlexBox direction={FlexBoxDirection.Column} className={styles.gridColumnSpan2}>
          <Label showColon required>
            {t('pages.property-create.basic-information.postal-code')}
          </Label>
          <Input
            id="property-postal-code"
            value={postalCode}
            required
            onChange={(e) => {
              setPropertyAttribute('postalCode', e.target.value)
              onAddressFieldUpdateHandler('postalCode', e.target.value)
            }}
            onBlur={() => onBlur('postalCode')}
            disabled={addressEditDisabled}
            valueState={getValueStateAddressMandatoryField(postalCode, 'postalCode')}
            valueStateMessage={getValueStateMessageAddress(
              postalCode,
              'postalCode',
              t('pages.property-create.basic-information.validation.postal-code'),
            )}
          />
        </FlexBox>

        <FlexBox direction={FlexBoxDirection.Column} className={styles.gridColumnSpan6}>
          <Label showColon required>
            {t('pages.property-create.basic-information.city')}
          </Label>
          <AddressAutocompleteInput
            id="property-city"
            onChange={(e) => {
              setPropertyAttribute('cityName', e.target.value)
              onAddressFieldUpdateHandler('cityName', e.target.value)
            }}
            onBlur={() => onBlur('cityName')}
            value={cityName}
            onAddressSelected={({ name, city }) => setPropertyAttribute('cityName', city || name)}
            apiParams={{ tag: 'place:*', countrycodes: countryCode?.toLowerCase() }}
            formatAddress={({ name, city }) =>
              [name, city].filter((it) => it?.length > 0).join(', ')
            }
            disabled={addressEditDisabled}
            valueState={getValueStateAddressMandatoryField(cityName, 'cityName')}
            valueStateMessage={getValueStateMessageAddress(
              cityName,
              'cityName',
              t('pages.property-create.basic-information.validation.city-name'),
            )}
          />
        </FlexBox>

        <FlexBox direction={FlexBoxDirection.Column} className={styles.gridColumnSpan6}>
          <Label showColon>{t('pages.property-create.basic-information.street-name')}</Label>
          <Input
            id="property-street"
            value={streetName}
            onChange={(e) => {
              setPropertyAttribute('streetName', e.target.value)
              onAddressFieldUpdateHandler('streetName', e.target.value)
            }}
            valueState={getValueStateAddress(streetName)}
            valueStateMessage={renderValueStateMessage(
              t('pages.property-create.basic-information.validation.no-special-char'),
            )}
            disabled={addressEditDisabled}
          />
        </FlexBox>

        <FlexBox direction={FlexBoxDirection.Column} className={styles.gridColumnSpan2}>
          <Label showColon>{t('pages.property-create.basic-information.house-number')}</Label>
          <Input
            id="property-house-number"
            value={houseNumber}
            onChange={(e) => {
              setPropertyAttribute('houseNumber', e.target.value)
              onAddressFieldUpdateHandler('houseNumber', e.target.value)
            }}
            valueState={getValueStateAddress(houseNumber)}
            valueStateMessage={renderValueStateMessage(
              t('pages.property-create.basic-information.validation.no-special-char'),
            )}
            disabled={addressEditDisabled}
          />
        </FlexBox>

        <FlexBox direction={FlexBoxDirection.Column} className={styles.gridColumnSpan4}>
          <Label showColon>{t('pages.property-create.basic-information.latitude')}</Label>
          <NumberInput
            id="property-latitude"
            required
            value={latitude}
            onChange={(e) => {
              setPropertyAttribute('latitude', e.target.value)
              if (property.marketId) {
                showToast({
                  children: t(
                    'pages.property-create.basic-information.market-assignment-change-message',
                  ),
                })
              }
              setPropertyAttribute('marketId', undefined, false)
              setPropertyAttribute('marketName', undefined, false)
            }}
            valueState={ValueState.None}
            disabled={addressEditDisabled}
          />
        </FlexBox>

        <FlexBox direction={FlexBoxDirection.Column} className={styles.gridColumnSpan4}>
          <Label showColon>{t('pages.property-create.basic-information.longitude')}</Label>
          <NumberInput
            id="property-longitude"
            required
            value={longitude}
            onChange={(e) => {
              setPropertyAttribute('longitude', e.target.value)
              if (property.marketId) {
                showToast({
                  children: t(
                    'pages.property-create.basic-information.market-assignment-change-message',
                  ),
                })
              }
              setPropertyAttribute('marketId', undefined, false)
              setPropertyAttribute('marketName', undefined, false)
            }}
            valueState={ValueState.None}
            disabled={addressEditDisabled}
          />
        </FlexBox>
        <FlexBox direction={FlexBoxDirection.Column} className={styles.gridColumnSpan8}>
          <ToolbarSeparator className={styles.horizontalSeparator} />
        </FlexBox>
        <PropertyCreateAssignMarketInput
          key={typeCode}
          propertyTypeCode={typeCode}
          countryCode={countryCode}
          latitude={latitude}
          longitude={longitude}
          onChange={(value) => {
            setPropertyAttribute('marketId', value?.marketId, true)
            setPropertyAttribute('marketName', value?.marketName, true)
          }}
          value={{ marketName }}
        />
        <FlexBox
          direction={FlexBoxDirection.Column}
          ref={messageContainerRef}
          className={styles.gridColumnSpan8}
        >
          <ExistingPropertyMessageBox infoMessage={infoMessage} />
        </FlexBox>
      </ResponsiveGridLayout>
    )
  },
)
PropertyCreateDialogBasics.displayName = 'PropertyCreateDialogBasics'
PropertyCreateDialogBasics.propTypes = {
  property: PropTypes.object.isRequired,
  propertiesWithSameAddressFilter: PropTypes.array.isRequired,
  addressFilter: PropTypes.shape({
    zipCode: PropTypes.string,
    country: PropTypes.string,
    street: PropTypes.string,
    city: PropTypes.string,
    houseId: PropTypes.string,
  }).isRequired,
  onChange: PropTypes.func.isRequired,
  onChangeMany: PropTypes.func.isRequired,
  deal: PropTypes.shape({
    dealName: PropTypes.string,
    dealUuid: PropTypes.string,
    dealId: PropTypes.string,
  }),
  onUpdateAddressFilter: PropTypes.func.isRequired,
}
export default PropertyCreateDialogBasics
