import { FlexBox, Icon, Link, ObjectStatus, ValueState } from '@fioneer/ui5-webcomponents-react'
import get from 'lodash.get'
import AnalyticalTableCell from 'components/domains/business-events-and-tasks/decision-paper/tiles/ops-memo/property/shared/AnalyticalTableCell'
import Labeled from 'components/domains/business-events-and-tasks/decision-paper/tiles/ops-memo/property/shared/Labeled'
import styles from 'components/domains/business-events-and-tasks/decision-paper/tiles/ops-memo/property/shared/TableCells.module.css'
import { getYearOrYears } from 'components/domains/business-events-and-tasks/decision-paper/tiles/shared/hooks/YearUnitUtil'

const renderCellWithGroupSize = (value, _rowData, _additionalData, { groupedRows }) => {
  const typeNameGroup = groupedRows?.find((group) => group.groupByVal === value)
  if (typeNameGroup?.isGrouped) {
    const groupSize = typeNameGroup.leafRows?.length
    return groupSize ? `${value} (${groupSize})` : value
  } else {
    return value
  }
}

const getGroupByValFromAggregatedCell = (cell) => cell?.row?.groupByVal
const getGroupByIdFromAggregatedCell = (cell) => cell?.row?.groupByID

const getGranularityValue = (groupById, groupByVal, multiPropertyKpis, subPortfolioKpis) => {
  let multiPropertyKpisValueGroupByVal
  switch (groupById) {
    case 'country':
      multiPropertyKpisValueGroupByVal = multiPropertyKpis?.countryRentRollKpis?.filter(
        (value) => value.countryName === groupByVal,
      )?.[0]
      break
    case 'city':
      multiPropertyKpisValueGroupByVal = multiPropertyKpis?.cityRentRollKpis?.filter(
        (value) => value.city === groupByVal,
      )?.[0]
      break
    case 'type_name':
      multiPropertyKpisValueGroupByVal = multiPropertyKpis?.propertyTypeRentRollKpis?.filter(
        (value) => value.propertyTypeName === groupByVal,
      )?.[0]
      break
    case 'sub_portfolio':
      multiPropertyKpisValueGroupByVal =
        groupByVal === 'No Data'
          ? subPortfolioKpis?.filter((value) => value.subPortfolio === null)?.[0]?.kpis
          : subPortfolioKpis?.filter((value) => value.subPortfolio === groupByVal)?.[0]?.kpis
      break
  }
  return multiPropertyKpisValueGroupByVal
}

export const DescriptionCell = (
  <AnalyticalTableCell aggregatedValue={renderCellWithGroupSize}>
    {({ description, id, typeName, propertyStatus }, { t }) => (
      <>
        <Link href={`/properties/${id}`} design="Emphasized">
          {description}
        </Link>
        <span>{id}</span>
        <Labeled
          label={t('table.columns.property-status')}
          showColon
          className={styles.extraTopMargin}
        >
          <span>
            <ObjectStatus
              inverted={
                propertyStatus?.valueState && propertyStatus?.valueState !== ValueState.None
              }
              state={propertyStatus?.valueState ?? ValueState.None}
            >
              {propertyStatus?.text ?? '-'}
            </ObjectStatus>
          </span>
        </Labeled>
        <Labeled
          label={t('table.columns.property-type')}
          showColon
          className={styles.extraTopMargin}
        />
        <span>{typeName}</span>
      </>
    )}
  </AnalyticalTableCell>
)

export const PropertyStatusCell = (
  <AnalyticalTableCell aggregatedValue={renderCellWithGroupSize}>{() => <></>}</AnalyticalTableCell>
)

export const PropertyTypeCell = (
  <AnalyticalTableCell aggregatedValue={renderCellWithGroupSize}>{() => <></>}</AnalyticalTableCell>
)

export const SubPortfolioCell = (
  <AnalyticalTableCell aggregatedValue={renderCellWithGroupSize}>{() => <></>}</AnalyticalTableCell>
)

const calculateShare = (value, sum) => {
  if (!value || !sum) {
    return 0
  }
  return value / sum
}

export const AllocatedLoanAmountCell = (
  <AnalyticalTableCell alignRight>
    {(
      {
        allocatedLoanAmount,
        allocatedLoanAmountHeadquarter,
        allocatedLoanAmountHeadquarterSum,
        releasePremium,
      },
      { t, formatCurrency, formatPercent },
    ) => (
      <>
        <span>
          {formatCurrency(
            allocatedLoanAmountHeadquarter?.number,
            allocatedLoanAmountHeadquarter?.currencyCode,
          ) || ''}
        </span>
        {allocatedLoanAmount?.currencyCode !== allocatedLoanAmountHeadquarter?.currencyCode && (
          <Labeled label={t('table.columns.original')} showColon>
            {formatCurrency(allocatedLoanAmount?.number, allocatedLoanAmount?.currencyCode) || '-'}
          </Labeled>
        )}
        {allocatedLoanAmountHeadquarter && (
          <Labeled label={t('table.columns.share')} showColon>
            {formatPercent(
              calculateShare(
                allocatedLoanAmountHeadquarter?.number,
                allocatedLoanAmountHeadquarterSum,
              ),
            )}
          </Labeled>
        )}
        {releasePremium !== null && (
          <Labeled label={t('table.columns.release-premium')} showColon>
            {formatPercent(releasePremium) || ''}
          </Labeled>
        )}
      </>
    )}
  </AnalyticalTableCell>
)

export const AddressCell = (
  <AnalyticalTableCell aggregatedValue={renderCellWithGroupSize}>
    {({ address }, { t }) => (
      <>
        <span>{address?.countryName}</span>
        <Labeled label={t('table.columns.postal-code')} showColon>
          {address?.postalCode}
        </Labeled>
        <Labeled label={t('table.columns.city')} showColon>
          {address?.cityName}
        </Labeled>
        <Labeled label={t('table.columns.street')} showColon>
          {address?.streetName}
        </Labeled>
        <Labeled label={t('table.columns.house-no')} showColon>
          {address?.houseId}
        </Labeled>
      </>
    )}
  </AnalyticalTableCell>
)

export const CountryCell = (
  <AnalyticalTableCell aggregatedValue={renderCellWithGroupSize}>{() => <></>}</AnalyticalTableCell>
)

export const CityCell = (
  <AnalyticalTableCell aggregatedValue={renderCellWithGroupSize}>{() => <></>}</AnalyticalTableCell>
)

export const MoneyValueCell = (
  <AnalyticalTableCell alignRight>
    {({ valuations }, { t, formatCurrency, formatPercent, classification }) => {
      const value = get(valuations, classification, null)
      const valueAmount = value?.valueAmount
      const originValueAmount = value?.originValueAmount
      const shares = value?.shares?.sum
      const isValidConversion = value?.isValidConversion
      return (
        valueAmount?.number > 0 && (
          <>
            {isValidConversion && (
              <span>{formatCurrency(valueAmount.number, valueAmount.currency)} </span>
            )}
            {originValueAmount?.number && (
              <FlexBox>
                <Labeled label={t('table.columns.original')} showColon>
                  {formatCurrency(originValueAmount.number, originValueAmount.currency)}
                </Labeled>
                {!isValidConversion && <Icon name="alert" className={styles.conversionErrorIcon} />}
              </FlexBox>
            )}
            <Labeled label={t('table.columns.share')} showColon>
              {formatPercent(shares)}
            </Labeled>
          </>
        )
      )
    }}
  </AnalyticalTableCell>
)

export const AggregatedMoneyValueCell = (
  <AnalyticalTableCell alignRight renderAggregateRegularly>
    {(
      { currencyCode },
      { t, formatCurrency, formatPercent, valuationSum, classification },
      { cell },
    ) => {
      const valuationByClassification = get(valuationSum, classification, null)
      return cell?.value ? (
        <>
          <b>{formatCurrency(cell.value, valuationByClassification?.currency || currencyCode)}</b>
          <Labeled label={t('table.columns.share')} showColon>
            {formatPercent(calculateShare(cell?.value, valuationByClassification?.number))}
          </Labeled>
        </>
      ) : (
        <></>
      )
    }}
  </AnalyticalTableCell>
)

export const LettableAreaCell = (
  <AnalyticalTableCell alignRight>
    {({ kpis }, { t, formatNumber, formatPercent, formatAreaUnit, multiPropertyKpis }) =>
      kpis?.totalAreaSurface?.value ? (
        <>
          <span>
            {formatNumber(kpis?.totalAreaSurface?.value)}&nbsp;
            {formatAreaUnit(kpis?.totalAreaSurface?.measurementUnit)}
          </span>
          <Labeled label={t('table.columns.share')} showColon>
            {formatPercent(
              kpis?.totalAreaSurface?.value / multiPropertyKpis?.totalAreaSurface?.value,
            )}
          </Labeled>
          <Labeled label={t('table.columns.rented')} showColon>
            {formatPercent(kpis?.letSurface?.percent)}
          </Labeled>
          <Labeled label={t('table.columns.number-of-units')} showColon>
            {formatNumber(kpis?.totalNumberOfUnits)}
          </Labeled>
        </>
      ) : (
        <></>
      )
    }
  </AnalyticalTableCell>
)

export const AggregatedLettableAreaCell = (
  <AnalyticalTableCell alignRight renderAggregateRegularly>
    {(
      { kpis },
      { t, formatNumber, formatPercent, formatAreaUnit, multiPropertyKpis },
      { cell },
    ) => {
      if (cell?.value) {
        return (
          <>
            <b>
              {formatNumber(cell.value)}&nbsp;
              {formatAreaUnit(kpis?.totalAreaSurface?.measurementUnit)}
            </b>
            <Labeled label={t('table.columns.share')} showColon>
              {formatPercent(cell.value / multiPropertyKpis?.totalAreaSurface?.value)}
            </Labeled>
          </>
        )
      } else {
        return <></>
      }
    }}
  </AnalyticalTableCell>
)

export const CurrentRentalIncomeCell = (
  <AnalyticalTableCell alignRight>
    {({ kpis }, { t, formatCurrency, formatPercent, multiPropertyKpis }) =>
      kpis?.annualizedCurrentRent?.number ? (
        <>
          <span>
            {formatCurrency(
              kpis?.annualizedCurrentRent?.number,
              kpis?.annualizedCurrentRent?.currency,
            )}
          </span>
          {kpis?.originalAnnualizedCurrentRent?.number && (
            <FlexBox>
              <Labeled label={t('table.columns.original')} showColon>
                {formatCurrency(
                  kpis?.originalAnnualizedCurrentRent?.number,
                  kpis?.originalAnnualizedCurrentRent?.currency,
                )}
              </Labeled>
            </FlexBox>
          )}
          <Labeled label={t('table.columns.share')} showColon>
            {formatPercent(
              kpis?.annualizedCurrentRent?.number /
                multiPropertyKpis?.annualizedCurrentRent?.number,
            )}
          </Labeled>
        </>
      ) : (
        <></>
      )
    }
  </AnalyticalTableCell>
)

export const AggregatedCurrentRentalIncomeDecisionPaperCell = (
  <AnalyticalTableCell alignRight renderAggregateRegularly>
    {(
      { _ },
      { t, formatCurrency, formatPercent, multiPropertyKpis, subPortfolioKpis },
      { cell },
    ) => {
      const groupByVal = getGroupByValFromAggregatedCell(cell)
      const groupById = getGroupByIdFromAggregatedCell(cell)
      const multiPropertyKpisValueGroupByVal = getGranularityValue(
        groupById,
        groupByVal,
        multiPropertyKpis,
        subPortfolioKpis,
      )
      return multiPropertyKpisValueGroupByVal?.annualizedCurrentRent?.number ? (
        <>
          <b>
            {formatCurrency(
              multiPropertyKpisValueGroupByVal?.annualizedCurrentRent?.number,
              multiPropertyKpisValueGroupByVal?.annualizedCurrentRent?.currency,
            )}
          </b>
          <Labeled label={t('table.columns.share')} showColon>
            {formatPercent(
              multiPropertyKpisValueGroupByVal?.annualizedCurrentRent?.number /
                multiPropertyKpis?.annualizedCurrentRent?.number,
            )}
          </Labeled>
        </>
      ) : null
    }}
  </AnalyticalTableCell>
)

export const MarketRentCell = (
  <AnalyticalTableCell alignRight>
    {({ kpis, tegovaRating }, { t, formatCurrency }) =>
      kpis?.totalMarketRent?.number || tegovaRating ? (
        <>
          <span>
            {kpis?.totalMarketRent?.number && kpis?.totalMarketRent?.currency
              ? formatCurrency(kpis?.totalMarketRent?.number, kpis?.totalMarketRent?.currency)
              : '-'}
          </span>
          {kpis?.originalTotalMarketRent?.number && (
            <FlexBox>
              <Labeled label={t('table.columns.original')} showColon>
                {formatCurrency(
                  kpis?.originalAnnualizedCurrentRent?.number,
                  kpis?.originalAnnualizedCurrentRent?.currency,
                )}
              </Labeled>
            </FlexBox>
          )}
          <Labeled label={t('table.columns.tegova-rating')} showColon>
            <span>{tegovaRating || '-'}</span>
          </Labeled>
          <span>{tegovaRating ? `- ${t(`rating.label.${tegovaRating}`)}` : ''}</span>
        </>
      ) : null
    }
  </AnalyticalTableCell>
)

export const AggregatedMarketRentDecisionPaperCell = (
  <AnalyticalTableCell alignRight renderAggregateRegularly>
    {({ _ }, { multiPropertyKpis, formatCurrency, subPortfolioKpis }, { cell }) => {
      const groupByVal = getGroupByValFromAggregatedCell(cell)
      const groupById = getGroupByIdFromAggregatedCell(cell)
      const multiPropertyKpisValueGroupByVal = getGranularityValue(
        groupById,
        groupByVal,
        multiPropertyKpis,
        subPortfolioKpis,
      )
      return multiPropertyKpisValueGroupByVal?.totalMarketRent?.number ? (
        <b>
          {formatCurrency(
            multiPropertyKpisValueGroupByVal?.totalMarketRent?.number,
            multiPropertyKpisValueGroupByVal?.totalMarketRent?.currency,
          )}
        </b>
      ) : null
    }}
  </AnalyticalTableCell>
)

export const WaultCell = (
  <AnalyticalTableCell alignRight>
    {({ kpis }, { t, formatWault }) =>
      kpis?.waultToBreakInYears ? (
        <>
          <span>{`${formatWault(kpis?.waultToBreakInYears)} ${getYearOrYears(
            t,
            kpis?.waultToBreakInYears,
          )}`}</span>
          <Labeled label={t('table.columns.wault-expiry')} showColon />
          <span>{`${formatWault(kpis?.waultToExpiryInYears)} ${getYearOrYears(
            t,
            kpis?.waultToExpiryInYears,
          )}`}</span>
        </>
      ) : (
        <></>
      )
    }
  </AnalyticalTableCell>
)
