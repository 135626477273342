import PropTypes from 'prop-types'
import { useMemo, useCallback } from 'react'
import { PieChart, Pie, Cell, Legend, Text, ResponsiveContainer, Tooltip } from 'recharts'
import styles from 'components/ui/charts/donut-chart/DonutChart.module.css'
import sharedChartStyles from 'components/ui/charts/shared-chart-styles.module.css'
import { usePercentageFormatter } from 'hooks/i18n/useI18n'

const donutChartPropTypes = {
  /** the data to display in the chart */
  data: PropTypes.arrayOf(
    PropTypes.shape({
      /** item donut slice background color */
      color: PropTypes.string.isRequired,
      /** item label text */
      name: PropTypes.string,
      /** relative share of the item compared to all items */
      value: PropTypes.number.isRequired,
    }),
  ).isRequired,
  /**
   * function that takes the data item value and returns a string that
   * is displayed in the tooltip of the item. If missing, no tooltip will be shown.
   */
  tooltipValueFormatter: PropTypes.func,
  /**
   * function that takes the data and returns a React element that
   * is displayed as tooltip If missing, no tooltip will be shown.
   */
  tooltipContent: PropTypes.func,
  /** props to apply to the chart, see https://recharts.org/en-US/api/PieChart */
  pieChartProps: PropTypes.object,
  /** props to apply to the "donut", see https://recharts.org/en-US/api/Pie */
  pieProps: PropTypes.object,
  /** props to apply to the individual slices, see https://recharts.org/en-US/api/Cell */
  cellProps: PropTypes.object,
  /** props to apply to the label next to a slice , see https://recharts.org/en-US/api/Cell */
  labelProps: PropTypes.object,
  /** props to apply to the text of a legend item, see https://developer.mozilla.org/en-US/docs/Web/HTML/Element/span, */
  legendTextProps: PropTypes.object,
}

/**
 * @param {PropTypes.InferProps<typeof donutChartPropTypes>} props
 */
const DonutChart = ({
  data,
  tooltipValueFormatter,
  tooltipContent,
  pieChartProps = {},
  pieProps = {},
  cellProps = {},
  labelProps = {},
  legendTextProps = {},
}) => {
  const formatPercent = usePercentageFormatter()

  const sumOfAllValues = useMemo(
    () => (data ?? []).reduce((currentSum, entry) => currentSum + entry.value, 0),
    [data],
  )

  const renderCellLabel = useCallback(
    ({ value, ...props }) => (
      <Text
        {...props}
        {...labelProps}
        className={[styles.cellLabel, labelProps?.className].join(' ')}
      >
        {formatPercent(value / sumOfAllValues)}
      </Text>
    ),
    [formatPercent, sumOfAllValues, labelProps],
  )

  const formatLegendText = useCallback(
    (value) => (
      <span
        {...legendTextProps}
        className={[styles.legendText, legendTextProps?.className].join(' ')}
      >
        {value}
      </span>
    ),
    [legendTextProps],
  )

  if (!(Array.isArray(data) && sumOfAllValues > 0)) {
    return null
  }

  const tooltipProps = tooltipValueFormatter
    ? { formatter: tooltipValueFormatter }
    : { content: tooltipContent }

  return (
    <ResponsiveContainer width="100%" aspect={1.25} minHeight={120} minWidth={150} maxHeight={400}>
      <PieChart
        {...pieChartProps}
        className={[styles.donutChart, sharedChartStyles.cwpChart, pieChartProps?.className].join(
          ' ',
        )}
      >
        <Pie
          dataKey="value"
          innerRadius="45%"
          outerRadius="75%"
          //setting minimum angle for displaying very small pieces of chart
          minAngle={7}
          label={renderCellLabel}
          labelLine={false}
          {...pieProps}
          data={data}
        >
          {data.map((entry) => (
            <Cell
              key={entry.name}
              fill={entry.color}
              {...cellProps}
              className={[styles.pieSlice, cellProps?.className].join(' ')}
            />
          ))}
        </Pie>
        {(tooltipValueFormatter || tooltipContent) && <Tooltip {...tooltipProps} />}
        <Legend iconType="square" formatter={formatLegendText} />
      </PieChart>
    </ResponsiveContainer>
  )
}

DonutChart.propTypes = donutChartPropTypes

export default DonutChart
