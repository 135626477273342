import PropTypes from 'prop-types'
import PropertyRentRollStructureOverviewTable from 'components/domains/properties/rent-roll/overview/structure-overview/PropertyRentRollStructureOverviewTable'
import { useSegmentAreaDetailsTableColumns } from 'hooks/services/properties/segments/area-details/useSegmentAreaDetailsTableColumns'
import { useSegmentAreaDetailsTableRows } from 'hooks/services/properties/segments/area-details/useSegmentAreaDetailsTableRows'

const PropertyRentRollOverviewAreaDetailsTable = ({ property, segments, hasPermission }) => {
  const columnDefinitions = useSegmentAreaDetailsTableColumns()
  const {
    isError: isError,
    isErrorAllSegmentsPublished: isErrorAllSegmentsPublished,
    data: tableData,
  } = useSegmentAreaDetailsTableRows(property, segments)
  return (
    <PropertyRentRollStructureOverviewTable
      id={'property-rent-roll-segments-area-details-table'}
      isError={isError}
      isErrorAllSegmentsPublished={isErrorAllSegmentsPublished}
      columnDefinitions={columnDefinitions}
      tableData={tableData}
      hasPermission={hasPermission}
    />
  )
}
PropertyRentRollOverviewAreaDetailsTable.propTypes = {
  property: PropTypes.object.isRequired,
  segments: PropTypes.arrayOf(PropTypes.object).isRequired,
  hasPermission: PropTypes.bool.isRequired,
}
export default PropertyRentRollOverviewAreaDetailsTable
